export const ERROR_500_BUTTON = {
  "en": "Back to Home",
  "fr": "Retourner à l’accueil",
  "de": "Zurück zur Startseite",
  "es": "Volver a Inicio",
  "pt-br": "Back to Home",
  "it": "Back to Home",
  "ja": "Back to Home",
  "ko": "Back to Home",
  "nl": "Back to Home",
  "pt-pt": "Back to Home",
  "sv": "Back to Home",
  "zh": "Back to Home"
}