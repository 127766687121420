const GetEditorBlock = () => {
  return `
      __typename
      id: clientId
      parentClientId
      name
    `;
};

export default GetEditorBlock;
