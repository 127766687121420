export const locales: { [key: string]: string } = {
  pt_BR: "pt-br",
  es_ES: "es",
  de_DE: "de",
  fr_FR: "fr",
  en_US: "en",
  it_IT: "it",
  ja_JA: "ja",
  ko_KO: "ko",
  nl_NL: "nl",
  pt_PT: "pt-pt",
  sv_SE: "sv",
  zh_CN: "zh",
};
