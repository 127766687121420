export const BUSINESS_TRIAL_SUCCESS_DISCLAIMER_RESEND = {
  "en": "resend the email",
  "fr": "Renvoyer l'e-mail",
  "de": "E-Mail erneut senden",
  "es": "volver a enviar el correo electrónico",
  "pt-br": "resend the email",
  "it": "resend the email",
  "ja": "resend the email",
  "ko": "resend the email",
  "nl": "resend the email",
  "pt-pt": "resend the email",
  "sv": "resend the email",
  "zh": "resend the email"
}