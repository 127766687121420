export type I18nVariables = Readonly<Record<string, string>>;
/**
 * Injects variables into an i18n string.
 *
 * Replaces:
 * - `"%(variableName)"` => `variables[variableName]`
 *
 * @param rawString Raw string from the i18n translation
 * @param variables Object who's key=>value pairs are variableName=>value
 * @see https://github.com/martinandert/counterpart#interpolation
 */
const interpolateI18nString = (
  rawString: string,
  variables?: I18nVariables
) => {
  if (!rawString.match(/%\([\w\d]+\)/)) {
    return rawString;
  }

  let interpolatedString = rawString;
  for (const varName in variables) {
    if (interpolatedString.includes(`%(${varName})`)) {
      if (variables) {
        interpolatedString = interpolatedString.replace(
          `%(${varName})`,
          // @ts-ignore
          variables[varName]
        );
      }
    }
  }

  return interpolatedString;
};

/**
 * Injects variables into a legacy handlebars i18n string.
 *
 * Replaces:
 * - `"{{variableName}}"` => `${variables[variableName]}`
 *
 * @param rawString Raw string from the i18n translation
 * @param variables Object who's key=>value pairs are variableName=>value
 * @deprecated Remove when we no longer have handlebars templating in I18n keys.
 */
const interpolateHandlebarsI18nString = (
  rawString: string,
  variables?: I18nVariables
) => {
  if (!rawString.match(/{{[\w\d.]+}}/)) {
    return rawString;
  }

  let interpolatedString = rawString;
  for (const varName in variables) {
    if (interpolatedString.includes(`{{${varName}}}`)) {
      if (variables) {
        interpolatedString = interpolatedString.replace(
          `{{${varName}}}`,
          // @ts-ignore
          variables[varName]
        );
      }
    }
  }

  return interpolatedString;
};

/**
 * Translate a given I18n key for a specific language, and optionally interpolate provided variables
 *
 * @param rawTranslation I18n value
 * @param variables Optional variables to inject into translated string
 */
export const interpolateTranslatedString = (
  rawTranslation: string,
  variables?: I18nVariables
) => {
  if (variables) {
    let interpolatedTranslation = rawTranslation;
    interpolatedTranslation = interpolateI18nString(
      interpolatedTranslation,
      variables
    );

    // Handle the legacy i18n keys with handlebars
    interpolatedTranslation = interpolateHandlebarsI18nString(
      interpolatedTranslation,
      variables
    );

    return interpolatedTranslation;
  }

  return rawTranslation;
};
