import { useEffect, useRef, useState } from "react";

interface InViewOptions {
  root?: HTMLElement | null;
  rootMargin?: string;
  threshold?: number | number[];
}

interface InViewResult {
  inView: boolean;
  entry: IntersectionObserverEntry | null;
}

const defaultOptions: InViewOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.5, // Adjust this threshold as needed
};

export function useInView(
  options: InViewOptions = defaultOptions
): [React.MutableRefObject<null>, InViewResult] {
  const [inView, setInView] = useState(false);
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
  const targetRef = useRef<null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [firstEntry] = entries;
        if (firstEntry) {
          setInView(firstEntry.isIntersecting);
          setEntry(firstEntry);
        }
      },
      { ...defaultOptions, ...options }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [options]);

  return [targetRef, { inView, entry }];
}

export default useInView;
