import { locales } from "@/constants/locales";
import { Locale, Translated } from "@/types";

interface Props {
  availableLocales?: Translated[];
  locale?: Locale;
  uri?: string;
  isFrontPage?: boolean;
}

export default function Hreflang({
  availableLocales,
  locale,
  uri,
  isFrontPage,
}: Props) {
  if (!availableLocales || !availableLocales.length) return null;

  let currentUrl = process.env["NEXT_PUBLIC_SITE_URL"];

  if (isFrontPage) {
    if (locale === "en") {
      currentUrl += "";
    } else {
      currentUrl += `/${locale}`;
    }
  } else {
    // @ts-ignore
    currentUrl += uri;
  }

  return (
    <>
      <link rel="alternate" hrefLang={locale} href={currentUrl} />
      {availableLocales.map((each) => {
        // Get language from the uri
        const language = each.language?.locale
          ? locales[each.language?.locale]
          : false;

        if (!language) return null;

        return (
          <link
            key={language}
            rel="alternate"
            hrefLang={language}
            href={
              process.env["NEXT_PUBLIC_SITE_URL"] + each.uri.replace(/\/$/, "")
            }
          />
        );
      })}
    </>
  );
}
