import { useState, useEffect, useRef } from "react";
import cn from "classnames";
import BlockContent from "../molecules/BlockContent";
import { RichText } from "@/components/atoms";
import { de, en, Faker, fr, ko } from "@faker-js/faker";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { CustomImageInterface } from "../molecules/CustomImage";
import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  h1TextLarge,
  h1Text,
  textBodyInverse,
  pTextSm,
  pTextL,
  textBodyNeutral,
  h2Text,
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import { useTranslate } from "@/hooks/useTranslate";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import { iconPlus, iconClose } from "@/constants/icons";
import { Slider } from "@nextui-org/slider";
import { leetSpeakGenerator } from "@/utilities/password-generator/leetSpeakGenerator";
import { generate } from "@/utilities/password-generator/generate";
import getPasswordGeneratorModalCookieName from "@/utilities/getPasswordGeneratorModalCookieName";
import { getCookie, setCookie } from "@/utilities/cookies";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeContent?: boolean;
  includeModal: boolean;
  aboveHeading?: string;
  heading?: string;
  headingSize?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  size?: string;
  inputPlaceholder?: string;
  icon: IconInterface;
  belowButtons?: string;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  checkboxGeneratorOne?: string;
  checkboxGeneratorTwo?: string;
  checkboxGeneratorThree?: string;
  usernameLengthText?: string;
  contentType: string;
  paddingTop?: string;
  paddingBottom?: string;
  buttonThree?: CustomButtonInterface;
  modalHeading?: string;
  modalContent?: string;
  image?: CustomImageInterface;
  media?: MediaObjectInterface;
  ga4SectionId?: string;
}

const MAX_SLIDER_LENGTH = 40;
const MIN_SLIDER_LENGTH = 4;
const MAX_LENGTH = 12;
const MIN_LENGTH = 3;

const langMap = {
  de,
  en,
  es: en,
  fr,
  it: en,
  ja: en,
  ko,
  nl: en,
  "pt-br": en,
  "pt-pt": en,
  sv: en,
  zh: en,
};

export default function UsernameGeneratorBlock({
  includeAboveHeading = false,
  includeBelowButtons = false,
  includeContent = false,
  includeModal = false,
  aboveHeading = "",
  heading = "",
  headingSize = "lg",
  headingOptions = {
    tag: 1,
  },
  content = "",
  variant = "dark",
  size = "lg",
  inputPlaceholder,
  icon = {
    source: iconPlus,
  },
  belowButtons = "",
  buttonOne,
  buttonTwo,
  checkboxGeneratorOne,
  checkboxGeneratorTwo,
  checkboxGeneratorThree,
  usernameLengthText,
  contentType,
  paddingTop,
  paddingBottom,
  buttonThree,
  modalHeading,
  modalContent,
  image = {
    full: "",
    alt: "",
  },
  media = {
    type: "image",
    media: {},
  },
  ga4SectionId = "",
}: Props) {
  const isFirstRender = useRef(true);
  const { locale } = useTranslate();
  const faker = new Faker({ locale: langMap[locale] });

  function generateKeyword() {
    return faker.word
      .sample({ length: { min: MIN_LENGTH, max: MAX_LENGTH } })
      .toLowerCase();
  }

  const [usernameLength, setUsernameLength] = useState<number>(20);
  const [generatedKeyword, setGeneratedKeyword] = useState("");
  const [generatedUsername, setGeneratedUsername] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [withRandomChars, setWithRandomChars] = useState(false);
  const [withKeywordAtEnd, setWithKeywordAtEnd] = useState(false);
  const [withLeetSpeak, setWithLeetSpeak] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const cookieName = getPasswordGeneratorModalCookieName("username");

  const wasModalTriggered = () => {
    const seen = getCookie(cookieName);

    if (!seen || seen === undefined) {
      return false;
    }

    return true;
  };

  const dismiss = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setCookie(cookieName, "true", tomorrow.toUTCString());

    setIsOpen(false);
  };

  function generateUsername(keyword: string, length: number | number[]) {
    if (typeof length !== "number") return;

    let username = keyword;
    if (withRandomChars) {
      const randomChars = generate({
        length:
          length - username.length > 4 ? length - username.length : length,
        digits: true,
        letters: true,
        symbols: true,
        avoidAmbiguous: true,
      });
      username += randomChars;
    } else {
      while (username.length < length) {
        const availableWordLength = length - username.length;
        const min = Math.max(
          MIN_LENGTH,
          availableWordLength < MAX_LENGTH ? availableWordLength : MAX_LENGTH
        );
        const max = Math.min(
          MAX_LENGTH,
          availableWordLength > MIN_LENGTH ? availableWordLength : MIN_LENGTH
        );
        const wordLength = faker.number.int({
          min,
          max,
        });
        const remainingLength = availableWordLength - wordLength;
        const word = faker.word.sample(
          remainingLength < MIN_LENGTH
            ? wordLength + remainingLength
            : wordLength
        );
        if (username.length + word.length > length) {
          const lastWordLength = availableWordLength;
          const lastWord = faker.word.sample(lastWordLength);
          username += lastWord;
        } else {
          username += word;
        }
      }
    }
    // fr lang puts spaces between some words
    username = username.split(" ").join("-");
    setGeneratedUsername(
      withKeywordAtEnd
        ? `${username.slice(keyword.length)}${keyword}`
        : username
    );
  }

  function copyUsername() {
    const TEXT_COPIED_TIMEOUT = 2000;
    void navigator.clipboard.writeText(
      withLeetSpeak
        ? leetSpeakGenerator(generatedUsername)
        : generatedUsername || generatedKeyword
    );
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, TEXT_COPIED_TIMEOUT);

    if (!wasModalTriggered()) {
      setIsOpen(true);
    }
  }

  useEffect(() => {
    setGeneratedKeyword(generateKeyword());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    generateUsername(generatedKeyword, usernameLength);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usernameLength, withKeywordAtEnd, withRandomChars]);

  const headingClassNames = cn(marginBMd, {
    [h1TextLarge]: size === "lg" && headingSize === "lg",
    [h1Text]:
      size === "md" || size === "sm" || (size === "lg" && headingSize === "md"),
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant === "light",
  });

  const belowTextClassNames = cn("mt-5", pTextSm, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const inputClassNames = cn(
    "bg-white rounded border border-grey-5 border-solid h-12 px-4 grow flex items-center text-blue-8 placeholder-blue-8 me-3 text-lg"
  );

  const sliderFillerClassNames = cn("rounded", {
    "bg-white": variant === "dark",
    "bg-blue-6": variant !== "dark",
  });

  const sliderTrackClassNames = cn("h-2", {
    "bg-[#ffffff66]": variant === "dark",
    "bg-blue-2": variant !== "dark",
  });

  const sliderThumbClassNames = cn("top-[44%] w-[36px] h-[36px]", {
    "bg-white": variant === "dark",
    "bg-blue-6": variant !== "dark",
  });

  const sliderLabelClassNames = cn("mb-4 uppercase", pTextSm, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const buttonContainerClassNames = cn("flex w-full items-end justify-end", {
    "mt-5": !includeBelowButtons,
  });

  const checkboxClassNames = cn("text-base my-1 mx-0", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const inputCheckboxClassNames = cn("w-5 h-5", {
    "accent-grey-3": variant === "dark",
    "accent-blue-6": variant !== "dark",
  });

  if (!icon.source) {
    icon.source = iconPlus;
  }

  const buttons: [
    CustomButtonInterface,
    CustomButtonInterface,
    CustomButtonInterface
  ] = [
    {
      ...buttonOne,
      size: "md",
      onClick: () => generateUsername(generatedKeyword, usernameLength),
      variant: variant === "dark" ? "white" : "dark",
    },
    {
      ...buttonTwo,
      size: "md",
      onClick: () => copyUsername(),
      disabled: isCopied,
      text: isCopied ? "Copied" : buttonTwo?.text,
      variant: variant === "dark" ? "dark" : "light",
    },
    {
      ...buttonThree,
      onClick: () => dismiss(),
      variant: "dark",
    },
  ];

  const modalClasses = cn(
    "relative mx-auto rounded overflow-hidden max-w-4xl bg-white h-full md:h-auto max-h-[100%] md:max-h-[90vh]"
  );

  const imageClassNames = cn(
    "h-full w-full object-cover rounded flex items-end"
  );

  const modalInnerClasses = cn(
    "flex flex-col-reverse	md:flex-row md:grid md:grid-cols-2 gap-0 items-center h-full md:h-auto"
  );

  const modalHeaderClassNames = cn(marginBMd, textHeadlineNeutral, h2Text);

  const pTextModalClassNames = cn("text-xl", textBodyNeutral);

  const closeButtonClasses = cn(
    "absolute right-4 top-4 z-10 h-6 w-6 [&>svg]:w-full",
    {
      "fill-grey-3": variant === "dark",
      "fill-grey-6": variant !== "dark",
    }
  );

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      constrain
      ga4SectionId={ga4SectionId}
    >
      <div
        className={`w-full lg:grid lg:grid-cols-1 lg:items-center lg:gap-20`}
      >
        <div>
          {includeAboveHeading && aboveHeading && (
            <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
          )}
          <CustomHeading
            heading={heading}
            headingClassNames={headingClassNames}
            headingOptions={headingOptions}
          />
          {includeContent && content && (
            <RichText
              className={cn("mb-10", {
                [textBodyInverse]: variant === "dark",
                [textBodyNeutral]: variant !== "dark",
                [pTextL]: size === "lg",
              })}
              tag="p"
            >
              {content}
            </RichText>
          )}
          <div className="flex w-full items-center justify-center">
            <input
              className={inputClassNames}
              placeholder={generatedKeyword}
              value={
                withLeetSpeak
                  ? leetSpeakGenerator(generatedUsername)
                  : generatedUsername ?? inputPlaceholder
              }
              maxLength={
                typeof usernameLength === "number" ? usernameLength : 20
              }
              onChange={(e) => {
                setGeneratedUsername(e.target.value);
                setGeneratedKeyword(e.target.value);
              }}
            />
            <button
              onClick={() => {
                setGeneratedKeyword(() => generateKeyword());
                setGeneratedUsername("");
              }}
            >
              <Icon {...icon} variant={variant} width={32} />
            </button>
          </div>
          {includeBelowButtons && (
            <div className={belowTextClassNames}>
              <RichText tag="small">{belowButtons}</RichText>
            </div>
          )}
          <div className={buttonContainerClassNames}>
            <CustomButtonGroup
              align="left"
              buttons={[buttons[0], buttons[1]]}
            />
          </div>
          <div className="pb-16 pt-8">
            <Slider
              color="foreground"
              label={`${usernameLengthText} (${usernameLength})`}
              maxValue={MAX_SLIDER_LENGTH}
              minValue={MIN_SLIDER_LENGTH}
              value={usernameLength}
              size="md"
              classNames={{
                track: sliderTrackClassNames,
                filler: sliderFillerClassNames,
                thumb: sliderThumbClassNames,
                label: sliderLabelClassNames,
              }}
              // eslint-disable-next-line react-hooks/exhaustive-deps
              onChange={(val: number | number[]) =>
                setUsernameLength(val as number)
              }
              hideValue={true}
            />
          </div>
          <div className="mb-10 flex gap-5">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                disabled={withLeetSpeak}
                checked={withRandomChars}
                className={inputCheckboxClassNames}
                onChange={() => setWithRandomChars((currentVal) => !currentVal)}
              />
              <div>
                <RichText className={checkboxClassNames} tag="span">
                  {checkboxGeneratorOne}
                </RichText>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={withKeywordAtEnd}
                className={inputCheckboxClassNames}
                onChange={() => {
                  setWithKeywordAtEnd((currentVal) => !currentVal);
                }}
              />
              <div>
                <RichText className={checkboxClassNames} tag="span">
                  {checkboxGeneratorTwo}
                </RichText>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                disabled={withRandomChars}
                checked={withLeetSpeak}
                className={inputCheckboxClassNames}
                onChange={() => setWithLeetSpeak((currentVal) => !currentVal)}
              />
              <div>
                <RichText className={checkboxClassNames} tag="span">
                  {checkboxGeneratorThree}
                </RichText>
              </div>
            </div>
          </div>
        </div>
        {includeModal && isOpen && (
          <dialog
            className="dialog fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/60 p-4"
            open={isOpen}
            onClick={() => dismiss()}
          >
            <div className={modalClasses} onClick={(e) => e.stopPropagation()}>
              <button
                className={closeButtonClasses}
                dangerouslySetInnerHTML={{ __html: iconClose }}
                onClick={() => dismiss()}
              />
              <div className={modalInnerClasses}>
                <div className="h-full w-full bg-blue-6 px-5 pt-6">
                  <div className={imageClassNames}>
                    <CustomMedia
                      className="h-full w-full"
                      imageClassName="!object-contain"
                      media={media ?? { media: {}, type: "image" }}
                      fallbackImage={image}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center gap-0 p-12">
                  <RichText className={modalHeaderClassNames} tag="h1">
                    {modalHeading}
                  </RichText>
                  <RichText className={pTextModalClassNames} tag="p">
                    {modalContent}
                  </RichText>
                  <CustomButtonGroup
                    className="mt-0"
                    align="left"
                    buttons={[buttons[2]]}
                  />
                </div>
              </div>
            </div>
          </dialog>
        )}
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupUsernameGenerator";

UsernameGeneratorBlock.displayName = BLOCK_NAME;

UsernameGeneratorBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          belowButtons
          buttonOne
          buttonTwo
          checkboxGeneratorOne
          checkboxGeneratorThree
          checkboxGeneratorTwo
          className
          content
          heading
          icon
          headingSize
          headingOptions
          includeAboveHeading
          includeBelowButtons
          includeContent
          inputPlaceholder
          metadata
          size
          usernameLengthText
          variant
          paddingTop
          paddingBottom
          buttonThree
          image
          media
          includeModal
          modalContent
          modalHeading
          ga4SectionId
        }
      }
    }
  `,
};
