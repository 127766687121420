import { Columns } from "@/components/atoms";
import { Blocks } from "@/components/molecules";
import type { Block, Style } from "@/types";
import getBlockStyles from "@/utilities/getBlockStyles";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  align?: "wide" | "full";
  anchor?: string;
  backgroundColor?: string;
  className?: string;
  gradient?: string;
  innerBlocks: Block[];
  isStackedOnMobile?: boolean;
  style?: Style;
  textColor?: string;
  verticalAlignment?: "top" | "center" | "bottom";
}

export default function ColumnsBlock({
  align,
  anchor,
  backgroundColor,
  className,
  gradient,
  innerBlocks,
  isStackedOnMobile,
  style,
  textColor,
  verticalAlignment,
}: Props) {
  const columnsStyle = getBlockStyles({
    backgroundColorHex: backgroundColor,
    gradientHex: gradient,
    textColorHex: textColor,
    style,
  });

  return (
    <Columns
      align={align}
      className={className}
      id={anchor}
      isStackedOnMobile={isStackedOnMobile}
      style={columnsStyle}
      verticalAlignment={verticalAlignment}
    >
      {!!innerBlocks.length && <Blocks blocks={innerBlocks} />}
    </Columns>
  );
}

const BLOCK_NAME = "CoreColumns";

ColumnsBlock.displayName = BLOCK_NAME;

ColumnsBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          align
          anchor
          backgroundColor
          borderColor
          className
          cssClassName
          fontFamily
          fontSize
          gradient
          isStackedOnMobile
          layout
          metadata
          style
          textColor
          verticalAlignment
        }
      }
    }
  `,
};
