import cn from "classnames";

import { Blocks } from "@/components/molecules";
import { Block, Style } from "@/types";
import getBlockStyles from "@/utilities/getBlockStyles";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor?: string;
  backgroundColor?: "white";
  className?: string;
  innerBlocks: Block[];
  style?: Style;
  verticalAlignment?: "top" | "center" | "bottom";
  definedWidth?: string;
}

export default function ColumnBlock({
  anchor,
  backgroundColor,
  className,
  innerBlocks,
  style,
  verticalAlignment,
  definedWidth,
}: Props) {
  const blockStyles = getBlockStyles({ style });

  return (
    <div
      className={cn(
        "flex w-full flex-col",
        verticalAlignment === "top" && "self-start",
        verticalAlignment === "center" && "self-center",
        verticalAlignment === "bottom" && "self-end",
        definedWidth === "25%" && "md:w-1/4",
        definedWidth === "33.33%" && "md:w-1/3",
        definedWidth === "50%" && "md:w-1/2",
        definedWidth === "66.66%" && "md:w-2/3",
        backgroundColor === "white" && "rounded-md bg-white p-8",
        style?.color?.background && "rounded-md p-8",
        className
      )}
      id={anchor}
      style={blockStyles}
    >
      {!!innerBlocks.length && <Blocks blocks={innerBlocks} />}
    </div>
  );
}

const BLOCK_NAME = "CoreColumn";

ColumnBlock.displayName = BLOCK_NAME;

ColumnBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          allowedBlocks
          anchor
          backgroundColor
          borderColor
          className
          cssClassName
          fontFamily
          fontSize
          gradient
          layout
          metadata
          style
          textColor
          verticalAlignment
          definedWidth: width
        }
      }
    }
  `,
};
