import React, { Children, PropsWithChildren, ReactElement } from 'react';
import cn from 'classnames';

interface Props {
  align?: 'wide' | 'full';
  className?: string;
  id?: string;
  isStackedOnMobile?: boolean;
  style?: {
    background?: string;
    backgroundColor?: string;
    color?: string;
  };
  verticalAlignment?: 'top' | 'center' | 'bottom';
}

export default function Columns({
  align,
  id,
  isStackedOnMobile = true,
  className,
  children,
  style,
  verticalAlignment,
}: PropsWithChildren<Props>) {
  return (
    <section
      className={cn(
        'flex items-center justify-center',
        align === 'wide' && 'w-full',
        align === 'full' && 'min-h-[80vh] w-full',
        align !== 'wide' && align !== 'full' && 'mx-auto max-w-7xl',
        align !== 'wide' &&
          align !== 'full' &&
          !className?.includes('p-0') &&
          'px-8',
        className
      )}
      style={style}
    >
      <div
        id={id}
        className={cn(
          'flex w-full gap-8',
          align === 'wide' && 'mx-auto max-w-7xl',
          align === 'wide' && !className?.includes('p-0') && 'px-8',
          align === 'full' && 'mx-auto max-w-7xl',
          align === 'full' && !className?.includes('p-0') && 'px-8',
          isStackedOnMobile && 'flex-col md:flex-row',
          verticalAlignment === 'top' && 'items-start',
          verticalAlignment === 'center' && 'items-center',
          verticalAlignment === 'bottom' && 'items-end'
        )}
      >
        {children &&
          Children.map(children, (column) => {
            const newColumn = React.cloneElement(column as ReactElement);
            return newColumn;
          })}
      </div>
    </section>
  );
}
