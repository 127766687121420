export const SIGNUP_FORM_LOADING = {
  "en": "Loading...",
  "fr": "Chargement en cours...",
  "de": "Laden ...",
  "es": "Cargando...",
  "pt-br": "Loading...",
  "it": "Loading...",
  "ja": "Loading...",
  "ko": "Loading...",
  "nl": "Loading...",
  "pt-pt": "Loading...",
  "sv": "Loading...",
  "zh": "Loading..."
}