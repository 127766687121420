export const BUSINESS_TRIAL_SUCCESS_DISCLAIMER_FIND = {
  "en": "Can’t find the email?",
  "fr": "Vous ne trouvez pas l'e-mail ?",
  "de": "Sie können die E-Mail nicht finden?",
  "es": "¿No puede encontrar el correo electrónico?",
  "pt-br": "Can’t find the email?",
  "it": "Can’t find the email?",
  "ja": "Can’t find the email?",
  "ko": "Can’t find the email?",
  "nl": "Can’t find the email?",
  "pt-pt": "Can’t find the email?",
  "sv": "Can’t find the email?",
  "zh": "Can’t find the email?"
}