export const PRIVACY_CONSENT_TITLE_CTA = {
  "en": "Cookie preferences.",
  "fr": "Préférences relatives aux cookies.",
  "de": "Cookie-Einstellungen.",
  "es": "Preferencias de cookies",
  "pt-br": "Cookie preferences.",
  "it": "Cookie preferences.",
  "ja": "Cookie preferences.",
  "ko": "Cookie preferences.",
  "nl": "Cookie preferences.",
  "pt-pt": "Cookie preferences.",
  "sv": "Cookie preferences.",
  "zh": "Cookie preferences."
}