import { setConfig } from "@faustwp/core";
import templates from "./wp-templates";
import possibleTypes from "./possibleTypes.json";
import SeedQueryWithLocalePlugin from "./plugins/SeedQueryWithLocalePlugin";
import PossibleTemplatesPlugin from "./plugins/PossibleTemplatesPlugins";
import ResolvedUrlPlugin from "./plugins/ResolvedUrlPlugin";

/**
 * @type {import('@faustwp/core').FaustConfig}
 **/
export default setConfig({
  basePath: "",
  experimentalPlugins: [
    new SeedQueryWithLocalePlugin(),
    new PossibleTemplatesPlugin(),
    new ResolvedUrlPlugin(),
  ],
  experimentalToolbar: true,
  possibleTypes,
  templates,
  usePersistedQueries: true, // Used in conjunction with WPGraphQL Smart Cache plugin in wp admin
});
