import { RichText } from "../atoms";
import cn from "classnames";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface PullQuoteProps {
  citation?: string;
  className?: string;
  id?: string;
  styleSolid: boolean;
  value: string;
  style: Record<string, any>;
}

function PullQuote({
  citation,
  className,
  id,
  styleSolid,
  value,
  style,
}: PullQuoteProps) {
  return (
    <>
      {!!value && (
        <figure
          id={id}
          className={cn(
            "mb-6 p-5 px-10 text-center",
            className,
            styleSolid && "ml-auto mr-auto max-w-60 text-left"
          )}
          style={style}
        >
          <div
            className={cn(styleSolid && "ml-auto mr-auto max-w-60 text-left")}
          >
            <blockquote>
              <RichText className="text-blue-8" tag="div">
                {value}
              </RichText>
            </blockquote>
            {!!citation && (
              <figcaption className={"font-bold text-blue-8"}>
                <RichText tag="span">{citation}</RichText>
              </figcaption>
            )}
          </div>
        </figure>
      )}
    </>
  );
}

interface BlockPullQuote {
  anchor?: string;
  citation?: string;
  className?: string;
  customMainColor: string;
  customTextColor: string;
  mainColorHex: string;
  textColorHex: string;
  maybeValue: string;
}

export default function PullQuoteBlock({
  anchor,
  citation,
  className,
  customMainColor,
  customTextColor,
  mainColorHex,
  textColorHex,
  maybeValue,
}: BlockPullQuote) {
  const backgroundcolor = mainColorHex || customMainColor || "inherit";
  const textcolor = textColorHex || customTextColor || "inherit";

  const pullQuoteStyle = {
    backgroundColor: backgroundcolor,
    color: textcolor,
  };

  const styleSolid = className && className.includes("is-style-solid-color");

  className &&
    className
      .replace("is-style-solid-color", "")
      .replace("is-style-default", "");

  if (!styleSolid) {
    pullQuoteStyle.backgroundColor = "inherit";
  }

  return (
    <PullQuote
      citation={citation}
      className={className}
      id={anchor}
      style={pullQuoteStyle}
      styleSolid={!!styleSolid}
      value={maybeValue}
    />
  );
}

// Must match __typename
const BLOCK_NAME = "CorePullquote";

PullQuoteBlock.displayName = BLOCK_NAME;

PullQuoteBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          backgroundColor
          borderColor
          citation
          className
          metadata
          style
          textAlign
          textColor
          maybeValue: value
        }
      }
    }
  `,
};
