import { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import MenuPanel, { ColumnIconLink, iconBg, iconColor } from "./MenuPanel";
import { mapNavPanelData } from "@/utilities/mapNavPanelData";
import { sanitizeRawHTML } from "@/utilities/sanitizeRawHTML";
import { EnhancedMenuItem, EnhancedMenuItems } from "@/types/Menu";
import cn from "classnames";
import { pTextSm } from "@/constants/standardCSSClasses";

export default function EnhancedMenuHeader({
  items,
}: {
  items: EnhancedMenuItems;
}) {
  const [isShowing, setIsShowing] = useState<Record<string, boolean>>({});
  function handleBgOVerlay(open: boolean, id: string) {
    if (typeof document === "undefined") return;

    const validClass = id.replace(/=/gi, "");
    const overlay = document.body.querySelector(
      `.enhanced-menu-overlay.${validClass}`
    );

    if (open && !overlay) {
      const overlay = document.createElement("div");
      overlay.classList.add("enhanced-menu-overlay");
      overlay.classList.add(validClass);
      overlay.classList.add("fixed");
      overlay.classList.add("top-0");
      overlay.classList.add("left-0");
      overlay.classList.add("right-0");
      overlay.classList.add("bottom-0");
      overlay.classList.add("bg-[#000000]");
      overlay.classList.add("opacity-20");
      document.body.appendChild(overlay);
    } else if (overlay && !open) {
      overlay.remove();
    }
  }

  function getInitialIsShowing() {
    const newIsShowing = items.reduce(
      (acc, { id }) => ({ ...acc, [id]: false }),
      {}
    );

    return newIsShowing;
  }
  function handleOnMouseEnter(id: string) {
    setIsShowing({ ...getInitialIsShowing(), [id]: true });
    handleBgOVerlay(true, id);
  }

  function handleOnMouseLeave(id: string) {
    setIsShowing({ ...isShowing, [id]: false });
    handleBgOVerlay(false, id);
  }

  useEffect(() => {
    setIsShowing(getInitialIsShowing());
  }, []);

  useEffect(() => {
    const overlays = document.querySelectorAll(".enhanced-menu-overlay");
    overlays.forEach((overlay) => overlay.remove());
  }, []);

  const listStyles = (
    item: EnhancedMenuItem,
    cssClasses: string[],
    isFirstRight: boolean
  ) =>
    cn(
      cssClasses,
      "flex h-full cursor-pointer items-center border-b-2 border-b-transparent hover:border-b-2 hover:border-b-blue-8",
      {
        "!ml-auto !mr-6": item.position === "right" && isFirstRight,
        "!ml-0 !mr-6": item.position === "right" && !isFirstRight,
        "!mx-3": item.position !== "right",
      }
    );
  return (
    <>
      {items.map((item, index) => {
        const { id, path, label, children, cssClasses, target } = item;
        const isPanel = item?.structure === "panel";
        const isSimplePanel = item?.structure === "simple_panel";
        const isFirstRight =
          item.position === "right" &&
          index === items.findIndex((i) => i.position === "right");

        return !!children?.length ? (
          <li
            key={id}
            id={id}
            className={listStyles(item, cssClasses, isFirstRight)}
            onMouseEnter={() => handleOnMouseEnter(id)}
            onMouseLeave={() => handleOnMouseLeave(id)}
          >
            <Menu as="div" className={isSimplePanel ? "relative" : ""}>
              {() => {
                const itemShowing = isShowing[id] ?? false;
                const textColor = itemShowing ? "text-blue-8" : "text-grey-6";
                const iconStyle = `ml-1 h-5 w-5 ${textColor} transition duration-150 ease-in-out hover:text-blue-8`;
                const ShowingArrow = itemShowing
                  ? ChevronUpIcon
                  : ChevronDownIcon;

                return (
                  <>
                    <Menu.Button
                      data-cy={`menu-button-${index}`}
                      className={`group mt-[4px] inline-flex items-center rounded-md text-base ${textColor}  hover:text-blue-8 focus:outline-1 focus:outline-blue-8`}
                      {...(item.ga4Id ? { "data-item": item.ga4Id } : {})}
                    >
                      {item.label}
                      <ShowingArrow className={iconStyle} aria-hidden="true" />
                    </Menu.Button>
                    {isPanel ? (
                      <MenuPanel
                        data={mapNavPanelData(item)}
                        isShowing={itemShowing}
                      />
                    ) : (
                      <Transition
                        show={itemShowing}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div className="absolute right-[-77%] top-0 pt-12">
                          <Menu.Items
                            data-cy={`menu-items-${index}`}
                            as="ul"
                            className="z-10 mt-1 max-w-sm transform px-2 sm:px-0 md:max-w-md lg:max-w-lg"
                            style={{ boxShadow: "0px 4px 12px 0px #A5B3BC33" }}
                          >
                            <div className="overflow-hidden rounded-lg bg-white p-5 ring-1 ring-black ring-opacity-5">
                              {children.map((item: any, innerIndex: number) => (
                                <Menu.Item
                                  as="li"
                                  key={item.id}
                                  data-cy={`menu-item-${innerIndex}`}
                                  className={`rounded-lg p-2 hover:bg-blue-1`}
                                >
                                  <Link
                                    href={item.path}
                                    target={item.target ?? "_self"}
                                    locale={false}
                                    className={
                                      "flex items-start justify-start py-0 hover:no-underline"
                                    }
                                    {...(item.ga4Id
                                      ? { "data-item": item.ga4Id }
                                      : {})}
                                  >
                                    {item.image && (
                                      <div
                                        className={`flex items-center justify-center`}
                                        style={{
                                          width: "37px",
                                          height: "37px",
                                          padding: "8px",
                                          borderRadius: "6px",
                                          backgroundColor:
                                            iconBg[
                                              item.imageVariant as ColumnIconLink["variant"]
                                            ] ?? "transparent",
                                          fill: iconColor[
                                            item.imageVariant as ColumnIconLink["variant"]
                                          ],
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: sanitizeRawHTML(
                                            atob(item.image)
                                          ),
                                        }}
                                      />
                                    )}
                                    <div className="ml-3">
                                      <div className="group mt-[2px] flex w-full items-center whitespace-nowrap font-medium text-blue-8 transition hover:text-blue-8 hover:no-underline">
                                        {item.label}
                                      </div>
                                      {item.description && (
                                        <p
                                          className={`${pTextSm} mt-1 text-grey-6`}
                                          dangerouslySetInnerHTML={{
                                            __html: item.description,
                                          }}
                                        ></p>
                                      )}
                                    </div>
                                  </Link>
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </div>
                      </Transition>
                    )}
                  </>
                );
              }}
            </Menu>
          </li>
        ) : (
          <li key={id} className={listStyles(item, cssClasses, isFirstRight)}>
            <Link
              href={path}
              target={target ?? "_self"}
              locale={false}
              className="mt-[4px] inline-flex items-center text-base text-grey-6 hover:text-dashgreen-active hover:no-underline focus:outline-1 focus:outline-blue-8"
              {...(item.ga4Id ? { "data-item": item.ga4Id } : {})}
            >
              {label}
            </Link>
          </li>
        );
      })}
    </>
  );
}
