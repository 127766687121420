import React from "react";
import classnames from "classnames";
import {
  backgroundInverse,
  backgroundNeutral,
  boxPaddingX,
} from "@/constants/standardCSSClasses";
import { BlockContentProps } from "@/utilities/getBlockContentProps";

interface BlockContentInterface extends BlockContentProps {
  id?: string;
  contentType?: string;
  width?: string;
  defaultValue?: string;
  className?: string;
  variant?: string;
  constrain?: boolean;
  narrow?: boolean;
  padding?: string;
  paddingTop?: string;
  paddingBottom?: string;
  dataAttributes?: Record<string, string | undefined>;
  children: React.ReactNode;
  noSpacing?: boolean;
}

const BlockContent = ({
  id,
  width = "",
  className = "",
  variant = "white",
  constrain = true,
  narrow = false,
  defaultValue = "full",
  padding,
  paddingTop = "",
  paddingBottom = "",
  ga4SectionId,
  children,
  dataAttributes = {},
  noSpacing = false,
}: BlockContentInterface) => {
  /*
  Need scenarios for:
  full width
  full width constrained
  full width constrained narrow
  boxed
  boxed narrow

  default should be full width constrained with white background
   */

  if (!width) width = defaultValue;

  const classNames = classnames(className, padding, {
    "w-bleed-full max-w-none": width === "full",
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
    [boxPaddingX]: width === "box",
    "mb-6 mt-6 rounded": width === "box",
    "pt-8": !padding && paddingTop === "default",
    "pb-8": !padding && paddingBottom === "default",
    "pt-0": !padding && paddingTop === "none",
    "pb-0": !padding && paddingBottom === "none",
    "pt-5": !padding && paddingTop === "small",
    "pb-5": !padding && paddingBottom === "small",
    "pt-10": !padding && paddingTop === "medium",
    "pb-10": !padding && paddingBottom === "medium",
    "pt-[60px]": !padding && paddingTop === "large",
    "pb-[60px]": !padding && paddingBottom === "large",
  });

  const innerClassNames = classnames({
    "max-w-7xl mx-auto": !narrow && constrain,
    "px-4 md:px-8": constrain && width === "full",
    "max-w-3xl mx-auto": narrow,
  });

  if (ga4SectionId) {
    dataAttributes = {
      ...dataAttributes,
      "data-component": ga4SectionId,
    };
  }

  return (
    <section
      className={!noSpacing ? classNames : ""}
      id={id}
      {...dataAttributes}
    >
      <div className={!noSpacing ? innerClassNames : ""}>{children}</div>
    </section>
  );
};

export default BlockContent;
