export default function flatListToHierarchical<T>(
  data: T[] = [],
  {
    idKey = "id",
    parentKey = "parentId",
    childrenKey = "children",
  }:
    | {
        idKey?: string;
        parentKey?: string;
        childrenKey?: string;
      }
    | undefined = {}
) {
  const tree: T[] = [];
  const childrenOf: Record<string, T[]> = {};

  data.forEach((item) => {
    const newItem: any = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;

    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];

    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });

  return tree;
}
