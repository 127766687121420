import useSWR from "swr";
import { Plans } from "./types";
import { BaseAPI } from "@/utilities/api/baseAPI";
import { PlanSegment } from "@/types/PlanSegment";
import { intlCurrency } from "@/utilities/intlCurrency";
import { getLocaleWithCountry } from "@/utilities/getLocaleWithCountry";

const baseApi = new BaseAPI();
const apiName = "payments";
const apiMethod = "GetDefaultWebOffers";
const payload = { hasEssentials: true };

const fetcher = () =>
  baseApi.postData<Plans>({ apiName, apiMethod, payload }).then((res) => res);

interface IUseDefaultWebOffersProps {
  planSegment: PlanSegment;
  discountGiven?: number;
}

export const useDefaultWebOffers = ({
  planSegment,
  discountGiven = 0,
}: IUseDefaultWebOffersProps) => {
  const locale = getLocaleWithCountry();
  const { data } = useSWR(
    planSegment === "premium" ||
      planSegment === "family" ||
      planSegment === "free"
      ? "/payments/GetDefaultWebOffers"
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  if (!data) {
    return {
      webOffers: data,
    };
  }

  const YEARLY_INDEX_PREM = data.plans.premium.offers.findIndex(
    (offer) => offer.duration.unit === "y"
  );
  const YEARLY_INDEX_FAM = data.plans.family.offers.findIndex(
    (offer) => offer.duration.unit === "y"
  );

  const currency = data.currency;
  const priceFormatWithDecimals = 0.01;
  const planPrice = data.plans.premium.offers[0]?.priceWithoutVAT
    ? "priceWithoutVAT"
    : "price";

  function calculateDiscountedMonthlyPrice(
    data: Plans,
    type: keyof Plans["plans"],
    discountedPercentage: number
  ) {
    const offers = data.plans[type].offers;
    const yearlyIndex = offers.findIndex(
      (offer) => offer.duration.unit === "y"
    );
    const annualPrice = offers[yearlyIndex]?.price ?? 0;
    const monthlyPrice = Math.floor(annualPrice / 12) / 100;

    if (discountGiven === 100) {
      return monthlyPrice;
    }

    return Number(
      (Math.ceil(monthlyPrice * (100 - discountedPercentage)) / 100).toFixed(2)
    );
  }

  return {
    webOffers: {
      currency,
      hasPriceWithoutVat: planPrice === "priceWithoutVAT",
      defaultWebOffers: data,
      formattedDefaultWebOffers: {
        free: {
          monthly: intlCurrency({
            value: 0,
            language: locale,
            currency,
          }),
          annually: intlCurrency({
            value: 0,
            language: locale,
            currency,
          }),
          yearly: intlCurrency({
            value: 0,
            language: locale,
            currency,
          }),
        },
        premium: {
          annually: intlCurrency({
            value:
              Math.floor(
                (data.plans.premium.offers[YEARLY_INDEX_PREM]?.[
                  planPrice
                ] as number) / 12
              ) / 100,
            language: locale,
            currency,
          }),
          yearly: intlCurrency({
            value:
              (data.plans.premium.offers[YEARLY_INDEX_PREM]?.[
                planPrice
              ] as number) * priceFormatWithDecimals,
            language: locale,
            currency,
          }),
          discountedMonthlyPrice: intlCurrency({
            value: calculateDiscountedMonthlyPrice(
              data,
              "premium",
              discountGiven
            ),
            language: locale,
            currency,
          }),
        },
        family: {
          annually: intlCurrency({
            value:
              Math.floor(
                (data.plans.family.offers[YEARLY_INDEX_FAM]?.[
                  planPrice
                ] as number) / 12
              ) / 100,
            language: locale,
            currency,
          }),
          yearly: intlCurrency({
            value:
              (data.plans.family.offers[YEARLY_INDEX_FAM]?.[
                planPrice
              ] as number) * priceFormatWithDecimals,
            language: locale,
            currency,
          }),
          discountedMonthlyPrice: intlCurrency({
            value: calculateDiscountedMonthlyPrice(
              data,
              "family",
              discountGiven
            ),
            language: locale,
            currency,
          }),
        },
      },
    },
  };
};
