import cn from "classnames";
import {
  backgroundInverse,
  backgroundNeutral,
  backgroundWhite,
} from "@/constants/standardCSSClasses";

const getWidthClasses = (columns = 3) => {
  return {
    "w-1/2": columns === 2,
    "w-1/3": columns === 3,
    "w-1/4": columns === 4,
    "w-1/5": columns === 5,
    "w-1/6": columns === 6,
    "w-[14.285%]": columns === 7,
    "w-[12.5%]": columns === 8,
    "w-[11.111%]": columns === 9,
    "w-[10%]": columns === 10,
    "w-[9.09%]": columns === 11,
  };
};

export const tabularCellClasses = ({
  columns = 3,
  sticky = false,
  variant = "white",
  headerColumn = false,
  striped = false,
}) => {
  return cn(
    "box-content lg:min-w-0 p-4 min-w-[120px]",
    getWidthClasses(columns),
    {
      [backgroundInverse]:
        (sticky || headerColumn) && variant === "dark" && !striped,
      [backgroundNeutral]:
        (sticky || headerColumn) &&
        (variant === "light" || (variant === "white" && striped)),
      [backgroundWhite]:
        (sticky || headerColumn) && variant === "white" && !striped,
      "sticky left-0 lg:relative z-15": headerColumn,
      "lg:sticky lg:top-[61px] lg:z-20": sticky,
    }
  );
};
