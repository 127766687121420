export const GENERIC_VIEW_MORE = {
  "en": "View more",
  "fr": "View more",
  "de": "View more",
  "es": "View more",
  "pt-br": "View more",
  "it": "View more",
  "ja": "View more",
  "ko": "View more",
  "nl": "View more",
  "pt-pt": "View more",
  "sv": "View more",
  "zh": "View more"
}