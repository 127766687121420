import React from "react";
import { RichText } from "@/components/atoms";

export interface HeadingOptions {
  tag: number;
}

interface Props {
  heading?: string;
  headingOptions?: HeadingOptions;
  headingClassNames?: string;
}

export default function CustomHeading({
  heading = "",
  headingOptions = {
    tag: 2,
  },
  headingClassNames = "",
}: Props) {
  const headingConversion = ["div", "h1", "h2", "h3", "h4", "h5", "h6"];

  return (
    <RichText
      className={headingClassNames}
      tag={headingConversion[headingOptions.tag] ?? "div"}
    >
      {heading}
    </RichText>
  );
}
