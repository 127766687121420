import cn from "classnames";
import CustomImage from "../molecules/CustomImage";
import ConditionalWrapper from "@/components/molecules/ConditionalWrapper";
import {
  h6Text,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { RichText } from "@/components/atoms";
import React from "react";
import Link from "next/link";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

export interface AccoladesGridItemBlockInterface {
  image?: object;
  content?: string;
  variant?: string;
  width?: string;
  index?: number;
  length?: number;
  className?: string;
  aspect?: string;
  link?: {
    url?: string;
    opensInNewTab?: string;
  };
  ga4SectionId?: string;
}

export interface AccoladeImageInterface {
  full?: string;
  medium?: string;
  alt?: string;
}

const AccoladesGridItemBlock = ({
  image = {},
  content = "",
  variant = "light",
  link = {},
  className = "",
  aspect = "1:1",
  ga4SectionId,
}: AccoladesGridItemBlockInterface) => {
  const { full = "", medium, alt = "" }: AccoladeImageInterface = image;

  //Classes to account for mobile, borders, variable number of items, etc.
  const wrapClassName = cn("text-center", className);

  const contentClassNames = cn("inline-block mt-4", h6Text, {
    [textHeadlineNeutral]: variant !== "dark",
    [textHeadlineInverse]: variant === "dark",
  });

  const includeLink = !!link.url;

  const linkWrapper = (children: React.ReactNode) => (
    <Link
      target={link.opensInNewTab ? "_blank" : undefined}
      rel={link.opensInNewTab ? "noreferrer noopener" : undefined}
      href={link.url ?? ""}
      locale={false}
    >
      {children}
    </Link>
  );

  return (
    <div
      className={wrapClassName}
      {...(ga4SectionId ? { "data-item": ga4SectionId } : {})}
    >
      <ConditionalWrapper condition={includeLink} wrapper={linkWrapper}>
        <CustomImage
          full={medium || full}
          alt={alt}
          className="w-full"
          objectFit="contain"
          aspect={aspect}
          rounded={false}
        />
      </ConditionalWrapper>
      {content && (
        <RichText className={contentClassNames} tag="span">
          {content}
        </RichText>
      )}
    </div>
  );
};

const BLOCK_NAME = "TenupAccoladesGridItem";

AccoladesGridItemBlock.displayName = BLOCK_NAME;

AccoladesGridItemBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          ga4SectionId
          image
          link
          metadata
        }
      }
    }
  `,
};

export default AccoladesGridItemBlock;
