import { Locale } from "@/types";

export const formatEventDate = (
  dateString?: string,
  locale: Locale | string = "en",
  expire: boolean = false,
  includeTime: boolean = true
) => {
  if (!dateString) return "";

  const formattedDate = new Date(dateString);

  // If the date should expire and return an empty string when it has passed, pass the "expire" option
  if (expire && new Date() > formattedDate) return "";

  const dateFormatted = formattedDate.toLocaleDateString(locale, {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const timeFormatted = formattedDate.toLocaleTimeString(locale, {
    timeZoneName: "short",
    hour: "2-digit",
    minute: "2-digit",
  });

  return dateFormatted + (includeTime ? ` ${timeFormatted}` : "");
};
