import { Fragment } from "react";
import Link from "next/link";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function MenuHeader({ items }: { items: any[] }) {
  return (
    <>
      {items.map((item, index) => {
        const { id, path, label, children, cssClasses, target } = item;
        // @TODO - Remove guard clause after ghost menu items are no longer appended to array.
        if (!item.hasOwnProperty("__typename")) {
          return null;
        }

        return !!children.length ? (
          <li key={id} className={cssClasses}>
            <Menu as="div" className="relative">
              <div>
                <Menu.Button
                  data-cy={`menu-button-${index}`}
                  className="group inline-flex items-center rounded-md px-3 py-2 text-base text-blue-8 hover:text-dashgreen-active"
                >
                  {item.label}
                  <ChevronDownIcon
                    className="ml-1 h-5 w-5 text-blue-8 transition duration-150 ease-in-out hover:text-dashgreen-active"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  data-cy={`menu-items-${index}`}
                  as="ul"
                  className="absolute z-10 mt-1 max-w-sm transform px-2 sm:px-0 md:max-w-md lg:max-w-lg "
                >
                  <div className="overflow-hidden rounded-lg bg-white p-5 shadow-lg ring-1 ring-black ring-opacity-5 ">
                    {children.map((item: any, innerIndex: number) => (
                      <Menu.Item
                        as="li"
                        key={item.id}
                        data-cy={`menu-item-${innerIndex}`}
                      >
                        <Link
                          href={item.path}
                          target={item.target ?? "_self"}
                          locale={false}
                          className="group flex w-full items-center whitespace-nowrap py-2 text-blue-8 transition hover:text-blue-8 hover:no-underline"
                        >
                          {item.label}
                        </Link>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
        ) : (
          <li key={id} className={cssClasses}>
            <Link
              href={path}
              target={target ?? "_self"}
              locale={false}
              className="inline-flex items-center px-3 py-2 text-base text-blue-8 hover:text-dashgreen-active hover:no-underline"
            >
              {label}
            </Link>
          </li>
        );
      })}
    </>
  );
}
