import { BaseAPI } from "./baseAPI";

export default class BusinessBreachReportRegistration extends BaseAPI {
  register(params: { email: string }) {
    const { email = "" } = params;
    const payload = {
      email: email,
    };

    return this.postData({
      apiName: "businessbreachreport",
      apiMethod: "RegisterBusinessBreachReport",
      payload,
    });
  }
}
