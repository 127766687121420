export default function () {
  if (
    process.env["NEXT_PUBLIC_SITE_URL"] === "https://dashlane.com" ||
    process.env["NEXT_PUBLIC_SITE_URL"] === "https://www.dashlane.com"
  )
    return "production";

  if (process.env["NEXT_PUBLIC_SITE_URL"] === "https://ripley-stg.vercel.app")
    return "staging";

  return "local";
}
