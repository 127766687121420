import { Locale } from "@/types";
import { useRouter } from "next/router";

export const useRouterBasePath = () => {
  const router = useRouter();
  const locale = (router.locale as Locale) ?? "en";

  let basePath = router.asPath;

  if (basePath.includes("?")) {
    const basePathParts = basePath.split("?");
    basePath = basePathParts[0] ?? router.asPath;
  }

  basePath = basePath.replace(`/${locale}`, "");

  return basePath;
};
