import { gql } from '@apollo/client';

const FeaturedImageFragment = gql`
  fragment FeaturedImageFragment on NodeWithFeaturedImage {
    featuredImage {
      node {
        id
        sourceUrl
        altText
        mediaDetails {
          width
          height
        }
      }
    }
  }
`;

export default FeaturedImageFragment;
