import React from "react";

import { Heading } from "@/components/atoms";
import CustomButton from "@/components/molecules/CustomButton";

interface Props {
  heading: string;
  subHeading: string;
  buttonText: string;
  buttonLink: string;
  className?: string;
}

export default function ErrorHeader({
  heading,
  subHeading,
  buttonText,
  buttonLink,
  className,
}: Props) {
  return (
    <div
      className={`${
        className ?? ""
      } relative h-[300px] md:h-[400px] lg:h-[500px] xl:h-[600px]`}
    >
      <div className="relative top-1/4 w-full md:top-1/3">
        <Heading className="mb-4 text-3xl md:text-4xl lg:text-5xl" level="h2">
          {heading}
        </Heading>
        <p className="text-xl">{subHeading}</p>
        <div className="mt-8">
          <CustomButton
            variant="dark"
            text={buttonText}
            align="center"
            href={buttonLink}
          />
        </div>
      </div>
    </div>
  );
}
