export const iconDefault = `<svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M12.0002 2L22.0002 12L12.0002 22L2.00024 12L12.0002 2Z"/></svg>`;
export const iconPlus = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M13.2 2H11V11H2V13.2H11V22H13.2V13.2H22V11H13.2V2Z" /></svg>`;
export const iconCaretDown = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_643_4102)"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 14.0157L5.55319 7L4 8.29409L12 17L20 8.29409L18.4468 7L12 14.0157Z"/></g><defs><clipPath id="clip0_643_4102"><rect width="24" height="24"/></clipPath></defs></svg>`;
export const iconCheckmark = `<svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_643_3967)"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.3379 5.71307L9.72865 20.5271L1.81152 14.1703L3.18889 12.4549L9.43902 17.4731L20.6626 4.28711L22.3379 5.71307Z"/></g><defs><clipPath id="clip0_643_3967"><rect width="24" height="24"/></clipPath></defs></svg>`;
export const iconClose = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_643_3722)"><path d="M10.4692 12.0399L3 4.53553L4.56083 2.98511L12.0214 10.4807L19.4685 2.99994L21.0147 4.56499L13.5733 12.04L20.9352 19.4365L19.3743 20.9869L12.0212 13.5991L4.6388 21.0149L3.09264 19.4498L10.4692 12.0399Z" /></g><defs><clipPath id="clip0_643_3722"><rect width="24" height="24"/></clipPath></defs></svg>`;
export const healthPositive = `<svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M3.71956 6.26394L12 3.67631L20.2804 6.26394C20.2006 6.96915 20.0789 7.90835 19.9048 8.93413C19.5237 11.1793 18.9334 13.5677 18.1235 14.9343C17.6632 15.7111 16.9198 16.509 16.0632 17.2632L17.1967 18.3967C18.1136 17.5805 18.9496 16.6788 19.5 15.75C21.4526 12.455 22 5.125 22 5.125L12 2L2 5.125C2 5.125 2.54738 12.455 4.5 15.75C6.45262 19.045 12 22 12 22C12 22 13.5553 21.1715 15.3087 19.9028L14.162 18.7561C14.1398 18.7719 14.1176 18.7876 14.0954 18.8033C13.3211 19.3496 12.6023 19.7996 12.0775 20.1128L12 20.1589L11.9225 20.1128C11.3977 19.7996 10.6789 19.3496 9.9046 18.8033C8.30087 17.6718 6.66901 16.2717 5.87647 14.9343C5.06663 13.5677 4.47627 11.1793 4.09521 8.93413C3.92111 7.90835 3.79941 6.96915 3.71956 6.26394Z"/><path d="M15.3474 8.31602L11.4101 12.3853L9.75683 10.6278L8.59141 11.724L11.3937 14.7032L16.4973 9.42859L15.3474 8.31602Z"/></svg>`;
export const healthNegative = `<svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path d="M3.71956 6.26394L12 3.67631L20.2804 6.26394C20.2006 6.96915 20.0789 7.90835 19.9048 8.93413C19.5237 11.1793 18.9334 13.5677 18.1235 14.9343C17.6632 15.7111 16.9198 16.509 16.0632 17.2632L17.1967 18.3967C18.1136 17.5805 18.9496 16.6788 19.5 15.75C21.4526 12.455 22 5.125 22 5.125L12 2L2 5.125C2 5.125 2.54738 12.455 4.5 15.75C6.45262 19.045 12 22 12 22C12 22 13.5553 21.1715 15.3087 19.9028L14.162 18.7561C14.1398 18.7719 14.1176 18.7876 14.0954 18.8033C13.3211 19.3496 12.6023 19.7996 12.0775 20.1128L12 20.1589L11.9225 20.1128C11.3977 19.7996 10.6789 19.3496 9.9046 18.8033C8.30087 17.6718 6.66901 16.2717 5.87647 14.9343C5.06663 13.5677 4.47627 11.1793 4.09521 8.93413C3.92111 7.90835 3.79941 6.96915 3.71956 6.26394Z"/><path d="M15.3657 8.73137L13.1314 10.9657L15.3657 13.2L14.2343 14.3314L12 12.0971L9.76569 14.3314L8.63432 13.2L10.8686 10.9657L8.63432 8.73137L9.76569 7.6L12 9.83431L14.2343 7.6L15.3657 8.73137Z"/></svg>`;

export const caretDown = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_643_4102)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 14.0157L5.55319 7L4 8.29409L12 17L20 8.29409L18.4468 7L12 14.0157Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_643_4102">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
  </svg>
);

export const caretRight = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className="h-full w-full"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0157 12L7 18.4468L8.29409 20L17 12L8.29409 4L7 5.55319L14.0157 12Z"
    />
  </svg>
);

export const checkmark = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3379 5.71307L9.72865 20.5271L1.81152 14.1703L3.18889 12.4549L9.43902 17.4731L20.6626 4.28711L22.3379 5.71307Z"
    />
  </svg>
);

export const leftArrow = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className="h-full w-full"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0617 13.0316L12.8377 19.5059L11.4194 21L2 12L11.4194 3L12.8377 4.49407L6.0617 10.9684H22V13.0316H6.0617Z"
    />
  </svg>
);

export const rightArrow = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className="h-full w-full"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9383 13.0316L11.1623 19.5059L12.5806 21L22 12L12.5806 3L11.1623 4.49407L17.9383 10.9684L2 10.9684L2 13.0316L17.9383 13.0316Z"
    />
  </svg>
);

export const search = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833496 17.5001L4.23207 14.1015L5.42903 15.2985L2.03046 18.6971L0.833496 17.5001Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 14.1666C14.2416 14.1666 16.6667 11.7415 16.6667 8.74996C16.6667 5.75842 14.2416 3.33329 11.25 3.33329C8.25849 3.33329 5.83336 5.75842 5.83336 8.74996C5.83336 11.7415 8.25849 14.1666 11.25 14.1666ZM11.25 15.8333C15.162 15.8333 18.3334 12.662 18.3334 8.74996C18.3334 4.83794 15.162 1.66663 11.25 1.66663C7.33801 1.66663 4.1667 4.83794 4.1667 8.74996C4.1667 12.662 7.33801 15.8333 11.25 15.8333Z"
    />
  </svg>
);

export const info = ({ width = "24px" }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width }}
  >
    <g clipPath="url(#clip0_643_3832)">
      <path d="M11 17H13V11H11V17Z" />
      <path d="M11 9H13V7H11V9Z" />
      <path d="M18.6178 16.4964C19.4901 15.215 20 13.667 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C13.6671 20 15.2151 19.4901 16.4965 18.6177L17.9309 20.0521C16.2716 21.2764 14.2203 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 14.2202 21.2764 16.2715 20.0522 17.9308L18.6178 16.4964Z" />
    </g>
    <defs>
      <clipPath id="clip0_643_3832">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export const clock = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.16648 4.66663L9.83309 5.6666V9.00001H12.3331V10.6667H8.16643L8.16648 4.66663Z" />
    <path d="M15.6665 9C15.6665 10.3892 15.2416 11.6791 14.5147 12.747L15.71 13.9423C16.7302 12.5596 17.3332 10.8502 17.3332 9C17.3332 4.39763 13.6022 0.666672 8.99984 0.666672C4.39746 0.666672 0.666504 4.39763 0.666504 9.00001C0.666504 13.6024 4.39746 17.3333 8.99984 17.3333C10.8501 17.3333 12.5595 16.7303 13.9423 15.7101L12.7469 14.5148C11.6791 15.2417 10.3891 15.6667 8.99984 15.6667C5.31794 15.6667 2.33317 12.6819 2.33317 9.00001C2.33317 5.31811 5.31794 2.33334 8.99984 2.33334C12.6817 2.33334 15.6665 5.31811 15.6665 9Z" />
  </svg>
);

export const headerContact = (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4 0.699951L13.4 10.2999H12.2L12.2 9.09995L10.2 9.09995L11 10.3L0.599976 10.2999V0.699951H13.4ZM12.2 1.89995V9.09995L1.79998 9.09995L1.79998 1.89995L12.2 1.89995Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3328 1.99918L6.99988 6.22106L0.667152 1.99918L1.3328 1.00072L6.99989 4.77884L12.6672 1.00072L13.3328 1.99918Z"
    />
  </svg>
);

export const headerDemo = (
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.36352 5.44898L8.75 3.0625L9.5455 3.858L7.15901 6.24448L6.36352 5.44898Z" />
    <path d="M7.16295 6.24458L5.57196 4.65359L6.36746 3.85809L7.95845 5.44908L7.16295 6.24458Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.625 11.5V10.4298L1.875 7.125V0.875H13.125V5.25003L11.875 6.00003V2.125H3.125V7.125H12.9109L14.375 10.4298V11.5H0.625ZM12.9282 10.25L12.0975 8.375H2.90254L2.07184 10.25H12.9282Z"
    />
  </svg>
);

export const logo = (
  <svg
    width="99"
    height="17"
    viewBox="0 0 99 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4948 5.03516C11.8911 3.91875 11.0206 3.02735 9.88325 2.36094C8.74594 1.69454 7.4119 1.36011 5.88237 1.36011H0.322449V16.1009H5.88237C7.4119 16.1009 8.74594 15.7714 9.88325 15.1111C11.0206 14.4509 11.8911 13.5595 12.4948 12.4369C13.0985 11.3144 13.3997 10.0787 13.3997 8.73112C13.3997 7.38356 13.0972 6.15157 12.4948 5.03516ZM9.39882 10.9627C9.07545 11.6512 8.58856 12.2021 7.93568 12.6164C7.2828 13.0308 6.49344 13.2373 5.56638 13.2373H3.73439V4.22491H5.56638C6.49344 4.22491 7.2828 4.4327 7.93568 4.84582C8.58856 5.26017 9.07668 5.811 9.39882 6.4983C9.72218 7.18684 9.88325 7.9307 9.88325 8.73112C9.88325 9.53154 9.72218 10.2754 9.39882 10.9627Z"
      fill="#09363F"
    />
    <path
      d="M34.5219 7.82628L32.0788 6.85741C31.3484 6.56233 30.8296 6.27093 30.521 5.98322C30.2124 5.69551 30.0574 5.34755 30.0574 4.94058C30.0574 4.4918 30.2148 4.1377 30.5308 3.87704C30.8468 3.61761 31.2784 3.48728 31.8255 3.48728C32.4858 3.48728 32.9911 3.66925 33.3415 4.03442C33.6919 4.39959 33.8677 4.92582 33.8677 5.61436H37.2797C37.2797 4.67377 37.0547 3.85245 36.6059 3.15039C36.1559 2.44833 35.5251 1.90488 34.7112 1.5188C33.8973 1.13273 32.9554 0.939697 31.8894 0.939697C30.8234 0.939697 29.9136 1.11921 29.1205 1.477C28.3275 1.83479 27.7164 2.34013 27.2885 2.99301C26.8607 3.64589 26.6467 4.40082 26.6467 5.25657C26.6467 6.21191 26.9 7.02955 27.4054 7.70947C27.9107 8.39063 28.6545 8.91318 29.6369 9.27835L32.2903 10.3099C32.964 10.5767 33.4313 10.8472 33.6907 11.1214C33.9501 11.3956 34.0805 11.7571 34.0805 12.2059C34.0805 12.7259 33.9083 13.1255 33.564 13.4059C33.2198 13.6862 32.7317 13.8276 32.1009 13.8276C31.357 13.8276 30.7706 13.6026 30.3427 13.1538C29.9148 12.705 29.7009 12.0792 29.7009 11.28H26.2889C26.2889 12.9362 26.8017 14.2247 27.8258 15.1444C28.85 16.0641 30.2689 16.524 32.08 16.524C33.1608 16.524 34.1087 16.3383 34.9227 15.9657C35.7366 15.5944 36.3686 15.0633 36.8174 14.376C37.2662 13.6887 37.4912 12.8882 37.4912 11.9747C37.4912 10.9776 37.2489 10.1353 36.7645 9.44802C36.2801 8.76072 35.5325 8.21973 34.5219 7.82628Z"
      fill="#09363F"
    />
    <path
      d="M47.1306 7.32097H42.0342V1.36145H38.6222V16.1023H42.0342V10.2472H47.1306V16.1023H50.5425V1.36145H47.1306V7.32097Z"
      fill="#09363F"
    />
    <path
      d="M55.4692 1.36145H52.0585V16.1023H60.9455V13.0702H55.4692V1.36145Z"
      fill="#09363F"
    />
    <path
      d="M85.1203 10.9001L80.0031 1.36145H76.5075V16.1023H79.7707V6.56235L84.888 16.1023H88.3847V1.36145H85.1203V10.9001Z"
      fill="#09363F"
    />
    <path
      d="M98.8924 4.09838V1.36145H89.8799V16.1023H98.8924V13.3641H93.2907V10.1218H98.8924V7.44638H93.2907V4.09838H98.8924Z"
      fill="#09363F"
    />
    <path
      d="M17.6747 1.36145L12.6841 16.1023H16.2854L16.9505 13.7514H21.8539L22.5191 16.1023H26.1204L21.1076 1.36145H17.6747ZM17.7067 11.0772L19.401 5.08815L21.0953 11.0772H17.7055H17.7067Z"
      fill="#09363F"
    />
    <path
      d="M66.841 1.36145L61.8503 16.1023H65.4516L66.1168 13.7514H71.0202L71.6853 16.1023H75.2866L70.2738 1.36145H66.841ZM66.873 11.0772L68.5672 5.08815L70.2615 11.0772H66.8717H66.873Z"
      fill="#09363F"
    />
  </svg>
);

export const locationIcon = (
  <svg
    width="14"
    height="19"
    viewBox="0 0 14 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.78152 6.53143C1.78152 4.89372 2.34504 3.74715 3.13475 3.0033C3.94116 2.24373 5.11636 1.78072 6.53223 1.78072C7.9481 1.78072 9.1233 2.24373 9.92971 3.0033C10.7194 3.74715 11.2829 4.89373 11.2829 6.53143C11.2829 7.17221 11.0107 8.14554 10.4303 9.3856C9.86995 10.5827 9.10601 11.8555 8.31603 13.0404C7.69028 13.9791 7.06232 14.8416 6.53223 15.5407C6.00213 14.8416 5.37418 13.9791 4.74843 13.0404C3.95845 11.8555 3.1945 10.5827 2.63416 9.3856C2.05371 8.14554 1.78152 7.17221 1.78152 6.53143ZM13.0645 6.53143C13.0645 9.58484 9.53994 14.5607 7.66875 16.9873C6.99253 17.8642 6.53223 18.4082 6.53223 18.4082C6.53223 18.4082 6.07193 17.8642 5.3957 16.9873C3.52451 14.5607 1.36741e-06 9.58484 1.63435e-06 6.53143C1.99775e-06 2.37456 2.92458 -0.000799052 6.53223 -0.000798737C10.1399 -0.000798421 13.0645 2.37456 13.0645 6.53143Z"
      fill="#0E353D"
    />
    <path
      d="M8.28252 6.23919C8.28252 7.18139 7.49874 7.94518 6.53189 7.94518C5.56504 7.94518 4.78125 7.18139 4.78125 6.23919C4.78125 5.297 5.56504 4.5332 6.53189 4.5332C7.49874 4.5332 8.28252 5.297 8.28252 6.23919Z"
      fill="#0E353D"
    />
  </svg>
);
