export const PRIVACY_CONSENT_REFUSE = {
  "en": "I refuse",
  "fr": "Je refuse",
  "de": "Ich lehne ab",
  "es": "Rechazar",
  "pt-br": "I refuse",
  "it": "I refuse",
  "ja": "I refuse",
  "ko": "I refuse",
  "nl": "I refuse",
  "pt-pt": "I refuse",
  "sv": "I refuse",
  "zh": "I refuse"
}