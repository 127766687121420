import cn from "classnames";
import BlockContent from "@/components/molecules/BlockContent";
import {
  h3Text,
  marginBSm,
  textBodyInverse,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { Blocks } from "@/components/molecules";
import React from "react";
import { Block } from "@/types";
import { useTouchSlider } from "@/hooks/useTouchSlider";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import AboveHeading from "../molecules/AboveHeading";
import ParagraphBlock from "./ParagraphBlock";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeHeading?: boolean;
  includeContent?: boolean;
  variant: string;
  aboveHeading?: string;
  heading?: string;
  content?: string;
  headingOptions?: HeadingOptions;
  innerBlocks?: Block[];
  contentType: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function TestimonialsBlock({
  includeAboveHeading = true,
  includeHeading = true,
  includeContent = true,
  aboveHeading = "",
  heading = "",
  content = "",
  headingOptions = {
    tag: 2,
  },
  variant = "white",
  innerBlocks,
  contentType,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const numSlides = innerBlocks?.length || 0;

  const testimonials = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;
        return each;
      })}
    />
  ) : null;

  const headerClassNames = cn("font-bold my-0 me-10", h3Text, marginBSm, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const paragraphClassNames = cn("mb-0", {
    [textBodyInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const testimonialsHeadingClassNames = cn(
    "items-top flex items-center justify-between",
    {
      "mb-10": includeAboveHeading || includeContent || includeHeading,
    }
  );

  const {
    wrapClassNames,
    fullWidthSliderClassNames,
    sliderControls,
    sliderDots,
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
    sliderRef,
  } = useTouchSlider(numSlides, variant, undefined, true);

  return (
    <BlockContent
      contentType={contentType}
      width="full"
      constrain
      variant={variant}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className={testimonialsHeadingClassNames}>
        <div className="items-top md:max-w-[50%]">
          {includeAboveHeading && aboveHeading && (
            <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
          )}
          {includeHeading && heading && (
            <CustomHeading
              heading={heading}
              headingOptions={headingOptions}
              headingClassNames={headerClassNames}
            />
          )}
          {includeContent && content && (
            <ParagraphBlock
              className={paragraphClassNames}
              content={content}
              fontSize="base"
            />
          )}
        </div>
        {numSlides > 1 && sliderControls}
      </div>
      <div className={wrapClassNames}>
        <div
          className={fullWidthSliderClassNames}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          ref={sliderRef}
        >
          {testimonials}
        </div>
      </div>
      {numSlides > 1 && sliderDots}
    </BlockContent>
  );
}

// Must match __typename
TestimonialsBlock.displayName = "TenupTestimonials";

TestimonialsBlock.fragments = {
  key: `TenupTestimonialsBlockFragment`,
  entry: gql`
    fragment TenupTestimonialsBlockFragment on TenupTestimonials {
      ${getEditorBlock()}
      attributes {
        ... on TenupTestimonialsAttributes {
          aboveHeading
          className
          content
          ga4SectionId
          heading
          headingOptions
          includeAboveHeading
          includeContent
          includeHeading
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
