import Image from 'next/image';
import Link from 'next/link';

import { RichText } from '@/components/atoms';
import { ReadMore } from '@/components/molecules';
import imageUrlParser from '@/utilities/imageUrlParser';
import { Author } from '@/types';

interface Props {
  author: Author;
}

export default function AuthorSummary({ author }: Props) {
  const { avatar, description, name, uri } = author.node;

  const parsedAvatarUrl = imageUrlParser(avatar?.url ?? '');

  return (
    <div className="my-4 flex flex-col rounded border-2 border-solid border-slate-100 p-12 text-center md:flex-row md:text-left">
      {avatar && (
        <Link className="flex-none px-6" href={uri}>
          <Image
            className="md:mx-none mx-auto mb-4 rounded-full"
            src={parsedAvatarUrl}
            alt={`A picture of the author: ${name}`}
            width={avatar.width}
            height={avatar.height}
          />
        </Link>
      )}
      <div className="px-4">
        <Link
          className="text-2xl font-medium text-dashgreen transition hover:text-midgreen hover:no-underline"
          href={uri}
        >
          {name}
          {description && (
            <RichText tag="p" className="mt-2 text-lg">
              {description}
            </RichText>
          )}
        </Link>
        <div>
          <ReadMore href={uri} />
        </div>
      </div>
    </div>
  );
}
