import cn from "classnames";
import CustomImage, {
  CustomImageInterface,
} from "@/components/molecules/CustomImage";
import { tabularCellClasses } from "@/utilities/getTabularCellClasses";
import { RichText } from "@/components/atoms";
import React from "react";
import {
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  image?: CustomImageInterface;
  columns: number;
  variant: string;
  sticky?: boolean;
  useText?: boolean;
  content?: string;
}

export default function TabularContentImageCellBlock({
  image = {
    full: "",
    alt: "",
  },
  columns,
  variant,
  sticky,
  useText = false,
  content = "",
}: Props) {
  const blockClassNames = cn(
    tabularCellClasses({ columns, sticky, variant }),
    "pr-8"
  );

  const pTextClassNames = cn({
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  return (
    <td className={blockClassNames}>
      {!useText && image.full && (
        <div className="max-w-[150px]">
          <CustomImage
            className="h-[60px]"
            imageClassName="object-left"
            alt={image.alt}
            full={image.full}
            objectFit="contain"
          />
        </div>
      )}
      {useText && (
        <RichText className={pTextClassNames} tag="span">
          {content}
        </RichText>
      )}
    </td>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupTabularContentImageCell";

TabularContentImageCellBlock.displayName = BLOCK_NAME;

TabularContentImageCellBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          image
          metadata
          useText
        }
      }
    }
  `,
};
