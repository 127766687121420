export default function (hostname: string) {
  const isLocalhost = hostname.includes("localhost");
  const isVercel = hostname.includes(".vercel.app");
  if (isLocalhost) {
    return "";
  }

  if (isVercel) {
    return hostname;
  }

  // Exclude subdomain portion from hostname
  const parts = hostname.split(".").slice(-2);
  parts.unshift("");
  return parts.join(".");
}
