export const BUSINESS_TRIAL_SUCCESS_OPEN_GMAIL = {
  "en": "Open Gmail",
  "fr": "Ouvrir Gmail",
  "de": "Gmail öffnen",
  "es": "Abrir Gmail",
  "pt-br": "Open Gmail",
  "it": "Open Gmail",
  "ja": "Open Gmail",
  "ko": "Open Gmail",
  "nl": "Open Gmail",
  "pt-pt": "Open Gmail",
  "sv": "Open Gmail",
  "zh": "Open Gmail"
}