import useSWR from "swr";
import { BaseAPI } from "@/utilities/api/baseAPI";
import { intlCurrency } from "@/utilities/intlCurrency";
import { PlanSegment } from "@/types/PlanSegment";
import { calculateMonthlyPrice } from "./helpers";
import { DataStatus } from "../types";
import { Plans } from "./types";
import { getLocaleWithCountry } from "@/utilities/getLocaleWithCountry";

const baseApi = new BaseAPI();
const apiName = "teams";
const apiMethod = "AccessibleTeamOffers";
const payload = { login: "", userKey: "" };

const fetcher = () =>
  baseApi.postData<Plans>({ apiName, apiMethod, payload }).then((res) => res);

interface IUseTeamOffersProps {
  planSegment: PlanSegment;
  discountGiven?: number;
}

export const useTeamOffers = ({
  planSegment,
  discountGiven = 0,
}: IUseTeamOffersProps) => {
  const locale = getLocaleWithCountry();

  const { data } = useSWR(
    planSegment === "business" ||
      planSegment === "standard" ||
      planSegment === "businessPlus"
      ? "/teams/AccessibleTeamOffers"
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  if (!data) {
    return {
      teamOffers: data,
    };
  }

  const businessOffer = data.businessOffer;
  const businessPlusOffer = data.businessPlusOffer;
  const standardOffer = data.entryLevelOffer;

  // Business parsed prices
  const rawYearlyBusinessPrice = businessOffer.priceRanges[0]!.price;
  const equivalentRawYearlyBusinessPrice =
    businessOffer.priceRanges[0]!.equivalentPrice;

  const monthlyBusinessPrice = calculateMonthlyPrice(
    businessPlusOffer.duration,
    rawYearlyBusinessPrice
  );

  const equivalentMonthlyBusinessPrice = calculateMonthlyPrice(
    businessPlusOffer.duration,
    equivalentRawYearlyBusinessPrice
  );

  // Business Plus parsed prices
  const rawYearlyBusinessPlusPrice = businessPlusOffer.priceRanges[0]!.price;
  const equivalentRawYearlyBusinessPlusPrice =
    businessPlusOffer.priceRanges[0]!.equivalentPrice;

  const monthlyBusinessPlusPrice = calculateMonthlyPrice(
    businessPlusOffer.duration,
    rawYearlyBusinessPlusPrice
  );

  const equivalentMonthlyBusinessPlusPrice = calculateMonthlyPrice(
    businessPlusOffer.duration,
    equivalentRawYearlyBusinessPlusPrice
  );

  // Standard parsed prices

  const standardPlanPrice = standardOffer.priceRanges[0]!.price;
  const standardPlanEquivalentPrice =
    standardOffer.priceRanges[0]!.equivalentPrice;
  const standardMinimunSeats = 10;

  const monthlyStandardPrice = calculateMonthlyPrice(
    standardOffer.duration,
    standardPlanPrice * standardMinimunSeats
  );
  const equivalentMonthlyStandardPrice = calculateMonthlyPrice(
    standardOffer.duration,
    standardPlanEquivalentPrice * standardMinimunSeats
  );

  const yearlyBusinessPrice = rawYearlyBusinessPrice / 100;
  const equivalentYearlyBusinessPrice = equivalentRawYearlyBusinessPrice / 100;

  function calculateDiscountedPrice(
    discountedPrice: number,
    percentage: number
  ) {
    const parsedPrice = Number(discountedPrice);
    const parsedDiscount = Number(percentage);

    if (parsedDiscount === 100) return parsedPrice;

    return Number(
      (Math.ceil(parsedPrice * (100 - parsedDiscount)) / 100).toFixed(2)
    );
  }

  return {
    teamOffers: {
      isLoading: false,
      status: DataStatus.Success,
      planPrices: {
        // These prices is the per seat per month cost
        currency: businessOffer.currency,
        monthlyBusinessPrice: monthlyBusinessPrice,
        equivalentMonthlyBusinessPrice: equivalentMonthlyBusinessPrice,
        yearlyBusinessPrice: yearlyBusinessPrice,
        equivalentYearlyBusinessPrice: equivalentYearlyBusinessPrice,
        equivalentMonthlyStandardPrice,
      },
      formattedPlanPrices: {
        business: {
          monthly: intlCurrency({
            value: monthlyBusinessPrice as number,
            language: locale,
            currency: businessOffer.currency,
          }),
          equivalentMonthly: intlCurrency({
            value: equivalentMonthlyBusinessPrice as number,
            language: locale,
            currency: businessOffer.currency,
          }),
          discountedMonthlyPrice: intlCurrency({
            value: calculateDiscountedPrice(
              monthlyBusinessPrice,
              discountGiven
            ),
            language: locale,
            currency: businessOffer.currency,
          }),
        },
        businessPlus: {
          monthly: intlCurrency({
            value: monthlyBusinessPlusPrice as number,
            language: locale,
            currency: businessPlusOffer.currency,
          }),
          equivalentMonthly: intlCurrency({
            value: equivalentMonthlyBusinessPlusPrice as number,
            language: locale,
            currency: businessPlusOffer.currency,
          }),
          discountedMonthlyPrice: intlCurrency({
            value: calculateDiscountedPrice(
              monthlyBusinessPlusPrice,
              discountGiven
            ),
            language: locale,
            currency: businessPlusOffer.currency,
          }),
        },
        standard: {
          monthly: intlCurrency({
            value: monthlyStandardPrice as number,
            language: locale,
            currency: standardOffer.currency,
          }),
          equivalentMonthly: intlCurrency({
            value: monthlyStandardPrice as number,
            language: locale,
            currency: standardOffer.currency,
          }),
          discountedMonthlyPrice: intlCurrency({
            value: calculateDiscountedPrice(
              monthlyStandardPrice as number,
              discountGiven
            ),
            language: locale,
            currency: standardOffer.currency,
          }),
        },
      },
    },
  };
};
