import React from "react";
import cn from "classnames";
import BlockContent from "../molecules/BlockContent";
import { Block } from "@/types";
import { Blocks } from "@/components/molecules";
import { useTouchSlider } from "@/hooks/useTouchSlider";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  variant?: string;
  width?: string;
  paddingTop?: string;
  paddingBottom?: string;
  innerBlocks?: Block[];
}

export default function RatingCardsBlock({
  variant = "dark",
  width = "full",
  paddingTop = "none",
  paddingBottom = "large",
  innerBlocks,
  blockContentProps,
}: Props) {
  const cards = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;

        return each;
      })}
    />
  ) : null;

  const {
    wrapClassNames,
    sliderClassNames,
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
    sliderRef,
  } = useTouchSlider(4, variant, 2, false, undefined);

  const cardsClassNames = cn(
    sliderClassNames,
    "md:grid md:gap-6 md:grid-cols-2 lg:grid-cols-4 md:w-full"
  );

  const blockClassNames = cn({
    "mt-[-40px]": width !== "full",
    "mt-[-8px]": width === "full",
  });

  const combinedWrapClassNames = cn(
    wrapClassNames,
    "relative md:w-full md:overflow-auto"
  );

  return (
    <BlockContent
      className={blockClassNames}
      variant={variant}
      width={width}
      defaultValue="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      constrain
      {...blockContentProps}
    >
      <div className={combinedWrapClassNames}>
        <div
          className={cardsClassNames}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          ref={sliderRef}
        >
          {cards}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupRatingCards";

RatingCardsBlock.displayName = BLOCK_NAME;

RatingCardsBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          metadata
          variant
          width
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
