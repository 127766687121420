import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import "yup-phone-lite";
import {
  marginBMd,
  textHeadlineNeutral,
  textBodyNeutral,
  h3Text,
  pTextSm,
} from "@/constants/standardCSSClasses";
import { yupResolver } from "@hookform/resolvers/yup";
import { MarketoForm } from "@/utilities/marketo/MarketoForm";
import { Locale } from "@/types";
import { useRouter } from "next/router";
import FormField from "@/components/atoms/form-fields/FormField";
import { MarketoFormFieldAny, MarketoFormValues } from "@/types";
import { usePathname } from "next/navigation";
import { sendLeadToMarketo } from "@/utilities/sendLeadToMarketo";
import { getFormRedirectUrl } from "@/utilities/getFormRedirectUrl";
import { createBusinessTrial } from "@/utilities/createBusinessTrial";
import { useTranslate } from "@/hooks/useTranslate";
import { TRIAL_FORM_ID } from "@/constants/formIds";
import CustomButton, {
  CustomButtonInterface,
} from "@/components/molecules/CustomButton";
import { RichText } from "@/components/atoms";
// import { submitAndGetCalendarLink } from "@/utilities/loadLeanData";

type Inputs = {
  Email: string;
};

interface MarketoFormBuilderInterface {
  fields: MarketoFormFieldAny[];
  formId: number;
  embed?: boolean;
  variant?: string;
  settings: {
    hasCustomRedirect: boolean;
    redirectUrl: string;
    formErrorMessage: string;
    starterInfo?: string;
    starterButton?: CustomButtonInterface;
    formTitle?: string;
    formContent?: string;
  };
}

export default function MarketoFormBuilder({
  fields,
  formId,
  embed = false,
  settings,
}: MarketoFormBuilderInterface) {
  const { locale } = useRouter();
  const pathname = usePathname();
  const { translate } = useTranslate();
  const {
    formErrorMessage,
    redirectUrl,
    hasCustomRedirect,
    starterInfo,
    starterButton,
    formTitle,
    formContent,
  } = settings;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState(false);
  const [customFormError, setCustomFormError] = useState("");

  const errorMessages = {};

  fields
    .filter(
      (field) =>
        field.type !== "submit" &&
        field.type !== "footer" &&
        field.hide === false
    )
    .forEach((field) => {
      let fieldValidation;

      const validationMessage = field.errorMessages?.validation
        ? field.errorMessages.validation
        : field.label + " must be valid";
      const requiredMessage = field.errorMessages?.required
        ? field.errorMessages.required
        : field.label + " is required";

      switch (field.type) {
        case "text":
          fieldValidation = field.required
            ? Yup.string().required(requiredMessage)
            : Yup.string();
          break;
        case "phone":
          fieldValidation = field.required
            ? Yup.string()
                .phone(undefined, validationMessage)
                .required(requiredMessage)
            : Yup.string().phone(undefined, validationMessage);
          break;
        case "email":
          fieldValidation = field.required
            ? Yup.string().email(validationMessage).required(requiredMessage)
            : Yup.string().email(validationMessage);

          // If down the road we want to block emails that are spam/freemail, use this validation instead
          // Currently (as of June 12 2024),
          /*? Yup.string()
                .test(
                  "is-spam",
                  "",
                  (email) => !isSpamEmail(email) && !isFreeEmail(email)
                )
                .email(validationMessage)
                .required(requiredMessage)
            : Yup.string()
                .test(
                  "is-spam",
                  "",
                  (email) => !isSpamEmail(email) && !isFreeEmail(email)
                )
                .email(validationMessage);*/
          break;
        case "select":
          fieldValidation = field.required
            ? Yup.string().required(requiredMessage)
            : Yup.string();
          break;
        case "checkbox":
          fieldValidation = field.required
            ? Yup.bool()
                .oneOf([true], requiredMessage)
                .required(requiredMessage)
            : Yup.bool().oneOf([true], requiredMessage);
          break;
        default:
          fieldValidation = field.required
            ? Yup.string().required(requiredMessage)
            : Yup.string();
          break;
      }

      // @ts-ignore
      errorMessages[field.name] = fieldValidation;
    });

  const defaultValues = fields.reduce((acc, each) => {
    if (each.defaultValue && !each.hide) {
      acc[each.name] = each.defaultValue;
    }

    return acc;
  }, {} as { [key: string]: string });

  const validationSchema = Yup.object().shape(errorMessages);

  const { register, handleSubmit, watch, reset, formState } = useForm<
    Inputs | {}
  >({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const { errors, isValid } = formState;

  /**
   * There is an issue with the affiliate form where someone can use the type of partnership dropdown and be redirected.
   * When they click the back button, the form will sometimes keep the value that they changed to,
   * rather than the default value for that page.
   * Seems to need the setTimeout
   */
  useEffect(() => {
    setTimeout(() => {
      reset(defaultValues);
    }, 500);
  }, []);

  // @ts-ignore
  const watchPlan: string = watch("product_plan__c", "business");

  const onSubmit = async (data: MarketoFormValues) => {
    if (isSubmitting) return;

    /**
     * Typically react-form-hook would be able to handle the submitting state on its own.
     * see: https://react-hook-form.com/api/useform/formstate/
     * In this case Marketo requires the use of callbacks (instead of promises)
     * which forces us to handle submitting state with useState and callbacks.
     **/
    setIsSubmitting(true);

    // Save form data in localStorage for use in the success page (ex: if we need to resend confirmation email)
    localStorage.setItem("form-submission-data", JSON.stringify(data));

    // If this is a trial form, create the trial
    if (formId === TRIAL_FORM_ID) {
      const trialResult = await createBusinessTrial(data, locale);

      const trialErrorMessage =
        trialResult && typeof trialResult !== "boolean"
          ? trialResult?.errorMessage
          : "";

      // If there was an error creating the trial, then set the error message and return without sending lead to Marketo
      if (trialErrorMessage) {
        setIsSubmitting(false);
        setCustomFormError(translate(trialErrorMessage));
        setFormError(true);
        return;
      }
    }

    /*
      Right now we wait for a event in the success page which returns the calendar link if available. 
      This causes a copy loading issue since it changes from the default when the link is saved in the state.
      In the future we can look into making the below function work with the current flow.  
    */
    // const calendarLink = await submitAndGetCalendarLink(formId, data);

    const successRedirectUrl = getFormRedirectUrl({
      data,
      locale,
      pathname,
      calendarLink: undefined,
      redirectUrl: hasCustomRedirect ? redirectUrl : "",
      formId,
    });

    // Send to Marketo
    sendLeadToMarketo(
      data,
      formId,
      locale as Locale,
      successRedirectUrl,
      setIsSubmitting,
      setFormError
    );
  };

  return (
    <>
      {formTitle && (
        <RichText
          tag="h2"
          className={cn(h3Text, textHeadlineNeutral, "mb-2 mt-0")}
        >
          {formTitle}
        </RichText>
      )}
      {formContent && (
        <RichText tag="p" className={cn(marginBMd, pTextSm, textBodyNeutral)}>
          {formContent}
        </RichText>
      )}
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      {!embed && (
        <form
          // @ts-ignore
          onSubmit={handleSubmit(onSubmit)}
          className={`marketo-form-${formId} md:mx-auto`}
          noValidate
        >
          <div className="align grid grid-cols-2 gap-x-6 gap-y-6 lg:gap-y-4">
            {fields
              .filter((field) => field.hide === false)
              .map((field) => {
                // Temporary: the starter plan is being removed and plan should default to "business" for the time being
                if (field.id === "product_plan__c") {
                  return (
                    <FormField
                      key={field.id}
                      field={{ ...field, type: "hidden", value: "business" }}
                      register={register}
                      // @ts-ignore
                      errors={errors}
                      isSubmitting={isSubmitting}
                      watch={watch}
                    />
                  );
                }

                return (
                  <FormField
                    key={field.id}
                    field={field}
                    register={register}
                    // @ts-ignore
                    errors={errors}
                    isSubmitting={isSubmitting}
                    watch={watch}
                    formIsValid={isValid}
                    hasNewDesign={
                      formTitle !== undefined || formContent !== undefined
                    }
                  />
                );
              })}
          </div>
          {watchPlan === "starter" && starterInfo && (
            <>
              <RichText className="mt-6" tag="p">
                {starterInfo}
              </RichText>
              {starterButton && (
                <CustomButton
                  className="mt-6"
                  variant="dark"
                  text={starterButton.text}
                  rel={starterButton.rel}
                  target={starterButton.target}
                  href={starterButton.href}
                  align="wide"
                />
              )}
            </>
          )}
          {formError && (
            <div className="py-2 text-xs text-red-600">
              {customFormError ? customFormError : formErrorMessage}
            </div>
          )}
        </form>
      )}
      <MarketoForm marketoFormId={formId} hidden={!embed} />
    </>
  );
}
