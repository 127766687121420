import { FetchResponse, GreenhouseJob, ParsedResults } from "./types";

export const parseJobs = (json: FetchResponse) => {
  const parsedResults: ParsedResults = {
    jobsList: [],
    departmentList: [],
    locationList: [],
  };

  const departmentSet = new Set<string>();
  const locationsSet = new Set<string>();

  json.jobs.forEach((job: GreenhouseJob) => {
    parsedResults.jobsList.push({
      title: job.title,
      departments: job.departments,
      location: job.location.name,
      url: job.absolute_url,
      id: job.id,
    });

    job.departments.map((department) => departmentSet.add(department.name));

    locationsSet.add(job.location.name);
  });

  // Array of Departments
  parsedResults.departmentList = [...departmentSet];

  // Array of Locations
  parsedResults.locationList = [...locationsSet];

  return parsedResults;
};
