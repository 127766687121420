import cn from "classnames";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor?: string;
  className?: string;
  style?: {
    color: {
      background: string;
    };
  };
}
/**
 * The separator block can be used to separate content. Wide can be selected in wordpress for the separator to go the full width of the container
 */
export default function Separator({ anchor, className, style }: Props) {
  return (
    <div
      className={cn(
        "mx-auto my-4",
        className?.includes("is-style-wide") ? "w-full max-w-7xl" : "w-24",
        !className?.includes("p-0") &&
          className?.includes("is-style-wide") &&
          "px-8",
        className
      )}
    >
      <hr
        id={anchor}
        className={"border-b border-dashgreen-text"}
        // The style comes as background color from wordpress but it needs to be borderColor
        style={{ borderColor: style?.color.background }}
      />
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreSeparator";

Separator.displayName = BLOCK_NAME;

Separator.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          className
          style
        }
      }
    }
  `,
};
