import { READ_MORE_BUTTON } from '@/i18n/translations/READ_MORE_BUTTON';
import { useTranslate } from '@/hooks/useTranslate';
import Link from 'next/link';

interface Props {
  href: string;
  label?: string;
}

export default function ReadMore({ href }: Props) {
  const { translate } = useTranslate();

  return (
    <Link
      href={href}
      className="mt-3 inline-block font-bold uppercase underline transition hover:text-dashgreen-text"
    >
      {translate(READ_MORE_BUTTON)}
    </Link>
  );
}
