import React, { HTMLAttributes } from "react";
import { I18nKey, Locale } from "@/types";

interface I18nElementProps extends HTMLAttributes<HTMLSpanElement> {
  i18nKey: I18nKey;
  locale: Locale;
}

/**
 * Render translation to a `span` using `dangerouslySetInnerHTML` to insert the
 * translated value into the DOM.
 *
 * **Note:** Intended to be used with i18n strings that have inline html only
 */
export const I18nElement = ({
  i18nKey,
  locale = "en",
  ...rest
}: I18nElementProps) => {
  const translationValue = i18nKey[locale] || i18nKey["en"];

  return (
    <span
      // eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention
      dangerouslySetInnerHTML={{ __html: translationValue }}
      {...rest}
    />
  );
};
