import UAParser from "ua-parser-js";

const affiliateSetup = {
  getUA: () => {
    return new UAParser().getResult();
  },

  getAdjustUrl: (
    iosCode: string | undefined,
    androidCode: string | undefined,
    utmCampaign: string,
    utmContent: string,
    utmTerm: string,
    utmMedium: string
  ) => {
    if (!utmCampaign || !utmContent) {
      return null;
    }

    const UAParsedResult = affiliateSetup.getUA();

    let adjustUrl = null;
    const params = `?campaign=${utmMedium}&adgroup=${utmCampaign}creative=${utmTerm}&label=${utmContent}`;
    if (UAParsedResult.os.name === "iOS") {
      adjustUrl = `https://app.adjust.com/${iosCode}${params}`;
    } else if (UAParsedResult.os.name === "Android") {
      adjustUrl = `https://app.adjust.com/${androidCode}${params}`;
    }
    return adjustUrl;
  },
};

export default affiliateSetup;
