import BaseWS1 from "./baseWS1";

interface AuthenticationParams {
  login: string;
}

/**
 * Authentication WS library
 */
export default class Authentication extends BaseWS1 {
  constructor() {
    super();
    this.WSNAME = "authentication";
    this.WSVERSION = "6";
  }

  /**
   * Posts a login email to check if an account exists
   * @param params
   */
  exists = (params: AuthenticationParams) => {
    return this.postData({
      wsMethod: "exists",
      params: { ...params },
      returnType: "text",
    });
  };

  /**
   * DownloadDashlanePosts a login email to get an otp code sent to phone.
   * @param {Object literal} params pass the data e.g. {Email: 'test@sse.com, ...}
   */
  otpPhoneLost = (params: AuthenticationParams) => {
    return new Promise((resolve, reject) => {
      const url = "otpphonelost";

      const combinedParams: AuthenticationParams & Record<string, string> = {
        ...params,
      };

      this.postData({ wsMethod: url, params: combinedParams })
        .then((result) => {
          if (result && result.code === 200) {
            resolve({ success: true });
            return;
          } else if (result && result.code === 429) {
            reject(new Error("Throttled"));
            return;
          } else {
            if (result.content) {
              reject(new Error(result.content));
              return;
            }
          }

          reject(new Error("Unknown error"));
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  };
}
