import React from "react";
import { gql } from "@apollo/client";

import * as MENUS from "@/constants/menus";
import { locales } from "@/constants/locales";
import {
  BlogInfoFragment,
  MenuItemFragment,
  PreviewFragment,
} from "@/fragments";
import { Main } from "@/components/atoms";
import { SEO } from "@/components/molecules";
import { Archive, Footer } from "@/components/organisms";
import type {
  Category,
  GeneralSettings,
  Locale,
  PostPreviews,
  Translated,
} from "@/types";
import { PAGE_SIZE } from "@/constants/utility";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import NewMenuItemFragment from "@/fragments/NewMenuItem";
import DynamicHeader from "@/components/organisms/DynamicHeader";

interface Props {
  // Seed Node isn't called for previews
  __SEED_NODE__?: {
    translated: Translated[];
  };
  data: {
    categories: {
      nodes: Category[];
    };
    footerMenuItems: { nodes: never[] };
    generalSettings: GeneralSettings;
    headerMenuItems: { nodes: never[] };
    newMenuItems: { nodes: never[] };
    page: { title: string };
    posts: PostPreviews;
  };
  locale: Locale;
}

export default function Component(props: Props) {
  let translated: Translated[] = [];
  // Check if this is the english and if has translated.
  const uri_in_english =
    // @ts-ignore
    props.__SEED_NODE__?.language?.locale === "en_US"
      ? // @ts-ignore
        props.__SEED_NODE__?.uri
      : props.__SEED_NODE__?.translated?.find(
          (each: Translated) => each.language?.locale === "en_US"
        )?.uri;
  if (props.__SEED_NODE__?.translated) {
    translated =
      props.__SEED_NODE__?.translated
        .filter((each: Translated) => {
          return each.status === "publish";
        })
        .map((each: Translated) => {
          const each_item = { ...each };
          if (each.language?.locale !== "en_US") {
            each_item.uri = `/${
              locales[each.language?.locale]
            }${uri_in_english}`;
          }
          return each_item;
        }) ?? [];
  }

  const { locale } = props;
  const { title } = props.data.page;
  const { nodes: posts } = props.data.posts;
  const { nodes: categories } = props.data.categories;
  const { offsetPagination } = props.data.posts.pageInfo;
  const { title: metaTitle, description: metaDescription } =
    props.data.generalSettings;
  const primaryMenu = props.data.headerMenuItems.nodes ?? [];
  const footerMenu = props.data.footerMenuItems.nodes ?? [];
  const newMenuItems = props.data.newMenuItems.nodes ?? [];

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        locale={locale}
        uri={uri_in_english}
        availableLocales={translated}
      />
      <DynamicHeader
        title={metaTitle}
        description={metaDescription}
        menuItems={primaryMenu}
        enhancedMenuItems={newMenuItems}
      />
      <Main>
        <Archive
          categories={categories}
          heading={title}
          posts={posts}
          offsetPagination={offsetPagination}
          variables={{
            locale,
          }}
        />
      </Main>
      <Footer title={metaTitle} menuItems={footerMenu} />
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${MenuItemFragment}
  ${NewMenuItemFragment}
  ${PreviewFragment}
  query GetArchive(
    $asPreview: Boolean = false
    $databaseId: ID!
    $footerLocation: MenuLocationEnum
    $headerLocation: MenuLocationEnum
    $locale: LanguageCodeFilterEnum = EN
    $newMenuLocation: MenuLocationEnum
    $size: Int = 12
    $offset: Int = 0
  ) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
    }
    posts(where: { language: $locale, offsetPagination: {offset: $offset, size: $size} }) {
      pageInfo {
        offsetPagination {
          total
          page
          pages
        }
      }
      nodes {
        ...PreviewFragment
      }
    }
    categories(where: { hideEmpty: true, language: $locale }, first: 28) {
      nodes {
        name
        uri
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    newMenuItems: menuItems(
      where: { location: $newMenuLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...NewMenuItemFragment
      }
    }
  }
`;

Component.variables = (
  { databaseId }: { databaseId: string },
  { asPreview, locale }: { asPreview?: boolean; locale: Locale },
  extra: any
) => {
  const { page } = extra;
  return {
    asPreview,
    databaseId,
    headerLocation: MENUS.newHeaderLocation(locale),
    footerLocation: MENUS.newFooterLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
    size: PAGE_SIZE,
    offset: page ? PAGE_SIZE * (page - 1) : 0,
  };
};
