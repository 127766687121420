import { useState } from "react";
import { MarketoFormErrorsInterface } from "@/types";
import {
  getFieldWrapClasses,
  getRequired,
  getErrorMessages,
} from "@/utilities/marketoForms";
import cn from "classnames";
import { RichText } from "@/components/atoms";
import { MarketoFormFieldAny } from "@/types";
import { checkmark } from "@/constants/icons";
import { iconFillInverse } from "@/constants/standardCSSClasses";

interface CheckboxFieldInterface {
  field: MarketoFormFieldAny;
  register: Function;
  errors: MarketoFormErrorsInterface;
}

export default function CheckboxField({
  field,
  register,
  errors,
}: CheckboxFieldInterface) {
  const [checked, setChecked] = useState(false);
  const inputClassNames = cn(
    iconFillInverse,
    "active:bg-blue-8 w-5 h-5 border rounded-md absolute cursor-pointer",
    {
      "border-pink-dark focus:border-pink-dark": errors[field.name],
      "border-grey-1": !checked && !errors[field.name],
      "border-blue-8 bg-blue-8": checked && !errors[field.name],
      "bg-white": !checked && !errors[field.name],
    }
  );

  const { onChange } = register(field.name, { required: field.required });

  return (
    <div className={getFieldWrapClasses(field)}>
      <div className="flex items-center gap-2">
        <div className="relative h-5 w-5">
          <div className={inputClassNames}>
            {checked && (
              <span className="ml-0.5 mt-0.5 inline-block">{checkmark}</span>
            )}
          </div>
          <input
            className="absolute h-5 w-5 cursor-pointer opacity-0"
            id={field.name}
            type="checkbox"
            value={true}
            {...register(field.name, { required: field.required })}
            onChange={(e) => {
              setChecked(e.target.checked);
              onChange(e);
            }}
          />
        </div>
        <div className="ml-2 grow">
          <RichText tag="label">{field.label}</RichText>
          {getRequired(field)}
        </div>
      </div>
      {getErrorMessages(field, errors)}
    </div>
  );
}
