import { useState } from "react";
import cn from "classnames";
import Icon from "@/components/molecules/Icon";
import {
  textHeadlineInverse,
  textHeadlineNeutral,
  h6Text,
} from "@/constants/standardCSSClasses";
import { iconPlus } from "@/constants/icons";
import dynamic from "next/dynamic";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

const FAQItem = dynamic(() => import("../molecules/FAQItem"), {
  ssr: false,
});

interface Props {
  variant: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  defaultOpen: boolean;
}

export default function FAQItemBlock({
  heading = "",
  headingOptions = {
    tag: 3,
  },
  content = "",
  variant = "white",
  defaultOpen = false,
}: Props) {
  const [open, setOpen] = useState(defaultOpen);

  const headerClassNames = cn(h6Text, "text-left grow my-0 cursor-pointer", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const iconClassNames = cn("ms-5 transition-all cursor-pointer", {
    "rotate-45": open,
  });

  return (
    <div className="border-b-0 border-l-0 border-r-0 border-t border-solid py-4">
      <button
        className="flex w-full items-start items-center justify-between"
        onClick={() => setOpen(!open)}
      >
        <CustomHeading
          heading={heading}
          headingOptions={headingOptions}
          headingClassNames={headerClassNames}
        />
        <Icon
          className={iconClassNames}
          source={iconPlus}
          variant={variant}
          width={20}
          name={open ? "close" : "open"}
        />
      </button>
      <FAQItem variant={variant} open={open} content={content} />
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupFaqItem";

FAQItemBlock.displayName = BLOCK_NAME;

FAQItemBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          heading
          metadata
        }
      }
    }
  `,
};
