export const getWebCrypto = (): Crypto | { randomBytes: Function } => {
  // Browser support (not IE)
  if (typeof window !== "undefined" && window.crypto) {
    return window.crypto;
  }

  // IE11 support
  if (typeof window !== "undefined" && (window as any)["msCrypto"]) {
    return (window as any)["msCrypto"];
  }

  try {
    // Node support
    return require("crypto");
  } catch (err) {
    throw new Error(
      "Crypto API unavailable - Please implement polyfill for this environment"
    );
  }
};

export const getCryptoStrongRandomInteger = (
  max?: number
): number | undefined => {
  const crypto = getWebCrypto();
  if ("getRandomValues" in crypto) {
    const randomValues = new Uint16Array(1);
    crypto.getRandomValues(randomValues);
    if (randomValues[0] === undefined) return;
    return max ? randomValues[0] % max : randomValues[0];
  } else if ("randomBytes" in crypto) {
    const buf = crypto.randomBytes(2);
    const val = buf.readUInt16LE(0);
    return max ? val % max : val;
  } else {
    throw new Error(
      "Crypto API unavailable - Please implement polyfill for this environment"
    );
  }
};
