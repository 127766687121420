import { useRouter } from "next/router";
import cn from "classnames";

import parseHtml from "@/utilities/parseHtml";
import getBlockStyles from "@/utilities/getBlockStyles";
import { Style } from "@/types";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  align?: string;
  anchor?: string;
  className?: string;
  content: string;
  fontSize?: "small" | "base" | "normal" | "large" | "huge";
  style?: Style;
  textColor?: string;
}

/**
 * The paragraph block also supports custom text sizes and colors through the wordpress sidebar
 */
export default function ParagraphBlock({
  align,
  anchor,
  className,
  content,
  style,
  textColor,
  fontSize = "normal",
}: Props) {
  const { locale } = useRouter();
  const paragraphStyles = getBlockStyles({ style });

  return (
    <p
      id={anchor}
      className={cn(
        "my-3",
        paragraphStyles["backgroundColor"] && "px-8 py-6",
        align === "center" && "mx-auto text-center",
        align === "right" && "text-right",
        textColor === "white" && "white",
        fontSize === "small" && "text-xs",
        fontSize === "base" && "text-base",
        fontSize === "normal" && "text-lg",
        fontSize === "large" && "text-xl",
        fontSize === "huge" && "text-2xl",
        !className?.includes("max-w-") && "max-w-7xl",
        className
      )}
      style={paragraphStyles}
    >
      {Boolean(content) && parseHtml(content, locale)}
    </p>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreParagraph";

ParagraphBlock.displayName = BLOCK_NAME;

ParagraphBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          align
          anchor
          backgroundColor
          className
          content
          cssClassName
          direction
          dropCap
          fontFamily
          fontSize
          gradient
          metadata
          placeholder
          style
          textColor
        }
      }
    }
  `,
};
