export const FEATURES_PAGE_BEST_SUBHEADING_2_V1 = {
  "en": "We help you identify security threats",
  "fr": "",
  "de": "",
  "es": "",
  "pt-br": "We help you identify security threats",
  "it": "We help you identify security threats",
  "ja": "We help you identify security threats",
  "ko": "We help you identify security threats",
  "nl": "We help you identify security threats",
  "pt-pt": "We help you identify security threats",
  "sv": "We help you identify security threats",
  "zh": "We help you identify security threats"
}