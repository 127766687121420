import { generateNumberArray } from "@/utilities/generateNumberArray";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MD_BREAK } from "@/constants/utility";

export const generatePaginationArray = (total: number, current: number) => {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < MD_BREAK;
  const pages = [];
  const ellipses = "&hellip;";

  if (isMobile) {
    if (total > 5) {
      pages.push(1);
      if (current <= 4) {
        for (let i = 2; i <= current + 1; i++) {
          pages.push(i);
        }
      }
      pages.push(ellipses);
      if (current >= total - 3) {
        for (let i = current - 1; i < total - 1; i++) {
          pages.push(i);
        }
      } else if (current > 4) {
        for (let i = current - 1; i <= current + 1; i++) {
          pages.push(i);
        }
        pages.push(ellipses);
      }
      pages.push(total);
    } else {
      pages.push(...generateNumberArray(total));
    }
  } else {
    if (total > 8) {
      pages.push(...[1, 2, 3]);
      if (current <= 5 && current > 2) {
        for (let i = 4; i <= current + 1; i++) {
          pages.push(i);
        }
      }
      pages.push(ellipses);
      if (current >= total - 3) {
        for (let i = current - 1; i < total - 1; i++) {
          pages.push(i);
        }
      } else if (current > 5) {
        for (let i = current - 1; i <= current + 1; i++) {
          pages.push(i);
        }
        pages.push(ellipses);
      }
      pages.push(...[total - 1, total]);
    } else {
      pages.push(...generateNumberArray(total));
    }
  }

  return pages;
};
