import cn from "classnames";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import { RichText } from "@/components/atoms";
import {
  textHeadlineInverse,
  textHeadlineNeutral,
  textBodyInverse,
  textBodyNeutral,
  pTextSm,
  pTextL,
  marginB2XS,
  marginBMd,
} from "@/constants/standardCSSClasses";
import { iconPlus } from "@/constants/icons";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import React from "react";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  variant: string;
  icon: IconInterface;
  iconStyle: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  includeButtons?: boolean;
  includeIcons?: boolean;
  button?: CustomButtonInterface;
  hasNewDesign?: boolean;
}

export default function IconFeatureBlock({
  heading = "",
  content = "",
  variant = "white",
  iconStyle = "minimal",
  headingOptions = {
    tag: 3,
  },
  icon = {
    source: iconPlus,
  },
  includeButtons = false,
  includeIcons = true,
  button,
  hasNewDesign = false,
}: Props) {
  const headerClassNames = cn(pTextL, marginB2XS, "mt-0", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const iconRichTextMargin = includeIcons ? "ms-5" : "";

  const pTextClassNames = cn("[&>a]:underline [&>strong>a]:underline", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
    [`[&_a]:${textBodyInverse}`]: variant === "dark",
    [`[&_a]:text-blue-8`]: variant !== "dark",
    [`[&_strong]:font-medium`]: true && !hasNewDesign,
    [pTextSm]: heading,
    [textHeadlineInverse]: hasNewDesign && variant === "dark",
    [`[&_strong]:font-bold`]: hasNewDesign,
  });

  if (!icon.source) {
    icon.source = iconPlus;
  }

  const buttons = [];

  if (includeButtons) {
    buttons.push({
      ...button,
      variant: variant,
      textOnly: true,
      arrow: true,
      size: "md",
      className: hasNewDesign && variant === "dark" ? "!text-white" : "",
    });
  }

  return (
    <div
      className={cn("flex", {
        "items-start": heading,
        "items-center": !heading,
        [marginBMd]: heading,
        "mb-4": !heading,
      })}
    >
      {includeIcons && (
        <Icon
          {...icon}
          variant={variant}
          iconStyle={iconStyle}
          width={heading ? 24 : 20}
          className="self-start"
        />
      )}
      <span className={iconRichTextMargin}>
        {heading && (
          <CustomHeading
            heading={heading}
            headingOptions={headingOptions}
            headingClassNames={headerClassNames}
          />
        )}
        <RichText className={pTextClassNames} tag="p">
          {content}
        </RichText>
        {includeButtons && (
          <CustomButtonGroup
            marginTop="mt-2.5"
            align="left"
            buttons={buttons}
            mobileWide={false}
          />
        )}
      </span>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupIconFeature";

IconFeatureBlock.displayName = BLOCK_NAME;

IconFeatureBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          button
          className
          content
          heading
          icon
          iconStyle
          includeButtons
          includeIcons
          metadata
        }
      }
    }
  `,
};
