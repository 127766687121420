import blocks from "@/wp-blocks";
import { gql } from "@apollo/client";

export const GetEditorBlocksFragments = () => {
  return gql`
    ${blocks.TenupAccolade.fragments.entry}
    ${blocks.TenupAccolades.fragments.entry}
    ${blocks.TenupAccoladesGrid.fragments.entry}
    ${blocks.TenupAccoladesGridItem.fragments.entry}
    ${blocks.TenupBanner.fragments.entry}
    ${blocks.TenupCareers.fragments.entry}
    ${blocks.CoreButton.fragments.entry}
    ${blocks.CoreButtons.fragments.entry}
    ${blocks.CoreColumn.fragments.entry}
    ${blocks.CoreCode.fragments.entry}
    ${blocks.CorePreformatted.fragments.entry}
    ${blocks.CoreColumns.fragments.entry}
    ${blocks.TenupContentCard.fragments.entry}
    ${blocks.TenupContentCards.fragments.entry}
    ${blocks.TenupContentCardsManual.fragments.entry}
    ${blocks.TenupCookiePreference.fragments.entry}
    ${blocks.TenupCookiePreferences.fragments.entry}
    ${blocks.CoreCover.fragments.entry}
    ${blocks.TenupCta.fragments.entry}
    ${blocks.TenupEmailCapture.fragments.entry}
    ${blocks.TenupEmailCaptureItem.fragments.entry}
    ${blocks.CoreEmbed.fragments.entry}
    ${blocks.TenupFaq.fragments.entry}
    ${blocks.TenupFaqItem.fragments.entry}
    ${blocks.TenupFeaturesInteractive.fragments.entry}
    ${blocks.TenupFeaturesInteractiveItem.fragments.entry}
    ${blocks.TenupFeaturesStatic.fragments.entry}
    ${blocks.CoreGallery.fragments.entry}
    ${blocks.TenupTwoColumnNarrative.fragments.entry}
    ${blocks.CoreGroup.fragments.entry}
    ${blocks.CoreHeading.fragments.entry}
    ${blocks.TenupHero.fragments.entry}
    ${blocks.TenupHeroRtb.fragments.entry}
    ${blocks.TenupHeroRtbSection.fragments.entry}
    ${blocks.TenupHomepageHero.fragments.entry}
    ${blocks.TenupIconCards.fragments.entry}
    ${blocks.TenupIconFeature.fragments.entry}
    ${blocks.TenupIconFeatureRtb.fragments.entry}
    ${blocks.CoreImage.fragments.entry}
    ${blocks.TenupImageCardAddon.fragments.entry}
    ${blocks.TenupImageCardsAddon.fragments.entry}
    ${blocks.TenupImageCards.fragments.entry}
    ${blocks.TenupInnovation.fragments.entry}
    ${blocks.TenupInnovationSlide.fragments.entry}
    ${blocks.TenupJumpLinkSection.fragments.entry}
    ${blocks.CoreList.fragments.entry}
    ${blocks.CoreListItem.fragments.entry}
    ${blocks.TenupMarketoForm.fragments.entry}
    ${blocks.TenupMarketoFormOverlay.fragments.entry}
    ${blocks.CoreMediaText.fragments.entry}
    ${blocks.TenupNewsFeed.fragments.entry}
    ${blocks.TenupNewsFeedArticle.fragments.entry}
    ${blocks.TenupOfferOverlay.fragments.entry}
    ${blocks.CoreParagraph.fragments.entry}
    ${blocks.TenupPartnershipForm.fragments.entry}
    ${blocks.TenupPasswordGenerator.fragments.entry}
    ${blocks.TenupPricing.fragments.entry}
    ${blocks.TenupPricingColumn.fragments.entry}
    ${blocks.TenupPricingItem.fragments.entry}
    ${blocks.CorePullquote.fragments.entry}
    ${blocks.CoreQuote.fragments.entry}
    ${blocks.TenupRatingCard.fragments.entry}
    ${blocks.TenupRatingCards.fragments.entry}
    ${blocks.CoreSeparator.fragments.entry}
    ${blocks.CoreShortcode.fragments.entry}
    ${blocks.CoreSpacer.fragments.entry}
    ${blocks.CoreTable.fragments.entry}
    ${blocks.TenupTabularContent.fragments.entry}
    ${blocks.TenupTabularContentRow.fragments.entry}
    ${blocks.TenupTabularContentPricingCell.fragments.entry}
    ${blocks.TenupTabularContentIconCell.fragments.entry}
    ${blocks.TenupTabularContentImageCell.fragments.entry}
    ${blocks.TenupTabularContentTextCell.fragments.entry}
    ${blocks.TenupTeam.fragments.entry}
    ${blocks.TenupTeamMember.fragments.entry}
    ${blocks.TenupTestimonial.fragments.entry}
    ${blocks.TenupTestimonials.fragments.entry}
    ${blocks.TenupUsernameGenerator.fragments.entry}
    ${blocks.TenupValidation.fragments.entry}
    ${blocks.CoreVideo.fragments.entry}
    ${blocks.TenupSecondaryBanner.fragments.entry}
    ${blocks.CoreHtml.fragments.entry}
  `;
};

export const GetEditorBlocksKeys = () => {
  return `
    editorBlocks {
      ...${blocks.TenupAccolade.fragments.key}
      ...${blocks.TenupAccolades.fragments.key}
      ...${blocks.TenupAccoladesGrid.fragments.key}
      ...${blocks.TenupAccoladesGridItem.fragments.key}
      ...${blocks.TenupBanner.fragments.key}
      ...${blocks.TenupCareers.fragments.key}
      ...${blocks.CoreButton.fragments.key}
      ...${blocks.CoreButtons.fragments.key}
      ...${blocks.CoreColumn.fragments.key}
      ...${blocks.CoreCode.fragments.key}
      ...${blocks.CorePreformatted.fragments.key}
      ...${blocks.CoreColumns.fragments.key}
      ...${blocks.TenupContentCard.fragments.key}
      ...${blocks.TenupContentCards.fragments.key}
      ...${blocks.TenupContentCardsManual.fragments.key}
      ...${blocks.TenupCookiePreference.fragments.key}
      ...${blocks.TenupCookiePreferences.fragments.key}
      ...${blocks.CoreCover.fragments.key}
      ...${blocks.TenupCta.fragments.key}
      ...${blocks.TenupEmailCapture.fragments.key}
      ...${blocks.TenupEmailCaptureItem.fragments.key}
      ...${blocks.CoreEmbed.fragments.key}
      ...${blocks.TenupFaq.fragments.key}
      ...${blocks.TenupFaqItem.fragments.key}
      ...${blocks.TenupFeaturesInteractive.fragments.key}
      ...${blocks.TenupFeaturesInteractiveItem.fragments.key}
      ...${blocks.TenupFeaturesStatic.fragments.key}
      ...${blocks.CoreGallery.fragments.key}
      ...${blocks.TenupTwoColumnNarrative.fragments.key}
      ...${blocks.CoreGroup.fragments.key}
      ...${blocks.CoreHeading.fragments.key}
      ...${blocks.TenupHero.fragments.key}
      ...${blocks.TenupHeroRtb.fragments.key}
      ...${blocks.TenupHeroRtbSection.fragments.key}
      ...${blocks.TenupHomepageHero.fragments.key}
      ...${blocks.TenupIconCards.fragments.key}
      ...${blocks.TenupIconFeature.fragments.key}
      ...${blocks.TenupIconFeatureRtb.fragments.key}
      ...${blocks.CoreImage.fragments.key}
      ...${blocks.TenupImageCardAddon.fragments.key}
      ...${blocks.TenupImageCardsAddon.fragments.key}
      ...${blocks.TenupImageCards.fragments.key}
      ...${blocks.TenupInnovation.fragments.key}
      ...${blocks.TenupInnovationSlide.fragments.key}
      ...${blocks.TenupJumpLinkSection.fragments.key}
      ...${blocks.CoreList.fragments.key}
      ...${blocks.CoreListItem.fragments.key}
      ...${blocks.TenupMarketoForm.fragments.key}
      ...${blocks.TenupMarketoFormOverlay.fragments.key}
      ...${blocks.CoreMediaText.fragments.key}
      ...${blocks.TenupNewsFeed.fragments.key}
      ...${blocks.TenupNewsFeedArticle.fragments.key}
      ...${blocks.TenupOfferOverlay.fragments.key}
      ...${blocks.CoreParagraph.fragments.key}
      ...${blocks.TenupPartnershipForm.fragments.key}
      ...${blocks.TenupPasswordGenerator.fragments.key}
      ...${blocks.TenupPricing.fragments.key}
      ...${blocks.TenupPricingColumn.fragments.key}
      ...${blocks.TenupPricingItem.fragments.key}
      ...${blocks.CoreQuote.fragments.key}
      ...${blocks.CorePullquote.fragments.key}
      ...${blocks.TenupRatingCard.fragments.key}
      ...${blocks.TenupRatingCards.fragments.key}
      ...${blocks.CoreSeparator.fragments.key}
      ...${blocks.CoreShortcode.fragments.key}
      ...${blocks.CoreSpacer.fragments.key}
      ...${blocks.CoreTable.fragments.key}
      ...${blocks.TenupTabularContent.fragments.key}
      ...${blocks.TenupTabularContentRow.fragments.key}
      ...${blocks.TenupTabularContentPricingCell.fragments.key}
      ...${blocks.TenupTabularContentIconCell.fragments.key}
      ...${blocks.TenupTabularContentImageCell.fragments.key}
      ...${blocks.TenupTabularContentTextCell.fragments.key}
      ...${blocks.TenupTeam.fragments.key}
      ...${blocks.TenupTeamMember.fragments.key}
      ...${blocks.TenupTestimonial.fragments.key}
      ...${blocks.TenupTestimonials.fragments.key}
      ...${blocks.TenupUsernameGenerator.fragments.key}
      ...${blocks.TenupValidation.fragments.key}
      ...${blocks.CoreVideo.fragments.key}
      ...${blocks.TenupSecondaryBanner.fragments.key}
      ...${blocks.CoreHtml.fragments.key}
    }
  `;
};
