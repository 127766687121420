const leetLetters = [
  "4",
  "8",
  "C",
  "d",
  "3",
  "F",
  "6",
  "h",
  "!",
  "J",
  "k",
  "1",
  "m",
  "n",
  "0",
  "p",
  "Q",
  "r",
  "5",
  "7",
  "u",
  "v",
  "w",
  "x",
  "Y",
  "2",
];
const letters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export function leetSpeakGenerator(text: string) {
  return [...text]
    .map((letter) => {
      const index = letters.indexOf(letter.toLowerCase());
      return index > -1 ? leetLetters[index] : letter;
    })
    .join("");
}
