import { VideoEmbed } from "@/components/molecules";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  className?: string;
  url: string;
  caption?: string;
  providerNameSlug: string;
}

/**
 * Supported urls are currently Youtube and Vimeo
 */
export default function BlockEmbed({
  className,
  url,
  caption,
  providerNameSlug,
}: Props) {
  const supportedVideoTypes = ["youtube", "vimeo"];

  return supportedVideoTypes.includes(providerNameSlug) ? (
    <VideoEmbed
      className={className}
      url={url}
      caption={caption}
      type={providerNameSlug}
    />
  ) : (
    <>Embed provider {providerNameSlug} not yet supported.</>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreEmbed";

BlockEmbed.displayName = BLOCK_NAME;

BlockEmbed.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          caption
          className
          providerNameSlug
          url
        }
      }
    }
  `,
};
