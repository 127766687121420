export const BUY_FORM_VAT = {
  "en": "VAT",
  "fr": "TVA",
  "de": "MwSt",
  "es": "IVA",
  "pt-br": "VAT",
  "it": "VAT",
  "ja": "VAT",
  "ko": "VAT",
  "nl": "VAT",
  "pt-pt": "VAT",
  "sv": "VAT",
  "zh": "VAT"
}