import React, { Fragment, useState } from "react";
import Link from "next/link";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import Icon from "@/components/molecules/Icon";
import { headerContact, headerDemo, iconCaretDown } from "@/constants/icons";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import { DashlaneIcon } from "@/components/icons";
import MenuPanelMobile from "./MenuPanelMobile";
import { mapNavPanelData } from "@/utilities/mapNavPanelData";
import { sanitizeRawHTML } from "@/utilities/sanitizeRawHTML";
import { CustomButtonInterface } from "./CustomButton";
import { EnhancedMenuItems, MenuItems } from "@/types/Menu";

export default function EnhancedMenuHeaderMobile({
  items,
  additionalItems,
  buttons,
}: {
  items: EnhancedMenuItems;
  additionalItems: MenuItems;
  buttons: CustomButtonInterface[];
}) {
  const [open, setOpen] = useState("");
  items = [...items];

  function scrollToTop() {
    if (typeof document !== "undefined") {
      const mobileList = document.getElementById("mobile-menu-list");
      mobileList?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  return (
    <div className="item ml-2 xl:hidden">
      <Menu as="div">
        <div>
          <Menu.Button
            data-cy="mobile-menu-button"
            className="inline-flex items-center justify-center rounded-md p-2 pe-0 text-blue-8 hover:text-dashgreen-active "
          >
            {({ open }) => {
              if (open) {
                scrollToTop();
              }

              return (
                <>
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </>
              );
            }}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-0"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-0"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            as="ul"
            id="mobile-menu-list"
            className="fixed inset-x-0 top-0 max-h-[85vh] origin-top-right divide-y divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none xl:hidden"
          >
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-4 py-2.5 md:px-8">
                <div className="flex items-center justify-between">
                  <div className="mr-3 inline-flex items-center">
                    <DashlaneIcon width="127" height="32" />
                  </div>
                  <div className="-mr-2 flex">
                    <Menu.Button
                      data-cy="mobile-menu-close-button"
                      className="ml-2 mr-1 inline-flex items-center justify-center rounded-md bg-white p-2 pe-0"
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                </div>
              </div>
              <div className="px-4 py-6 md:px-8">
                <ul data-cy="mobile-menu" className="flex flex-col">
                  {items.map((item) => {
                    const isOpen = item.id === open;
                    return item.children?.length ? (
                      <li key={item.id}>
                        <button
                          className={cn(
                            "flex w-full items-center border-l-0 border-r-0 border-t-0 border-grey-1 py-4 text-left text-blue-8 xl:border-b-0 xl:py-0",
                            {
                              border: !isOpen,
                            }
                          )}
                          onClick={() => setOpen(isOpen ? "" : item.id)}
                        >
                          {item.label}
                          <Icon
                            className={cn("ms-1 xl:hidden", {
                              "rotate-180": isOpen,
                            })}
                            source={iconCaretDown}
                            variant="white"
                            width={16}
                            name={isOpen ? "close" : "open"}
                          />
                        </button>
                        {item?.structure === "panel" ? (
                          <MenuPanelMobile
                            data={mapNavPanelData(item)}
                            hide={!isOpen}
                          />
                        ) : (
                          <ul
                            className={cn("xl:block", {
                              hidden: !isOpen,
                              "border border-l-0 border-r-0 border-t-0 border-grey-1 pb-4":
                                isOpen,
                            })}
                          >
                            {item.children.map(
                              (child: any, childIndex: number) => (
                                <Menu.Item
                                  data-cy={`mobile-menu-item-${childIndex}`}
                                  as="li"
                                  key={child.id}
                                >
                                  <Link
                                    target={child.target ?? "_self"}
                                    href={child.path}
                                    locale={false}
                                    className="flex rounded-md py-4 text-blue-8 hover:no-underline"
                                  >
                                    {child.image && (
                                      <div
                                        className="mr-2"
                                        dangerouslySetInnerHTML={{
                                          __html: sanitizeRawHTML(
                                            atob(child.image)
                                          ),
                                        }}
                                      />
                                    )}
                                    <div className="my-auto">
                                      <h1 className="flex w-full items-center whitespace-nowrap font-medium text-blue-8 transition hover:text-blue-8 hover:no-underline">
                                        {child.label}
                                      </h1>
                                      {child.description && (
                                        <p className="text-xs font-extralight text-grey-6">
                                          {child.description}
                                        </p>
                                      )}
                                    </div>
                                  </Link>
                                </Menu.Item>
                              )
                            )}
                          </ul>
                        )}
                      </li>
                    ) : (
                      <Menu.Item as="li" key={item.id}>
                        <Link
                          target={item.target ?? "_self"}
                          href={item.path}
                          locale={false}
                          className="flex w-full items-center border border-l-0 border-r-0 border-t-0 border-grey-1 py-4 text-left text-blue-8 hover:no-underline xl:border-b-0 xl:py-0"
                        >
                          {item.label}
                        </Link>
                      </Menu.Item>
                    );
                  })}
                  {additionalItems.map((item, index) => {
                    return (
                      <Menu.Item as="li" key={item.id}>
                        <Link
                          target={item.target ?? "_self"}
                          href={item.path}
                          locale={false}
                          className={cn(
                            "flex w-full items-center border-l-0 border-r-0 border-t-0 border-grey-1 py-4 text-left text-blue-8 hover:no-underline xl:border-b-0 xl:py-0",
                            {
                              border: index !== additionalItems.length - 1,
                            }
                          )}
                        >
                          {item.cssClasses.includes("header-demo") && (
                            <span className="me-1.5 inline-block fill-blue-8">
                              {headerDemo}
                            </span>
                          )}
                          {item.cssClasses.includes("header-contact") && (
                            <span className="me-1.5 inline-block fill-blue-8">
                              {headerContact}
                            </span>
                          )}
                          {item.label}
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </ul>
                <div className="sticky bottom-0 w-full bg-white py-4">
                  <CustomButtonGroup
                    align="center"
                    buttons={buttons}
                    marginTop="mt-4"
                  />
                </div>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
