export const BUSINESS_TRIAL_SUCCESS_DISCLAIMER_RESENDING = {
  "en": "resending",
  "fr": "renvoi en cours",
  "de": "senden Sie die E-Mail erneut",
  "es": "volviendo a enviar",
  "pt-br": "resending",
  "it": "resending",
  "ja": "resending",
  "ko": "resending",
  "nl": "resending",
  "pt-pt": "resending",
  "sv": "resending",
  "zh": "resending"
}