import React, { useRef, useEffect } from "react";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  renderedHtml: string;
}

export default function CoreHtmlBlock({ renderedHtml }: Props) {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;

      function isMyScriptLoaded(url: string) {
        var scripts = document.getElementsByTagName("script");
        if (scripts !== undefined) {
          for (var i = scripts.length; i--; ) {
            if (scripts[i]?.src == url) return true;
          }
        }
        return false;
      }

      const extractScriptsAndExecute = (htmlString: string) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;

        const scripts = tempDiv.getElementsByTagName("script");
        for (let script of scripts) {
          const newScript = document.createElement("script");
          if (script.src && !isMyScriptLoaded(script.src)) {
            newScript.src = script.src;
            newScript.async = true;
          } else {
            newScript.innerHTML = script.innerHTML;
          }
          document.body.appendChild(newScript);
        }
      };

      // Ejecutar la función cuando el componente se monte
      extractScriptsAndExecute(renderedHtml as string);
    }
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: renderedHtml
          ? renderedHtml.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, "")
          : "",
      }}
    />
  );
}

// Must match __typename
const BLOCK_NAME = "CoreHtml";

CoreHtmlBlock.displayName = BLOCK_NAME;

CoreHtmlBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          content
        }
      }
      renderedHtml
    }
  `,
};
