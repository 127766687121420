import React, { PropsWithChildren } from 'react';
import Image from 'next/image';
import cn from 'classnames';

import { BlockStyle } from '@/utilities/getBlockStyles';

interface Props {
  className?: string;
  focalPoint?: {
    x: string;
    y: string;
  } | null;
  id?: string;
  image: {
    url: string;
    alt: string;
  };
  imageFill?: boolean;
  mediaLeft?: boolean;
  stackOnMobile?: boolean;
  style?: BlockStyle;
  verticalAlignment?: string;
}

export default function MediaText({
  children,
  className,
  focalPoint,
  id,
  image,
  imageFill,
  mediaLeft = false,
  stackOnMobile,
  style,
  verticalAlignment,
}: PropsWithChildren<Props>) {
  const imageFillStyle =
    imageFill && focalPoint
      ? {
          backgroundImage: `url(${image?.url || ''})`,
          backgroundPosition: `${focalPoint.x} ${focalPoint.y}`,
        }
      : undefined;

  return (
    <section
      id={id}
      className={cn(
        'md:grid md:grid-cols-2',
        !stackOnMobile && 'grid grid-cols-2',
        className
      )}
      style={style}
    >
      <div
        className={cn(
          'col-[1] row-[1] flex flex-col items-start justify-center p-8 md:max-w-[40rem]',
          verticalAlignment === 'top' && 'justify-start',
          verticalAlignment === 'bottom' && 'justify-end',
          mediaLeft && 'col-[2] items-end'
        )}
      >
        {children}
      </div>
      <div
        className={cn(
          'row-[1] flex flex-col items-start justify-center',
          verticalAlignment === 'top' && 'justify-start',
          verticalAlignment === 'bottom' && 'justify-end',
          imageFill && 'h-full min-h-[15.5rem] max-w-full',
          mediaLeft ? 'col-[1]' : 'col-[2]'
        )}
        style={imageFillStyle}
      >
        {image && image.url && (
          <div
            className={cn(
              'relative mb-0 h-0 w-full bg-opacity-20 pt-[67.58%]',
              imageFill && 'pt-0'
            )}
          >
            <Image
              alt={image.alt}
              className="absolute top-0 left-0 h-full w-full object-cover"
              fill={true}
              src={image.url}
              style={
                imageFill
                  ? {
                      clip: 'rect(0, 0, 0, 0)',
                    }
                  : undefined
              }
            />
          </div>
        )}
      </div>
    </section>
  );
}
