import type { Author, FeaturedImage as FeaturedImageType } from "@/types";
import { Heading } from "@/components/atoms";
import { FeaturedImage, PostInfo } from "@/components/molecules";
import CustomMedia from "@/components/molecules/CustomMedia";

interface Props {
  author?: Author;
  date?: string;
  modified?: string;
  image?: FeaturedImageType;
  hideFeaturedImage?: {
    value: "Yes" | "No";
  };
  title: string;
  category?: string;
  youtubeUrl?: string;
  youtubeTitle?: string;
}

export default function EntryHeader({
  title,
  image,
  hideFeaturedImage,
  date,
  modified,
  author,
  category,
  youtubeUrl,
  youtubeTitle,
}: Props) {
  const hideImage = hideFeaturedImage?.value === "Yes" ? true : false;

  return (
    <div className="mb-4 mt-8 md:mb-8 md:mt-16">
      {category && (
        <div className="mb-6 text-lg font-medium text-blue-8">{category}</div>
      )}
      {title && <Heading className="text-4xl md:text-5xl">{title}</Heading>}
      {author && date && (
        <PostInfo
          author={author}
          date={date}
          modified={modified}
          className="mt-4"
        />
      )}
      {youtubeUrl && (
        <CustomMedia
          className="my-4 md:my-8"
          media={{
            media: {
              url: youtubeUrl,
              title: youtubeTitle,
              providerNameSlug: "youtube",
            },
            type: "youtube",
          }}
        />
      )}
      {image && !hideImage && !youtubeUrl && (
        <div className="my-4 md:my-8">
          <FeaturedImage
            image={image}
            priority
            className="overflow-hidden rounded"
          />
        </div>
      )}
    </div>
  );
}
