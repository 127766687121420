import { createRef } from "react";

import { RichText } from "@/components/atoms";
import {
  Block,
  ButtonBlock,
  ButtonsBlock,
  CodeBlock,
  CorePreformattedBlock,
  ColumnBlock,
  ColumnsBlock,
  CoverBlock,
  EmbedBlock,
  GalleryBlock,
  GroupBlock,
  HeadingBlock,
  HeroRTBBlock,
  HeroRTBSectionBlock,
  ImageBlock,
  ListBlock,
  ListItemBlock,
  MediaTextBlock,
  ParagraphBlock,
  QuoteBlock,
  SeparatorBlock,
  ShortCodeBlock,
  SpacerBlock,
  TableBlock,
  VideoBlock,
  HeroBlock,
  GenericTwoColumnNarrativeBlock,
  HomepageHeroBlock,
  CTABlock,
  FeaturesStaticBlock,
  ValidationBlock,
  IconFeatureBlock,
  TestimonialBlock,
  TestimonialsBlock,
  IconCardsBlock,
  ImageCardsBlock,
  FAQItemBlock,
  JumpLinkStartBlock,
  AccoladesBlock,
  AccoladeBlock,
  AccoladesGridBlock,
  AccoladesGridItemBlock,
  EmailCaptureBlock,
  EmailCaptureItemBlock,
  ContentCardsManualBlock,
  ImageCardAddonBlock,
  ImageCardsAddonBlock,
  OfferOverlayBlock,
  FAQBlock,
  ContentCardsBlock,
  FeaturesInteractiveItemBlock,
  FeaturesInteractiveBlock,
  PricingColumnBlock,
  PricingItemBlock,
  PricingBlock,
  BannerBlock,
  TabularContentBlock,
  TabularContentRowBlock,
  TabularContentTextCellBlock,
  TabularContentIconCellBlock,
  TabularContentPricingCellBlock,
  TabularContentImageCellBlock,
  PullQuoteBlock,
  MarketoFormBlock,
  NewsFeedBlock,
  NewsFeedArticleBlock,
  MarketoFormOverlayBlock,
  CareersBlock,
  PartnershipFormBlock,
  TeamBlock,
  TeamMemberBlock,
  InnovationsBlock,
  InnovationBlock,
  CookiePreferencesBlock,
  RatingCardBlock,
  RatingCardsBlock,
  IconFeatureRTBBlock,
  UsernameGeneratorBlock,
  PasswordGeneratorBlock,
  SecondaryBannerBlock,
  CoreHtmlBlock,
} from "@/components/blocks";
import type { Block as BlockType } from "@/types";
import { getBlockContentProps } from "@/utilities/getBlockContentProps";

interface Props {
  blocks: BlockType[];
  contentType?: string;
}

export default function Blocks({ blocks, contentType }: Props) {
  const blocksRef = createRef();

  return (
    <>
      {blocks.map((block: BlockType, index: number) => {
        const { attributes: atts, name, innerBlocks, renderedHtml } = block;

        const attributes = {
          ...atts,
          contentType,
          blockContentProps: getBlockContentProps(atts),
        };

        switch (name) {
          case "core/button":
            return <ButtonBlock {...attributes} key={index} />;
          case "core/buttons":
            return (
              <ButtonsBlock
                {...attributes}
                innerBlocks={innerBlocks}
                key={index}
              />
            );
          case "core/block":
            return (
              <Block
                {...attributes}
                innerBlocks={innerBlocks}
                key={index}
                ref={blocksRef}
              />
            );
          case "core/code":
            return <CodeBlock {...attributes} key={index} />;
          case "core/preformatted":
            return <CorePreformattedBlock {...attributes} key={index} />;
          case "core/column":
            return (
              <ColumnBlock
                {...attributes}
                innerBlocks={innerBlocks}
                key={index}
              />
            );
          case "core/columns":
            return (
              <ColumnsBlock
                {...attributes}
                innerBlocks={innerBlocks}
                key={index}
              />
            );
          case "core/cover":
            return (
              <CoverBlock
                {...attributes}
                innerBlocks={innerBlocks}
                key={index}
              />
            );
          case "core/embed":
          case "core-embed/youtube":
            return <EmbedBlock {...attributes} key={index} />;
          case "core/gallery":
            return (
              <GalleryBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "core/group":
            return (
              <GroupBlock
                {...attributes}
                innerBlocks={innerBlocks}
                key={index}
              />
            );
          case "core/heading":
            return <HeadingBlock {...attributes} key={index} />;
          case "core/html":
            if (attributes.content) {
              return <RichText key={index}>{attributes.content}</RichText>;
            } else {
              return (
                <CoreHtmlBlock renderedHtml={renderedHtml ?? ""} key={index} />
              );
            }
          case "core/image":
            return <ImageBlock {...attributes} key={index} />;
          case "core/list":
            return (
              <ListBlock
                {...attributes}
                innerBlocks={innerBlocks}
                key={index}
              />
            );
          case "core/list-item":
            return <ListItemBlock {...attributes} key={index} />;
          case "core/media-text":
            return (
              <MediaTextBlock
                innerBlocks={innerBlocks}
                {...attributes}
                key={index}
              />
            );
          case "core/paragraph":
            return <ParagraphBlock {...attributes} key={index} />;
          case "core/pullquote":
            return (
              <PullQuoteBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "core/quote":
            return (
              <QuoteBlock
                {...attributes}
                innerBlocks={innerBlocks}
                key={index}
              />
            );
          case "core/separator":
            return <SeparatorBlock {...attributes} key={index} />;
          case "core/shortcode":
            return <ShortCodeBlock {...attributes} key={index} />;
          case "core/spacer":
            return <SpacerBlock {...attributes} key={index} />;
          case "core/table":
            return (
              <TableBlock
                {...attributes}
                key={index}
                renderedHtml={block.renderedHtml}
              />
            );
          case "core/video":
            return <VideoBlock {...attributes} key={index} />;
          case "tenup/hero":
            return <HeroBlock {...attributes} key={index} />;
          case "tenup/two-column-narrative":
            return (
              <GenericTwoColumnNarrativeBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/homepage-hero":
            return <HomepageHeroBlock {...attributes} key={index} />;
          case "tenup/cta":
            return <CTABlock {...attributes} key={index} />;
          case "tenup/features-static":
            return (
              <FeaturesStaticBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/validation":
            return <ValidationBlock {...attributes} key={index} />;
          case "tenup/icon-feature":
            return <IconFeatureBlock {...attributes} key={index} />;
          case "tenup/icon-feature-rtb":
            return <IconFeatureRTBBlock {...attributes} key={index} />;
          case "tenup/testimonials":
            return (
              <TestimonialsBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/testimonial":
            return <TestimonialBlock {...attributes} key={index} />;
          case "tenup/hero-rtb":
            return (
              <HeroRTBBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/hero-rtb-section":
            return (
              <HeroRTBSectionBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/icon-cards":
            return <IconCardsBlock {...attributes} key={index} />;
          case "tenup/image-cards":
            return <ImageCardsBlock {...attributes} key={index} />;
          case "tenup/content-cards":
            return <ContentCardsBlock {...attributes} key={index} />;
          case "tenup/jump-link-section":
            return <JumpLinkStartBlock {...attributes} key={index} />;
          case "tenup/faq":
            return (
              <FAQBlock {...attributes} key={index} innerBlocks={innerBlocks} />
            );
          case "tenup/faq-item":
            return <FAQItemBlock {...attributes} key={index} />;
          case "tenup/features-interactive":
            return (
              <FeaturesInteractiveBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/features-interactive-item":
            return <FeaturesInteractiveItemBlock {...attributes} key={index} />;
          case "tenup/accolades":
            return (
              <AccoladesBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/accolade":
            return <AccoladeBlock {...attributes} key={index} />;
          case "tenup/accolades-grid":
            return (
              <AccoladesGridBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/accolades-grid-item":
            return <AccoladesGridItemBlock {...attributes} key={index} />;
          case "tenup/pricing":
            return (
              <PricingBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/pricing-column":
            return (
              <PricingColumnBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/pricing-item":
            return <PricingItemBlock {...attributes} key={index} />;
          case "tenup/banner":
            return <BannerBlock {...attributes} key={index} />;
          case "tenup/email-capture":
            return (
              <EmailCaptureBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/email-capture-item":
            return <EmailCaptureItemBlock {...attributes} key={index} />;
          case "tenup/content-cards-manual":
            return (
              <ContentCardsManualBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/tabular-content":
            return (
              <TabularContentBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/tabular-content-row":
            return (
              <TabularContentRowBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/tabular-content-text-cell":
            return <TabularContentTextCellBlock {...attributes} key={index} />;
          case "tenup/tabular-content-icon-cell":
            return <TabularContentIconCellBlock {...attributes} key={index} />;
          case "tenup/tabular-content-pricing-cell":
            return (
              <TabularContentPricingCellBlock {...attributes} key={index} />
            );
          case "tenup/tabular-content-image-cell":
            return <TabularContentImageCellBlock {...attributes} key={index} />;
          case "tenup/offer-overlay":
            return <OfferOverlayBlock {...attributes} key={index} />;
          case "tenup/marketo-form":
            return (
              <MarketoFormBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/image-cards-addon":
            return (
              <ImageCardsAddonBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/image-card-addon":
            return <ImageCardAddonBlock {...attributes} key={index} />;
          case "tenup/news-feed":
            return (
              <NewsFeedBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/news-feed-article":
            return <NewsFeedArticleBlock {...attributes} key={index} />;
          case "tenup/marketo-form-overlay":
            return <MarketoFormOverlayBlock {...attributes} key={index} />;
          case "tenup/careers":
            return <CareersBlock {...attributes} key={index} />;
          case "tenup/partnership-form":
            return <PartnershipFormBlock {...attributes} key={index} />;
          case "tenup/team":
            return (
              <TeamBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/innovation":
            return (
              <InnovationsBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/team-member":
            return <TeamMemberBlock {...attributes} key={index} />;
          case "tenup/innovation-slide":
            return <InnovationBlock {...attributes} key={index} />;
          case "tenup/cookie-preferences":
            return (
              <CookiePreferencesBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/rating-card":
            return <RatingCardBlock {...attributes} key={index} />;
          case "tenup/rating-cards":
            return (
              <RatingCardsBlock
                {...attributes}
                key={index}
                innerBlocks={innerBlocks}
              />
            );
          case "tenup/username-generator":
            return <UsernameGeneratorBlock {...attributes} key={index} />;
          case "tenup/password-generator":
            return <PasswordGeneratorBlock {...attributes} key={index} />;
          case "tenup/secondary-banner":
            return <SecondaryBannerBlock {...attributes} key={index} />;
          default:
            console.log("BLOCK NOT FOUND: " + name);
            return (
              <div
                key={index}
                className="my-3 rounded-lg bg-red-50 p-1 text-center text-red-600"
              >
                BLOCK NOT FOUND
              </div>
            );
        }
      })}
    </>
  );
}
