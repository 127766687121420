export const SIGNUP_FORM_PLACEHOLDER = {
  "en": "Enter email address",
  "fr": "Saisissez votre adresse e-mail",
  "de": "E-Mail-Adresse eingeben",
  "es": "Ingresar el correo electrónico",
  "pt-br": "Enter email address",
  "it": "Enter email address",
  "ja": "Enter email address",
  "ko": "Enter email address",
  "nl": "Enter email address",
  "pt-pt": "Enter email address",
  "sv": "Enter email address",
  "zh": "Enter email address"
}