export const BUSINESS_TRIAL_SUCCESS_OPEN_OUTLOOK = {
  "en": "Open Outlook",
  "fr": "Ouvrir Outlook",
  "de": "Outlook öffnen",
  "es": "Abrir Outlook",
  "pt-br": "Open Outlook",
  "it": "Open Outlook",
  "ja": "Open Outlook",
  "ko": "Open Outlook",
  "nl": "Open Outlook",
  "pt-pt": "Open Outlook",
  "sv": "Open Outlook",
  "zh": "Open Outlook"
}