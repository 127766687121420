import { forwardRef, LegacyRef } from 'react';

import { Blocks } from '@/components/molecules';
import type { Block as BlockType } from '@/types';

interface Props {
  innerBlocks: BlockType[];
}

const Block = forwardRef(({ innerBlocks }: Props, ref) => {
  return (
    <div ref={ref as LegacyRef<HTMLDivElement>}>
      {!!innerBlocks.length && <Blocks blocks={innerBlocks} />}
    </div>
  );
});

Block.displayName = 'BlockComponent';

export default Block;
