import React from "react";
import cn from "classnames";
import Card, { CardInterface } from "@/components/molecules/Card";
import { useTouchSlide, useTouchSlider } from "@/hooks/useTouchSlider";
import { CARD_CAROUSEL_CUTOFF } from "@/constants/slider";

export interface CardGroupInterface {
  columns: number;
  cardsNumber: number;
  variant?: string;
  cards: Array<CardInterface>;
}

const CardGroup = ({
  columns,
  cardsNumber,
  variant = "",
  cards,
}: CardGroupInterface) => {
  const {
    wrapClassNames,
    sliderClassNames,
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
    sliderRef,
  } = useTouchSlider(cardsNumber, variant, undefined, false, undefined);

  const combinedWrapClassNames = cn(wrapClassNames, "md:me-auto", {
    "me-auto": cardsNumber < CARD_CAROUSEL_CUTOFF,
  });

  const { slideWrapClass } = useTouchSlide();

  const slideWrapClasses = cn(slideWrapClass, "md:me-auto md:w-full", {
    "me-auto w-full": cardsNumber < CARD_CAROUSEL_CUTOFF,
  });

  const classNames = cn("md:grid md:gap-8 md:w-auto", {
    "grid gap-8 grid-cols-1": cardsNumber < CARD_CAROUSEL_CUTOFF,
    [sliderClassNames]: cardsNumber >= CARD_CAROUSEL_CUTOFF,
    "md:grid-cols-2": columns === 2,
    "md:grid-cols-2 lg:grid-cols-3": columns === 3,
    "md:grid-cols-2 lg:grid-cols-4": columns === 4,
  });

  return (
    <div className={combinedWrapClassNames}>
      <div
        className={classNames}
        onTouchStart={
          cardsNumber >= CARD_CAROUSEL_CUTOFF ? handleTouchStart : undefined
        }
        onTouchEnd={
          cardsNumber >= CARD_CAROUSEL_CUTOFF ? handleTouchEnd : undefined
        }
        onTouchMove={
          cardsNumber >= CARD_CAROUSEL_CUTOFF ? handleTouchMove : undefined
        }
        ref={sliderRef}
      >
        {cards.map((each, index) => {
          if (index >= cardsNumber) return null;

          return <Card key={index} className={slideWrapClasses} {...each} />;
        })}
      </div>
    </div>
  );
};

export default CardGroup;
