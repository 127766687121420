export const SIGNUP_FORM_EMAIL_REQUIRED = {
  "en": "Email is required.",
  "fr": "Veuillez renseigner une adresse e-mail.",
  "de": "Es muss eine E-Mail-Adresse angegeben werden.",
  "es": "El correo electrónico es obligatorio.",
  "pt-br": "Email is required.",
  "it": "Email is required.",
  "ja": "Email is required.",
  "ko": "Email is required.",
  "nl": "Email is required.",
  "pt-pt": "Email is required.",
  "sv": "Email is required.",
  "zh": "Email is required."
}