import React from "react";
import cn from "classnames";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { CustomImageInterface } from "../molecules/CustomImage";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  h3Text,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeImage?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  size?: string;
  imageAlign?: string;
  width?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  image?: CustomImageInterface;
  contentType: string;
  innerBlocks?: Block[];
  media?: MediaObjectInterface;
  ga4SectionId?: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function FeaturesStaticBlock({
  includeAboveHeading = false,
  includeBelowButtons = false,
  includeButtons = false,
  includeContent = false,
  includeImage = false,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 2,
  },
  content = "",
  variant = "white",
  imageAlign = "right",
  width = "box",
  buttonsNumber = 0,
  buttonOne,
  buttonTwo,
  belowButtons = "",
  image = {
    full: "",
    alt: "",
  },
  media = {
    type: "image",
    media: {},
  },
  contentType,
  innerBlocks,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const features = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;

        if (each.attributes.headingOptions === undefined) {
          each.attributes.headingOptions = {};
        }
        each.attributes.headingOptions.tag = headingOptions.tag + 1;
        return each;
      })}
    />
  ) : null;

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
      size: "md",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
      size: "md",
    });
  }

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width={width}
      defaultValue="box"
      constrain
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className={`grid grid-cols-1 items-center lg:grid-cols-2 lg:gap-20`}>
        <div>
          {includeAboveHeading && (
            <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
          )}
          <CustomHeading
            heading={heading}
            headingOptions={headingOptions}
            headingClassNames={cn(marginBMd, h3Text, {
              [textHeadlineInverse]: variant === "dark",
              [textHeadlineNeutral]: variant === "light",
            })}
          />
          {includeContent && (
            <RichText
              className={cn({
                [textBodyInverse]: variant === "dark",
                [textBodyNeutral]: variant !== "dark",
              })}
              tag="p"
            >
              {content}
            </RichText>
          )}
          {includeImage && <div className="mt-10">{features}</div>}
          {includeButtons && (
            <CustomButtonGroup
              marginTop="mt-10"
              align="left"
              buttons={buttons}
            />
          )}
          {includeBelowButtons && (
            <BelowButtonsText variant={variant}>
              {belowButtons}
            </BelowButtonsText>
          )}
        </div>
        <div
          className={cn({
            "lg:order-last": imageAlign === "right" || !includeImage,
            "order-first": includeImage,
          })}
        >
          {includeImage ? (
            <CustomMedia
              className="mb-10 lg:mb-0"
              media={media ?? { media: {}, type: "image" }}
              aspect="1:1"
              fallbackImage={image}
            />
          ) : (
            <div className="mt-10 lg:mt-0">{features}</div>
          )}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupFeaturesStatic";

FeaturesStaticBlock.displayName = BLOCK_NAME;

FeaturesStaticBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          ga4SectionId
          heading
          headingOptions
          image
          imageAlign
          includeAboveHeading
          includeBelowButtons
          includeButtons
          includeContent
          includeImage
          media
          metadata
          size
          variant
          width
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
