import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  backgroundInverse,
  backgroundNeutral,
  h3Text,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import { useTouchSlider } from "@/hooks/useTouchSlider";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MOBILE_BREAK } from "@/constants/utility";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  width?: string;
  stringLayout?: string;
  innerBlocks?: Block[];
  paddingTop?: string;
  paddingBottom?: string;
}

export default function NewsFeedBlock({
  includeAboveHeading = false,
  includeBelowButtons = false,
  includeButtons = false,
  includeContent = false,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 2,
  },
  content = "",
  variant = "white",
  buttonsNumber = 0,
  buttonOne,
  buttonTwo,
  belowButtons = "",
  width,
  stringLayout = "list",
  innerBlocks,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const articlesNumber = innerBlocks?.length ? innerBlocks?.length : 0;

  let totalBleedSlides = Math.floor(articlesNumber / 2);

  if (articlesNumber % 3 === 0 && articlesNumber > 3)
    totalBleedSlides = totalBleedSlides + 1;
  if (articlesNumber % 2 !== 0) totalBleedSlides = totalBleedSlides + 1;
  if (articlesNumber % 2 === 0 && articlesNumber >= 8)
    totalBleedSlides = totalBleedSlides + 1;

  let totalSlides =
    useWindowSize().width >= MOBILE_BREAK ? totalBleedSlides : articlesNumber;

  const articles = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;
        each.attributes.layout = stringLayout;

        if (each.attributes.headingOptions === undefined) {
          each.attributes.headingOptions = {};
        }
        each.attributes.headingOptions.tag = headingOptions.tag + 1;
        return each;
      })}
    />
  ) : null;

  //Create the slider
  const {
    sliderControls,
    wrapClassNames,
    sliderClassNames,
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
    sliderRef,
  } = useTouchSlider(totalSlides, variant, 1);

  const blockClassNames = cn({
    rounded: width !== "full",
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
  });

  const aboveHeadingClassNames = cn({
    "text-center": stringLayout === "list",
    "text-left": stringLayout !== "list",
  });

  const headerClassNames = cn("max-w-xl mb-2 lg:mb-4", h3Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    "text-center mx-auto": stringLayout === "list",
    "text-left": stringLayout !== "list",
  });

  const pTextClassNames = cn("max-w-xl", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
    "mx-auto text-center": stringLayout === "list",
    "text-left": stringLayout !== "list",
  });

  const upperCardsContainerClassNames = cn("mb-14 lg:mb-20", {
    "flex items-center justify-between": stringLayout !== "list",
  });

  const cardLayoutHeadersClassNames = cn({
    "flex flex-col": stringLayout !== "list",
  });

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  return (
    <BlockContent
      className={blockClassNames}
      width={width}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className={upperCardsContainerClassNames}>
        <div className={cardLayoutHeadersClassNames}>
          {includeAboveHeading && (
            <AboveHeading variant={variant} className={aboveHeadingClassNames}>
              {aboveHeading}
            </AboveHeading>
          )}
          <CustomHeading
            heading={heading}
            headingOptions={headingOptions}
            headingClassNames={headerClassNames}
          />
          {includeContent && (
            <RichText className={pTextClassNames} tag="p">
              {content}
            </RichText>
          )}
        </div>
        {stringLayout !== "list" && <div>{sliderControls}</div>}
      </div>
      {stringLayout === "list" ? (
        <div>{articles}</div>
      ) : (
        <div className={wrapClassNames}>
          <div
            className={sliderClassNames}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            ref={sliderRef}
          >
            {articles}
          </div>
        </div>
      )}
      {includeButtons && (
        <CustomButtonGroup
          marginTop="mt-14 lg:mt-20"
          align="center"
          buttons={buttons}
        />
      )}
      {includeBelowButtons && (
        <BelowButtonsText centered={true} variant={variant}>
          {belowButtons}
        </BelowButtonsText>
      )}
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupNewsFeed";

NewsFeedBlock.displayName = BLOCK_NAME;

NewsFeedBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          ga4SectionId
          heading
          headingOptions
          includeAboveHeading
          includeBelowButtons
          includeButtons
          includeContent
          includeHeading
          stringLayout: layout
          metadata
          variant
          width
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
