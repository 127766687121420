import * as dictionary from "./dictionary";
import { getCryptoStrongRandomInteger } from "./crypto-utils";
import { EFF_WORDLIST } from "./eff-wordlist";

const MAX_NUMBER_ATTEMPTS = 1000;

export interface GeneratePasswordOptions {
  length?: number;
  digits?: boolean;
  letters?: boolean;
  symbols?: boolean;
  avoidAmbiguous?: boolean;
  passphrases?: boolean;
}

/**
 * Generate a random passphrase
 *
 * We pick rwords randomly in the eff-wordlist file.
 *
 * @param length - The number of words in the passphrase
 * @return a string with the random words separated by a '-'
 */

export function getRandomPassphrase(length: number) {
  // This is the expected length defined by the EFF
  if (EFF_WORDLIST.length < 7776) {
    throw new Error(
      "The number of words in the wordlist is too low for this function to be secure"
    );
  }
  let passphrase = "";
  for (let numberOfWords = 0; numberOfWords < length; numberOfWords++) {
    const randomWord =
      EFF_WORDLIST[getCryptoStrongRandomInteger(EFF_WORDLIST.length) as number];
    const space = numberOfWords !== length - 1 ? "-" : "";
    passphrase += randomWord + space;
  }
  return passphrase;
}

/**
 * Genere a password respecting the provided criteria.
 *
 * Algorithm documented within https://confluence.dashlane.com/display/FD/Technical+Spec+-+Password+Generator
 * TL;DR: Our security team is asking us to use a specific version to be approved by some organization
 *
 * @param length - The length of the password to generate. 4 is the minimum, 8 the default.
 * @param digits - If it should contains a digit (0-9)
 * @param letters - If it should contains a lower case (a-z) and uppercase (A-Z)
 * @param symbols - If it should contains a symbol, one of &@$!#?
 * @param avoidAmbiguous - If we want to avoid all the ambigigous chars to read (for example 1 and l are too similar, it's hard to read them)
 * @returns a string representation of a password that respect the given criteria
 */
export function generate({
  length = 8,
  digits = false,
  letters = false,
  symbols = false,
  avoidAmbiguous = false,
  passphrases = false,
}: GeneratePasswordOptions = {}) {
  if (passphrases) {
    return getRandomPassphrase(length);
  }

  if (length < 4) {
    throw new Error("The length of the password cannot be below 4 chars");
  }
  if (!digits && !letters && !symbols) {
    throw new Error("You need to have at least one kind of char selected");
  }

  const allPossibleChars = getAllPossibleChars({
    digits,
    letters,
    symbols,
    avoidAmbiguous,
  });

  for (let i = 0; i < MAX_NUMBER_ATTEMPTS; i++) {
    // try a maximum of 1000 times to generate a valid algorithm, or fail
    let password = "";
    while (password.length < length) {
      password += getRandomCharFrom(allPossibleChars);
    }
    // password is generated, check if it contains the mandatory char
    if (containsMandatoryChars({ password, digits, letters, symbols })) {
      return password;
    }
  }
  // we did not generated a valid password within the given iteration
  throw new Error("Unable to generate a password with the given criteria");
}

export function containsMandatoryChars({
  password,
  digits,
  letters,
  symbols,
}: {
  password: string;
  digits?: boolean;
  letters?: boolean;
  symbols?: boolean;
}): boolean {
  let expectingDigit = digits;
  let expectingSymbol = symbols;
  let expectingLowerCase = letters;
  let expectingUpperCase = letters;

  for (let char of password) {
    // check for each expected char, if this new char is one of them
    if (expectingDigit && dictionary.NUMERALS.includes(char)) {
      expectingDigit = false;
    } else if (expectingSymbol && dictionary.SYMBOLS.includes(char)) {
      expectingSymbol = false;
    } else if (expectingLowerCase && dictionary.LOWER_ALPHA.includes(char)) {
      expectingLowerCase = false;
    } else if (expectingUpperCase && dictionary.UPPER_ALPHA.includes(char)) {
      expectingUpperCase = false;
    }
    if (
      !expectingDigit &&
      !expectingSymbol &&
      !expectingLowerCase &&
      !expectingUpperCase
    ) {
      // we are not expecting anything anymore, so we passed the test
      return true;
    }
  }
  return false; // we did not found all the expected chars
}

export const getNumerals = (avoidAmbiguous: boolean = false): string => {
  return avoidAmbiguous
    ? dictionary.NUMERALS_DISTINGUISHABLE
    : dictionary.NUMERALS;
};

export const getAlphaLower = (avoidAmbiguous: boolean = false): string => {
  return avoidAmbiguous
    ? dictionary.LOWER_ALPHA_DISTINGUISHABLE
    : dictionary.LOWER_ALPHA;
};

export const getAlphaUpper = (avoidAmbiguous: boolean = false): string => {
  return avoidAmbiguous
    ? dictionary.UPPER_ALPHA_DISTINGUISHABLE
    : dictionary.UPPER_ALPHA;
};

export const getRandomCharFrom = (chars: string): string => {
  return chars.charAt(getCryptoStrongRandomInteger(chars.length) as number);
};

export const getAllPossibleChars = ({
  digits = false,
  letters = false,
  symbols = false,
  avoidAmbiguous = false,
}: {
  digits?: boolean;
  letters?: boolean;
  symbols?: boolean;
  avoidAmbiguous?: boolean;
} = {}): string => {
  let all = "";
  digits && (all += getNumerals(avoidAmbiguous));
  letters &&
    (all += getAlphaLower(avoidAmbiguous) + getAlphaUpper(avoidAmbiguous));
  symbols && (all += dictionary.SYMBOLS);
  return all;
};
