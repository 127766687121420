export const COPYRIGHT_TITLE = {
  "en": "All rights reserved.",
  "fr": "Tous droits réservés.",
  "de": "Alle Rechte vorbehalten.",
  "es": "Todos los derechos reservados.",
  "pt-br": "All rights reserved.",
  "it": "All rights reserved.",
  "ja": "All rights reserved.",
  "ko": "All rights reserved.",
  "nl": "All rights reserved.",
  "pt-pt": "All rights reserved.",
  "sv": "All rights reserved.",
  "zh": "All rights reserved."
}