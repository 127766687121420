import Link from "next/link";
import { wordpressUrl } from "../../utilities/variables";

interface Props {
  tags: {
    link: string;
    name: string;
  }[];
}

export default function PostTags({ tags }: Props) {
  return (
    <div className="my-5 flex flex-wrap justify-center">
      {tags.map((tag) => {
        const { link, name } = tag;
        const url = link.includes(wordpressUrl!)
          ? link.replace(wordpressUrl!, "https://www.dashlane.com")
          : link;
        return (
          <Link
            className="m-2 border-2 border-solid border-slate-100 p-2 text-xs uppercase duration-150 ease-in-out hover:bg-dashgreen-active hover:text-white hover:no-underline"
            href={url}
            key={url}
          >
            {name}
          </Link>
        );
      })}
    </div>
  );
}
