import React from "react";
import cn from "classnames";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  h1Text,
  pTextXL,
  marginBLg,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import PartnershipForm from "@/components/molecules/PartnershipForm";
import { useRouter } from "next/router";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeContent?: boolean;
  includeImage?: boolean;
  hasCustomRedirect?: boolean;
  redirectUrl?: string;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  width?: string;
  buttonText?: string;
  label?: string;
  belowButtons?: string;
  contentType: string;
  inputPlaceholder?: string;
  specialOfferToken?: string;
  checkAccount?: boolean;
  identifier?: string;
  plan?: string;
  media?: MediaObjectInterface;
  validDomains?: string;
  paddingTop?: string;
  paddingBottom?: string;
  errorMessages?: {
    validation?: string;
    required?: string;
    domainError?: string;
  };
}

export default function PartnershipFormBlock({
  includeAboveHeading = true,
  includeBelowButtons = true,
  includeContent = true,
  includeImage = true,
  hasCustomRedirect = false,
  redirectUrl,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 2,
  },
  content = "",
  variant = "white",
  label = "",
  buttonText = "",
  specialOfferToken = "",
  identifier = "",
  plan = "",
  inputPlaceholder = "",
  checkAccount = false,
  width = "",
  belowButtons = "",
  media = {
    type: "image",
    media: {},
  },
  errorMessages = {
    validation: "Email is invalid",
    required: "Email is required",
    domainError: "Email is not from an allowed domain",
  },
  contentType,

  // Comma separated list of accepted domains
  validDomains = "",
  blockContentProps,
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const { locale } = useRouter();

  const headerClassNames = cn("mt-0", h1Text, marginBLg, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    "text-center": !includeImage,
  });

  const pTextClassNames = cn(marginBLg, pTextXL, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
    "text-center": !includeImage,
  });

  const innerClasses = cn("lg:grid relative", {
    "lg:gap-40 ": width === "full",
    "lg:gap-20": width !== "full",
    "lg:grid-cols-2": includeImage,
  });

  const fullRedirectUrl =
    hasCustomRedirect && redirectUrl
      ? redirectUrl
      : locale === "en" || !locale
      ? `/partnerships/email-confirmation`
      : `/${locale}/partnerships/email-confirmation`;

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width={width}
      defaultValue="box"
      constrain
      narrow={!includeImage}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className={innerClasses}>
        <div className="self-center">
          {includeAboveHeading && aboveHeading && (
            <AboveHeading
              variant={variant}
              className={!includeImage ? "text-center" : ""}
            >
              {aboveHeading}
            </AboveHeading>
          )}
          {heading && (
            <CustomHeading
              heading={heading}
              headingOptions={headingOptions}
              headingClassNames={headerClassNames}
            />
          )}
          {includeContent && content && (
            <RichText className={pTextClassNames} tag="p">
              {content}
            </RichText>
          )}
          <div className={cn("mx-auto md:mx-0 md:max-w-none", marginBLg)}>
            <PartnershipForm
              buttonText={buttonText}
              inputPlaceholder={inputPlaceholder}
              variant={variant}
              label={label}
              validDomains={validDomains}
              redirectUrl={fullRedirectUrl}
              specialOfferToken={specialOfferToken}
              errorMessages={errorMessages}
              identifier={identifier}
              plan={plan}
              center={!includeImage}
              checkAccount={checkAccount}
            />
          </div>
          {includeBelowButtons && (
            <BelowButtonsText variant={variant} centered={!includeImage}>
              {belowButtons}
            </BelowButtonsText>
          )}
        </div>
        {includeImage && (
          <div className={cn("mt-8 lg:mt-0")}>
            <CustomMedia
              media={media ?? { media: {}, type: "image" }}
              aspect={"3:2"}
            />
          </div>
        )}
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupPartnershipForm";

PartnershipFormBlock.displayName = BLOCK_NAME;

PartnershipFormBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          belowButtons
          buttonText
          className
          checkAccount
          content
          errorMessages
          formErrorMessage
          formId
          formName
          hasCustomRedirect
          heading
          headingOptions
          identifier
          includeAboveHeading
          includeBelowButtons
          includeContent
          includeImage
          inputPlaceholder
          label
          media
          metadata
          plan
          redirectUrl
          specialOfferToken
          validDomains
          variant
          width
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
