export const COPYRIGHT_DISCLAIMER = {
  "en": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries.",
  "fr": "Dashlane et le logo Dashlane sont des marques commerciales de Dashlane SAS, enregistrées aux États-Unis et dans d'autres pays.",
  "de": "Dashlane und das Dashlane-Logo sind in den USA und anderen Ländern eingetragene Marken der Dashlane SAS.",
  "es": "Dashlane y el logo de Dashlane son marcas comerciales de Dashlane SAS, registradas en los Estados Unidos y otros países.",
  "pt-br": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries.",
  "it": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries.",
  "ja": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries.",
  "ko": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries.",
  "nl": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries.",
  "pt-pt": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries.",
  "sv": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries.",
  "zh": "Dashlane and the Dashlane logo are trademarks of Dashlane SAS, registered in the U.S. and other countries."
}