export const TRIAL_SUCCESS_HEADING = {
  "en": "Thanks for getting started with Dashlane!",
  "fr": "Merci d'avoir fait les premiers pas avec Dashlane !",
  "de": "Vielen Dank für die ersten Schritte mit Dashlane!",
  "es": "¡Gracias por comenzar con Dashlane!",
  "pt-br": "Thanks for getting started with Dashlane!",
  "it": "Thanks for getting started with Dashlane!",
  "ja": "Thanks for getting started with Dashlane!",
  "ko": "Thanks for getting started with Dashlane!",
  "nl": "Thanks for getting started with Dashlane!",
  "pt-pt": "Thanks for getting started with Dashlane!",
  "sv": "Thanks for getting started with Dashlane!",
  "zh": "Thanks for getting started with Dashlane!"
}