import cn from "classnames";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { textBodyCatchyNeutral } from "@/constants/standardCSSClasses";
import { generatePaginationArray } from "@/utilities/generatePaginationArray";
import { decode } from "html-entities";
import { leftArrow, rightArrow } from "@/constants/icons";
import { useTranslate } from "@/hooks/useTranslate";
import { useRouter } from "next/router";

const PREVIOUS_PAGE = {
  en: "Previous",
  fr: "Précédente",
  de: "Vorherige",
  es: "Anterior",
  it: "Precedente",
  ja: "前のページ",
  ko: "이전 페이지",
  nl: "Vorige",
  "pt-br": "Anterior",
  "pt-pt": "Anterior",
  sv: "Föregående",
  zh: "上一页",
};

const NEXT_PAGE = {
  en: "Next",
  fr: "Suivante",
  de: "Nächste",
  es: "Siguiente",
  it: "Successiva",
  ja: "次のページ",
  ko: "다음 페이지",
  nl: "Volgende",
  "pt-br": "Próxima",
  "pt-pt": "Próxima",
  sv: "Nästa",
  zh: "下一页",
};

interface Props {
  total: number;
  current: number;
  base: string;
  className?: string;
  paginationType?: "default" | "queryParam";
}

export default function Pagination(props: Props) {
  const { className, total, current, paginationType = "default" } = props;
  const { translate } = useTranslate();
  const pathname = usePathname();
  const router = useRouter();
  const pathnameParts = pathname.split("/");
  const maybePage = pathnameParts.pop();
  let pathnameNoPage: string;

  if (maybePage && parseInt(maybePage, 10)) {
    pathnameNoPage = pathnameParts.join("/");
  } else {
    pathnameNoPage = pathname;
  }

  const pages = generatePaginationArray(total, current);
  const classNames = cn(
    textBodyCatchyNeutral,
    "inline-block h-10 w-10 border border-b-0 border-l-0 border-r-0 border-grey-1 text-center align-middle leading-10"
  );

  const hasPrevious = current > 1 && current <= total + 1;
  const hasNext = current < total;

  const activeNavClass =
    "flex items-center hover:no-underline [&:hover>span>span]:fill-blue-8 [&:hover>span]:text-blue-8";
  const disabledNavClass = "flex items-center text-grey-5";
  const navIconClass = "inline-block h-4 w-4 min-w-fit";
  const navIconWrapClass = "flex h-10 w-10 items-center justify-center";
  const navTextClass = "hidden lg:inline-block";

  const previous = (
    <>
      <span className={navIconWrapClass}>
        <span
          className={cn(navIconClass, {
            "fill-blue-5": hasPrevious,
            "fill-grey-5": !hasPrevious,
          })}
        >
          {leftArrow}
        </span>
      </span>
      <span className={navTextClass}>{translate(PREVIOUS_PAGE)}</span>
    </>
  );

  const next = (
    <>
      <span className={navTextClass}>{translate(NEXT_PAGE)}</span>
      <span className={navIconWrapClass}>
        <span
          className={cn(navIconClass, {
            "fill-blue-5": hasNext,
            "fill-grey-5": !hasNext,
          })}
        >
          {rightArrow}
        </span>
      </span>
    </>
  );

  const getPageLink = (pathname: string, page = 0) => {
    const paramStrings: string[] = [];
    Object.keys(router.query).forEach((key) => {
      if (key !== "p") {
        paramStrings.push(`${key}=${router.query[key]}`);
      }
    });

    if (!page) {
      if (paginationType === "queryParam") {
        // Get current query params
        return `${pathname}?${paramStrings.join("&")}`;
      } else {
        return pathname;
      }
    } else {
      if (paginationType === "queryParam") {
        // Get current query params and add the p param
        paramStrings.push(`p=${page}`);
        return `${pathname}?${paramStrings.join("&")}`;
      } else {
        return `${pathname}/${page}`;
      }
    }
  };

  return (
    <div
      className={cn(
        "mt-10 flex items-center justify-between border border-b-0 border-l-0 border-r-0 border-grey-1",
        className
      )}
    >
      <div>
        {hasPrevious ? (
          <Link
            href={
              current - 1 === 1
                ? getPageLink(pathnameNoPage)
                : getPageLink(pathnameNoPage, current - 1)
            }
            className={activeNavClass}
          >
            {previous}
          </Link>
        ) : (
          <span className={disabledNavClass}>{previous}</span>
        )}
      </div>
      <div className="mt-[-2px]">
        {pages.map((pageNum, index) => {
          if (typeof pageNum !== "number") {
            return (
              <span key={index} className={cn(classNames)}>
                {decode(pageNum)}
              </span>
            );
          }

          if (pageNum === current) {
            return (
              <span
                key={index}
                className={cn(classNames, "border-t-blue-8 text-blue-8")}
              >
                {pageNum}
              </span>
            );
          }

          const link =
            pageNum !== 1
              ? getPageLink(pathnameNoPage, pageNum)
              : getPageLink(pathnameNoPage);

          return (
            <Link key={index} href={link}>
              <span className={cn(classNames, "hover:text-blue-8")}>
                {pageNum}
              </span>
            </Link>
          );
        })}
      </div>
      <div>
        {hasNext ? (
          <Link
            href={getPageLink(pathnameNoPage, current + 1)}
            className={activeNavClass}
          >
            {next}
          </Link>
        ) : (
          <span className={disabledNavClass}>{next}</span>
        )}
      </div>
    </div>
  );
}
