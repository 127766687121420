import Image from 'next/image';

interface Props {
  image: any;
  width?: number;
  height?: number;
  className?: string;
  priority?: boolean;
  props?: any;
}

export default function FeaturedImage({
  image,
  width,
  height,
  className,
  priority,
  ...props
}: Props) {
  const { altText, sourceUrl: src, mediaDetails } = image.node;
  width = width ? width : mediaDetails?.width;
  height = height ? height : mediaDetails?.height;

  return src && width && height ? (
    <figure className={className}>
      <Image
        src={src}
        alt={altText}
        width={width}
        height={height}
        priority={priority}
        {...props}
      />
    </figure>
  ) : null;
}
