export enum DataStatus {
  Success = "success",
  Loading = "loading",
  Error = "error",
}

interface QuerySuccessResult<ResponseData> {
  status: DataStatus.Success;
  data: ResponseData;
}

interface QueryErrorResult {
  status: DataStatus.Error;
  error: unknown;
}

interface QueryLoadingResult {
  status: DataStatus.Loading;
}

export type QueryResult<ResponseData> =
  | QuerySuccessResult<ResponseData>
  | QueryLoadingResult
  | QueryErrorResult;
