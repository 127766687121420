import React from "react";
import cn from "classnames";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  h3Text,
  pTextSm,
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  variant?: string;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  width?: string;
  contentType: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  innerBlocks?: Block[];
  columns?: number;
  belowButtons?: string;
  rows?: number;
  aspect?: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function AccoladesGridBlock({
  includeAboveHeading = true,
  includeBelowButtons = true,
  includeButtons = true,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 0,
  },
  content = "",
  buttonsNumber = 1,
  buttonOne,
  buttonTwo,
  variant = "white",
  width = "",
  contentType,
  innerBlocks,
  columns = 2,
  belowButtons = "",
  rows = 2,
  aspect = "1:1",
  blockContentProps,
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const accolades = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks
        .filter((_, index) => index < columns * rows)
        .map((each, index) => {
          each.attributes.variant = variant;
          each.attributes.index = index;
          each.attributes.aspect = aspect;
          each.attributes.length = innerBlocks.length;
          each.attributes.className = cn("w-[calc(50%-16px)]", {
            "md:w-[calc(33.3333%-11px)]": columns === 3,
            "md:w-[calc(25%-16px)]": columns === 4,
          });
          return each;
        })}
    />
  ) : null;

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      size: "md",
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      size: "md",
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  const headingClassNames = cn("mb-0 mt-0", h3Text, {
    [textHeadlineNeutral]: variant !== "dark",
    [textHeadlineInverse]: variant === "dark",
  });

  const pClassNames = cn("mt-0 mt-2 md:mt-4", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const belowTextClassNames = cn(pTextSm, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const gridClassNames = cn(
    "flex flex-wrap gap-8 md:gap-4 mt-12 px-4 lg:mt-0 lg:px-0 justify-center"
  );

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width={width}
      defaultValue="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className={`lg:grid lg:grid-cols-2 lg:items-center`}>
        <div className="lg:pr-40">
          {includeAboveHeading && (
            <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
          )}
          {heading && (
            <CustomHeading
              heading={heading}
              headingOptions={headingOptions}
              headingClassNames={headingClassNames}
            />
          )}
          {content && (
            <RichText className={pClassNames} tag="p">
              {content}
            </RichText>
          )}
          {includeButtons && (
            <CustomButtonGroup align="left" buttons={buttons} />
          )}
          {includeBelowButtons && (
            <div className="mt-5 text-center md:text-left">
              <RichText className={belowTextClassNames} tag="small">
                {belowButtons}
              </RichText>
            </div>
          )}
        </div>
        <div className={gridClassNames}>{accolades}</div>
      </div>
    </BlockContent>
  );
}

const BLOCK_NAME = "TenupAccoladesGrid";

AccoladesGridBlock.displayName = BLOCK_NAME;

AccoladesGridBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          aspect
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          columns
          content
          ga4SectionId
          heading
          headingOptions
          includeAboveHeading
          includeBelowButtons
          includeButtons
          metadata
          rows
          variant
          width
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
