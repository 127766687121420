import { useState, useEffect } from "react";
import cn from "classnames";
import BlockContent from "../molecules/BlockContent";
import { RichText } from "@/components/atoms";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { CustomImageInterface } from "../molecules/CustomImage";
import AboveHeading from "../molecules/AboveHeading";
import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  h1TextLarge,
  h1Text,
  textBodyInverse,
  pTextSm,
  pTextL,
  pTextXL,
  h3Text,
  textBodyNeutral,
  h2Text,
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import {
  healthNegative,
  healthPositive,
  iconPlus,
  iconClose,
} from "@/constants/icons";
import { Slider } from "@nextui-org/slider";
import {
  generate,
  GeneratePasswordOptions,
} from "@/utilities/password-generator/generate";
import { evaluate } from "@/utilities/password-generator/evaluate";
import getPasswordGeneratorModalCookieName from "@/utilities/getPasswordGeneratorModalCookieName";
import { getCookie, setCookie } from "@/utilities/cookies";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  includeAboveHeading: boolean;
  includeIcons: boolean;
  includeContent: boolean;
  includeModal: boolean;
  aboveHeading?: string;
  heading?: string;
  headingSize?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  size?: string;
  buttonOne?: CustomButtonInterface;
  icon?: IconInterface;
  textPasswordStatusStrong: string;
  textPasswordStatusFair: string;
  textPasswordStatusWeak: string;
  checkboxGeneratorOne?: string;
  checkboxGeneratorTwo?: string;
  checkboxGeneratorThree?: string;
  checkboxGeneratorFour?: string;
  usernameLengthText?: string;
  buttonTwo?: CustomButtonInterface;
  modalHeading?: string;
  modalContent?: string;
  image?: CustomImageInterface;
  media?: MediaObjectInterface;
  contentType: string;
  paddingTop?: string;
  paddingBottom?: string;
  ga4SectionId?: string;
}

export default function PasswordGeneratorBlock({
  includeAboveHeading = false,
  includeIcons = false,
  includeContent = false,
  includeModal = false,
  aboveHeading = "",
  heading = "",
  headingSize = "lg",
  headingOptions = {
    tag: 1,
  },
  content = "",
  variant = "dark",
  size = "lg",
  buttonOne,
  icon = {
    source: iconPlus,
  },
  textPasswordStatusStrong,
  textPasswordStatusFair,
  textPasswordStatusWeak,
  checkboxGeneratorOne,
  checkboxGeneratorTwo,
  checkboxGeneratorThree,
  checkboxGeneratorFour,
  usernameLengthText,
  buttonTwo,
  modalHeading,
  modalContent,
  image = {
    full: "",
    alt: "",
  },
  media = {
    type: "image",
    media: {},
  },
  contentType,
  ga4SectionId = "",
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const [passwordLength, setPasswordLength] = useState(16);
  const [withLetters, setWithLetters] = useState(true);
  const [withDigits, setWithDigits] = useState(true);
  const [withSymbols, setWithSymbols] = useState(false);
  const [withSimilarCharacters, setWithSimilarCharacters] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("=E=QY]!{PjD53Mq");
  const [isCopied, setIsCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const cookieName = getPasswordGeneratorModalCookieName("password");

  const wasModalTriggered = () => {
    const seen = getCookie(cookieName);

    if (!seen || seen === undefined) {
      return false;
    }

    return true;
  };

  const dismiss = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setCookie(cookieName, "true", tomorrow.toUTCString());

    setIsOpen(false);
  };

  const generatePassword = ({
    length,
    digits,
    letters,
    symbols,
    avoidAmbiguous,
  }: GeneratePasswordOptions) => {
    const password = generate({
      length,
      digits,
      letters,
      symbols,
      avoidAmbiguous,
    });
    setGeneratedPassword(password);
  };

  useEffect(() => {
    generatePassword({
      length: passwordLength,
      digits: withDigits,
      letters: withLetters,
      symbols: withSymbols,
      avoidAmbiguous: withSimilarCharacters,
    });
  }, [
    passwordLength,
    withDigits,
    withLetters,
    withSymbols,
    withSimilarCharacters,
  ]);

  const { score } = evaluate(generatedPassword);

  const getStrengthIcon = () => {
    if (score >= 50) {
      return (
        <Icon {...{ source: healthPositive }} variant={variant} width={32} />
      );
    }

    return (
      <Icon {...{ source: healthNegative }} variant={variant} width={32} />
    );
  };

  const getStrengthText = () => {
    if (score >= 75) return textPasswordStatusStrong;
    if (score >= 50) return textPasswordStatusFair;

    return textPasswordStatusWeak;
  };

  const headingClassNames = cn(marginBMd, {
    [h1TextLarge]: size === "lg" && headingSize === "lg",
    [h1Text]:
      size === "md" || size === "sm" || (size === "lg" && headingSize === "md"),
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant === "light",
  });

  const buttonContainerClassNames = cn(
    "mt-5 flex w-full items-center justify-center md:justify-end"
  );

  const inputClassNames = cn(
    "bg-transparent h-12 pr-4 grow flex items-center me-3",
    h3Text,
    {
      [textHeadlineInverse]: variant === "dark",
      [textHeadlineNeutral]: variant !== "dark",
    }
  );

  const placeHolderClassNames = cn(
    "flex w-full items-center justify-center border-t-0 border-r-0 border-l-0 border-b-2 border-solid",
    {
      "border-b-white": variant === "dark",
      "border-b-blue-8": variant !== "dark",
    }
  );

  const sliderFillerClassNames = cn("rounded", {
    "bg-white": variant === "dark",
    "bg-blue-6": variant !== "dark",
  });

  const sliderTrackClassNames = cn("h-2", {
    "bg-[#ffffff66]": variant === "dark",
    "bg-blue-2": variant !== "dark",
  });

  const sliderThumbClassNames = cn("top-[44%] w-[36px] h-[36px]", {
    "bg-white": variant === "dark",
    "bg-blue-6": variant !== "dark",
  });

  const sliderLabelClassNames = cn("mb-4 uppercase", pTextSm, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const checkboxClassNames = cn("text-base my-1 mx-0", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const inputCheckboxClassNames = cn("w-5 h-5", {
    "accent-grey-3": variant === "dark",
    "accent-blue-6": variant !== "dark",
  });

  const strongPasswordTextClassNames = cn("ml-2", pTextXL, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const modalClasses = cn(
    "relative mx-auto rounded overflow-hidden max-w-4xl bg-white h-full md:h-auto max-h-[100%] md:max-h-[90vh]"
  );

  const imageClassNames = cn(
    "h-full w-full object-cover rounded flex items-end"
  );

  const modalInnerClasses = cn(
    "flex flex-col-reverse	md:flex-row md:grid md:grid-cols-2 gap-0 items-center h-full md:h-auto"
  );

  const modalHeaderClassNames = cn(marginBMd, textHeadlineNeutral, h2Text);

  const pTextModalClassNames = cn("text-xl", textBodyNeutral);

  const closeButtonClasses = cn(
    "absolute right-4 top-4 z-10 h-6 w-6 [&>svg]:w-full",
    {
      "fill-grey-3": variant === "dark",
      "fill-grey-6": variant !== "dark",
    }
  );

  const buttons: [CustomButtonInterface, CustomButtonInterface] = [
    {
      ...buttonOne,
      onClick: () => {
        const TEXT_COPIED_TIMEOUT = 1000;
        void navigator.clipboard.writeText(generatedPassword);
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, TEXT_COPIED_TIMEOUT);

        if (!wasModalTriggered()) {
          setIsOpen(true);
        }
      },
      variant: variant === "dark" ? "white" : "dark",
      text: isCopied ? "Copied" : buttonOne?.text ?? "",
      disabled: isCopied,
    },
    {
      ...buttonTwo,
      variant: "dark",
    },
  ];

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      constrain
      ga4SectionId={ga4SectionId}
    >
      <div
        className={`w-full lg:grid lg:grid-cols-1 lg:items-center lg:gap-20`}
      >
        <div>
          {includeAboveHeading && aboveHeading && (
            <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
          )}
          <CustomHeading
            heading={heading}
            headingClassNames={headingClassNames}
            headingOptions={headingOptions}
          />
          {includeContent && content && (
            <RichText
              className={cn("mb-10", {
                [textBodyInverse]: variant === "dark",
                [textBodyNeutral]: variant !== "dark",
                [pTextL]: size === "lg",
              })}
              tag="p"
            >
              {content}
            </RichText>
          )}
          <div className={placeHolderClassNames}>
            <input
              className={inputClassNames}
              placeholder={generatedPassword}
              value={generatedPassword}
              onChange={(e) => {
                setGeneratedPassword(e.target.value);
              }}
            />
            <button
              onClick={() =>
                generatePassword({
                  length: passwordLength,
                  digits: withDigits,
                  letters: withLetters,
                  symbols: withSymbols,
                  avoidAmbiguous: withSimilarCharacters,
                })
              }
            >
              <Icon {...icon} variant={variant} width={32} />
            </button>
          </div>
          <div className="mb-8 flex w-full flex-row flex-wrap items-center md:flex-nowrap">
            <div className="mt-5 flex min-w-fit flex-auto flex-grow flex-row items-center">
              {includeIcons && getStrengthIcon()}
              {includeIcons && (
                <RichText className={strongPasswordTextClassNames} tag="span">
                  {getStrengthText()}
                </RichText>
              )}
            </div>
            <div className={buttonContainerClassNames}>
              <CustomButtonGroup
                className="mt-0"
                align="left"
                buttons={[buttons[0]]}
              />
            </div>
          </div>
          <div className="pb-16 pt-8">
            <Slider
              color="foreground"
              label={`${usernameLengthText} (${passwordLength})`}
              maxValue={40}
              minValue={4}
              value={passwordLength}
              size="md"
              classNames={{
                track: sliderTrackClassNames,
                filler: sliderFillerClassNames,
                thumb: sliderThumbClassNames,
                label: sliderLabelClassNames,
              }}
              // eslint-disable-next-line react-hooks/exhaustive-deps
              onChange={(val: number | number[]) =>
                setPasswordLength(val as number)
              }
              hideValue={true}
            />
          </div>
          <div className="mb-10 grid grid-cols-2 gap-5 md:grid-cols-4">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                disabled={!withDigits}
                checked={withLetters}
                className={inputCheckboxClassNames}
                onChange={() =>
                  setWithLetters((currentValLetters) => !currentValLetters)
                }
              />
              <div>
                <RichText className={checkboxClassNames} tag="span">
                  {checkboxGeneratorOne}
                </RichText>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={withDigits}
                disabled={!withLetters}
                className={inputCheckboxClassNames}
                onChange={() => {
                  setWithDigits((currentValDigits) => !currentValDigits);
                }}
              />
              <div>
                <RichText className={checkboxClassNames} tag="span">
                  {checkboxGeneratorTwo}
                </RichText>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={withSymbols}
                className={inputCheckboxClassNames}
                onChange={() =>
                  setWithSymbols((currentValSymbols) => !currentValSymbols)
                }
              />
              <div>
                <RichText className={checkboxClassNames} tag="span">
                  {checkboxGeneratorThree}
                </RichText>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={withSimilarCharacters}
                className={inputCheckboxClassNames}
                onChange={() =>
                  setWithSimilarCharacters(
                    (currentValSimilar) => !currentValSimilar
                  )
                }
              />
              <div>
                <RichText className={checkboxClassNames} tag="span">
                  {checkboxGeneratorFour}
                </RichText>
              </div>
            </div>
          </div>
        </div>
        {includeModal && isOpen && (
          <dialog
            className="dialog fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/60 p-4"
            open={isOpen}
            onClick={() => dismiss()}
          >
            <div className={modalClasses} onClick={(e) => e.stopPropagation()}>
              <button
                className={closeButtonClasses}
                dangerouslySetInnerHTML={{ __html: iconClose }}
                onClick={() => dismiss()}
              />
              <div className={modalInnerClasses}>
                <div className="h-full w-full bg-blue-6 px-5 pt-6">
                  <div className={imageClassNames}>
                    <CustomMedia
                      className="h-full w-full"
                      imageClassName="!object-contain"
                      media={media ?? { media: {}, type: "image" }}
                      fallbackImage={image}
                    />
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center gap-0 p-12">
                  <RichText className={modalHeaderClassNames} tag="h1">
                    {modalHeading}
                  </RichText>
                  <RichText className={pTextModalClassNames} tag="p">
                    {modalContent}
                  </RichText>
                  <CustomButtonGroup
                    className="mt-0"
                    align="left"
                    buttons={[buttons[1]]}
                  />
                </div>
              </div>
            </div>
          </dialog>
        )}
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupPasswordGenerator";

PasswordGeneratorBlock.displayName = BLOCK_NAME;

PasswordGeneratorBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          buttonOne
          buttonTwo
          checkboxGeneratorFour
          checkboxGeneratorOne
          checkboxGeneratorThree
          checkboxGeneratorTwo
          className
          content
          heading
          headingOptions
          headingSize
          icon
          image
          includeAboveHeading
          includeContent
          includeIcons
          includeModal
          media
          metadata
          modalContent
          modalHeading
          size
          textPasswordStatusFair
          textPasswordStatusStrong
          textPasswordStatusWeak
          usernameLengthText
          variant
          paddingTop
          paddingBottom
          ga4SectionId
        }
      }
    }
  `,
};
