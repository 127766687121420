import { MarketoFormErrorsInterface } from "@/types";
import {
  getFieldWrapClasses,
  getRequired,
  getFieldClasses,
  getErrorMessages,
} from "@/utilities/marketoForms";
import cn from "classnames";
import { MarketoFormFieldAny } from "@/types";

interface TextareaFieldInterface {
  field: MarketoFormFieldAny;
  register: Function;
  errors: MarketoFormErrorsInterface;
  watch: Function;
}

export default function TextareaField({
  field,
  register,
  errors,
}: TextareaFieldInterface) {
  const inputClassNames = cn(
    getFieldClasses(),
    "px-4 py-2.5 rounded border leading-tight focus:outline-none bg-transparent",
    {
      "border-pink-dark focus:border-pink-dark": errors[field.name],
      "border-grey-1 focus:border-blue-8": !errors[field.name],
    }
  );

  return (
    <div className={getFieldWrapClasses(field)}>
      <label className="block text-sm">
        {field.label}
        {getRequired(field)}
      </label>
      <textarea
        className={inputClassNames}
        id={field.name}
        placeholder={field.placeholder}
        {...register(field.name, { required: true })}
      />
      {getErrorMessages(field, errors)}
    </div>
  );
}
