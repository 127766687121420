export type LinkObject = {
  url: string;
};

type ExternalLink =
  | "adjustAndroid"
  | "adjustIOS"
  | "adminConsole"
  | "appStoreLink"
  | "blog"
  | "blogFIDOBoardMembers"
  | "blogPasskeyAndroid"
  | "blogPasskeyCommonQuestions"
  | "blogPasskeySupportAndroid"
  | "blogPasskeySupportiOS"
  | "blogPasswordless"
  | "blogPasswordlessFuture"
  | "blogPasswordlessProsAndCons"
  | "blogPatentedSecurity"
  | "blogStartUsingPasswordHealthDarkWebMonitoring"
  | "brandKit"
  | "braveExtension"
  | "chromeExtension"
  | "chromeWebStore"
  | "contactSupport"
  | "creditMonitoringProduction"
  | "creditMonitoringStaging"
  | "dashlaneAndroidOpenSource"
  | "dashlaneApp"
  | "dashlaneAppAccount"
  | "dashlaneAppleOpenSource"
  | "dashlaneAppSignup"
  | "dashlaneAppSubscription"
  | "dashlaneBlog"
  | "dashlaneBlogBadPasswords"
  | "dashlaneBlogBusiness"
  | "dashlaneBlogEngineering"
  | "dashlaneBlogPersonal"
  | "dashlaneBlogPostDevelopers"
  | "dashlaneBlogPostRandomPasswordGenerator"
  | "dashlaneBlogPostUITestingFramework"
  | "dashlaneBlogPostWebpackEmails"
  | "dashlaneBlogPostPerfectUX"
  | "dashlaneBrandFolder"
  | "dashlaneCLI"
  | "dashlaneConsole"
  | "dashlaneLegalEmail"
  | "dbtInvoke"
  | "devRelationshipEmail"
  | "eaAdaGuidelinesCompliance"
  | "eaDashlaneLp"
  | "eaScotchSoda"
  | "eaWebContentAccessibilityGuidelinesWcag"
  | "ebookSafeguardSensitiveDataForBusiness"
  | "edgeExtension"
  | "edgeWebStore"
  | "facebook"
  | "familyDashboard"
  | "fidoU2f"
  | "firefoxWebStore"
  | "forbesArticle"
  | "gatsby"
  | "getHelpWithDashlane"
  | "githubDeveloperPack"
  | "googlePatentedSecurity"
  | "googlePlayLink"
  | "guideCybersecurityMarketo"
  | "hackeroneBountyProgram"
  | "helpCenter"
  | "instagram"
  | "lifeAtDashlane"
  | "linkedin"
  | "mailtoSupport"
  | "mailtoSupportGdprErasure"
  | "mailtoSupportGdprAccess"
  | "mailtoSupportGdprRectification"
  | "mailtoSupportGdprRestrictionProcessing"
  | "mailtoSupportGdprDataPortability"
  | "mailtoSupportGdprObject"
  | "mailtoSupportGdprComplaint"
  | "mailtoSupportGdprWithdrawConsent"
  | "partners"
  | "passkeysDirectory"
  | "passkeysWhatIs"
  | "passwordManagerWeakLink"
  | "passwordPlaybook"
  | "pcWorldArticle"
  | "phisingGuideLink"
  | "reddit"
  | "saexOnboarding"
  | "safariExtension"
  | "safariWebStore"
  | "safetyDetectivesArticle"
  | "sawfDocs"
  | "sawfGithub"
  | "securityResources"
  | "startupProgramMarketo"
  | "status"
  | "support"
  | "support2fa"
  | "supportBusinessFaqs"
  | "supportContact"
  | "supportGeneralAccount"
  | "supportedIOSApps"
  | "supportForgotMasterPassword"
  | "supportPasskeysArticle"
  | "supportSecurityFaqs"
  | "supportSsoOverview"
  | "supportTwoFactor"
  | "supportFamilyFaq"
  | "supportMasterPasswordBiometrics"
  | "supportTicket"
  | "teamAdminConsole"
  | "teamAppSubscriptionPayment"
  | "tsEventBus"
  | "twitter"
  | "universalDashlane"
  | "x"
  | "webApp"
  | "webappTeamCreate"
  | "wellKnownPasswordChanger"
  | "whyYouShouldIntegratePwm"
  | "youtube"
  | "youtubeDashlaneBusiness"
  | "youtubeSecurityMadeSimple"
  | "youtubeTimeToPasswordless";

export const externalLinks: Record<ExternalLink, LinkObject> = {
  adjustAndroid: {
    url: "https://app.adjust.com/8n9sd4",
  },
  adjustIOS: {
    url: "https://app.adjust.com/jq0xdf",
  },
  adminConsole: {
    url: "https://console.dashlane.com",
  },
  appStoreLink: {
    url: "https://apps.apple.com/app/id517914548",
  },
  blog: {
    url: "https://www.dashlane.com/blog",
  },
  blogFIDOBoardMembers: {
    url: "https://www.dashlane.com/blog/dashlane-board-member-fido-alliance",
  },
  blogPasskeyAndroid: {
    url: "https://www.dashlane.com/blog/dashlane-passkey-support-android",
  },
  blogPasskeyCommonQuestions: {
    url: "https://www.dashlane.com/blog/common-passkey-questions-cpo",
  },
  blogPasskeySupportAndroid: {
    url: "https://www.dashlane.com/blog/dashlane-releases-passkey-support-on-android",
  },
  blogPasskeySupportiOS: {
    url: "https://www.dashlane.com/blog/dashlane-passkey-support-ios",
  },
  blogPasswordless: {
    url: "https://www.dashlane.com/blog/dashlane-passwordless",
  },
  blogPasswordlessFuture: {
    url: "https://www.dashlane.com/blog/ushering-in-the-passwordless-future-at-dashlane",
  },
  blogPasswordlessProsAndCons: {
    url: "https://www.dashlane.com/blog/pros-and-cons-of-passwordless-future",
  },
  blogPatentedSecurity: {
    url: "https://www.dashlane.com/blog/dashlane-patented-security-architecture",
  },
  blogStartUsingPasswordHealthDarkWebMonitoring: {
    url: "https://www.dashlane.com/blog/start-using-password-health-dark-web-monitoring/",
  },
  brandKit: {
    url: "https://brandfolder.com/dashlane/brand-kit",
  },
  braveExtension: {
    url: "https://chrome.google.com/webstore/detail/dashlane-password-manager/fdjamakpfbbddfjaooikfcpapjohcfmg",
  },
  chromeExtension: {
    url: "https://chrome.google.com/webstore/detail/dashlane-password-manager/fdjamakpfbbddfjaooikfcpapjohcfmg",
  },
  chromeWebStore: {
    url: "https://chrome.google.com/webstore/detail/dashlane-password-manager/fdjamakpfbbddfjaooikfcpapjohcfmg",
  },
  contactSupport: {
    url: "https://support.dashlane.com/hc/articles/11502683784978",
  },
  creditMonitoringProduction: {
    url: "https://www.creditviewdashboard.com/CreditView/welcome.page",
  },
  creditMonitoringStaging: {
    url: "https://creditview-live.sd.demo.truelink.com/CreditView/welcome.page",
  },
  dashlaneAndroidOpenSource: {
    url: "https://github.com/Dashlane/android-apps",
  },
  dashlaneApp: {
    url: "https://app.dashlane.com",
  },
  dashlaneAppleOpenSource: {
    url: "https://github.com/Dashlane/apple-apps",
  },
  dashlaneAppAccount: {
    url: "https://app.dashlane.com/#/account",
  },
  dashlaneAppSignup: {
    url: "https://app.dashlane.com/#/signup",
  },
  dashlaneAppSubscription: {
    url: "https://app.dashlane.com/#/subscription",
  },
  dashlaneBlog: {
    url: "https://www.dashlane.com/blog",
  },
  dashlaneBlogBadPasswords: {
    url: "https://www.dashlane.com/blog/bad-password-examples-mistakes-to-avoid",
  },
  dashlaneBlogBusiness: {
    url: "https://www.dashlane.com/blog/tag/business",
  },
  dashlaneBlogEngineering: {
    url: "https://www.dashlane.com/blog/category/engineering/",
  },
  dashlaneBlogPersonal: {
    url: "https://www.dashlane.com/blog/category/security-tips/personal-tips",
  },
  dashlaneBlogPostDevelopers: {
    url: "https://www.dashlane.com/blog/developers-help-us-help-your-users/",
  },
  dashlaneBlogPostRandomPasswordGenerator: {
    url: "https://www.dashlane.com/blog/how-random-password-generators-work/",
  },
  dashlaneBlogPostUITestingFramework: {
    url: "https://www.dashlane.com/blog/how-to-choose-a-more-effective-ui-testing-framework/",
  },
  dashlaneBlogPostWebpackEmails: {
    url: "https://www.dashlane.com/blog/building-a-webpack-plugin-to-generate-localized-emails/",
  },
  dashlaneBlogPostPerfectUX: {
    url: "https://www.dashlane.com/blog/dashlane-integration-forms/",
  },
  dashlaneBrandFolder: {
    url: "https://brandfolder.com/dashlane/brand-kit",
  },
  dashlaneCLI: {
    url: "https://github.com/Dashlane/dashlane-cli",
  },
  dashlaneConsole: {
    url: "https://console.dashlane.com",
  },
  dashlaneLegalEmail: {
    url: "legal@dashlane.com",
  },
  dbtInvoke: {
    url: "https://github.com/Dashlane/dbt-invoke",
  },
  devRelationshipEmail: {
    url: "mailto: dev-relationship@dashlane.com",
  },
  eaAdaGuidelinesCompliance: {
    url: "https://www.essentialaccessibility.com/blog/ada-guidelines-compliance/",
  },
  eaDashlaneLp: {
    url: "https://www.levelaccess.com/dashlane?utm_source=dashlanehomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=dashlane",
  },
  eaScotchSoda: {
    url: "https://www.essentialaccessibility.com/scotch-soda",
  },
  eaWebContentAccessibilityGuidelinesWcag: {
    url: "https://www.essentialaccessibility.com/blog/web-content-accessibility-guidelines-wcag/",
  },
  ebookSafeguardSensitiveDataForBusiness: {
    url: "https://go.dashlane.com/eBook-How-to-Safeguard-Sensitive-Data-for-Businesses.html?ut[%E2%80%A6]e&utm_medium=webpage&utm_campaign=SafeguardSensitiveDataEBOOK",
  },
  edgeExtension: {
    url: "https://microsoftedge.microsoft.com/addons/detail/dashlane-password-manag/gehmmocbbkpblljhkekmfhjpfbkclbph",
  },
  edgeWebStore: {
    url: "https://microsoftedge.microsoft.com/addons/detail/dashlane-password-manag/gehmmocbbkpblljhkekmfhjpfbkclbph",
  },
  facebook: {
    url: "https://www.facebook.com/GetDashlane",
  },
  familyDashboard: {
    url: "https://app.dashlane.com#family-dashboard",
  },
  fidoU2f: {
    url: "https://www.dashlane.com/fido-u2f",
  },
  firefoxWebStore: {
    url: "https://addons.mozilla.org/en-US/firefox/addon/dashlane/",
  },
  forbesArticle: {
    url: "https://www.forbes.com/advisor/business/software/best-password-managers/",
  },
  gatsby: {
    url: "https://www.gatsbyjs.com",
  },
  getHelpWithDashlane: {
    url: "https://support.dashlane.com/hc/articles/4516905332370",
  },
  githubDeveloperPack: {
    url: "https://education.github.com/pack",
  },
  googlePatentedSecurity: {
    url: "https://www.google.com/patents/US9330245?dq=ininventor:%22Guillaume+Maron%22&hl=en&sa=X&ved=0ahUKEwjt0YrPyr3RAhUjQZoKHQpWDzwQ6AEIIzAB",
  },
  googlePlayLink: {
    url: "https://play.google.com/store/apps/details?id=com.dashlane",
  },
  guideCybersecurityMarketo: {
    url: "https://go.dashlane.com/eBook-Guide-to-Cybersecurity-with-a-PWM.html?utm_source=website&utm_medium=webpage&utm_campaign=apracticalguidetocs",
  },
  hackeroneBountyProgram: {
    url: "https://hackerone.com/dashlane",
  },
  helpCenter: {
    url: "https://support.dashlane.com",
  },
  instagram: {
    url: "https://www.instagram.com/dashlane",
  },
  lifeAtDashlane: {
    url: "https://lifeatdashlane.com/",
  },
  linkedin: {
    url: "https://www.linkedin.com/company/dashlane",
  },
  mailtoSupport: {
    url: "mailto:support@dashlane.com",
  },
  mailtoSupportGdprErasure: {
    url: "mailto:support@dashlane.com?subject=%5BGDPR%20Request%5D%20Right%20to%20Erasure%20or%20to%20be%20Forgotten",
  },
  mailtoSupportGdprAccess: {
    url: "mailto:support@dashlane.com?subject=%5BGDPR%20Request%5D%20Right%20of%20Access",
  },
  mailtoSupportGdprRectification: {
    url: "mailto:support@dashlane.com?subject=%5BGDPR%20Request%5D%20Right%20to%20Rectification",
  },
  mailtoSupportGdprRestrictionProcessing: {
    url: "mailto:support@dashlane.com?subject=%5BGDPR%20Request%5D%20Right%20to%20Restriction%20of%20Processing",
  },
  mailtoSupportGdprDataPortability: {
    url: "mailto:support@dashlane.com?subject=%5BGDPR%20Request%5D%20Right%20to%20Data%20Portability",
  },
  mailtoSupportGdprObject: {
    url: "mailto:support@dashlane.com?subject=%5BGDPR%20Request%5D%20Right%20to%20Object",
  },
  mailtoSupportGdprComplaint: {
    url: "mailto:support@dashlane.com?subject=%5BGDPR%20Request%5D%20Right%20to%20Lodge%20a%20Complaint",
  },
  mailtoSupportGdprWithdrawConsent: {
    url: "mailto:support@dashlane.com?subject=%5BGDPR%20Request%5D%20Right%20to%20Withdraw%20Consent",
  },
  passkeysDirectory: {
    url: "https://passkeys-directory.dashlane.com/",
  },
  partners: {
    url: "https://www.partners.dashlane.com/English/",
  },
  passkeysWhatIs: {
    url: "https://www.youtube-nocookie.com/embed/o843r0T9WmI",
  },
  passwordManagerWeakLink: {
    url: "https://go.dashlane.com/WP-Password-Manager-Weak-Link.html",
  },
  passwordPlaybook: {
    url: "https://go.dashlane.com/Password-Playbook-SMB.html?utm_source=website&utm_medium=resourcelibrary&utm_campaign=SmallBusinessesPasswordPlaybook",
  },
  pcWorldArticle: {
    url: "https://www.pcworld.com/article/407081/dashlane-password-manager-review.html",
  },
  phisingGuideLink: {
    url: "https://go.dashlane.com/Phishing-101.html",
  },
  reddit: {
    url: "https://www.reddit.com/r/Dashlane/",
  },
  saexOnboarding: {
    url: "https://app.dashlane.com#onboarding",
  },
  safariExtension: {
    url: "https://apps.apple.com/us/app/dashlane-for-safari-legacy/id1505779553?mt=12",
  },
  safariWebStore: {
    url: "https://apps.apple.com/app/dashlane-password-manager/id517914548",
  },
  safetyDetectivesArticle: {
    url: "https://www.safetydetectives.com/best-password-managers/dashlane/",
  },
  sawfDocs: {
    url: "https://dashlane.github.io/SAWF/",
  },
  sawfGithub: {
    url: "https://github.com/Dashlane/SAWF",
  },
  securityResources: {
    url: "https://www.dashlane.com/blog/resources",
  },
  startupProgramMarketo: {
    url: "https://go.dashlane.com/Startup-Program.html",
  },
  status: {
    url: "https://status.dashlane.com",
  },
  support: {
    url: "https://support.dashlane.com/hc/",
  },
  support2fa: {
    url: "https://support.dashlane.com/hc/articles/202699101",
  },
  supportBusinessFaqs: {
    url: "https://support.dashlane.com/hc/categories/200958889-Dashlane-Business",
  },
  supportContact: {
    url: "https://support.dashlane.com/hc/requests/new",
  },
  supportGeneralAccount: {
    url: "https://support.dashlane.com/hc/articles/202698981",
  },
  supportedIOSApps: {
    url: "https://support.dashlane.com/hc/articles/360000734299",
  },
  supportForgotMasterPassword: {
    url: "https://support.dashlane.com/hc/articles/202698981",
  },
  supportPasskeysArticle: {
    url: "https://support.dashlane.com/hc/articles/7888558064274-Passkeys-in-Dashlane",
  },
  supportSecurityFaqs: {
    url: "https://support.dashlane.com/hc/sections/200961092-Security-at-Dashlane",
  },
  supportSsoOverview: {
    url: "https://support.dashlane.com/hc/articles/360013149040-Dashlane-SSO-Overview-Deployment-steps",
  },
  supportTwoFactor: {
    url: "https://support.dashlane.com/hc/articles/202625042-Protect-your-account-using-Two-Factor-Authentication",
  },
  supportFamilyFaq: {
    url: "https://support.dashlane.com/hc/articles/360013369820",
  },
  supportMasterPasswordBiometrics: {
    url: "https://support.dashlane.com/hc/articles/360010264499",
  },
  supportTicket: {
    url: "https://support.dashlane.com/hc/requests/new",
  },
  teamAdminConsole: {
    url: "https://console.dashlane.com",
  },
  teamAppSubscriptionPayment: {
    url: "https://console.dashlane.com/#/account",
  },
  tsEventBus: {
    url: "https://github.com/Dashlane/ts-event-bus",
  },
  twitter: {
    url: "https://twitter.com/dashlane",
  },
  universalDashlane: {
    url: "https://universal.dashlane.com",
  },
  webApp: {
    url: "https://app.dashlane.com",
  },
  webappTeamCreate: {
    url: "https://app.dashlane.com/#/signup",
  },
  wellKnownPasswordChanger: {
    url: "https://github.com/Dashlane/password-changer-well-known",
  },
  whyYouShouldIntegratePwm: {
    url: "https://go.dashlane.com/Why-You-Should-Integrate-PWM-SSO.html",
  },
  x: {
    url: "https://www.x.com/dashlane",
  },
  youtube: {
    url: "https://www.youtube.com/channel/UC29QiP0ZFzBVTnCeLytXXCg",
  },
  youtubeDashlaneBusiness: {
    url: "https://youtu.be/jBpft60jmec",
  },
  youtubeSecurityMadeSimple: {
    url: "https://www.youtube.com/embed/i5GNwcSTqto",
  },
  youtubeTimeToPasswordless: {
    url: "https://www.youtube-nocookie.com/embed/gEaRLhtGQs0",
  },
};
