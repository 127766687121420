import React, {
  FormEvent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { caretDown, iconClose, search as searchIcon } from "@/constants/icons";
import { useRouter } from "next/router";
import cn from "classnames";
import { useRouterBasePath } from "@/hooks/useRouterBasePath";
import { gql, useQuery } from "@apollo/client";
import { iconFillDark } from "@/constants/standardCSSClasses";
import { useTranslate } from "@/hooks/useTranslate";
import { RESOURCE_SEARCH_CHOOSE_CATEGORY } from "@/i18n/translations";

interface CustomElements extends HTMLFormControlsCollection {
  search: HTMLInputElement;
  category?: HTMLSelectElement;
}

interface SearchForm extends HTMLFormElement {
  readonly elements: CustomElements;
}

interface Props {
  className?: string;
  path?: string;
  basePathOverwrite?: string;
  searchTerm?: string;
  category?: string;
  setPageNum?: Function;
  setSearchTerm?: Function;
  setSearchFocusParent?: Function;
  searchPlaceholder?: string;
  foundResultsText?: string;
  filters?: {
    resourceCategory?: boolean;
    resourceType?: boolean;
  };
}

const searchQuery = gql`
  query GetResourceCategories {
    resourceTypes(first: 100) {
      nodes {
        name
        slug
      }
    }
    resourceCategories(first: 100) {
      nodes {
        name
        slug
      }
    }
  }
`;

export default function SearchForm({
  className = "",
  path = "",
  basePathOverwrite,
  searchTerm = "",
  category = "",
  setPageNum,
  setSearchTerm,
  setSearchFocusParent,
  searchPlaceholder = "Search...",
  foundResultsText,
  filters,
}: PropsWithChildren<Props>) {
  const { translate } = useTranslate();
  const router = useRouter();
  const basePath = useRouterBasePath();
  const [searchFocus, setSearchFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // Get a list of resource categories for the search form filter
  const resourceQuery =
    filters?.resourceCategory || filters?.resourceType
      ? useQuery(searchQuery, {
          skip: !router.isReady,
        })
      : { loading: false, data: [] };

  useEffect(() => {
    if (router.isReady) {
      if (setSearchTerm !== undefined) {
        setSearchTerm(router.query?.search ?? "");
      }
      if (setPageNum !== undefined) {
        setPageNum(
          router.query?.p && typeof router.query?.p === "string"
            ? parseInt(router.query?.p, 10)
            : 0
        );
      }
    }
  }, [router.isReady]);

  const inputClassNames = cn(
    "border-grey-1 text-gray-800 h-full w-full rounded border px-4 py-3 leading-tight focus:outline-none bg-transparent"
  );

  const iconClasses = "[&>svg]:w-full [&>svg]:h-full";

  const closeButtonClasses = cn(
    iconClasses,
    "absolute right-2.5 top-2.5 h-6 w-6 cursor-pointer fill-grey-6"
  );
  const submitButtonClasses = cn(
    iconClasses,
    "h-10 w-10 ml-3 mt-1 fill-blue-8"
  );

  const resetSearch = () => {
    if (inputRef?.current) {
      inputRef.current.focus();
      inputRef.current.value = "";
    }
    setTimeout(() => setSearchFocus(true), 200);
  };

  const doSearch = (e: FormEvent<SearchForm>) => {
    e.preventDefault();
    let searchHref = `${basePathOverwrite ?? basePath}${path}?search=${
      e.currentTarget.elements.search.value
    }`;
    if (e.currentTarget.elements.category?.value) {
      searchHref += `&category=${e.currentTarget.elements.category.value}`;
    }
    window.location.href = searchHref;
  };

  return (
    <div className={className}>
      <form
        className="mb-4 flex"
        onSubmit={(e: FormEvent<SearchForm>) => doSearch(e)}
      >
        <div className="relative flex grow">
          <div className="relative min-w-[45%] grow">
            <input
              type="text"
              name="search"
              defaultValue={searchTerm}
              ref={inputRef}
              placeholder={searchPlaceholder}
              className={inputClassNames}
              onFocus={() => {
                setSearchFocus(true);

                if (setSearchFocusParent) {
                  setSearchFocusParent(true);
                }
              }}
              // Use timeout because clicking on the button causes blur before the button onClick can fire
              onBlur={() =>
                setTimeout(() => {
                  setSearchFocus(false);

                  if (setSearchFocusParent) {
                    setSearchFocusParent(false);
                  }
                }, 200)
              }
            />
            {searchFocus && (
              <div
                className={closeButtonClasses}
                dangerouslySetInnerHTML={{ __html: iconClose }}
                onClick={() => resetSearch()}
              />
            )}
          </div>
          {!!resourceQuery?.data?.resourceCategories?.nodes?.length && (
            <div className="ml-3 min-w-[45%] md:min-w-fit">
              <select
                className="h-full w-full max-w-full appearance-none rounded border border-grey-1 bg-transparent py-2.5 pl-2.5 pr-8 leading-tight text-gray-800 focus:border-blue-8 focus:outline-none"
                name="category"
                defaultValue={category}
              >
                <option value="">
                  {translate(RESOURCE_SEARCH_CHOOSE_CATEGORY)}
                </option>
                {resourceQuery.data.resourceCategories.nodes.map(
                  (each: { slug: string; name: string }) => (
                    <option value={each.slug}>{each.name}</option>
                  )
                )}
              </select>
              <div
                className={cn(
                  iconFillDark,
                  "pointer-events-none absolute right-2 top-3 h-5"
                )}
              >
                {caretDown}
              </div>
            </div>
          )}
        </div>
        <button className={submitButtonClasses} type="submit">
          {searchIcon}
        </button>
      </form>
      {searchTerm && <div>{foundResultsText}</div>}
    </div>
  );
}
