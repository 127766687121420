import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import classnames from "classnames";
const ReactPlayer = dynamic(() => import("react-player"));

export interface CustomVideoEmbedInterface {
  videoClassName?: string;
  className?: string;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsinline?: boolean;
  url: string;
  aspect?: string;
}

function CustomVideoEmbed({
  videoClassName = "",
  className = "",
  autoplay = true,
  controls = false,
  loop = true,
  muted = true,
  playsinline = true,
  url,
  aspect,
}: CustomVideoEmbedInterface) {
  const [hasWindow, setHasWindow] = useState(false);

  // Prevent hydration error
  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  const classNames = classnames(className, "relative rounded overflow-hidden", {
    "aspect-[1/1]": aspect === "1:1",
    "aspect-[3/2]": aspect === "3:2",
    "aspect-[2/1]": aspect === "2:1",
    "aspect-[824/477]": aspect === "824:477" || aspect === "contentCard",
  });

  const videoClassNames = classnames(videoClassName, {
    "w-full h-full [&>video]:w-full [&>video]:h-full [&>video]:object-cover":
      aspect,
  });

  return (
    <div className={classNames}>
      {hasWindow && url && (
        <ReactPlayer
          className={videoClassNames}
          url={url}
          loop={loop}
          playing={autoplay}
          controls={controls}
          muted={muted}
          playsinline={playsinline}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
}

export default CustomVideoEmbed;
