import cn from "classnames";
import { RichText } from "@/components/atoms";
import {
  borderNeutral,
  pTextL,
  marginBMd,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import CustomImage, {
  CustomImageInterface,
} from "@/components/molecules/CustomImage";
import { useTouchSlide } from "@/hooks/useTouchSlider";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  logo: CustomImageInterface;
  content?: string;
  attributionImage: CustomImageInterface;
  attributionName: string;
  attributionPosition: string;
  linkText?: string;
  linkUrl?: string;
  border: boolean;
}

export default function TestimonialBlock({
  content,
  logo,
  attributionImage,
  attributionName,
  attributionPosition,
  linkText,
  linkUrl,
  border = true,
}: Props) {
  const { slideWrapClass } = useTouchSlide(undefined, true);

  const wrapClassNames = cn(slideWrapClass, "flex flex-col justify-between", {
    "p-8 md:p-20 rounded bg-blue-1 bg-testimonials-pattern-mobile md:bg-testimonials-pattern bg-right bg-contain  bg-no-repeat bg-gradient-to-r from-blue-1 to-blue-1 from-blue-3-light to-blue-3-lighter":
      border,
    borderNeutral,
  });

  const pTextClassNames = cn("text-2xl md:text-5xl text-blue-8");

  const attrClassNames = cn("block", pTextL, textHeadlineNeutral);
  const titleAttrClassNames = cn("font-medium", attrClassNames);

  const logoClassNames = cn("object-contain h-[55px]", marginBMd);

  const imageClassNames = cn("h-[54px] w-[54px] rounded-full");

  return (
    <div className={wrapClassNames}>
      {logo?.full && (
        <CustomImage
          className={logoClassNames}
          imageClassName="object-left"
          objectFit="contain"
          {...logo}
        />
      )}
      <RichText className={pTextClassNames} tag="h2">
        {content}
      </RichText>
      <div className="flex w-full flex-col md:flex-row">
        <div className="mt-10 flex md:flex-nowrap">
          {attributionImage?.full && (
            <div className="mr-4">
              <CustomImage
                className={imageClassNames}
                imageClassName="rounded-full"
                objectFit="cover"
                {...attributionImage}
              />
            </div>
          )}
          <div className="flex-1">
            <RichText className={titleAttrClassNames}>
              {attributionName}
            </RichText>
            <RichText className={attrClassNames} tag="span">
              {attributionPosition}
            </RichText>
          </div>
        </div>
        {linkText && linkUrl && (
          <div className="ml-0 mt-7 flex md:ml-auto md:mt-0 md:items-end">
            <a href={linkUrl} className="text-lg hover:no-underline">
              {linkText}
            </a>
            <ArrowRightIcon
              width={20}
              className="mb-[2px] ml-2 stroke-blue-5"
              color=""
            />
          </div>
        )}
      </div>
    </div>
  );
}
// Must match __typename
TestimonialBlock.displayName = "TenupTestimonial";

TestimonialBlock.fragments = {
  key: `TenupTestimonialBlockFragment`,
  entry: gql`
    fragment TenupTestimonialBlockFragment on TenupTestimonial {
      ${getEditorBlock()}
      attributes {
        ... on TenupTestimonialAttributes {
          attributionImage
          attributionName
          attributionPosition
          className
          content
          linkText
          linkUrl
          logo
          metadata
        }
      }
    }
  `,
};
