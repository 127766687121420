import { RichText } from "@/components/atoms";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor?: string;
  innerBlocks?: Block[];
  ordered?: boolean;
  start?: number;
  values?: string;
}

/**
 * List blocks support either unordered or ordered lists
 */
export default function ListBlock({
  anchor,
  innerBlocks,
  ordered,
  start,
  values,
}: Props) {
  const handleListItems = innerBlocks?.length ? (
    <Blocks blocks={innerBlocks} />
  ) : (
    <RichText>{values}</RichText>
  );

  return (
    <div id={anchor} className="my-3 pl-6 text-base">
      {ordered ? (
        <ol start={start} className="list-decimal space-y-1">
          {handleListItems}
        </ol>
      ) : (
        <ul className="list-disc space-y-1">{handleListItems}</ul>
      )}
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreList";

ListBlock.displayName = BLOCK_NAME;

ListBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          ordered
          start
          values
        }
      }
    }
  `,
};
