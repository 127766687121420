declare global {
  interface Window {
    LDBookItV2: any;
    opera: any;
  }
}

import {
  DEMO_FORM_ID,
  SALES_FORM_ID,
  TRIAL_FORM_ID,
} from "@/constants/formIds";
import FELogger from "./monitoring/feLogger";
import { MarketoFormValues } from "@/types";
import { isMobileBrowser } from "./isMobileBrowser";

const logger = new FELogger();

export const formTypeMap: { [key: number]: LeanDataFormType } = {
  [SALES_FORM_ID]: "Contact Sales",
  [TRIAL_FORM_ID]: "Business Trial",
  [DEMO_FORM_ID]: "Demo Request",
};

export type LeanDataFormType =
  | "Contact Sales"
  | "Demo Request"
  | "Business Trial";

export const submitAndGetCalendarLink = (
  formId: number,
  formData: MarketoFormValues
): Promise<string | undefined> => {
  return new Promise((resolve) => {
    loadLeanData(formTypeMap[formId] as LeanDataFormType);

    saveFormDataAndSubmit(formData);

    const handleBookItResponse = (e: MessageEvent) => {
      if (e.data.message === "LD_ROUTING_RESPONSE") {
        const routingResponseData = e.data.responseData;
        const calendarLink = routingResponseData?.calendarLink;
        window.removeEventListener("message", handleBookItResponse);
        resolve(calendarLink);
      }
    };

    window.addEventListener("message", handleBookItResponse);

    setTimeout(() => {
      window.removeEventListener("message", handleBookItResponse);
      resolve(undefined);
    }, 1000);
  });
};

function saveFormDataAndSubmit(formData: MarketoFormValues) {
  (formData as any).LD_BookIt_Log_ID__c = window.LDBookItV2.getUniqueId();
  window.LDBookItV2.saveFormData(formData);
  window.LDBookItV2.submit();
}

export function setMarketoAsProvider() {
  window.LDBookItV2.setFormProvider("marketo");
}

function getInitConfig() {
  const config = {
    useIframe: true,
    skipSubmissionConfirmation: true,
    loadingText: "Verifying your details",
    mobileCalendarOptions: {},
  };

  if (isMobileBrowser()) {
    config.useIframe = false;
    config.mobileCalendarOptions = { useModal: true };
  }

  return config;
}

export const loadLeanData = (nodeName: LeanDataFormType) => {
  try {
    if (window.LDBookItV2) {
      return;
    }

    var _ld_scriptEl = document.createElement("script");
    _ld_scriptEl.src = "https://cdn.leandata.com/js-snippet/ld-book-v2.js";
    _ld_scriptEl.addEventListener("load", function () {
      window.LDBookItV2.initialize(
        "00Dj0000000HBlEEAW",
        nodeName,
        "LD_BookIt_Log_ID__c",
        getInitConfig()
      );
    });

    document.body.appendChild(_ld_scriptEl);
  } catch (error) {
    logger.error("loadLeanData", "Error on Lean Data API request", {
      error,
    });
  }
};

export const loadBookItIframe = (nodeName: LeanDataFormType) => {
  if (window.LDBookItV2) {
    return;
  }
  try {
    var _ld_scriptEl = document.createElement("script");
    _ld_scriptEl.src = "https://cdn.leandata.com/js-snippet/ld-book-v2.js";
    _ld_scriptEl.addEventListener("load", function () {
      window.LDBookItV2.initialize(
        "00Dj0000000HBlEEAW",
        nodeName,
        "LD_BookIt_Log_ID__c",
        getInitConfig()
      );

      const cb = window.LDBookItV2.getIframeFn();
      window.LDBookItV2.submit({ cb });
    });

    document.body.appendChild(_ld_scriptEl);
  } catch (error) {
    logger.error("loadBookItIframe", "Error on Lean Data API request", {
      error,
    });
  }
};
