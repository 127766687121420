export const ERROR_500_SUBHEADING = {
  "en": "Not sure what happened, but something went wrong. Please try again.",
  "fr": "Je ne sais pas exactement ce qui s'est passé, mais quelque chose a mal tourné. Veuillez réessayer.",
  "de": "Ich bin mir nicht sicher, was passiert ist, aber etwas ist schiefgegangen. Bitte versuchen Sie es erneut.",
  "es": "No estamos seguros de lo que ha pasado, pero algo ha salido mal. Inténtelo nuevamente.",
  "pt-br": "Not sure what happened, but something went wrong. Please try again.",
  "it": "Not sure what happened, but something went wrong. Please try again.",
  "ja": "Not sure what happened, but something went wrong. Please try again.",
  "ko": "Not sure what happened, but something went wrong. Please try again.",
  "nl": "Not sure what happened, but something went wrong. Please try again.",
  "pt-pt": "Not sure what happened, but something went wrong. Please try again.",
  "sv": "Not sure what happened, but something went wrong. Please try again.",
  "zh": "Not sure what happened, but something went wrong. Please try again."
}