import getJumpLinkId from "@/utilities/getJumpLinkId";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  name: string;
}

export default function JumpLinkStartBlock({ name = "" }: Props) {
  return <div id={`${getJumpLinkId(name)}`} />;
}

// Must match __typename
const BLOCK_NAME = "TenupJumpLinkSection";

JumpLinkStartBlock.displayName = BLOCK_NAME;

JumpLinkStartBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          name
        }
      }
    }
  `,
};
