export default function TwitterIcon({ className }: { className: string }) {
  return (
    <>
      <span className="sr-only">X</span>

      <svg className={className} fill="currentColor" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1171 3.33331H16.379L11.4374 8.98118L17.2508 16.6666H12.699L9.13385 12.0054L5.05453 16.6666H2.79128L8.07675 10.6256L2.5 3.33331H7.16735L10.3899 7.59383L14.1171 3.33331ZM14.5766 15.3128H13.3232L5.14137 4.61605H6.48632L14.5766 15.3128Z"
        />
      </svg>
    </>
  );
}
