import { PropsWithChildren } from "react";
import Link from "next/link";
import cn from "classnames";

interface Props {
  attributes?: { id?: string; target?: string; rel?: string };
  className?: string;
  externalLink?: boolean;
  href?: string;
  onClick?: () => any;
  style?: {
    backgroundColor?: string;
    color?: string;
  };
}

export default function Button({
  attributes,
  children,
  className,
  externalLink,
  href,
  onClick,
  style,
}: PropsWithChildren<Props>) {
  const classNames =
    "rounded-md cursor-pointer hover:no-underline transition-colors duration-500 min-w-fit flex items-center px-4 py-3 bg-blue-6 hover:bg-blue-7 active:bg-blue-8 disabled:opacity-10 text-white";

  return (
    <>
      {href && !externalLink && (
        <Link
          {...attributes}
          href={href}
          className={cn(classNames, className)}
          style={style}
        >
          {children}
        </Link>
      )}
      {href && externalLink && (
        <a
          {...attributes}
          href={href}
          className={cn(classNames, className)}
          style={style}
        >
          {children}
        </a>
      )}
      {onClick && (
        <button
          {...attributes}
          className={cn(classNames, className)}
          onClick={onClick}
          style={style}
        >
          {children}
        </button>
      )}
    </>
  );
}
