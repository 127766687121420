import { useEffect, useState } from "react";
import { gql } from "@apollo/client";

import * as MENUS from "@/constants/menus";
import {
  BlogInfoFragment,
  FeaturedImageFragment,
  MenuItemFragment,
} from "@/fragments";
import { Main } from "@/components/atoms";
import { PreviewLoader, SEO } from "@/components/molecules";
import type { Locale, MarketoFormValues, PageData } from "@/types";
import { getPostDataFromProps } from "@/utilities/getPostDataFromProps";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import NewMenuItemFragment from "@/fragments/NewMenuItem";
import {
  GetEditorBlocksFragments,
  GetEditorBlocksKeys,
} from "@/fragments/fragmentFunctions";
import cn from "classnames";
import DashlaneLogo from "./trial-success/Logo.png";
import EmailIcon from "./trial-success/email_icon.svg";
import EmailWhiteIcon from "./trial-success/email_white_icon.svg";
import CheckIcon from "./trial-success/check_icon.svg";
import Image from "next/image";
import { redirectToUrl } from "@/utilities/redirectToUrl";
import { createBusinessTrial } from "@/utilities/createBusinessTrial";
import { useTranslate } from "@/hooks/useTranslate";

enum PageState {
  Default = "Default",
  Sending = "Sending",
  Sent = "Sent",
  Error = "Error",
  Invalid = "Invalid",
}

export default function Component(props: PageData) {
  const [email, setEmail] = useState("");
  const [pageState, setPageState] = useState(PageState.Default);
  const [formValues, setFormValues] = useState({} as MarketoFormValues);
  const [from, setFrom] = useState("/business-password-manager/try");
  const [plan, setPlan] = useState("");

  const { locale } = useTranslate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const fromParam = queryParams.get("from");
    const planParam = queryParams.get("plan");

    if (fromParam) {
      setFrom(fromParam);
    }

    if (planParam) {
      setPlan(planParam);
    }
  }, []);

  useEffect(() => {
    const localStorageForm = localStorage.getItem("form-submission-data");
    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get("email");

    if (emailParam) {
      setEmail(emailParam);
    }

    if (localStorageForm) {
      const formData = JSON.parse(localStorageForm);

      if (formData) {
        setFormValues(formData);

        const formEmail = formData?.Email ?? emailParam;
        if (formEmail) {
          setEmail(formEmail);
        }
      }
    }
  }, []);
  // We're checking if the user has already requested to receive the email
  // If yes, he's redirected to the form he comes from
  // This is usefull to avoid having a user refreshing this page and submitting the request again and again
  useEffect(() => {
    const decodedFrom = decodeURIComponent(from);
    const localStorageKey = `${decodedFrom}-EmailResent`;
    const resent = localStorage.getItem(localStorageKey);

    if (resent !== null && resent === "true") {
      localStorage.removeItem(localStorageKey);
      redirectToUrl(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  // By clicking on the submit button, the user requests to receive the form confirmation email again.
  // We use the same function and endpoint as previously used to submit the form.
  // The parent component receives the PageState, then displays the error page or the success page
  const onClick = async () => {
    setPageState(PageState.Sending);
    localStorage.setItem(`${from}-EmailResent`, "true");

    const trialResult = await createBusinessTrial(
      { ...formValues, product_plan__c: plan },
      locale
    );

    const trialErrorMessage =
      trialResult && typeof trialResult !== "boolean"
        ? trialResult?.errorMessage
        : "";

    // If there was an error creating the trial, then set the error message and return without sending lead to Marketo
    if (trialErrorMessage) {
      setPageState(PageState.Error);
    }
  };

  // Needed for previews to load on the client
  if (props.loading) {
    return <PreviewLoader />;
  }

  const { translated, seo, uri, isFrontPage } = getPostDataFromProps(
    props,
    "page"
  );

  const mainContainerStyles = cn(
    "flex max-h-[448px] items-center bg-[#F6F9FA] p-5 md:p-10 font-sans"
  );
  const headingContainerStyles = cn(
    "w-full h-full flex flex-col justify-center items-center mt-8 max-w-[600px]"
  );
  const headingStyles = cn(
    "text-center text-[22px] md:text-3xl color-[rgba(42,49,56,0.92)] font-sans"
  );
  const stepsContainerStyles = cn("min-w-[536px] mt-8 flex justify-center");
  const stepStyles = cn("w-20 flex flex-col items-center gap-2");
  const stepNumberStyles = (status = "default") =>
    cn(
      "relative flex justify-center items-center w-[46px] h-[46px] border rounded-[50%] text-[26px] font-sans",
      {
        "border-transparent": status === "default" || status === "done",
        "border-[rgba(50,169,178,0.50)]": status === "active",
        "bg-[rgba(150,167,176,0.10)]": status === "default",
        "bg-[rgba(62,186,149,0.20)]": status === "done",
        "bg-white": status === "active",
        "text-[rgba(129,145,158,0.70)]": status === "default",
        "text-[#0C7D8C]": status === "active",
        "text-[#058063]": status === "done",
      }
    );
  const stepCheckStyles = cn("absolute top-[-1px] right-[-10px]");
  const stepTextStyles = (status = "default") =>
    cn("text-[11px] text-center font-sans", {
      "text-[rgba(129,145,158,0.70)]": status === "default",
      "text-[#058063]": status === "active",
      "text-[#0C7D8C]": status === "done",
    });
  const connectLineStyles = cn(
    "hidden md:block w-[72px] h-[1px] border-t border-[#191D211A] mt-5"
  );

  const verifyContainerStyles = cn(
    "flex flex-col justify-start items-center w-full p-8 md:py-[46.5px] md:px-8 shadow-lg min-h-[360px] bg-white mt-5 rounded"
  );

  const ctasContainerStyles = cn(
    "flex  flex-col md:flex-row justify-center items-center gap-4 mt-6"
  );
  const ctaStyles = cn(
    "flex justify-center items-center gap-2 bg-blue-bold text-white text-[15px] px-[18px] py-[15px] pointer rounded font-sans"
  );
  const disclaimerContainerStyles = cn(
    "[&>p]:text-center [&>p]:text-[15px] [&>p]:text-[#4A5B69DE] mt-8 font-sans"
  );
  const resendEmailStyles = cn("cursor-pointer", {
    underline: pageState !== PageState.Sending,
  });

  return (
    <>
      <SEO
        fullHead={seo.fullHead}
        availableLocales={translated}
        locale={props.locale}
        uri={uri}
        isFrontPage={isFrontPage}
      />
      <Main className={mainContainerStyles}>
        <div className="flex h-10 w-full items-center justify-start">
          <Image src={DashlaneLogo} alt="Dashlane Logo" height={40} />
        </div>
        <div className={headingContainerStyles}>
          <h1 className={headingStyles}>
            Thanks for getting started with Dashlane!
          </h1>
          <div className={stepsContainerStyles}>
            <div className={stepStyles}>
              <div className={stepNumberStyles("done")}>
                1
                <div className={stepCheckStyles}>
                  <Image src={CheckIcon} alt="email icon" />
                </div>
              </div>
              <div className={stepTextStyles("done")}>
                Submit the trial form
              </div>
            </div>
            <div className={connectLineStyles}></div>
            <div className={stepStyles}>
              <div className={stepNumberStyles("active")}>2</div>
              <div className={stepTextStyles("active")}>Verify your email</div>
            </div>
            <div className={connectLineStyles}></div>
            <div className={stepStyles}>
              <div className={stepNumberStyles()}>3</div>
              <div className={stepTextStyles()}>Install browser extension</div>
            </div>
            <div className={connectLineStyles}></div>
            <div className={stepStyles}>
              <div className={stepNumberStyles()}>4</div>
              <div className={stepTextStyles()}>Create master password</div>
            </div>
          </div>
          <div className={verifyContainerStyles}>
            {email && (
              <div className="flex items-center gap-1 rounded-b-xl rounded-t-xl border px-3">
                <div className="icon-container">
                  <Image src={EmailIcon} alt="email icon" />
                </div>
                <div className="email text-[13px]">{email}</div>
              </div>
            )}
            <h2 className="mt-6 text-[22px] text-[#212529]">
              Verify your email
            </h2>
            <p className="mt-2 text-center text-[13px] text-[#2A3138EB]">
              {email && (
                <>
                  We’ve sent a verification email to <strong>{email}</strong>
                  <br />
                </>
              )}
              Please select your provider to confirm your email.
            </p>
            <div className={ctasContainerStyles}>
              <a
                className={ctaStyles}
                href="https://mail.google.com/mail/u/0/#inbox"
                target="_blank"
              >
                <Image src={EmailWhiteIcon} alt="email icon" />
                Open Gmail
              </a>
              <a
                className={ctaStyles}
                href="https://outlook.live.com/mail/inbox"
                target="_blank"
              >
                <Image src={EmailWhiteIcon} alt="email icon" />
                Open Outlook
              </a>
            </div>
            <div className={disclaimerContainerStyles}>
              {pageState !== PageState.Sent &&
                pageState !== PageState.Error && (
                  <>
                    <p>Can't find the email?</p>
                    <p>
                      Check your spam folder or{" "}
                      <span onClick={onClick} className={resendEmailStyles}>
                        {pageState === PageState.Sending
                          ? "Resending..."
                          : "resend the email"}
                      </span>
                    </p>
                  </>
                )}
              {pageState === PageState.Error && <p>Something went wrong</p>}
            </div>
          </div>
        </div>
      </Main>
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${GetEditorBlocksFragments()}
  ${FeaturedImageFragment}
  ${MenuItemFragment}
  ${NewMenuItemFragment}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
    $newMenuLocation: MenuLocationEnum
    $asPreview: Boolean = false
    $locale: LanguageCodeFilterEnum = EN
  ) {
    page(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      ${GetEditorBlocksKeys()}
      excerpt
      seo {
        fullHead
      }
      uri
      isFrontPage
      contentType {
        node {
          name
        }
      }
      ...FeaturedImageFragment
    }
    generalSettings {
      ...BlogInfoFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    newMenuItems: menuItems(
      where: { location: $newMenuLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...NewMenuItemFragment
      }
    }
  }
`;

Component.variables = (
  { databaseId }: { databaseId: string },
  { asPreview, locale }: { asPreview?: boolean; locale: Locale }
) => {
  return {
    asPreview,
    footerLocation: MENUS.newFooterLocation(locale),
    headerLocation: MENUS.newHeaderLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
    databaseId,
  };
};
