import cn from "classnames";
import Icon, { IconInterface } from "./Icon";
import {
  backgroundInverse,
  backgroundInverseLightAlt,
  backgroundNeutral,
  textBodyCatchyInverse,
  textBodyCatchyNeutral,
} from "@/constants/standardCSSClasses";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import React from "react";
import { RichText } from "@/components/atoms";

export interface AboveHeadingChipInterface {
  title: string;
  icon: IconInterface;
  includeTitle: boolean;
  includeIcon: boolean;
  button: CustomButtonInterface;
  ga4Id?: string;
}

interface AboveHeadingChipFullInterface {
  aboveHeadingChip: AboveHeadingChipInterface;
  variant: string;
  className: string;
  style?: {};
}

const AboveHeadingChip = ({
  aboveHeadingChip,
  variant,
  className = "",
  style = {},
}: AboveHeadingChipFullInterface) => {
  const {
    includeIcon = false,
    includeTitle = false,
    button = {},
    icon,
    title = "Title Here",
    ga4Id,
  } = aboveHeadingChip;

  const classNames = cn(
    "text-sm inline-flex mb-10 rounded-full items-center p-1 pr-3 font-medium",
    className,
    {
      [backgroundInverseLightAlt]: variant === "dark",
      [backgroundNeutral]: variant === "white",
      "bg-white": variant === "light",
      "p-1 pr-3": includeTitle || includeIcon,
      "py-1 px-3": !includeTitle && !includeIcon,
    }
  );

  const chipClassNames = cn("mr-1.5 box-border rounded-full", {
    [backgroundInverse]: variant === "dark",
    "bg-white": variant === "white",
    [backgroundNeutral]: variant === "light",
  });

  const iconWrapClassNames = cn(chipClassNames, "w-8 h-8 p-2");

  const titleClassNames = cn(chipClassNames, "py-1.5 px-2.5", {
    [textBodyCatchyNeutral]: variant !== "dark",
    [textBodyCatchyInverse]: variant === "dark",
  });

  const buttons = [
    {
      ...button,
      text: button?.text?.substring(0, 38),
      wrap: false,
      variant: variant,
      textOnly: true,
      arrow: true,
      size: "sm",
      ga4Id
    },
  ];

  return (
    <div className={classNames} style={style}>
      {includeIcon && (
        <Icon
          {...icon}
          variant={variant}
          iconStyle="catchy"
          className={iconWrapClassNames}
          iconClassName="w-full h-full max-w-4 mt-[-3px]"
        />
      )}
      {!includeIcon && includeTitle && title && (
        <RichText className={titleClassNames}>
          {title.substring(0, 24)}
        </RichText>
      )}
      <CustomButtonGroup
        align="left"
        buttons={buttons}
        marginTop=""
        mobileWide={false}
      />
    </div>
  );
};

export default AboveHeadingChip;
