import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import {
  backgroundInverse,
  textBodyInverse,
} from "@/constants/standardCSSClasses";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MD_BREAK } from "@/constants/utility";
import { iconClose } from "@/constants/icons";

interface Props {
  delay: number;
  children: string | React.ReactNode;
  direction: string;
  content: string;
  textAlign?: "center" | "left" | "right";
}

export default function Tooltip({
  delay = 0,
  children,
  direction = "top",
  content,
  textAlign = "center",
}: Props) {
  let timeout: ReturnType<typeof setTimeout>;
  const [active, setActive] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < MD_BREAK;
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (active) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [active]);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const textAlignStyles = {
    "text-center": textAlign === "center",
    "text-right": textAlign === "right",
    "text-left": textAlign === "left",
  };

  const tipClassNames = cn(
    `tooltip-tip tooltip-tip-${direction} text-white px-3 py-2 max-w-[220px] lg:max-w-[300px]`,
    backgroundInverse,
    {
      "before:border-t-blue-8": direction === "top",
      "before:border-s-blue-8": direction === "left",
      "before:border-e-blue-8": direction === "right",
      "before:border-b-blue-8": direction === "bottom",
    },
    textAlignStyles
  );

  const mobileTipClassNames = cn(
    "dialog fixed py-10 px-8 w-full max-w-[96vw] ml-[2vw] rounded",
    backgroundInverse,
    textBodyInverse,
    textAlignStyles
  );

  return (
    <div
      className="tooltip-wrapper"
      onMouseEnter={!isMobile ? showTip : undefined}
      onMouseLeave={!isMobile ? hideTip : undefined}
      onClick={isMobile && !active ? showTip : undefined}
    >
      {children}
      {active && !isMobile && <div className={tipClassNames}>{content}</div>}
      {active && isMobile && (
        <dialog ref={modalRef} className={mobileTipClassNames}>
          <button
            className="absolute right-3 top-3 h-6 w-6 fill-grey-3"
            dangerouslySetInnerHTML={{ __html: iconClose }}
            onClick={() => setActive(false)}
          />
          {content}
        </dialog>
      )}
    </div>
  );
}
