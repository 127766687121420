import { gql } from "@apollo/client";

const NewMenuItemFragment = gql`
  fragment NewMenuItemFragment on MenuItem {
    id
    databaseId
    path
    position
    title
    description
    url
    label
    parentId
    linkRelationship
    cssClasses
    target
    structure
    image
    imageVariant
    isShowMoreLink
    customImage
    ga4Id
    connectedNode {
      node {
        id
        databaseId
        __typename
        ... on Post {
          id
          featuredImage {
            node {
              sourceUrl(size: THUMBNAIL)
            }
          }
        }
        ... on Page {
          id
          featuredImage {
            node {
              sourceUrl(size: THUMBNAIL)
            }
          }
        }
      }
    }
    menu {
      node {
        locations
      }
    }
  }
`;

export default NewMenuItemFragment;
