import React from "react";
import { gql } from "@apollo/client";

import * as MENUS from "@/constants/menus";
import {
  BlogInfoFragment,
  MenuItemFragment,
  PreviewFragment,
} from "@/fragments";
import { Main } from "@/components/atoms";
import { SEO } from "@/components/molecules";
import { Archive, Footer } from "@/components/organisms";
import type {
  GeneralSettings,
  Locale,
  PostPreviews,
  SeedNode,
  Seo,
} from "@/types";
import { PAGE_SIZE } from "@/constants/utility";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import NewMenuItemFragment from "@/fragments/NewMenuItem";
import DynamicHeader from "@/components/organisms/DynamicHeader";

interface Props {
  __SEED_NODE__: SeedNode;
  data: {
    footerMenuItems: { nodes: never[] };
    generalSettings: GeneralSettings;
    headerMenuItems: { nodes: never[] };
    newMenuItems: { nodes: never[] };
    posts: PostPreviews;
    tag: {
      description?: string;
      name: string;
      seo?: Seo;
    };
  };
  locale: Locale;
}

export default function Component(props: Props) {
  const { locale } = props;
  const tagName = props.__SEED_NODE__.slug;
  const uri = props.__SEED_NODE__.uri;
  const { nodes } = props.data.posts;
  const { offsetPagination } = props.data.posts.pageInfo;
  const { description, name, seo } = props.data.tag;
  const { title: metaTitle, description: metaDescription } =
    props.data.generalSettings;
  const primaryMenu = props.data.headerMenuItems.nodes ?? [];
  const footerMenu = props.data.footerMenuItems.nodes ?? [];
  const newMenuItems = props.data.newMenuItems.nodes ?? [];

  return (
    <>
      <SEO
        title={`${name} - ${metaTitle}`}
        description={seo?.metaDesc ?? metaDescription}
        uri={uri}
        locale={locale}
      />
      <DynamicHeader
        title={metaTitle}
        description={metaDescription}
        menuItems={primaryMenu}
        enhancedMenuItems={newMenuItems}
      />
      <Main>
        <Archive
          description={description}
          heading={name}
          posts={nodes}
          offsetPagination={offsetPagination}
          variables={{
            locale,
            tagName,
          }}
        />
      </Main>
      <Footer title={metaTitle} menuItems={footerMenu} />
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${MenuItemFragment}
  ${NewMenuItemFragment}
  ${PreviewFragment}
  query GetArchive(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
    $locale: LanguageCodeFilterEnum = EN
    $newMenuLocation: MenuLocationEnum
    $tag: String
    $size: Int = 12
    $offset: Int = 0
  ) {
    posts(where: { tag: $tag, language: $locale, offsetPagination: {offset: $offset, size: $size} }) {
      pageInfo {
        offsetPagination {
          total
          page
          pages
        }
      }
      nodes {
        ...PreviewFragment
      }
    }
    tag(id: $databaseId, idType: DATABASE_ID) {
      name
      description
      seo {
        fullHead
        metaDesc
        title
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    newMenuItems: menuItems(
      where: { location: $newMenuLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...NewMenuItemFragment
      }
    }
  }
`;

Component.variables = (
  { databaseId, slug }: SeedNode,
  { locale }: { locale: Locale },
  extra: any
) => {
  const { page } = extra;
  return {
    databaseId,
    footerLocation: MENUS.newFooterLocation(locale),
    headerLocation: MENUS.newHeaderLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
    tag: slug,
    size: PAGE_SIZE,
    offset: page ? PAGE_SIZE * (page - 1) : 0,
  };
};
