export const BUSINESS_TRIAL_SUCCESS_DISCLAIMER_CHECK_SPAM = {
  "en": "Check your spam folder or",
  "fr": "Veuillez vérifier votre dossier Spam ou cliquer sur",
  "de": "Überprüfen Sie Ihren Spam-Ordner oder",
  "es": "Compruebe su carpeta de correo electrónico no deseado",
  "pt-br": "Check your spam folder or",
  "it": "Check your spam folder or",
  "ja": "Check your spam folder or",
  "ko": "Check your spam folder or",
  "nl": "Check your spam folder or",
  "pt-pt": "Check your spam folder or",
  "sv": "Check your spam folder or",
  "zh": "Check your spam folder or"
}