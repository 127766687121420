export const PARTNERS_REGISTRATION_SUCCESS_PARAGRAPH = {
  "en": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>.",
  "fr": "L'un de nos directeurs commerciaux du canal vous contactera sous peu. Veuillez prendre contact avec <strong>%(resellersEmail)</strong> si vous avez des questions ou des demandes urgentes.",
  "de": "Einer unserer Channel Sales Manager wird sich in Kürze bei Ihnen melden. Wenn Sie dringende Fragen haben, wenden Sie sich bitte an <strong>%(resellersEmail)</strong>.",
  "es": "Uno de nuestros administradores de ventas de canal se pondrá en contacto con usted en breve. Si tiene alguna pregunta o solicitud urgente, póngase en contacto con <strong>%(resellersEmail)</strong>.",
  "pt-br": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>.",
  "it": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>.",
  "ja": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>.",
  "ko": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>.",
  "nl": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>.",
  "pt-pt": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>.",
  "sv": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>.",
  "zh": "One of our Channel Sales Managers will reach out shortly. If you have any urgent questions or requests, please contact <strong>%(resellersEmail)</strong>."
}