import cn from "classnames";
import React from "react";
import {
  backgroundInverse,
  backgroundNeutral,
  h6Text,
  marginBSm,
  marginTMd,
  textBodyCatchyNeutral,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { RichText } from "@/components/atoms";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import Tooltip from "../molecules/Tooltip";
import { info as InfoIcon } from "@/constants/icons";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import { PlanSegment } from "@/types/PlanSegment";
import { useTranslate } from "@/hooks/useTranslate";
import { useTeamOffers } from "@/hooks/useTeamOffers";
import { useDefaultWebOffers } from "@/hooks/useDefaultWebOffers";
import { BUY_FORM_VAT } from "@/i18n/translations";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import Price from "@/components/molecules/Price";
import { tabularCellClasses } from "@/utilities/getTabularCellClasses";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import { iconCheckmark } from "@/constants/icons";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant: string;
  index: number;
  includeTag?: boolean;
  tag?: string;
  price?: string;
  per?: string;
  period?: string;
  buttonsNumber: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  planSegment: PlanSegment;
  highlightColumn?: number;
  columns: number;
  sticky?: boolean;
  useIcon?: boolean;
  icon?: IconInterface;
  displayCustomPrice?: boolean;
  customPrice?: string;
  includeTooltip?: boolean;
  tooltip?: string;
}

export default function TabularContentPricingCellBlock({
  heading,
  headingOptions = {
    tag: 3,
  },
  variant,
  includeTag = false,
  tag,
  per,
  period,
  buttonsNumber = 2,
  buttonOne = {
    text: "Click Here",
    variant: "dark",
  },
  buttonTwo = {
    text: "Click Here",
    variant: "light",
  },
  planSegment,
  columns,
  sticky = false,
  useIcon = false,
  displayCustomPrice = false,
  customPrice,
  icon = { source: "" },
  includeTooltip = false,
  tooltip,
}: Props) {
  const { translate } = useTranslate();
  const { teamOffers } = useTeamOffers({
    planSegment,
  });
  const { webOffers } = useDefaultWebOffers({
    planSegment,
  });

  if (planSegment === "enterprise" && !icon.source) {
    icon.source = iconCheckmark;
  }

  const blockClassNames = cn(
    "align-top",
    tabularCellClasses({ columns, sticky, variant })
  );

  const headerContainerClassNames = cn(marginBSm, "flex items-center");
  const headerClassNames = cn(h6Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const priceClassNames = cn("text-3xl font-medium h-9", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const perClassNames = cn("text-sm block", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const tagClassNames = cn(
    "inline-block ms-2 rounded py-1.5 px-2.5 font-medium text-xs uppercase",
    {
      [backgroundInverse]: variant === "light",
      [backgroundNeutral]: variant !== "light",
      [textHeadlineInverse]: variant === "light",
      [textBodyCatchyNeutral]: variant !== "light",
    }
  );

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: "whiteBorder",
      size: "sm",
      ga4PlanSegment: planSegment,
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
      textOnly: true,
      darkerText: true,
      size: "sm",
      ga4PlanSegment: planSegment,
    });
  }

  return (
    <td className={blockClassNames}>
      <span className={headerContainerClassNames}>
        <CustomHeading
          heading={heading}
          headingOptions={headingOptions}
          headingClassNames={headerClassNames}
        />
        {includeTooltip && tooltip && (
          <Tooltip
            delay={0}
            direction="bottom"
            content={tooltip}
            textAlign="left"
          >
            <span
              className={cn("ml-1 mt-[-8px] block h-[16px] w-[16px]", {
                "fill-white": variant === "dark",
                "fill-grey-6": variant !== "dark",
              })}
            >
              <InfoIcon width="16px" />
            </span>
          </Tooltip>
        )}
      </span>
      <div className="flex flex-wrap items-center">
        <span className={priceClassNames}>
          {useIcon && !displayCustomPrice && (
            <Icon
              {...icon}
              variant={variant}
              width={36}
              iconClassName="mt-[-4px]"
            />
          )}
          {!useIcon && !displayCustomPrice && (
            <Price
              planSegment={planSegment}
              teamOffers={teamOffers}
              webOffers={webOffers}
            />
          )}
          {!useIcon && displayCustomPrice && (
            <RichText className={priceClassNames} tag="span">
              {customPrice}
            </RichText>
          )}
        </span>
        {(webOffers?.hasPriceWithoutVat ||
          webOffers?.currency === "eur" ||
          teamOffers?.planPrices.currency === "eur") && (
          <div className="ml-1 ms-2 flex rounded bg-blue-1 px-2.5 py-1.5 text-xs font-medium uppercase text-blue-5">
            <span className="mx-0 my-auto">+</span>
            <p className="mx-0 my-auto">{translate(BUY_FORM_VAT)}</p>
          </div>
        )}
        {includeTag && tag && (
          <RichText className={tagClassNames} tag="span">
            {tag}
          </RichText>
        )}
      </div>
      <RichText className={perClassNames} tag="span">
        {per}
      </RichText>
      <RichText className={perClassNames} tag="span">
        {period}
      </RichText>
      <CustomButtonGroup
        className={marginTMd}
        align="start"
        buttons={buttons}
        column={true}
      />
    </td>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupTabularContentPricingCell";

TabularContentPricingCellBlock.displayName = BLOCK_NAME;

TabularContentPricingCellBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          buttonOne
          buttonTwo
          buttonsNumber
          className
          heading
          icon
          includeButtons
          metadata
          per
          period
          planSegment
          useIcon
          includeTooltip
          tooltip
        }
      }
    }
  `,
};
