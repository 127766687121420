import { useState, useEffect } from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import CustomButton, { CustomButtonInterface } from "../molecules/CustomButton";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  backgroundNeutral,
  textBodyNeutral,
  backgroundNeutralDark,
  backgroundInverseLight,
  backgroundInverse,
  backgroundWhite,
} from "@/constants/standardCSSClasses";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import { iconDefault, iconClose } from "@/constants/icons";
import getBannerCookieName from "@/utilities/getBannerCookieName";
import { getCookie, setCookie } from "@/utilities/cookies";

export interface BannerInterface {
  includeButtons?: boolean;
  includeIcons?: boolean;
  disableCookie?: boolean;
  heading?: string;
  headingVariant?: string;
  content?: string;
  variant?: string;
  button?: CustomButtonInterface;
  width?: string;
  icon?: IconInterface;
  bannerId?: string;
}

export default function Banner({
  width,
  includeButtons = true,
  includeIcons = true,
  disableCookie = false,
  heading = "",
  headingVariant = "inline",
  content = "",
  variant = "white",
  button,
  icon = {
    source: iconDefault,
  },
  bannerId,
}: BannerInterface) {
  const [showBanner, setShowBanner] = useState(false);
  const cookieName = getBannerCookieName(bannerId || "default");

  //Get the cookie value to see if we should show the banner
  useEffect(() => {
    //Get the cookie for this bannerId
    const dismissed = getCookie(cookieName);

    if (!dismissed || disableCookie) {
      setShowBanner(true);
    }
  }, []);

  if (!showBanner) return null;

  const dismiss = () => {
    //Set a cookie to save that this banner has been dismissed
    setCookie(cookieName, "true");

    setShowBanner(false);
  };

  const wrapClassNames = cn({
    "lg:px-8 lg:max-w-7xl lg:mx-auto bg-white": width !== "full",
    "lg:pt-2 lg:pb-2": width !== "full",
  });

  const bannerClassNames = cn("w-full", {
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
    [backgroundWhite]: variant === "white",
    "lg:rounded": width !== "full",
  });

  const contentClassNames = cn(
    "flex grow items-start justify-between p-4 md:items-center md:px-6 md:py-2",
    {
      "md:py-2": width !== "full",
      "md:py-3": width === "full",
    }
  );

  const headerBlockClassNames = cn(
    "px-8 py-4 items-center justify-between max-w-sm hidden md:flex",
    {
      [backgroundInverseLight]: variant === "dark",
      [backgroundNeutralDark]: variant === "light",
      [backgroundNeutral]: variant === "white",
      "lg:rounded-s": width !== "full",
    }
  );

  const headerClassNames = cn("my-0 font-medium", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const pTextClassNames = cn("my-0 underline-links", {
    [textBodyInverse]: variant === "dark" && headingVariant === "inline",
    [textBodyNeutral]: variant !== "dark" && headingVariant === "inline",
    "text-grey-3 md:text-white":
      variant === "dark" && headingVariant === "block",
    "text-grey-6 md:text-blue-8":
      variant !== "dark" && headingVariant === "block",
    "text-sm": headingVariant === "inline",
    "text-sm md:text-base md:font-medium": headingVariant === "block",
  });

  return (
    <div className={wrapClassNames}>
      <div className={bannerClassNames}>
        <div
          className={cn("flex items-stretch", {
            "md:mx-auto md:max-w-7xl":
              width === "full" && headingVariant === "inline",
          })}
        >
          {headingVariant === "block" && (
            <div className={headerBlockClassNames}>
              {includeIcons && (
                <Icon
                  {...icon}
                  variant={variant}
                  className="me-3 flex items-center"
                  width={16}
                />
              )}
              <RichText className={headerClassNames} tag="p">
                {heading}
              </RichText>
            </div>
          )}
          <div className={contentClassNames}>
            <div className="flex w-full flex-col items-center md:flex-row md:justify-between">
              <div className="w-full md:me-3">
                <RichText
                  className={cn(headerClassNames, {
                    "md:hidden": headingVariant === "block",
                  })}
                  tag="p"
                >
                  {heading}
                </RichText>
                <RichText className={pTextClassNames} tag="p">
                  {content}
                </RichText>
              </div>
              {includeButtons && (
                <CustomButton
                  {...button}
                  variant={variant === "dark" ? "white" : "dark"}
                  size="sm"
                  align="right"
                  className={cn("mt-2.5 whitespace-nowrap md:ms-auto md:mt-0")}
                />
              )}
            </div>
            <Icon
              source={iconClose}
              variant={variant}
              className="ms-6 flex cursor-pointer items-center"
              width={16}
              as="button"
              onClick={dismiss}
              ariaLabel="Close"
              name="close"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
