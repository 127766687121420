import { useRouter } from "next/router";
import { getCountryCode } from "./getCountryCode";

function formatLocaleWithCountry(lang: string, country: string) {
  return `${lang}-${country.toUpperCase()}`;
}

export function getLocaleWithCountry() {
  const { locale = "" } = useRouter();
  const [lang, country] = locale?.split("-") as Array<string>;

  if (lang && country) {
    return formatLocaleWithCountry(lang, country);
  }
  const countryCode = getCountryCode();

  if (!countryCode) return locale;
  return formatLocaleWithCountry(locale, countryCode);
}
