export const TRIAL_STEP_4 = {
  "en": "Create master password",
  "fr": "Créer un mot de passe maître",
  "de": "Master-Passwort erstellen",
  "es": "Crear contraseña maestra",
  "pt-br": "Create master password",
  "it": "Create master password",
  "ja": "Create master password",
  "ko": "Create master password",
  "nl": "Create master password",
  "pt-pt": "Create master password",
  "sv": "Create master password",
  "zh": "Create master password"
}