import { gql } from "@apollo/client";

const ResourcePageInfoFragment = gql`
  fragment ResourceTypeToResourceConnectionPageInfoFragment on ResourceTypeToResourceConnectionPageInfo {
    offsetPagination {
      total
      page
      pages
    }
  }
`;

export default ResourcePageInfoFragment;
