import React, { ChangeEventHandler, useState, useEffect } from "react";
import cn from "classnames";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import getCookieDomain from "@/utilities/getCookieDomain";
import Cookies from "js-cookie";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  marginBSm,
  h5Text,
} from "@/constants/standardCSSClasses";
import CustomHeading from "@/components/molecules/CustomHeading";
import Toggle from "@/components/molecules/Toggle";
import CustomButton from "@/components/molecules/CustomButton";
import {
  PRIVACY_SETTINGS_ACTIVE,
  PRIVACY_SETTINGS_ALWAYS,
  PRIVACY_SETTINGS_INACTIVE,
  PRIVACY_SETTINGS_STATUS,
} from "@/constants/tempTranslations";
import { useTranslate } from "@/hooks/useTranslate";
import { Block } from "@/types";
import ButtonSpinner from "@/components/molecules/ButtonSpinner";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  variant?: string;
  contentType?: string;
  belowButtons?: string;
  buttonText?: string;
  paddingTop?: string;
  paddingBottom?: string;
  innerBlocks?: Block[];
}

export default function CookiePreferencesBlock({
  variant = "white",
  belowButtons = "Footer text",
  buttonText = "Save Settings",
  innerBlocks,
  contentType,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const { translate } = useTranslate();
  const [performance, setPerformance] = useState(true); // Analytics
  const [publisher, setPublisher] = useState(true); // Marketing
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    const userPrivacy = Cookies.get("userPrivacyCookie");
    const userPrivacyDefault = {
      analytics: { enabled: true },
      marketing: { enabled: true },
    };
    const userPrivacyParsed = userPrivacy
      ? JSON.parse(userPrivacy)
      : userPrivacyDefault;

    setPerformance(userPrivacyParsed.analytics?.enabled ?? true);
    setPublisher(userPrivacyParsed.marketing?.enabled ?? true);
  }, []);

  const preferencesBlocks = innerBlocks?.map((each) => {
    const {
      cookieType,
      heading,
      content,
    }: {
      cookieType: string;
      heading: string;
      content: string;
    } = each.attributes;

    switch (cookieType) {
      case "essential":
        return {
          id: cookieType,
          title: heading,
          description: content,
          toggle: false,
        };
      case "performance":
        return {
          id: "performance",
          title: heading,
          description: content,
          toggle: true,
          checked: performance,
          update: (e: React.ChangeEvent<HTMLInputElement>) =>
            setPerformance(e.target.checked),
        };
      case "publisher":
        return {
          id: "publisher",
          title: heading,
          description: content,
          toggle: true,
          checked: publisher,
          update: (e: React.ChangeEvent<HTMLInputElement>) =>
            setPublisher(e.target.checked),
        };
    }

    return {
      id: cookieType,
      title: heading,
      description: content,
      toggle: false,
    };
  });

  const headerClassNames = cn(h5Text, marginBSm, {
    [textHeadlineNeutral]: variant !== "dark",
    [textHeadlineInverse]: variant === "dark",
  });

  const pClassNames = cn({
    [textBodyNeutral]: variant !== "dark",
    [textBodyInverse]: variant === "dark",
  });

  const update = () => {
    setSubmitting(true);
    Cookies.set(
      "userPrivacyCookie",
      JSON.stringify({
        analytics: { enabled: performance, date: +new Date() },
        marketing: { enabled: publisher, date: +new Date() },
      }),
      {
        expires: 365,
        path: "/",
        secure: true,
        sameSite: "Strict",
        domain: getCookieDomain(window.location.hostname),
      }
    );
    setTimeout(() => {
      setSubmitting(false);
      setSubmitSuccess(true);

      setTimeout(() => {
        setSubmitSuccess(false);
      }, 1000);
    }, 1000);
  };

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width="box"
      defaultValue="box"
      constrain
      narrow
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      {preferencesBlocks
        ? preferencesBlocks.map(
            (
              each: {
                id: string;
                title: string;
                description: string;
                toggle: boolean;
                checked?: boolean;
                update?: ChangeEventHandler<HTMLInputElement>;
              },
              index
            ) => {
              return (
                <div
                  key={each.id}
                  className={cn("pb-4 md:flex", {
                    "border-b-2 border-solid border-b-grey-1":
                      index < preferencesBlocks.length - 1,
                    "pt-4": index > 0,
                  })}
                >
                  <div className="md:w-2/3 md:pr-10 lg:w-4/5">
                    <CustomHeading
                      heading={each.title}
                      headingOptions={{ tag: 2 }}
                      headingClassNames={headerClassNames}
                    />
                    <RichText className={pClassNames} tag="p">
                      {each.description}
                    </RichText>
                  </div>
                  <div
                    className={cn(
                      "mt-4 md:mt-0 md:w-1/3 lg:w-1/5",
                      pClassNames
                    )}
                  >
                    <label className="block">
                      <span className="mb-2 block">
                        {translate(PRIVACY_SETTINGS_STATUS)}
                      </span>
                      {each.toggle ? (
                        <Toggle
                          checked={each.checked}
                          onChange={each.update}
                          activeText={translate(PRIVACY_SETTINGS_ACTIVE)}
                          inactiveText={translate(PRIVACY_SETTINGS_INACTIVE)}
                        />
                      ) : (
                        <span className="block">
                          <strong>{translate(PRIVACY_SETTINGS_ALWAYS)}</strong>
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              );
            }
          )
        : null}
      <div className="mt-8 justify-between md:flex">
        <RichText
          className={cn("mb-8 pr-10 md:mb-0 md:flex-grow", pClassNames)}
          tag="div"
        >
          {belowButtons}
        </RichText>
        <div>
          <div className="relative flex items-center">
            {(submitting || submitSuccess) && (
              <ButtonSpinner
                className="absolute right-4 top-3 md:right-[-32px]"
                isSubmitting={submitting}
                submitSuccess={submitSuccess}
                variant={variant}
              />
            )}
            <CustomButton
              className="md:w-max"
              variant={variant === "dark" ? "white" : "dark"}
              text={buttonText}
              onClick={update}
            />
          </div>
        </div>
      </div>
    </BlockContent>
  );
}

const BLOCK_NAME = "TenupCookiePreferences";

CookiePreferencesBlock.displayName = BLOCK_NAME;

CookiePreferencesBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          belowButtons
          button
          className
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
