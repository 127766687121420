import { useRouter } from "next/router";
import { attrs } from "@wordpress/shortcode";

import {
  PostGridByCategory,
  PostGridByTag,
  SignUpForm,
} from "@/components/molecules";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  text: string;
}

type colorOptions = "midgreen" | "orange" | "pink";
type headingOptions = "h1" | "h2";

export default function ShortCodeBlock({ text }: Props) {
  const { locale = "en" } = useRouter();

  if (!text) return null;

  // Get the translated text between brackets
  const textNode = text.split("]")[1]?.split("[/")[0] ?? "";

  // Parse shortcode text to get named and numeric attributes
  const { named, numeric } = attrs(text);

  const shortCodeType = numeric[0];

  const category = named["category"];
  const tag = named["tag"];

  const headingColor = named["heading_color"] as colorOptions | undefined;
  const headingLevel = named["heading_level"] as headingOptions | undefined;
  const hideExcerpt =
    named["hide_excerpt"] === undefined
      ? undefined
      : named["hide_excerpt"] === "true"; // coerce to bool when string and not undefined
  const hideImage =
    named["hide_image"] === undefined
      ? undefined
      : named["hide_image"] === "true"; // coerce to bool when string and not undefined
  const items = Number(named["items"]);
  const topBorderColor = named["top_border_color"] as colorOptions | undefined;

  // Safelist of shortcodes handled with conditionals below
  if (shortCodeType === "[post_grid" && tag) {
    return (
      <PostGridByTag
        heading={textNode}
        headingColor={headingColor}
        headingLevel={headingLevel}
        hideExcerpt={hideExcerpt}
        hideImage={hideImage}
        items={items}
        locale={locale}
        tag={tag}
        topBorderColor={topBorderColor}
      />
    );
  }
  if (shortCodeType === "[post_grid" && category) {
    return (
      <PostGridByCategory
        heading={textNode}
        headingColor={headingColor}
        headingLevel={headingLevel}
        hideExcerpt={hideExcerpt}
        hideImage={hideImage}
        items={items}
        locale={locale}
        category={category}
        topBorderColor={topBorderColor}
      />
    );
  }
  if (shortCodeType === "[sign_up_form]") {
    return <SignUpForm />;
  }

  return (
    // shortCodeType doesn't have a match, return error block
    <div className="my-3 rounded-lg bg-red-50 p-1 text-center text-red-600">
      SHORTCODE NOT FOUND
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreShortcode";

ShortCodeBlock.displayName = BLOCK_NAME;

ShortCodeBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          text
        }
      }
    }
  `,
};
