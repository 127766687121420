import cn from "classnames";
import CustomImage from "../molecules/CustomImage";
import { borderInverse, borderNeutral } from "@/constants/standardCSSClasses";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

export interface AccoladeBlockInterface {
  fullImage?: object;
  variant?: string;
  width?: string;
  index?: number;
  length?: number;
  className?: string;
}

export interface AccoladeImageInterface {
  full?: string;
  medium?: string;
  alt?: string;
}

const AccoladeBlock = ({
  fullImage = {},
  variant = "light",
  width = "full",
  index = 0,
  length = 1,
  className = "",
}: AccoladeBlockInterface) => {
  const { full = "", medium, alt = "" }: AccoladeImageInterface = fullImage;

  //Classes to account for mobile, borders, variable number of items, etc.
  const wrapClassName = cn(
    "p-5 grow flex items-center justify-center border-solid border-t-[1px] border-r-0 border-b-0",
    className,
    {
      [borderInverse]: variant === "dark",
      [borderNeutral]: variant !== "dark",
      "w-full":
        (width === "full" || (length === 5 && index === 0) || length <= 3) &&
        !(length === 5 && index > 0),
      "lg:w-full": width === "full" && length > 3,
      "w-1/2":
        length > 3 &&
        (width === "half" || (length === 5 && index > 0) || length === 4),
      "md:w-1/2": width === "half" && length <= 3,
      "lg:w-1/2": width === "half" && length > 3,
      "lg:border-l-[1px] lg:border-t-0 lg:border-r-0 lg:border-b-0": length > 3,
      "border-l-0 md:border-l-[1px] md:border-t-0 md:border-r-0 md:border-b-0":
        length <= 3,
      "border-l-[1px]":
        (length === 5 && index % 2 === 0) || (length === 4 && index % 2 === 1),
    }
  );

  const imageClassNames = cn("w-full", {
    "h-[70px]": width !== "half",
    "h-[93px]": width === "half",
  });

  return (
    <div className={wrapClassName}>
      <CustomImage
        full={medium || full}
        alt={alt}
        className={imageClassNames}
        objectFit="contain"
      />
    </div>
  );
};

const BLOCK_NAME = "TenupAccolade";

AccoladeBlock.displayName = BLOCK_NAME;

AccoladeBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          fullImage
          metadata
          width
        }
      }
    }
  `,
};

export default AccoladeBlock;
