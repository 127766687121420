import Link from "next/link";
import { useRouter } from "next/router";
import { SkipNavigationLink } from "@/components/atoms";
import { Navigation } from "@/components/molecules";
import Banner from "@/components/molecules/Banner";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import React from "react";
import { headerDemo, headerContact } from "@/constants/icons";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";
import { getMenuItemIds } from "@/utilities/getMenuItemIds";
import { MenuItem } from "@/types";
import cn from "classnames";
import { DashlaneIcon } from "@/components/icons";
import { HeaderProps } from "./DynamicHeader";

export default function Header({
  menuItems = [],
  banner,
  variant = "white",
}: HeaderProps) {
  const { locale } = useRouter();
  let newMenuItems = [...menuItems];

  //Get the last column - this will be the top menu
  const hierarchicalMenu = flatListToHierarchical(menuItems);
  const topMenuItems = hierarchicalMenu.pop() as MenuItem;
  const topMenuItemsChildren = topMenuItems?.children;
  const numTopMenuItems = topMenuItemsChildren
    ? topMenuItemsChildren.length
    : 0;

  //Get the menu item ids of the last menu item(s) so that we can remove them
  const lastMenuIds = getMenuItemIds(topMenuItems);

  //Remove the menu items that are for the top menu
  newMenuItems = newMenuItems.filter((each) => !lastMenuIds.includes(each.id));

  //Get the button links
  const lastMenuItem = newMenuItems.pop();
  const secondLastMenuItem = newMenuItems.pop();

  const buttons = [];
  if (secondLastMenuItem) {
    buttons.push({
      variant: variant === "dark" ? "dark" : "light",
      href: secondLastMenuItem.path,
      target: secondLastMenuItem.target ?? "_self",
      text: secondLastMenuItem.label,
      size: "sm",
      className: "hidden xl:block",
    });
  }
  if (lastMenuItem) {
    buttons.push({
      variant: variant === "dark" ? "white" : "dark",
      href: lastMenuItem.path,
      target: lastMenuItem.target ?? "_self",
      text: lastMenuItem.label,
      size: "sm",
      className: "mt-0",
    });
  }

  return (
    <header className="sticky top-0 z-30">
      <SkipNavigationLink />
      {banner && <Banner {...banner.attributes} />}
      <div className="hidden bg-blue-8 py-2.5 xl:block">
        <ul className="mx-auto flex max-w-7xl justify-end px-4 md:px-8">
          {topMenuItemsChildren?.map((each: MenuItem, index: number) => {
            const { id, path, label } = each;
            // Removes trailing slash errors in the console
            const urlWithoutTrailingSlash = path.endsWith("/")
              ? path.slice(0, -1)
              : path;
            return (
              <li key={id}>
                <Link
                  target={each.target ?? "_self"}
                  href={urlWithoutTrailingSlash}
                  className={cn(
                    "block text-sm text-white transition hover:text-white-8 hover:no-underline",
                    {
                      "pe-3": index === 0,
                      "px-3": index !== 0 && index !== numTopMenuItems - 1,
                      "ps-3": index === numTopMenuItems - 1,
                    }
                  )}
                >
                  {each.cssClasses.includes("header-demo") && (
                    <span className="me-1.5 inline-block fill-white">
                      {headerDemo}
                    </span>
                  )}
                  {each.cssClasses.includes("header-contact") && (
                    <span className="me-1.5 inline-block fill-white">
                      {headerContact}
                    </span>
                  )}
                  <span className="inline-block">{label}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="header-background border border-l-0 border-r-0 border-t-0 border-grey-1">
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="relative flex w-full items-center py-3">
            <Link
              href="/"
              className="mr-3 inline-flex items-center md:mr-12"
              locale={locale}
            >
              <DashlaneIcon width="127" height="32" />
            </Link>

            <Navigation layout="Header" menuItems={newMenuItems} />

            <div className="ms-auto flex grow items-center justify-end space-x-2 xl:grow-0">
              <CustomButtonGroup align="right" buttons={buttons} marginTop="" />
              <Navigation
                menuItems={menuItems}
                additionalMenuItems={topMenuItemsChildren}
                layout="Header"
                mobile
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
