export const PRIVACY_CONSENT_ACCEPT = {
  "en": "I accept",
  "fr": "J’accepte",
  "de": "Ich bin einverstanden",
  "es": "Acepto",
  "pt-br": "I accept",
  "it": "I accept",
  "ja": "I accept",
  "ko": "I accept",
  "nl": "I accept",
  "pt-pt": "I accept",
  "sv": "I accept",
  "zh": "I accept"
}