import { Code } from "@/components/molecules";
import { Style } from "@/types";
import getBlockStyles from "@/utilities/getBlockStyles";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor: string;
  backgroundColorHex?: string;
  className?: string;
  content: string;
  gradientHex?: string;
  style?: Style;
  textColorHex?: string;
}

export default function CodeBlock({
  anchor,
  backgroundColorHex,
  className,
  content,
  gradientHex,
  style,
  textColorHex,
}: Props) {
  const codeStyle = getBlockStyles({
    backgroundColorHex,
    gradientHex,
    textColorHex,
    style,
  });

  return (
    <Code
      className={className}
      id={anchor}
      content={content}
      style={codeStyle}
    />
  );
}

// Must match __typename
const BLOCK_NAME = "CoreCode";

CodeBlock.displayName = BLOCK_NAME;

CodeBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          align
          anchor
          backgroundColor
          borderColor
          className
          content
          cssClassName
          fontFamily
          fontSize
          gradient
          metadata
          style
          textColor
        }
      }
    }
  `,
};
