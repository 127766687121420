import React from "react";
import cn from "classnames";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import ValidationLogo, {
  ValidationLogoInterface,
} from "../molecules/ValidationLogo";
import {
  backgroundInverse,
  backgroundNeutral,
} from "@/constants/standardCSSClasses";
import { marginTMd } from "@/constants/standardCSSClasses";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  aboveHeading?: string;
  variant?: string;
  paddingTop?: string;
  paddingBottom?: string;
  width?: string;
  images: Array<ValidationLogoInterface>;
  contentType: string;
}

export default function ValidationBlock({
  includeAboveHeading = true,
  aboveHeading = "",
  variant = "white",
  paddingTop = "default",
  paddingBottom = "default",
  images,
  contentType,
  blockContentProps,
}: Props) {
  const logos = [...images, ...images];

  return (
    <BlockContent
      contentType={contentType}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width="full"
      defaultValue="box"
      constrain
      {...blockContentProps}
    >
      <div
        className={cn("max-h[124] w-full overflow-hidden rounded-full", {
          [backgroundInverse]: variant === "dark",
          [backgroundNeutral]: variant === "light",
        })}
      >
        {aboveHeading && includeAboveHeading && (
          <AboveHeading
            className={cn("hidden pt-6 text-center md:block")}
            variant={variant}
          >
            {aboveHeading}
          </AboveHeading>
        )}
        <div
          className={cn(
            "flex w-max animate-logo-slide-carousel flex-nowrap items-center justify-center gap-10 pb-4 md:hover:[animation-play-state:paused]",
            { [marginTMd]: !!aboveHeading && includeAboveHeading }
          )}
        >
          {logos.map((each, index) => (
            <ValidationLogo key={index} variant={variant} {...each} />
          ))}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupValidation";

ValidationBlock.displayName = BLOCK_NAME;

ValidationBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          className
          images
          includeAboveHeading
          metadata
          paddingBottom
          paddingTop
          variant
          width
        }
      }
    }
  `,
};
