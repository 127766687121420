import React, { memo } from "react";
import cn from "classnames";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import { CustomImageInterface } from "../molecules/CustomImage";
import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  pTextL,
  h3Text,
  textBodyInverse,
  textBodyNeutral,
  marginBLg,
  backgroundInverseLight,
  backgroundNeutral,
  backgroundNeutralDark,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import EmailCaptureForm from "@/components/molecules/EmailCaptureForm";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import { EmailFormMessages, EmailFormName } from "@/types/MarketoForm";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeContent?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  size?: string;
  belowButtons?: string;
  image?: CustomImageInterface;
  contentType: string;
  supportingContent?: string;
  buttonText?: string;
  inputPlaceholder?: string;
  innerBlocks?: Block[];
  media?: MediaObjectInterface;
  formName?: EmailFormName;
  messages?: EmailFormMessages;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function EmailCaptureBlock({
  includeAboveHeading = false,
  includeBelowButtons = false,
  includeContent = false,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 2,
  },
  size = "lg",
  content = "",
  variant = "white",
  belowButtons = "",
  image = {
    full: "",
    alt: "",
  },
  media = {
    type: "image",
    media: {},
  },
  supportingContent = "stats",
  buttonText,
  inputPlaceholder = "",
  contentType,
  innerBlocks,
  formName = "emailCapture",
  messages = {
    successMessage: "",
    validationErrorMessage: "",
    requiredErrorMessage: "",
    freemailErrorMessage: "",
    duplicateErrorMessage: "",
    unknownErrorMessage: "",
  },
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const stats = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each) => {
        return {
          ...each,
          attributes: {
            ...each.attributes,
            variant,
          },
        };
      })}
    />
  ) : null;

  const headerClassNames = cn("mt-0", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    [h3Text]: size === "lg",
    "text-2xl font-medium": size === "sm",
    [marginBMd]: size === "lg",
    "mb-2 mb-0": size === "sm",
  });

  const pTextClassNames = cn({
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
    [marginBLg]: size === "lg",
  });

  const statsClassNames = cn(
    "px-6 py-8 md:px-16 md:py-12 grid grid-cols-1 gap-10 rounded mt-8 lg:mt-0",
    {
      [backgroundInverseLight]: variant === "dark",
      [backgroundNeutral]: variant === "white",
      [backgroundNeutralDark]: variant === "light",
    }
  );

  const form = (
    <div className="mx-auto md:mx-0 md:max-w-none">
      <MemoizedEmailCapture
        buttonText={buttonText}
        inputPlaceholder={inputPlaceholder}
        size={size}
        variant={variant}
        formName={formName}
        messages={messages}
      />
    </div>
  );

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width={size === "lg" ? "full" : "box"}
      constrain
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      {size === "lg" && (
        <div className={`lg:grid lg:grid-cols-2 lg:gap-20`}>
          <div className="self-center">
            {includeAboveHeading && aboveHeading && (
              <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
            )}
            {heading && (
              <CustomHeading
                heading={heading}
                headingOptions={headingOptions}
                headingClassNames={headerClassNames}
              />
            )}
            {includeContent && content && (
              <RichText className={pTextClassNames} tag="p">
                {content}
              </RichText>
            )}
            {form}
            {includeBelowButtons && belowButtons && (
              <BelowButtonsText variant={variant}>
                {belowButtons}
              </BelowButtonsText>
            )}
          </div>
          {supportingContent === "image" && (
            <div className={cn("mt-8 self-center lg:mt-0")}>
              <CustomMedia
                media={media ?? { media: {}, type: "image" }}
                aspect="3:2"
                fallbackImage={image}
              />
            </div>
          )}
          {supportingContent !== "image" && (
            <div className="self-center">
              <div className={statsClassNames}>{stats}</div>
            </div>
          )}
        </div>
      )}
      {size === "sm" && (
        <div className="flex flex-col items-center gap-6 lg:flex-row">
          <div className="w-full">
            {heading && (
              <CustomHeading
                heading={heading}
                headingOptions={headingOptions}
                headingClassNames={headerClassNames}
              />
            )}
            {includeContent && content && (
              <RichText className={pTextClassNames} tag="p">
                {content}
              </RichText>
            )}
          </div>
          <div className="w-full md:max-w-none">{form}</div>
        </div>
      )}
    </BlockContent>
  );
}

interface MemoizedEmailCaptureProps {
  variant?: string;
  size?: string;
  placeholderSize?: string;
  buttonText?: string;
  inputPlaceholder?: string;
  formName?: EmailFormName;
  messages?: EmailFormMessages;
}

const MemoizedEmailCapture = memo(
  ({
    buttonText,
    inputPlaceholder,
    size,
    variant,
    formName = "emailCapture",
    messages = {
      successMessage: "",
      validationErrorMessage: "",
      requiredErrorMessage: "",
      freemailErrorMessage: "",
      duplicateErrorMessage: "",
      unknownErrorMessage: "",
    },
  }: MemoizedEmailCaptureProps) => {
    return (
      <EmailCaptureForm
        buttonText={buttonText}
        inputPlaceholder={inputPlaceholder}
        placeholderSize={size === "lg" ? pTextL : ""}
        buttonTextSize={size}
        variant={variant}
        formName={formName}
        messages={messages}
      />
    );
  }
);

// Must match __typename
const BLOCK_NAME = "TenupEmailCapture";

EmailCaptureBlock.displayName = BLOCK_NAME;

EmailCaptureBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          belowButtons
          buttonText
          className
          content
          formName
          ga4SectionId
          heading
          headingOptions
          image
          includeAboveHeading
          includeBelowButtons
          includeContent
          inputPlaceholder
          media
          messages
          metadata
          size
          supportingContent
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
