import { gql, useQuery } from "@apollo/client";

import { PostPreviewSection } from "@/components/organisms";
import { PreviewLoader } from "@/components/molecules";
import { PreviewFragment } from "@/fragments";
import { HeadingLevel } from "@/types";

export const postGridByTagQuery = gql`
  ${PreviewFragment}
  query GetPostsByTag(
    $items: Int
    $locale: LanguageCodeFilterEnum = EN
    $tag: String
  ) {
    posts(where: { tag: $tag, language: $locale }, first: $items) {
      nodes {
        ...PreviewFragment
      }
    }
  }
`;

export interface PostGridByTagProps {
  heading: string;
  headingColor?: string;
  headingLevel?: HeadingLevel;
  hideExcerpt?: boolean;
  hideImage?: boolean;
  items?: number;
  locale: string;
  tag: string;
  topBorderColor?: string;
}

export default function PostGridByTag({
  heading,
  headingColor = "orange",
  headingLevel = "h2",
  hideExcerpt = false,
  hideImage = true,
  items = 3,
  locale,
  tag,
  topBorderColor,
}: PostGridByTagProps) {
  const results = useQuery(postGridByTagQuery, {
    variables: {
      items,
      locale: locale.toUpperCase().replace("-", "_"),
      tag,
    },
  });

  if (!results.data) {
    return <PreviewLoader />;
  }

  return (
    <PostPreviewSection
      className="bg-white py-14 lg:py-28"
      heading={heading}
      headingColor={headingColor}
      hideImage={hideImage}
      hideExcerpt={hideExcerpt}
      level={headingLevel}
      posts={results?.data?.posts?.nodes}
      topBorderColor={topBorderColor}
    />
  );
}
