const webservicesUrl = "https://ws1.dashlane.com";

interface PostDataProps {
  wsMethod: string;
  params: Record<string, string>;
  returnType?: "json" | "text";
}

/**
 * Base class for use with Dashlane Web Services (WS)
 *
 * @deprecated  Please migrate to BaseAPI
 */
export default class BaseWS1 {
  protected WSURL: string;

  protected WSVERSION: string;

  protected WSNAME: string;

  protected CONFIG: Record<string, unknown>;

  constructor() {
    this.WSURL = webservicesUrl;
    this.WSVERSION = "";
    this.WSNAME = "";
    this.CONFIG = {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "client-agent": "{ version: 1, platform: website }",
      },
    };
  }
  /**
   * Makes a POST request to the specified URL path.
   *
   * Not to be confused with BaseAPI.postData()
   * @param {string} wsMethod
   * @param {Object} params
   * @param {string} returnType
   */

  postData = async ({
    wsMethod,
    params,
    returnType = "json",
  }: PostDataProps) => {
    const url = new URL(
      `${this.WSURL}/${this.WSVERSION}/${this.WSNAME}/${wsMethod}`
    );
    const data = new URLSearchParams();
    // @ts-ignore
    Object.keys(params).forEach((key) => data.append(key, params[key]));
    const response = await fetch(
      url.toString(),
      Object.assign(this.CONFIG, {
        method: "POST",
        body: data,
      })
    );

    if (returnType === "text") {
      return response.text();
    }
    return response.json();
  };
}
