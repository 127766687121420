export const POST_SHARE_TITLE = {
  "en": "Share",
  "fr": "Partager",
  "de": "Teilen",
  "es": "Compartir",
  "pt-br": "Share",
  "it": "Share",
  "ja": "Share",
  "ko": "Share",
  "nl": "Share",
  "pt-pt": "Share",
  "sv": "Share",
  "zh": "Share"
}