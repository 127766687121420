declare global {
  interface Window {
    marketoFormApi: any;
    FormsPlus: any;
    MktoForms2: any;
    MktoForms2XDIframe: any;
    MutationObserver: any;
  }
}

import FELogger from "@/utilities/monitoring/feLogger";
const logger = new FELogger();

function createScriptPolicy() {
  if (window.trustedTypes && window.trustedTypes.createPolicy) {
    const policy = window.trustedTypes.createPolicy("marketoScript", {
      createScriptURL: (untrustedUrl: string) => {
        if (untrustedUrl.startsWith("https://go.dashlane.com/")) {
          return untrustedUrl;
        }
        throw new Error("Invalid script URL");
      },
    });
    return policy;
  }
  return null;
}
const loadScript = (
  url: string,
  retries: number = 3,
  delay: number = 1000
): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (window.marketoFormApi) {
      resolve();
      return;
    }

    const attemptLoad = (attempt: number) => {
      const script = document.createElement("script");

      const browserInfo = {
        userAgent: navigator.userAgent,
      };

      try {
        const policy = createScriptPolicy();
        if (policy) {
          script.src = policy.createScriptURL(`${url}`) as unknown as string;
        } else {
          script.src = `${url}`;
        }
      } catch (error) {
        logger.error(
          "TrustedScriptURLError",
          "Failed to create TrustedScriptURL",
          { error, url, browserInfo }
        );
        reject(error);
        return;
      }

      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = (error) => {
        if (attempt < retries) {
          setTimeout(() => attemptLoad(attempt + 1), delay);
        } else {
          logger.error(
            "ScriptLoadError",
            "Failed to load script after retries",
            {
              error,
              url,
              browserInfo,
            }
          );
          reject(error);
        }
      };

      document.head.appendChild(script);
    };

    attemptLoad(1);
  });
};

export const marketoFormApi = async (marketoFormsId: number) => {
  try {
    await loadScript("https://go.dashlane.com/js/forms2/js/forms2.min.js");

    window.MktoForms2.setOptions({
      formXDPath: "/rs/403-EXY-689/images/marketo-xdframe-relative.html",
    });
    window.marketoFormApi = window.MktoForms2.loadForm(
      "//go.dashlane.com",
      "403-EXY-689",
      marketoFormsId
    );
  } catch (error: any) {
    logger.error(
      "loadMarketoFormApiError",
      "Error in marketoFormApi function:",
      { error, marketoFormsId }
    );
  }

  // https://blog.teknkl.com/adding-a-network-server-error-handler-to-marketo-forms/
  window.FormsPlus = {
    allDescriptors: {},
    allMessages: {},
    detours: {},
  };

  window.FormsPlus.onSubmitError = (cb: any) => {
    let listenPending = true;

    const getOrigin = (loc: any) => {
      return loc.origin || [loc.protocol, loc.host].join("//");
    };

    const listenErrors = (
      callback: (arg0: any) => void,
      sameOrigin: boolean
    ) => {
      window.addEventListener("message", (e) => {
        let msg;
        let allowedLoc;

        if (sameOrigin) {
          allowedLoc = document.location;
        } else {
          allowedLoc = document.createElement("a");
          allowedLoc.href = window.MktoForms2XDIframe.src;
        }

        const allowedOrigin = getOrigin(allowedLoc);
        if (e.origin !== allowedOrigin) {
          return;
        }

        try {
          msg = JSON.parse(e.data);
          if (msg.mktoResponse && msg.mktoResponse.error === true) {
            callback(msg.mktoResponse.data);
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      });

      listenPending = false;
    };

    const addSameOriginHandler = (form: {
      getId: () => any;
      getFormElem: () => any[];
    }) => {
      if (!window.MutationObserver) {
        // eslint-disable-next-line no-console
        console.log("Cannot listen for named form errors in this browser.");
        return;
      }

      const formId = form.getId();
      const formEl = form.getFormElem()[0];
      const submitEl = formEl.querySelector(".mktoButton");
      const observerConfig = {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ["disabled"],
      };

      const observer = new MutationObserver((mutations) => {
        mutations
          .filter((mutation) => {
            return mutation.oldValue === "disabled";
          })
          .forEach(() => {
            const data = {
              mktoResponse: {
                error: true,
                data: "Form ID " + formId,
              },
            };

            const dataString = JSON.stringify(data);
            const targetOrigin = getOrigin(document.location);

            window.postMessage(dataString, targetOrigin);
          });
      });

      observer.observe(submitEl, observerConfig);
    };

    window.MktoForms2.whenReady(
      (form: { getId: () => any; getFormElem: () => any[] }) => {
        const sameOrigin = !window.MktoForms2XDIframe;
        if (sameOrigin) {
          addSameOriginHandler(form);
        }
        if (listenPending) {
          listenErrors(cb, sameOrigin);
        }
      }
    );
  };
};

// const marketoLimitOne = (id: string | number) => {
//   let formEls = document.querySelectorAll("#mktoForm_" + id);

//   formEls.forEach((formEl, index) => {
//     // Only keep the first form for a given id
//     if (index > 0 && formEl.parentNode) {
//       formEl.parentNode.removeChild(formEl);
//     }
//   });
// };
