import { v4 as uuidv4 } from "uuid";

export default class Uuid {
  private _uuid: string;
  constructor() {
    this._uuid = uuidv4();
  }

  // Update uuid with a new value.
  _updateUuid() {
    this._uuid = uuidv4();
  }

  _getUuid() {
    return this._uuid;
  }

  /**
   * Returns a uuid from a new generated value.
   */
  fetchUuid() {
    return this._getUuid();
  }
}
