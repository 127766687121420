export default function FacebookIcon({ className }: { className: string }) {
  return (
    <>
      <span className="sr-only">Facebook</span>
      <svg
        className={className}
        fill="currentColor"
        width="9"
        height="16"
        viewBox="0 0 9 16"
      >
        <path d="M5.90339 16L5.9034 8.93625H8.18912L8.55002 6.04903H5.9034V4.36482C5.9034 3.52272 6.144 3.04151 7.22671 3.04151H8.67032V0.394898C8.42972 0.394898 7.58761 0.274597 6.62521 0.274597C4.58009 0.274597 3.13648 1.59791 3.13648 4.00392V6.04903H0.730469V8.93625H3.13648L3.13648 16H5.90339Z" />
      </svg>
    </>
  );
}
