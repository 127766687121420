export const PRIVACY_CONSENT_TITLE_NEW = {
  "en": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:",
  "fr": "<strong>Remarque :</strong> nous utilisons des cookies sur notre site pour vous offrir la meilleure expérience possible. Veuillez les accepter ou modifier vos préférences ici :",
  "de": "<strong>Hinweis:</strong> Für ein bestmögliches Nutzungserlebnis verwenden wir auf unserer Website Cookies. Bitte akzeptieren Sie unsere Cookies oder passen Sie Ihre Einstellungen hier an:",
  "es": "<strong>Tenga en cuenta</strong> que usamos cookies en nuestro sitio para ofrecerle la mejor experiencia. Acepte estas cookies o cambie sus opciones aquí:",
  "pt-br": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:",
  "it": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:",
  "ja": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:",
  "ko": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:",
  "nl": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:",
  "pt-pt": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:",
  "sv": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:",
  "zh": "<strong>Please note:</strong> We use cookies on our site to give you the best experience. Please accept these cookies, or change your settings here:"
}