import cn from "classnames";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  RedditIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/components/icons";

export default function SocialSharing() {
  const linkClassNames = cn(
    "flex h-6 w-6 lg:h-9 lg:w-9 items-center justify-center text-white hover:text-grey-3 transition-all lg:border lg:border-white lg:rounded-full",
    {}
  );
  const iconClassNames = "h-4 w-4";

  return (
    <div className="flex flex-wrap justify-center gap-6 lg:max-w-[180px] lg:justify-start">
      <a
        href="https://www.facebook.com/GetDashlane"
        target="_blank"
        className={linkClassNames}
      >
        <FacebookIcon className={iconClassNames} />
      </a>
      <a
        href="https://www.x.com/dashlane"
        target="_blank"
        className={linkClassNames}
      >
        <TwitterIcon className={iconClassNames} />
      </a>
      <a
        href="https://www.youtube.com/channel/UC29QiP0ZFzBVTnCeLytXXCg"
        target="_blank"
        className={linkClassNames}
      >
        <YoutubeIcon className={iconClassNames} />
      </a>
      <a
        href="https://www.instagram.com/dashlane"
        target="_blank"
        className={linkClassNames}
      >
        <InstagramIcon className={iconClassNames} />
      </a>
      <a
        href="https://www.reddit.com/r/dashlane"
        target="_blank"
        className={linkClassNames}
      >
        <RedditIcon className={iconClassNames} />
      </a>
      <a
        href="https://www.linkedin.com/company/dashlane"
        target="_blank"
        className={linkClassNames}
      >
        <LinkedInIcon className={iconClassNames} />
      </a>
    </div>
  );
}
