interface Props {
  sourceUrl?: string;
  altText?: string;
}

export default function (node: Props | undefined) {
  return {
    full: node?.sourceUrl ?? "/images/blog-post-default.webp",
    alt:
      node?.altText ??
      "A green and blue background with three blue slanted rectangles in the middle. A white Dashlane logo is overlaid on top.",
  };
}
