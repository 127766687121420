import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player"));

import { RichText } from "../atoms";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor?: string;
  autoplay?: boolean;
  caption?: string;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  src: string;
}

function VideoBlock({
  anchor,
  autoplay = false,
  caption,
  controls = true,
  loop = false,
  muted = false,
  src,
}: Props) {
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  return (
    <>
      {hasWindow && (
        <div
          id={anchor}
          className="my-4 flex flex-col items-center justify-center gap-4"
        >
          {src && (
            <ReactPlayer
              url={src}
              loop={loop}
              playing={autoplay}
              controls={controls}
              muted={muted}
              width="100%"
              height="auto"
            />
          )}
          {caption && (
            <RichText className="text-xs" tag="p">
              {caption}
            </RichText>
          )}
        </div>
      )}
    </>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreVideo";

VideoBlock.displayName = BLOCK_NAME;

VideoBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          autoplay
          caption
          className
          controls
          id
          loop
          metadata
          muted
          playsInline
          poster
          preload
          src
          style
          tracks
        }
      }
    }
  `,
};

export default VideoBlock;
