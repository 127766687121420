import BlockContent from "@/components/molecules/BlockContent";
import { Blocks } from "@/components/molecules";
import React, { useEffect, useState } from "react";
import { Block } from "@/types";
import { HeadingOptions } from "@/components/molecules/CustomHeading";
import LottieEmbed from "../molecules/LottieEmbed";
import { observeElementIntersection } from "@/utilities/observerHelper";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  variant: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  innerBlocks?: Block[];
  contentType: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function HeroRTBBlock({
  variant = "white",
  innerBlocks,
  contentType,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const [topDistance, setTopDistance] = useState("0px");
  const [animations, setAnimations] = useState<string[]>([]);

  const heroRTBSections = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each, i) => {
        each.attributes.variant = variant;
        each.attributes.animation = animations[i];
        each.attributes.index = i;
        return each;
      })}
    />
  ) : null;

  const lottieAnimations = innerBlocks?.map((block) => block.attributes.media);

  function getDynamicStickyPosition() {
    if (!document || !window) return 0;

    const animationHolder = document.querySelector(".animation-holder");
    const innerHeight = window.innerHeight;
    const diff = innerHeight - (animationHolder?.clientHeight ?? 0);

    return diff / 2;
  }

  useEffect(() => {
    if (!document) return;

    function createTopDistance() {
      const distance = getDynamicStickyPosition();
      setTopDistance(`${distance}px`);
    }

    createTopDistance();
    observeElementIntersection("section[data-target]");

    window.addEventListener("resize", createTopDistance);

    return () => window.removeEventListener("resize", createTopDistance);
  }, []);

  async function fetchLottieAnimation(url: string): Promise<string> {
    try {
      return (await fetch(url)).json();
    } catch (error) {
      return "";
    }
  }

  useEffect(() => {
    if (!document) return;

    (async () => {
      for (let lottieAnimation of lottieAnimations ?? []) {
        const animationURL = lottieAnimation.media.url;

        if (animationURL) {
          const parsedURL = animationURL.replace(".lottie", ".json");

          const animationJSON = await fetchLottieAnimation(parsedURL);

          if (animationJSON) {
            setAnimations((prevState) => [...prevState, animationJSON]);
          }
        }
      }
    })();
  }, []);

  return (
    <BlockContent
      className="hero-rtb"
      contentType={contentType}
      width="full"
      constrain
      variant={variant}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className="animation-block flex h-full flex-row-reverse pt-14 md:pb-10 md:pt-0">
        <div className="hidden h-auto w-[50%] overflow-clip md:block">
          <div
            className={`animation-holder sticky aspect-[1/1] w-full`}
            style={{ top: topDistance }}
          >
            {animations?.map((animation, i) => (
              <div
                key={i}
                className={`${
                  "animation-" + i
                } absolute h-full w-full opacity-0 transition-opacity duration-700 ease-linear`}
              >
                <LottieEmbed aspect="1:1" url={animation} className="w-full" />
              </div>
            ))}
          </div>
        </div>
        <div className="content-block md:mr-20 md:max-w-[50%]">
          {heroRTBSections}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupHeroRtb";

HeroRTBBlock.displayName = BLOCK_NAME;

HeroRTBBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          ga4SectionId
          metadata
          variant
          paddingTop,
          paddingBottom,
        }
      }
    }
  `,
};
