import cn from "classnames";
import { Blocks } from "@/components/molecules";
import {
  backgroundInverse,
  backgroundNeutral,
  h3Text,
  marginBSm,
  textBodyInverse,
  textBodyNeutral,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import React from "react";
import { Block } from "@/types";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import BlockContent from "@/components/molecules/BlockContent";
import AboveHeading from "@/components/molecules/AboveHeading";
import { RichText } from "@/components/atoms";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeContent?: boolean;
  border?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  requiredTextAlign: string;
  width?: string;
  columns?: number;
  striped?: boolean;
  highlightColumn?: number;
  stickyHeader?: boolean;
  headerColumn?: boolean;
  hideMobile?: boolean;
  innerBlocks?: Block[];
  ga4SectionId?: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function TabularContentBlock({
  includeAboveHeading = true,
  includeContent = true,
  border = false,
  hideMobile = false,
  aboveHeading,
  heading,
  headingOptions = { tag: 2 },
  content,
  variant = "white",
  requiredTextAlign = "center",
  width,
  striped,
  columns = 3,
  highlightColumn = 0,
  stickyHeader,
  headerColumn,
  innerBlocks,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const blockClassNames = cn("relative", {
    rounded: width !== "full",
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
    "hidden md:block": hideMobile,
  });

  const numCol = columns + (headerColumn ? 1 : 0);

  const wrapClassNames = cn("overflow-x-auto lg:overflow-x-visible lg:mr-0", {
    "mr-[-16px]": numCol > 2 && width === "full",
    "mr-[-32px]": numCol > 2 && width !== "full",
    "md:mr-[-32px]": numCol > 3 && width === "full",
    "md:mr-[-88px]": numCol > 3 && width !== "full",
  });

  const aboveHeadingClassNames = cn({
    "text-center": requiredTextAlign === "center",
  });

  const headerClassNames = cn(marginBSm, h3Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    "text-center": requiredTextAlign === "center",
  });

  const pTextClassNames = cn("mb-6 md:mb-14", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
    "text-center": requiredTextAlign === "center",
  });

  const mapTabularRows = (rows: Block[], sticky = false) => {
    return rows.map((each, index) => {
      each.attributes.variant = variant;
      each.attributes.index = index;
      each.attributes.highlightColumn = highlightColumn;
      each.attributes.striped = striped;
      each.attributes.stickyHeader = stickyHeader;
      each.attributes.border = border;
      each.attributes.columns = headerColumn ? columns + 1 : columns;
      each.attributes.headerColumn = headerColumn;

      if (each.attributes.headingOptions === undefined) {
        each.attributes.headingOptions = {};
      }
      each.attributes.headingOptions.tag = headingOptions.tag + 1;
      each.attributes.sticky = sticky;

      return each;
    });
  };

  const headerRow =
    innerBlocks?.length && stickyHeader ? (
      <Blocks
        blocks={mapTabularRows(
          innerBlocks.filter((_, index) => stickyHeader && index === 0),
          true
        )}
      />
    ) : null;

  const rows = innerBlocks?.length ? (
    <Blocks
      blocks={mapTabularRows(
        innerBlocks.filter((_, index) => !stickyHeader || index !== 0)
      )}
    />
  ) : null;

  return (
    <BlockContent
      className={blockClassNames}
      width={width}
      defaultValue="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div
        className={cn("flex", {
          "justify-center": requiredTextAlign === "center",
          "justify-start": requiredTextAlign !== "center",
        })}
      >
        <div className="max-w-[540px]">
          {includeAboveHeading && (
            <AboveHeading variant={variant} className={aboveHeadingClassNames}>
              {aboveHeading}
            </AboveHeading>
          )}
          <CustomHeading
            heading={heading}
            headingOptions={headingOptions}
            headingClassNames={headerClassNames}
          />
          {includeContent && (
            <RichText className={pTextClassNames} tag="p">
              {content}
            </RichText>
          )}
        </div>
      </div>
      <div className={wrapClassNames}>
        <table className="w-full table-auto border-collapse lg:relative">
          <thead className="z-20 lg:relative">{headerRow}</thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupTabularContent";

TabularContentBlock.displayName = BLOCK_NAME;

TabularContentBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          border
          className
          columns
          content
          ga4SectionId
          headerColumn
          heading
          headingOptions
          hideMobile
          highlightColumn
          includeAboveHeading
          includeContent
          metadata
          stickyHeader
          striped
          requiredTextAlign: textAlign
          variant
          width
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
