export default function formatFocalPoint(focalPoint: { x: string; y: string }) {
  let x: number | string = focalPoint?.x;
  let y: number | string = focalPoint?.y;

  x = parseFloat(x ? x : '.5') ?? 0.5;
  y = parseFloat(y ? y : '.5') ?? 0.5;

  if (isNaN(x) || isNaN(y)) {
    return {
      x: '50%',
      y: '50%',
    };
  }

  const newX = `${x * 100}%`;
  const newY = `${y * 100}%`;

  return { x: newX, y: newY };
}
