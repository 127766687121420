import BaseLogs from "./baseLogs";

export default class WebsiteLog extends BaseLogs {
  constructor() {
    super();
    this.WSNAME = "websitelog";
    this.WSVERSION = "1";
  }

  websitelogs(params: any) {
    return this.makeRequest("websitelogs", params).then((_) => {
      return;
    });
  }
}
