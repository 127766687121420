import { useTranslate } from "@/hooks/useTranslate";
import {
  BUSINESS_ENTERPRISE_SUCCESS_CTA_LABEL,
  BUSINESS_REQUEST_DEMO_SUCCESS_HERO_DESCRIPTION,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_1,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_2,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_3,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_V2,
  BUSINESS_ENTERPRISE_SUCCESS_SECONDARY_DESCRIPTION,
  BUSINESS_REQUEST_DEMO_SUCCESS_HERO_TITLE,
} from "@/constants/tempTranslations";
import { RichText } from "@/components/atoms";
import CustomButton from "@/components/molecules/CustomButton";
import React from "react";
import { internalLinks } from "@/constants/internalLinks";
import cn from "classnames";
import {
  h3Text,
  marginBMd,
  marginBSm,
  marginTMd,
} from "@/constants/standardCSSClasses";

interface Props {
  bookItParam: string;
  inboundCompanySize: string;
}

export default function DemoFormSuccess(props: Props) {
  const { bookItParam, inboundCompanySize } = props;
  const { translate } = useTranslate();

  return (
    <>
      <RichText tag="h1" className={cn(h3Text, marginBMd)}>
        {translate(BUSINESS_REQUEST_DEMO_SUCCESS_HERO_TITLE)}
      </RichText>
      {bookItParam && (
        <>
          <RichText tag="p" className={marginBSm}>
            {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_V2)}
          </RichText>
          <RichText tag="p">
            {translate(BUSINESS_ENTERPRISE_SUCCESS_SECONDARY_DESCRIPTION)}
          </RichText>
        </>
      )}
      {!bookItParam &&
        (inboundCompanySize === "1-20" || inboundCompanySize === "21-50") && (
          <>
            <RichText tag="p" className={marginBSm}>
              {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_1)}
            </RichText>
            <RichText tag="p" className={marginBSm}>
              {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_2)}
            </RichText>
            <RichText tag="p">
              {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_3)}
            </RichText>
          </>
        )}
      {!bookItParam &&
        inboundCompanySize !== "1-20" &&
        inboundCompanySize !== "21-50" && (
          <>
            <RichText tag="p" className={marginBSm}>
              {translate(BUSINESS_REQUEST_DEMO_SUCCESS_HERO_DESCRIPTION)}
            </RichText>
            <RichText tag="p">
              {translate(BUSINESS_ENTERPRISE_SUCCESS_SECONDARY_DESCRIPTION)}
            </RichText>
          </>
        )}
      <CustomButton
        className={marginTMd}
        variant="dark"
        href={internalLinks.businessTry}
        text={translate(BUSINESS_ENTERPRISE_SUCCESS_CTA_LABEL)}
      />
    </>
  );
}
