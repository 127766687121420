import dynamic from "next/dynamic";
import React, { MouseEventHandler } from "react";
import cn from "classnames";
import Icon from "@/components/molecules/Icon";
import {
  textHeadlineInverse,
  textHeadlineNeutral,
  h6Text,
  borderBoth,
} from "@/constants/standardCSSClasses";
import { iconCaretDown } from "@/constants/icons";
import TimeProgressBar from "@/components/molecules/TimeProgressBar";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import { CustomImageInterface } from "@/components/molecules/CustomImage";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { MediaObjectInterface } from "@/components/molecules/CustomMedia";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

const FeatureInteractiveItem = dynamic(
  () => import("../molecules/FeaturesInteractiveItem"),
  {
    ssr: false,
  }
);

interface Props {
  variant: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  open: boolean;
  prevOpen: boolean;
  time: number;
  slide: number;
  updateSlide: MouseEventHandler<HTMLButtonElement>;
  isMobile: boolean;
  button: CustomButtonInterface;
  image: CustomImageInterface;
  media?: MediaObjectInterface;
}

export default function FeaturesInteractiveItemBlock({
  heading = "",
  headingOptions = {
    tag: 3,
  },
  content = "",
  variant = "white",
  open = false,
  prevOpen = false,
  time = 0,
  updateSlide,
  slide,
  isMobile,
  button,
  image,
  media = {
    type: "image",
    media: {},
  },
}: Props) {
  const wrapClassNames = cn(
    "relative border-b-0 border-l-0 border-r-0 border-t border-solid px-2 transition-colors",
    borderBoth,
    {
      "hover:bg-blue-1": !open && !isMobile && variant === "white",
      "hover:bg-white": !open && !isMobile && variant === "light",
      "hover:bg-blue-7": !open && !isMobile && variant === "dark",
      "pb-6": open,
    }
  );

  const headerClassNames = cn(h6Text, "text-left grow my-0", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const buttonClassNames = cn(
    "flex w-full items-start items-center justify-between pt-6 transition-all",
    {
      "cursor-default": open && !isMobile,
      "pb-6": !open,
    }
  );

  const iconClassNames = cn("w-[36px] ms-5 transition-all", {
    "rotate-180": open,
  });

  const buttons = [];

  if (button.href) {
    buttons.push({
      ...button,
      variant: variant,
      size: "md",
      textOnly: true,
      arrow: true,
    });
  }

  return (
    <div className={wrapClassNames}>
      {!isMobile && (open || prevOpen) && (
        <TimeProgressBar time={time} variant={variant} />
      )}
      <button
        className={buttonClassNames}
        // @ts-ignore
        onClick={() => updateSlide(slide)}
      >
        <CustomHeading
          heading={heading}
          headingOptions={headingOptions}
          headingClassNames={headerClassNames}
        />
        <Icon
          className={iconClassNames}
          source={iconCaretDown}
          variant={variant}
          name={open ? "close" : "open"}
        />
      </button>
      <FeatureInteractiveItem
        content={content}
        variant={variant}
        button={button}
        image={image}
        isMobile={isMobile}
        open={open}
        media={media}
      />
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupFeaturesInteractiveItem";

FeaturesInteractiveItemBlock.displayName = BLOCK_NAME;

FeaturesInteractiveItemBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          button
          className
          content
          heading
          image
          media
          metadata
        }
      }
    }
  `,
};
