import { useRouter } from 'next/router';

interface Props {
  date: Date | string;
}

export default function FormatDate({ date }: Props) {
  const { locale } = useRouter();
  let formattedDate = new Date(date);

  if (isNaN(formattedDate.valueOf())) {
    return null;
  }

  const timeformat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour12: false,
  };

  return <>{formattedDate.toLocaleDateString(locale ?? 'en', timeformat)}</>;
}
