import { useRouter } from "next/router";
import Link from "next/link";
import { leftArrow } from "@/constants/icons";
import React from "react";

interface Props {
  text: string;
  className?: string;
  href: string;
}

export default function ArchiveBackButton({ text, href, className }: Props) {
  const { locale } = useRouter();

  const fullHref =
    locale && locale !== "en" ? `/${locale}/${href}` : `/${href}`;

  return (
    <div className={className}>
      <Link className="flex items-center" href={fullHref}>
        <span className="mr-4 inline-block w-[16px] [&>svg]:fill-blue-5">
          {leftArrow}
        </span>
        <span className="inline-block">{text}</span>
      </Link>
    </div>
  );
}
