import cn from "classnames";
import Link from "next/link";
import {
  backgroundNeutral,
  textHeadlineNeutral,
  backgroundWhite,
} from "@/constants/standardCSSClasses";

export interface CustomTagInterface {
  link?: string;
  name: string;
  variant: string;
  className?: string;
}

export default function CustomTag({
  link = "",
  name,
  variant,
  className,
}: CustomTagInterface) {
  const classNames = cn(
    className,
    "mb-4 inline-block rounded px-3 py-1 text-[12px] uppercase",
    textHeadlineNeutral,
    {
      [backgroundWhite]: variant === "light",
      [backgroundNeutral]: variant !== "light",
    }
  );

  if (link) {
    return (
      <Link className={classNames} href={link} locale={false}>
        {name}
      </Link>
    );
  }

  return <span className={classNames}>{name}</span>;
}
