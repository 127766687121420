import flatListToHierarchical from "@/utilities/flatListToHierarchical";
import {
  MenuFooter,
  MenuHeader,
  MenuHeaderMobile,
} from "@/components/molecules";
import type { MenuItem, MenuItems } from "@/types";
import { EnhancedMenuItems } from "@/types/Menu";
import EnhancedMenuHeader from "./EnhancedMenuHeader";
import EnhancedMenuHeaderMobile from "./EnhancedMenuHeaderMobile";
import { CustomButtonInterface } from "./CustomButton";

interface Props {
  menuItems?: MenuItems;
  enhancedMenuItems?: EnhancedMenuItems;
  additionalMenuItems?: MenuItems;
  layout: "Header" | "Footer";
  mobile?: boolean;
  buttons?: CustomButtonInterface[];
  enhanced?: boolean;
}

export default function Navigation({
  menuItems,
  enhancedMenuItems,
  additionalMenuItems,
  layout,
  mobile = false,
  buttons = [],
  enhanced = false,
}: Props) {
  if (!menuItems && !enhancedMenuItems) {
    return null;
  }
  // Based on https://www.wpgraphql.com/docs/menus/#hierarchical-data
  const hierarchicalMenuItems =
    menuItems && flatListToHierarchical<MenuItem>(menuItems);
  const isHeader = layout === "Header";
  const ariaLabel = menuItems?.[0]?.menu?.node?.name ?? "enhanced";

  return (
    <>
      {isHeader && !mobile && (
        <nav
          role="navigation"
          aria-label={`${ariaLabel} menu`}
          className={`hidden flex-1 items-center xl:flex ${
            enhanced ? "h-full py-0" : ""
          }`}
        >
          <ul
            className={`flex h-full w-full items-center xl:space-x-4 ${
              enhanced ? "h-full py-0" : ""
            }`}
          >
            {enhancedMenuItems ? (
              <EnhancedMenuHeader items={enhancedMenuItems} />
            ) : (
              <MenuHeader items={hierarchicalMenuItems ?? []} />
            )}
          </ul>
        </nav>
      )}
      {isHeader && mobile && (
        <nav
          role="navigation"
          aria-label={`${ariaLabel} menu`}
          className="flex items-center xl:hidden"
        >
          {enhancedMenuItems ? (
            <EnhancedMenuHeaderMobile
              items={enhancedMenuItems}
              additionalItems={additionalMenuItems ?? []}
              buttons={buttons}
            />
          ) : (
            <MenuHeaderMobile
              items={hierarchicalMenuItems ?? []}
              additionalItems={additionalMenuItems ?? []}
            />
          )}
        </nav>
      )}
      {!isHeader && (
        <nav
          role="navigation"
          aria-label={`${ariaLabel} menu`}
          className="flex w-full bg-dashgreen"
        >
          <MenuFooter items={hierarchicalMenuItems ?? []} />
        </nav>
      )}
    </>
  );
}
