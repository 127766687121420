import React from "react";
import cn from "classnames";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import BlockContent from "../molecules/BlockContent";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  h3Text,
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  variant?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  contentType: string;
  innerBlocks?: Block[];
  paddingTop?: string;
  paddingBottom?: string;
}

export default function TeamBlock({
  heading = "",
  headingOptions = {
    tag: 2,
  },
  variant = "white",
  contentType,
  innerBlocks,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const team = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each, index) => {
        each.attributes.variant = variant;
        each.attributes.index = index;
        return each;
      })}
    />
  ) : null;

  const gridClassNames = cn(
    "grid gap-x-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 py-4"
  );

  const headingClassNames = cn("mb-0 mt-0", h3Text, {
    [textHeadlineNeutral]: variant !== "dark",
    [textHeadlineInverse]: variant === "dark",
  });

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width="full"
      defaultValue="full"
      constrain
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      {heading && (
        <CustomHeading
          heading={heading}
          headingOptions={headingOptions}
          headingClassNames={headingClassNames}
        />
      )}
      <div className={gridClassNames}>{team}</div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupTeam";

TeamBlock.displayName = BLOCK_NAME;

TeamBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          ga4SectionId
          heading
          headingOptions
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
