import React from "react";
import cn from "classnames";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  h3Text,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeContent?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  width?: string;
  contentType: string;
  innerBlocks?: Block[];
  paddingTop?: string;
  paddingBottom?: string;
}

export default function FAQBlock({
  includeAboveHeading = false,
  includeContent = true,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 2,
  },
  content = "",
  variant = "white",
  width = "full",
  contentType,
  innerBlocks,
  blockContentProps,
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const faqs = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;

        if (each.attributes.headingOptions === undefined) {
          each.attributes.headingOptions = {};
        }
        each.attributes.headingOptions.tag = headingOptions.tag + 1;

        return each;
      })}
    />
  ) : null;

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width={width}
      defaultValue="box"
      constrain
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className="md:flex md:gap-20">
        <div className="md:basis-1/3">
          {includeAboveHeading && (
            <AboveHeading className="mt-0" variant={variant}>
              {aboveHeading}
            </AboveHeading>
          )}
          <CustomHeading
            heading={heading}
            headingOptions={headingOptions}
            headingClassNames={cn(marginBMd, h3Text, {
              [textHeadlineInverse]: variant === "dark",
              [textHeadlineNeutral]: variant === "light",
              "mt-0": includeAboveHeading,
            })}
          />
          {includeContent && (
            <RichText
              className={cn("md:text-lg", {
                [textBodyInverse]: variant === "dark",
                [textBodyNeutral]: variant !== "dark",
              })}
              tag="p"
            >
              {content}
            </RichText>
          )}
        </div>
        <div className="mt-10 border-b border-l-0 border-r-0 border-t-0 border-solid md:mt-0 md:basis-2/3">
          {faqs}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupFaq";

FAQBlock.displayName = BLOCK_NAME;

FAQBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          className
          content
          ga4SectionId
          heading
          headingOptions
          includeAboveHeading
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
