export const BLOG_LAST_UPDATED = {
  "en": "Last updated:",
  "fr": "Last updated:",
  "de": "Last updated:",
  "es": "Last updated:",
  "pt-br": "Last updated:",
  "it": "Last updated:",
  "ja": "Last updated:",
  "ko": "Last updated:",
  "nl": "Last updated:",
  "pt-pt": "Last updated:",
  "sv": "Last updated:",
  "zh": "Last updated:"
}