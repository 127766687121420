export const POST_PREVIEW_SECTION_SEE_MORE = {
  "en": "See more",
  "fr": "En savoir plus",
  "de": "Mehr anzeigen",
  "es": "See more",
  "pt-br": "See more",
  "it": "See more",
  "ja": "See more",
  "ko": "See more",
  "nl": "See more",
  "pt-pt": "See more",
  "sv": "See more",
  "zh": "See more"
}