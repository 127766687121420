import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { CardInterface } from "@/components/molecules/Card";
import ContentCards from "@/components/molecules/ContentCards";
import React from "react";
import { HeadingOptions } from "@/components/molecules/CustomHeading";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import { formatEventDate } from "@/utilities/formatEventDate";
import { ResourcesCardFragment } from "@/fragments";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { useBrowserLanguage } from "@/hooks/useBrowserLanguage";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface QueryInterface {
  category: string | undefined;
  order: string;
  orderBy: string;
  numberOfItems: number;
  postType?: string;
  featured?: number;
}

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeTag?: boolean;
  border?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  buttonsNumber?: number;
  button?: CustomButtonInterface;
  contentType: string;
  columns?: number;
  query: QueryInterface;
  paddingTop?: string;
  paddingBottom?: string;
}

const postGridByCategoryQuery = gql`
  query GetPostsByCategory(
    $items: Int
    $locale: LanguageCodeFilterEnum = EN
    $category: [ID] = "-1"
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
  ) {
    posts(
      where: {
        categoryIn: $category
        language: $locale
        orderby: { field: $orderBy, order: $order }
      }
      first: $items
    ) {
      nodes {
        id
        excerpt
        date
        title
        uri
        featuredImage {
          node {
            id
            sourceUrl
            altText
            mediaDetails {
              width
              height
            }
          }
        }
        author {
          node {
            name
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`;

const resourceGridByCategoryQuery = gql`
  ${ResourcesCardFragment}
  query GetResourcesByCategory(
    $items: Int
    $category: Int = -1
    $orderBy: PostObjectsConnectionOrderbyEnum!
    $order: OrderEnum!
    $featured: Int = 0
  ) {
    resources(
      where: {
        resourceCategory: $category
        featured: $featured
        orderby: { field: $orderBy, order: $order }
      }
      first: $items
    ) {
      ...RootQueryToResourceConnectionFragment
    }
  }
`;

export default function ContentCardsBlock({
  includeAboveHeading = true,
  includeButtons = true,
  includeContent = true,
  includeTag = true,
  border = false,
  aboveHeading,
  heading,
  headingOptions = {
    tag: 2,
  },
  content,
  variant = "dark",
  button,
  contentType,
  columns = 3,
  query = {
    order: "DESC",
    orderBy: "DATE",
    numberOfItems: 6,
    category: "-1",
    postType: "post",
    featured: 0,
  },
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const { locale = "en" } = useRouter();
  const { language } = useBrowserLanguage(locale);

  //Grab the posts
  const { loading, data } = useQuery(
    query.postType === "post"
      ? postGridByCategoryQuery
      : resourceGridByCategoryQuery,
    {
      variables: {
        category: query.category ? parseInt(query.category, 10) : null,
        items: query.numberOfItems,
        order: query.order.toUpperCase(),
        orderBy: query.orderBy.toUpperCase(),
        locale: formatLocaleForGraphQL(locale),
        featured: query.featured,
      },
    }
  );

  const postProp = query.postType === "resource" ? "resources" : "posts";

  const cards: Array<CardInterface> =
    data && data[postProp]
      ? data[postProp].nodes?.map((each: any) => {
          return {
            variant,
            imageAspect: "contentCard",
            visual: "image",
            border: false,
            heading: each.title,
            content: each.excerpt,
            button: false,
            icon: false,
            tag: includeTag && {
              link:
                query.postType === "resource"
                  ? each.resourceTypes?.nodes[0]?.uri
                  : each.categories?.nodes[0]?.uri,
              name:
                query.postType === "resource"
                  ? each.resourceTypes?.nodes[0]?.name
                  : each.categories?.nodes[0]?.name,
            },
            image: {
              full: each.featuredImage?.node?.sourceUrl
                ? each.featuredImage.node.sourceUrl
                : "/images/blog-post-default.webp",
              alt: each.featuredImage?.node?.altText
                ? each.featuredImage.node.altText
                : "A green and blue background with three blue slanted rectangles in the middle. A white Dashlane logo is overlaid on top.",
            },
            includeCardButtons: false,
            linkUri:
              query.postType === "resource" && each.resourceFields.externalUrl
                ? each.resourceFields.externalUrl
                : each.uri,
            linkCard: true,
            eventDate: each.resourceFields?.eventDate
              ? formatEventDate(each.resourceFields.eventDate, language, true)
              : "",
          };
        })
      : [];

  return (
    <ContentCards
      includeAboveHeading={includeAboveHeading}
      includeButtons={includeButtons}
      includeContent={includeContent}
      border={border}
      aboveHeading={aboveHeading}
      heading={heading}
      headingOptions={headingOptions}
      content={content}
      variant={variant}
      button={button}
      contentType={contentType}
      loading={loading}
      cards={cards}
      columns={columns}
      blockContentProps={blockContentProps}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    />
  );
}

const BLOCK_NAME = "TenupContentCards";

ContentCardsBlock.displayName = BLOCK_NAME;

ContentCardsBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          border
          button
          className
          columns
          content
          ga4SectionId
          heading
          headingOptions
          includeAboveHeading
          includeButtons
          includeTag
          metadata
          query
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
