import WebsiteLog from "./websiteLog";
import getEnvironment from "@/utilities/getEnvironment";

const loggerAPI = new WebsiteLog();
const activeEnv = getEnvironment();

interface LoggerParams {
  level: string;
  message: string;
  data: string;
}

export default class FELogger {
  /**
   * sends log Data to the server.
   * @param {Object literal} params object literal
   */
  send(params: LoggerParams) {
    return loggerAPI.websitelogs(params);
  }

  /**
   * @description builds the object that's gonna be sent to the server.
   * @param {String} level type of log
   * @param {String} method
   * @param {String} message custom log message
   * @param {Object} logData
   * @return {Object}
   */
  buildData(
    level: string,
    method: string,
    message: string,
    logData: { [key: string]: any } = {}
  ) {
    logData["page-identifier"] = window.pageConfig?.identifier || ""; // tracking where the log was generated.
    logData["originLog"] = "Website"; // for filtering purposes.
    logData["method"] = method || "None";

    const data = JSON.stringify(logData);

    return { level, message, data };
  }

  /**
   * @description prepare the data and send the log Data to the server.
   * @param {String} level
   * @param {String} method
   * @param {String} message
   * @param {Object literal} data
   */
  log(
    level: string,
    method: string,
    message: string,
    data: { [key: string]: any } = {}
  ) {
    const logData = this.buildData(level, method, message, data);
    if (activeEnv === "production" || activeEnv === "staging") {
      void this.send(logData);
      return;
    }
    
    this.debug(logData);
  }

  /**
   * @description prepares the error log to submit it to the server.
   * @param {String} method function name where the looger is called.
   * @param {String} message
   * @param {Object literal} data, data[error] must be passed.
   */
  error(method: string, message: string, data?: { [key: string]: any }) {
    // make sure that an Error object is always passed in the data.

    const error = data?.error;
    if (error) {
      data["error-stacktrace"] = error.stack ?? null;
      data["error-name"] = error.name || null;
      data["error"] = error.message || null;
    } else {
      this.debug('no Error object was passed to "error" property');
    }

    this.log("error", method, message, data);
  }

  warn(method: string, message: string, data: { [key: string]: any }) {
    this.log("warn", method, message, data);
  }

  info(method: string, message: string, data: { [key: string]: any }) {
    this.log("info", method, message, data);
  }

  /**
   * log on the browser anything we pass.
   * @param {Any Type} message any kind data.
   */
  debug(message: any) {
    // we don't have to worry to log anything and remove it on production because it won't show up on production.
    if (activeEnv !== "production") {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  }
}
