import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { useHover } from "@/hooks/useHover";
import { useInView } from "@/hooks/useInView";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  pTextL,
  h3Text,
  textBodyNeutral,
  marginTMd,
  borderBoth,
} from "@/constants/standardCSSClasses";
import { CAROUSEL_TIME } from "@/constants/slider";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MOBILE_BREAK } from "@/constants/utility";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia from "@/components/molecules/CustomMedia";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeContent?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  width?: string;
  contentType: string;
  innerBlocks?: Block[];
  paddingTop?: string;
  paddingBottom?: string;
}

export default function FeaturesInteractiveBlock({
  includeAboveHeading = false,
  includeContent = true,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 2,
  },
  content = "",
  variant = "white",
  width = "full",
  contentType,
  innerBlocks,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const prevHoverRef = useRef<boolean>(isHover);
  const [slide, setSlide] = useState(0);
  const [time, setTime] = useState(1);
  const [imageOpacity, setImageOpacity] = useState("opacity-100");
  const [inViewRef, { inView }] = useInView();
  const numSlides = innerBlocks?.length === undefined ? 0 : innerBlocks.length;
  const { width: windowWidth } = useWindowSize();

  //even though we're updating time inside the effect and we have it as a dependency, it is really only updated in the interval
  //adding the dependency ensures we have up-to-date values for time and isHover inside of the interval
  //and the interval is cleared and reset every time time or hover changes
  useEffect(() => {
    if (windowWidth < MOBILE_BREAK) return;
    if (!inView) return;

    const interval = setInterval(() => {
      //If we're not hovered, do the carousel
      if (!isHover) {
        if (time === CAROUSEL_TIME) {
          //Next slide
          updateSlide();
        } else {
          //Increment time
          setTime(time + 1);
        }
      }

      prevHoverRef.current = isHover;
    }, 1000);

    //Make sure that the progress bar starts up again right after isHover turns false
    if (prevHoverRef.current && !isHover) {
      prevHoverRef.current = false;
      setTime(time + 1);
    }

    return () => clearInterval(interval);
  }, [time, isHover, inView]);

  const updateSlide = (index: boolean | number = false) => {
    if (windowWidth < MOBILE_BREAK && index === slide) {
      index = -1;
    } else if (index === slide) {
      // If we're on desktop and someone clicks the currently open slide, reset time
      // but don't refresh the image
      setTime(0);
      return;
    } else if (index === false || index === true) {
      index = slide === numSlides - 1 ? 0 : slide + 1;
    }

    setSlide(index);
    setTime(1);
    setImageOpacity("opacity-0");
    setTimeout(() => {
      setImageOpacity("transition-opacity ease-in opacity-100");
    }, 100);
  };

  const images = innerBlocks?.length
    ? innerBlocks.map((each) => {
        return each.attributes.image;
      })
    : [];
  const medias = innerBlocks?.length
    ? innerBlocks.map((each) => {
        return each.attributes.media;
      })
    : [];
  const features = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each, index) => {
        each.attributes.variant = variant;
        each.attributes.open = index === slide;
        each.attributes.prevOpen =
          index - 1 === slide || (index === 0 && slide === numSlides - 1);
        each.attributes.time = index === slide ? time : 0;
        each.attributes.updateSlide = updateSlide;
        each.attributes.slide = index;
        each.attributes.isMobile = windowWidth < MOBILE_BREAK;
        each.attributes.image = images[index];

        if (each.attributes.headingOptions === undefined) {
          each.attributes.headingOptions = {};
        }
        each.attributes.headingOptions.tag = headingOptions.tag + 1;
        return each;
      })}
    />
  ) : null;

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width={width}
      defaultValue="box"
      constrain
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className="md:flex md:gap-20" ref={inViewRef}>
        <div className="md:w-[40%]">
          {includeAboveHeading && (
            <AboveHeading className="mt-0" variant={variant}>
              {aboveHeading}
            </AboveHeading>
          )}
          <CustomHeading
            heading={heading}
            headingOptions={headingOptions}
            headingClassNames={cn(marginBMd, h3Text, {
              [textHeadlineInverse]: variant === "dark",
              [textHeadlineNeutral]: variant === "light",
              "mt-0": includeAboveHeading,
            })}
          />
          {includeContent && (
            <RichText
              className={cn(pTextL, {
                [textBodyInverse]: variant === "dark",
                [textBodyNeutral]: variant !== "dark",
              })}
              tag="p"
            >
              {content}
            </RichText>
          )}
          <div
            className={cn(
              "border-b border-l-0 border-r-0 border-t-0 border-solid",
              borderBoth,
              marginTMd
            )}
            ref={hoverRef}
          >
            {features}
          </div>
        </div>
        <div className="hidden md:block md:w-[60%]">
          {medias[slide] && (
            <CustomMedia
              className={cn(imageOpacity, "h-full w-full")}
              media={medias[slide] ?? { media: {}, type: "image" }}
              fallbackImage={images[slide]}
            />
          )}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupFeaturesInteractive";

FeaturesInteractiveBlock.displayName = BLOCK_NAME;

FeaturesInteractiveBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          className
          content
          ga4SectionId
          heading
          headingOptions
          includeAboveHeading
          includeFeatureButtons
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
