export default function extractRgbValues(rgbString: string) {
  const rgbValues = rgbString.match(
    /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?/
  );

  // Remove first element (original RGB string).
  rgbValues!.shift();

  return rgbValues;
}
