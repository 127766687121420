/* eslint-disable @typescript-eslint/naming-convention */
import { Maybe } from "@/types";
import { TrackingConfig } from "@/types";
import Url from "domurl";
import Cookies from "js-cookie";
import { getParsedUserAgent } from "@/utilities/uaParserWrapper";

// Used to associate our funnelCookie and the respective utm property
const utmSourceMap: Record<string, string> = {
  utm_source: "last_click_source",
  utm_campaign: "last_click_campaign",
  utm_medium: "last_click_medium",
  utm_content: "last_click_content",
  utm_term: "last_click_term",
};

const paidTracking = {
  getUA: () => {
    return getParsedUserAgent();
  },
  getUrl: () => {
    return new Url();
  },
  getCustomUTMsForPage: (
    trackingConfig: TrackingConfig,
    pageIdentifier: string
  ) => {
    const urlObject = paidTracking.getUrl();
    const campaign = paidTracking.getSelectedCampaignForPage(
      trackingConfig,
      pageIdentifier,
      urlObject
    );

    if (campaign === null || !campaign.utms) {
      return {};
    }

    const utms: any = {};
    for (const utm in campaign.utms) {
      utms[utm] =
        paidTracking.replaceParameters(
          campaign.utms[utm as keyof typeof campaign.utms],
          urlObject,
          null,
          false
        ) ?? "";
    }

    return utms;
  },

  getAdjustURLForPage: (
    trackingConfig: TrackingConfig,
    pageIdentifier: string
  ) => {
    const urlObject = paidTracking.getUrl();
    const campaign = paidTracking.getSelectedCampaignForPage(
      trackingConfig,
      pageIdentifier,
      urlObject
    );

    if (campaign === null) {
      return null;
    }

    let adjustURL = null;
    const UAParsedResult = paidTracking.getUA();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (UAParsedResult?.os) {
      if (UAParsedResult.os.name === "iOS") {
        adjustURL = campaign.iosAdjustURL;
      } else if (UAParsedResult.os.name === "Android") {
        adjustURL = campaign.androidAdjustURL;
      }
    }

    if (adjustURL) {
      const utms: any = {};
      if (campaign.utms) {
        for (const utm in campaign.utms) {
          utms[utm] = paidTracking.replaceParameters(
            campaign.utms[utm as keyof typeof campaign.utms],
            urlObject,
            null,
            false
          );
        }
      }

      return paidTracking.replaceParameters(adjustURL, urlObject, utms, true);
    } else {
      return null;
    }
  },

  getDesktopPartnerNameForPage: (
    trackingConfig: TrackingConfig,
    pageIdentifier: string
  ) => {
    const urlObject = paidTracking.getUrl();
    const campaign = paidTracking.getSelectedCampaignForPage(
      trackingConfig,
      pageIdentifier,
      urlObject
    );

    if (campaign === null) {
      return null;
    }

    return paidTracking.replaceParameters(
      campaign.desktopPartnerName,
      urlObject,
      null,
      true
    );
  },

  getSpecialOfferTokenForPage: (
    trackingConfig: TrackingConfig,
    pageIdentifier: string
  ) => {
    const urlObject = paidTracking.getUrl();
    const campaign = paidTracking.getSelectedCampaignForPage(
      trackingConfig,
      pageIdentifier,
      urlObject
    );

    if (campaign === null) {
      return null;
    }

    return campaign.specialOfferToken ?? null;
  },

  getSelectedCampaignForPage: (
    trackingConfig: Maybe<TrackingConfig>,
    pageIdentifier: string,
    urlObject: Url<unknown>
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!trackingConfig || !trackingConfig.campaigns) {
      return null;
    }

    for (const campaign of trackingConfig.campaigns) {
      if (
        paidTracking.isCampaignMatchingPage(campaign, pageIdentifier, urlObject)
      ) {
        return campaign.tracking;
      }
    }

    return null;
  },

  isCampaignMatchingPage: (
    campaign: any,
    pageIdentifier: string,
    urlObject: Url<any>
  ) => {
    if (!campaign || !campaign.conditions) {
      return false;
    }

    if (
      campaign.conditions.pageIdentifier &&
      campaign.conditions.pageIdentifier !== pageIdentifier
    ) {
      return false;
    }

    // Campaign will check against the query condition (ex: utm_source === 'XYZ')
    // Will trigger if the user has this query in their current URL or in their last_XYZ_source in their funnelCookie
    if (campaign.conditions.query) {
      //const funnelCookie = Cookies.getJSON("userFunnelCookie");
      const funnelCookie = JSON.parse(Cookies.get("userFunnelCookie") || "{}");

      if (!urlObject.query) {
        return false;
      }
      const parameters = Object.keys(campaign.conditions.query);

      for (const parameter of parameters) {
        const campaignParameter =
          campaign.conditions.query[parameter].toLowerCase();
        const queryParameter = (urlObject.query[parameter] || "").toLowerCase();
        const funnelParameter = funnelCookie
          ? // @ts-ignore
            (funnelCookie[utmSourceMap[parameter]] || "").toLowerCase()
          : "";
        if (
          campaignParameter !== queryParameter &&
          campaignParameter !== funnelParameter
        ) {
          return false;
        }
      }
    }

    if (campaign.conditions.path) {
      const urlPath = urlObject.path.toLowerCase();
      const isMatchingPath = campaign.conditions.path.some((path: string) =>
        urlPath.includes(path.toLowerCase())
      );
      if (!isMatchingPath) {
        return false;
      }
    }

    return true;
  },

  replaceParameters: (
    pattern: any,
    urlObject: any,
    extraQuery: any,
    shouldURLEncode: boolean
  ) => {
    // Available options:
    // - partner (query)
    // - utm_source (query)
    // - utm_campaign (query)
    // - utm_medium (query)
    // - utm_content (query)
    // - utm_term (query)
    // - last_url_component

    if (!pattern || !urlObject) {
      return null;
    }

    const queryParameters = [
      "partner",
      "utm_source",
      "utm_campaign",
      "utm_medium",
      "utm_content",
      "utm_term",
      "campaign_id",
      "adgroup_name",
      "ad_name",
      "ios_advertising_id",
      "click_id",
      "android_advertising_id",
      "ad_id",
    ];

    const regexp = /\{\{([a-z_]+)\}\}/gi;
    let result = pattern;
    let matches = regexp.exec(pattern);

    while (matches !== null) {
      const key = matches[1] ?? "";
      let replaceValue = null;
      /* istanbul ignore else */
      if (queryParameters.includes(key)) {
        if (urlObject.query?.[key]) {
          replaceValue = urlObject.query[key];
        } else if (extraQuery?.[key]) {
          replaceValue = extraQuery[key];
        } else {
          replaceValue = "";
        }
      } else if (urlObject.path && key === "last_path_component") {
        replaceValue = urlObject.path.split("/").pop();
      }

      /* istanbul ignore else */
      if (replaceValue !== null) {
        if (shouldURLEncode) {
          replaceValue = encodeURIComponent(replaceValue);
        }
        result = result.replace(new RegExp(`{{${key}}}`), replaceValue);
      }

      matches = regexp.exec(pattern);
    }

    return result;
  },
};

export default paidTracking;
