// @ts-ignore
export { ZXCVBNFrequencyLists } from "zxcvbn/lib/main";

export enum EvaluatePasswordSuggestions {
  ADD_ANOTHER_WORD = "zxcvbn_add_another_word_or_two_uncommon_words_are_better",
  ALL_UPPERCASE = "zxcvbn_all_uppercase_is_almost_as_easy_to_guess_as_all_lowercase",
  AVOID_RECENT_YEARS = "zxcvbn_avoid_recent_years",
  AVOID_RELATED_DATES = "zxcvbn_avoid_dates_and_years_that_are_associated_with_you",
  AVOID_RELATED_YEARS = "zxcvbn_avoid_years_that_are_associated_with_you",
  AVOID_REPEATED = "zxcvbn_avoid_repeated_words_and_characters",
  AVOID_SEQUENCES = "zxcvbn_avoid_sequences",
  CAPITALIZATION = "zxcvbn_capitalization_doesnt_help_very_much",
  NO_NEED_FOR_SYMBOL = "zxcvbn_no_need_for_symbols_digits_or_uppercase_letters",
  PREDICTABLE_SUBSTITUTIONS = "zxcvbn_predictable_substitutions_like_at_instead_of_a_dont_help_very_much",
  REVERSED_WORDS = "zxcvbn_reversed_words_arent_much_harder_to_guess",
  USE_A_FEW_WORDS = "zxcvbn_use_a_few_words_avoid_common_phrases",
  USE_A_LONGER = "zxcvbn_use_a_longer_keyboard_pattern_with_more_turns",
}

export enum EvaluatePasswordWarning {
  COMMON_NAMES = "zxcvbn_common_names_and_surnames_are_easy_to_guess",
  COMMON_PASSWORD = "zxcvbn_this_is_a_very_common_password",
  DATES_TOO_EASY = "zxcvbn_dates_are_often_easy_to_guess",
  NAMES = "zxcvbn_names_and_surnames_by_themselves_are_easy_to_guess",
  RECENT_YEARS = "zxcvbn_recent_years_are_easy_to_guess",
  REPEATS_EASY = "zxcvbn_repeats_like_aaa_are_easy_to_guess",
  REPEATS_SLIGHTLY_HARDER = "zxcvbn_repeats_like_abcabcabc_are_only_slightly_harder_to_guess_than_abc",
  SEQUENCES = "zxcvbn_sequences_like_abc_or_6543_are_easy_to_guess",
  SHORT_KEYBOARD_PATTERNS = "zxcvbn_short_keyboard_patterns_are_easy_to_guess",
  SIMILAR_COMMON_PASSWORD = "zxcvbn_this_is_similar_to_a_commonly_used_password",
  SINGLE_WORD = "zxcvbn_a_word_by_itself_is_easy_to_guess",
  STRAIGHT_ROWS = "zxcvbn_straight_rows_of_keys_are_easy_to_guess",
  TOP_10_COMMON_PASSWORD = "zxcvbn_this_is_a_top_10_common_password",
  TOP_100_COMMON_PASSWORD = "zxcvbn_this_is_a_top_100_common_password",
}

export interface EvaluatePasswordCrackTime<T extends number | string> {
  offlineFastHashing1e10PerSecond: T;
  offlineSlowHashing1e4PerSecond: T;
  onlineNoThrottling10PerSecond: T;
  onlineThrottling100PerHour: T;
}

export interface EvaluatePasswordFeedback {
  suggestions: Array<EvaluatePasswordSuggestions>;
  warning: EvaluatePasswordWarning | undefined;
}

export interface EvaluatePasswordResult {
  crackTimesDisplay: EvaluatePasswordCrackTime<string>;
  crackTimesSeconds: EvaluatePasswordCrackTime<number>;
  feedback: EvaluatePasswordFeedback;
  guesses: number;
  guessesLog10: number;
  score: number;
}
