import { RichText } from "@/components/atoms";
import { Button } from "@/components/molecules";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor?: string;
  backgroundColor?: string;
  className?: string;
  linkTarget?: string;
  rel?: string;
  style?: {
    color?: {
      background?: string;
      text?: string;
    };
  };
  text: string;
  textColor?: string;
  url?: string;
  urlExternal?: boolean;
}

export default function ButtonBlock({
  anchor,
  backgroundColor,
  className,
  linkTarget,
  rel,
  style,
  text,
  textColor,
  url,
  urlExternal,
}: Props) {
  return (
    <Button
      attributes={{
        id: anchor,
        target: linkTarget,
        rel: rel,
      }}
      className={className}
      href={url ? url : "#"}
      externalLink={urlExternal}
      style={{
        backgroundColor:
          backgroundColor === "white" ? "#fff" : style?.color?.background,
        color: textColor === "white" ? "#fff" : style?.color?.text,
      }}
    >
      <RichText>{text}</RichText>
    </Button>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreButton";

ButtonBlock.displayName = BLOCK_NAME;

ButtonBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          floatWidth: width
          url
          type
          title
          textColor
          textAlign
          text
          tagName
          style
          rel
          placeholder
          metadata
          linkTarget
          linkClassName
          gradient
          fontSize
          fontFamily
          cssClassName
          className
          borderColor
          backgroundColor
        }
      }
    }
  `,
};
