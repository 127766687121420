import { useState, Fragment } from 'react';
import { useRouter } from 'next/router';
import { Popover, Transition } from '@headlessui/react';

import { SearchIcon } from '@/components/icons';

export default function SearchPopover() {
  const router = useRouter();

  const [searchValue, setSearchValue] = useState('');

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <Popover className="relative">
      <>
        <Popover.Button className="group inline-flex items-center rounded-md px-3 py-2">
          <SearchIcon />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute right-0 z-10 mt-3 w-screen max-w-sm  transform px-4 sm:px-0 md:max-w-md lg:max-w-lg">
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow-lg ring-1 ring-black ring-opacity-5 sm:p-6">
              <form
                className="relative mt-1 flex"
                onSubmit={(e) => {
                  e.preventDefault();
                  router.push({
                    pathname: '/',
                    query: {
                      s: searchValue,
                    },
                  });
                }}
              >
                <input
                  type="Search"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={handleChange}
                  className="w-11/12 rounded-md  border-gray-300 p-4 shadow-sm focus:border-dashgreen focus:ring-dashgreen sm:text-sm"
                />
                <button type="submit" className="px-4">
                  <SearchIcon />
                </button>
              </form>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
}
