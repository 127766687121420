import type { Style } from "@/types";

export type BlockStyle = {
  color?: string;
  width?: string | number;
  fontWeight?: number;
  fontSize?: number;
  background?: string;
  backgroundColor?: string;
};

export interface GetBlockStyles {
  backgroundColorHex?: string;
  gradientHex?: string;
  textColorHex?: string;
  width?: number | string;
  style?: Style;
}

export default function getBlockStyles({
  backgroundColorHex,
  gradientHex,
  textColorHex,
  width,
  style,
}: GetBlockStyles) {
  const blockStyle: BlockStyle = {};

  const parsedStyle =
    typeof style === "string" ? JSON.parse(style as string) : style;

  // Determine styles, using stylelint-accepted const names.
  const background = gradientHex || parsedStyle?.color?.gradient;
  const backgroundcolor = backgroundColorHex || parsedStyle?.color?.background;
  const fontsize = parsedStyle?.typography?.fontSize;
  const fontweight = parsedStyle?.typography?.fontWeight;
  const textcolor = textColorHex || parsedStyle?.color?.text;

  // Only add styles if set.
  if (background) {
    blockStyle.background = background;
  }

  if (backgroundcolor) {
    blockStyle.backgroundColor = backgroundcolor;
  }

  if (fontsize) {
    blockStyle.fontSize = fontsize;
  }

  if (fontweight) {
    blockStyle.fontWeight = fontweight;
  }

  if (textcolor) {
    blockStyle.color = textcolor;
  }

  if (width) {
    if (typeof width !== "number") {
      // If width is not a number, return full string.
      blockStyle.width = width;
    } else {
      // If width is a number, return as percent.
      blockStyle.width = `${width}%`;
    }
  }

  return blockStyle;
}
