export const LOAD_MORE_BUTTON = {
  "en": "Load more",
  "fr": "Voir plus",
  "de": "Mehr laden",
  "es": "Cargar más",
  "pt-br": "Load more",
  "it": "Load more",
  "ja": "Load more",
  "ko": "Load more",
  "nl": "Load more",
  "pt-pt": "Load more",
  "sv": "Load more",
  "zh": "Load more"
}