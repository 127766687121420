import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  pTextSm,
} from "@/constants/standardCSSClasses";
import { useTouchSlide } from "@/hooks/useTouchSlider";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  content?: string;
  variant?: string;
  attributionName?: string;
  attributionPosition?: string;
}

export default function RatingCardBlock({
  content = "",
  variant = "dark",
  attributionName = "",
  attributionPosition = "",
}: Props) {
  const { slideWrapClass } = useTouchSlide();

  const pTextClassNames = cn("text-base leading-6", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const attrClassNames = cn("block", pTextSm, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const titleAttrClassNames = cn("font-medium", attrClassNames);

  const starsClassNames = cn("text-left", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const cardsWrapper = cn(
    slideWrapClass,
    "md:w-full grid items-center gap-4 rounded border border-solid border-grey-1 p-6 md:p-6"
  );

  return (
    <div className={cardsWrapper}>
      <div className={starsClassNames}>
        <span className="me-2">&#9733;</span>
        <span className="me-2">&#9733;</span>
        <span className="me-2">&#9733;</span>
        <span className="me-2">&#9733;</span>
        &#9733;
      </div>
      <div className="flex-1">
        <RichText className={pTextClassNames} tag="p">
          {content}
        </RichText>
      </div>
      <div className="flex-1">
        <RichText className={titleAttrClassNames}>{attributionName}</RichText>
        <RichText className={attrClassNames} tag="span">
          {attributionPosition}
        </RichText>
      </div>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupRatingCard";

RatingCardBlock.displayName = BLOCK_NAME;

RatingCardBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          attributionName
          attributionPosition
          className
          content
          metadata
          starsNumber
        }
      }
    }
  `,
};
