import { Locale } from "@/types";

const langs: Locale[] = [
  "en",
  "de",
  "fr",
  "es",
  "it",
  "ja",
  "ko",
  "nl",
  "pt-br",
  "pt-pt",
  "sv",
  "zh",
];

export const MOCK_I18N_KEY = {
  en: "en",
  fr: "fr",
  de: "de",
  es: "es",
  it: "it",
  ja: "ja",
  ko: "ko",
  nl: "nl",
  "pt-br": "pt",
  "pt-pt": "pt",
  sv: "sv",
  zh: "zh",
};

export const EMPTY_I18N_KEY = {
  en: "",
  fr: "",
  de: "",
  es: "",
  it: "",
  ja: "",
  ko: "",
  nl: "",
  "pt-br": "",
  "pt-pt": "",
  sv: "",
  zh: "",
};

export const getMocki18n = (text: string) => {
  const copies: Record<Locale, string> = { ...EMPTY_I18N_KEY };
  langs.forEach((lang: Locale) => {
    copies[lang] = text;
  });
  return copies;
};
