import { gql } from "@apollo/client";

const BlocksFragment = gql`
  fragment BlocksFragment on BlockEditorContentNode {
    blocks {
      name
      attributesJSON
      innerBlocks {
        name
        attributesJSON
        innerBlocks {
          name
          attributesJSON
          innerBlocks {
            name
            attributesJSON
            innerBlocks {
              name
              attributesJSON
              innerBlocks {
                name
                attributesJSON
              }
            }
          }
        }
      }
    }
  }
`;

export default BlocksFragment;
