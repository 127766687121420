export const BLOG_ORIGINALLY_PUBLISHED = {
  "en": "Originally published:",
  "fr": "Originally published:",
  "de": "Originally published:",
  "es": "Originally published:",
  "pt-br": "Originally published:",
  "it": "Originally published:",
  "ja": "Originally published:",
  "ko": "Originally published:",
  "nl": "Originally published:",
  "pt-pt": "Originally published:",
  "sv": "Originally published:",
  "zh": "Originally published:"
}