import { BaseAPI } from "./baseAPI";

interface GetSpecialOffersParams {
  offerToken: string;
  email: string;
  browserUuid: string;
  language: string;
  userAgent?: string;
}
/**
 * Special Offer library
 */
export default class SpecialOffer extends BaseAPI {
  getSpecialOffer(params: GetSpecialOffersParams, partnerWebservice?: string) {
    const method = partnerWebservice ?? "Redeem";

    return this.postData({
      apiName: "partnership",
      apiMethod: method,
      payload: { ...params },
    });
  }
}
