import React, { PropsWithChildren } from "react";
import cn from "classnames";

import { ButtonsLayout } from "@/components/blocks/ButtonsBlock";

interface Props {
  id?: string;
  layout: ButtonsLayout;
}

export default function ButtonGroup({
  children,
  id,
  layout,
}: PropsWithChildren<Props>) {
  const parsedLayout = typeof layout === "string" ? JSON.parse(layout) : layout;

  return (
    <>
      <div
        id={id}
        className={cn(
          "my-3 flex",
          parsedLayout?.flexWrap === "wrap" ? "flex-wrap" : "flex-nowrap",
          parsedLayout?.orientation === "vertical" ? "flex-col" : "flex-row",
          parsedLayout?.justifyContent === "left" && " justify-start",
          parsedLayout?.justifyContent === "center" && " justify-center",
          parsedLayout?.justifyContent === "right" && " justify-end",
          parsedLayout?.justifyContent === "space-between" && "justify-between",
          parsedLayout?.verticalAlignment === "top" && "items-start",
          parsedLayout?.verticalAlignment === "middle" && "items-center",
          parsedLayout?.verticalAlignment === "bottom" && "items-end"
        )}
      >
        {children}
      </div>
    </>
  );
}
