import { Blocks } from "@/components/molecules";
import { MediaText } from "@/components/organisms";
import { Block, Style } from "@/types";
import formatFocalPoint from "@/utilities/formatFocalPoint";
import getBlockStyles from "@/utilities/getBlockStyles";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor?: string;
  backgroundColorHex?: string;
  className?: string;
  focalPoint: {
    x: string;
    y: string;
  };
  gradientHex?: string;
  imageFill?: boolean;
  innerBlocks: Block[];
  isStackedOnMobile?: boolean;
  mediaAlt: string;
  mediaPosition?: "left" | "right";
  mediaUrl: string;
  mediaWidth?: number;
  style?: Style;
  textColorHex?: string;
  verticalAlignment?: string;
}

export default function BlockMediaText({
  anchor,
  backgroundColorHex,
  className,
  focalPoint,
  gradientHex,
  imageFill,
  innerBlocks,
  isStackedOnMobile,
  mediaAlt,
  mediaPosition = "left",
  mediaUrl,
  mediaWidth,
  style,
  textColorHex,
  verticalAlignment,
}: Props) {
  const mediaTextStyle = getBlockStyles({
    backgroundColorHex,
    gradientHex,
    textColorHex,
    style,
  });

  // Add additional styles.
  const gridTemplateColumns =
    mediaPosition === "left" ? `${mediaWidth}% 1fr` : `1fr ${mediaWidth}%`;
  Object.assign(mediaTextStyle, { gridTemplateColumns });

  const newFocalPoint = imageFill ? formatFocalPoint(focalPoint) : null;

  return (
    <>
      {!!mediaUrl && innerBlocks?.length && (
        <MediaText
          className={className}
          focalPoint={newFocalPoint}
          id={anchor}
          image={{ url: mediaUrl, alt: mediaAlt }}
          imageFill={imageFill}
          mediaLeft={mediaPosition === "left" ? true : false}
          style={mediaTextStyle}
          stackOnMobile={isStackedOnMobile}
          verticalAlignment={verticalAlignment}
        >
          <Blocks blocks={innerBlocks} />
        </MediaText>
      )}
    </>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreMediaText";

BlockMediaText.displayName = BLOCK_NAME;

BlockMediaText.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          backgroundColor
          className
          focalPoint
          href
          imageFill
          isStackedOnMobile
          mediaAlt
          mediaId
          mediaLink
          mediaPosition
          mediaSizeSlug
          mediaType
          mediaUrl
          mediaWidth
          metadata
          rel
          style
          textColor
          verticalAlignment
        }
      }
    }
  `,
};
