import { useEffect } from "react";
import TagManager, { TagManagerArgs } from "react-gtm-module";

interface Props {
  customEvent?: string;
}

let didInit = false;

function GTM({ customEvent }: Props) {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: "GTM-5BB7G3",
    dataLayer: {
      page_type: "ripley",
      event: customEvent,
    },
  };

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      // ✅ Only runs once per app load
      TagManager.initialize(tagManagerArgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default GTM;
