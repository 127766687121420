import {
  Block as BlockType,
  Block,
  MenuItems,
  PageData,
  Translated,
} from "@/types";
import { locales } from "@/constants/locales";
import { JumpLinkInterface } from "@/components/molecules/JumpLinkSubnav";
import { BannerInterface } from "@/components/molecules/Banner";
import { parseBlocks } from "@/utilities/parseBlocks";
import { EnhancedMenuItems } from "@/types/Menu";

type PostDataFromProps = {
  translated: Translated[];
  siteTitle: string;
  siteDescription: string;
  uri: string;
  isFrontPage: boolean;
  primaryMenu?: MenuItems;
  footerMenu: MenuItems;
  newMenuItems?: EnhancedMenuItems;
  content: string;
  seo: { fullHead: string };
  editorBlocks: BlockType[];
  contentType: string;
  jumpLinks: Array<JumpLinkInterface>;
  banner?: Array<{
    attributes: BannerInterface;
  }>;
};

export const getPostDataFromProps = (
  props: PageData,
  type: "post" | "page" = "post"
): PostDataFromProps => {
  // Check if this is the english and if it has translations.
  const uri_in_english =
    // @ts-ignore
    props.__SEED_NODE__?.language?.locale === "en_US"
      ? // @ts-ignore
        props.__SEED_NODE__?.uri
      : props.__SEED_NODE__?.translations?.find(
          (each: Translated) => each.language?.locale === "en_US"
        )?.uri;
  const translated =
    props.__SEED_NODE__?.translations
      .filter((each: Translated) => {
        return each.status === "publish";
      })
      .map((each: Translated) => {
        const each_item = { ...each };
        if (each.language?.locale !== "en_US") {
          each_item.uri = `/${locales[each.language?.locale]}${uri_in_english}`;
        }
        return each_item;
      }) ?? [];
  const { title: siteTitle, description: siteDescription } =
    props.data.generalSettings;
  const primaryMenu = props.data.headerMenuItems?.nodes;
  const footerMenu = props.data.footerMenuItems.nodes;
  const newMenuItems = props.data.newMenuItems?.nodes;

  // @ts-ignore
  const pageData = props.data[type];
  const { content, seo, isFrontPage, uri } = pageData;
  const editorBlocks = parseBlocks(pageData.editorBlocks);

  const contentType = pageData.contentType.node.name;

  const jumpLinks = editorBlocks.filter(
    (each: Block) => each.name === "tenup/jump-link-section"
  );

  const banner = editorBlocks.filter(
    (each: Block) => each.name === "tenup/banner"
  );

  return {
    translated,
    siteTitle,
    siteDescription,
    primaryMenu,
    footerMenu,
    newMenuItems,
    content,
    seo,
    editorBlocks,
    contentType,
    jumpLinks,
    banner,
    uri,
    isFrontPage,
  };
};
