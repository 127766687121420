import cn from "classnames";
import {
  backgroundInverseLight,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { RichText } from "@/components/atoms";
import Link from "next/link";

export interface ToggleInterface {
  linkText: string;
  linkUrl: string;
  ga4Id?: string;
}

export interface TogglesInterface {
  linkOne: ToggleInterface;
  linkTwo: ToggleInterface;
  active: number;
}

export interface ToggleButtonsInterface {
  toggle: TogglesInterface;
}

export default function ToggleButtons({ toggle }: ToggleButtonsInterface) {
  const toggleItemClassNames =
    "inline-block h-10 w-[164px] leading-10 text-center align-middle rounded-full hover:no-underline";

  const { active, linkOne, linkTwo } = toggle;

  const toggleItemOne = cn(toggleItemClassNames, {
    [textHeadlineInverse]: active === 1,
    [textHeadlineNeutral]: active !== 1,
    [backgroundInverseLight]: active === 1,
  });
  const toggleItemTwo = cn(toggleItemClassNames, {
    [textHeadlineInverse]: active === 2,
    [textHeadlineNeutral]: active !== 2,
    [backgroundInverseLight]: active === 2,
  });

  function getGA4DataAttribute(linkData: ToggleInterface) {
    return linkData.ga4Id ? { "data-item": linkData.ga4Id } : {};
  }

  return (
    <div className="mb-4 flex justify-center md:mb-12">
      <div className="mx-auto inline-block rounded-full bg-blue-1 p-1.5">
        {active === 1 ? (
          <RichText
            className={toggleItemOne}
            tag="span"
            attributes={getGA4DataAttribute(linkOne)}
          >
            {linkOne.linkText}
          </RichText>
        ) : (
          <Link
            className={toggleItemOne}
            href={linkOne.linkUrl}
            {...getGA4DataAttribute(linkOne)}
          >
            {linkOne.linkText}
          </Link>
        )}
        {active === 2 ? (
          <RichText
            className={toggleItemTwo}
            tag="span"
            attributes={getGA4DataAttribute(linkTwo)}
          >
            {linkTwo.linkText}
          </RichText>
        ) : (
          <Link
            className={toggleItemTwo}
            href={linkTwo.linkUrl}
            {...getGA4DataAttribute(linkTwo)}
          >
            {linkTwo.linkText}
          </Link>
        )}
      </div>
    </div>
  );
}
