const freeEmailDomains = [
  "mailinator",
  "gmail",
  "aol",
  "outlook",
  "zoho",
  "mail",
  "yahoo",
  "protonmail",
  "icloud",
  "gmx",
  "mozilla",
  "yandex",
  "hotmail",
  "msn",
  "wanadoo",
  "comcast",
  "ymail",
  "sbcglobal",
  "rocketmail",
  "me",
  "mac",
];

export const isFreeEmail = (email?: string, error?: string) => {
  if (!email || email.length < 5 || error) {
    return false;
  }
  const lowerCaseEmail = email.toLowerCase();
  const emailAddress = lowerCaseEmail.match(/.+@(.+)/) ?? [];
  const emailDomain =
    emailAddress.length > 0 ? emailAddress[1]?.split(".")[0] : "";
  if (
    lowerCaseEmail.startsWith("kw_test") &&
    (lowerCaseEmail.endsWith("@mailinator.com") ||
      lowerCaseEmail.endsWith("@yopmail.com"))
  ) {
    return false;
  }
  return freeEmailDomains.some((domain) => emailDomain === domain);
};
