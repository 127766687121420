import cn from "classnames";
import { RichText } from "@/components/atoms";
import {
  textBodyInverse,
  textBodyNeutral,
  marginBMd,
  pTextL,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import LottieEmbed from "../molecules/LottieEmbed";
import CustomHeading, { HeadingOptions } from "../molecules/CustomHeading";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { Blocks } from "../molecules";
import { Block } from "@/types";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  index: number;
  variant: string;
  includeAboveHeading?: boolean;
  includeContent?: boolean;
  includeSecondaryCTA?: boolean;
  includeLinkCTA?: boolean;
  aboveHeading?: string;
  aboveHeadingType?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  button?: CustomButtonInterface;
  contentType: string;
  innerBlocks?: Block[];
  animation: object;
}

export default function HeroRTBSectionBlock({
  index,
  includeAboveHeading = false,
  includeContent = false,
  includeSecondaryCTA = false,
  includeLinkCTA = false,
  aboveHeading = "",
  aboveHeadingType = "text",
  heading = "",
  headingOptions = {
    tag: 1,
  },
  content = "",
  variant = "white",
  button,
  contentType,
  innerBlocks,
  animation,
}: Props) {
  const wrapClassNames = cn("flex flex-col justify-center md:h-[680px]");

  const headingClassNames = cn(marginBMd, "text-4xl md:text-6xl font-medium", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant === "light",
  });

  const features = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;

        if (each.attributes.headingOptions === undefined) {
          each.attributes.headingOptions = {};
        }
        each.attributes.headingOptions.tag = headingOptions.tag + 1;
        return each;
      })}
    />
  ) : null;

  const buttons = [
    {
      ...button,
      variant: variant,
      textOnly: true,
      arrow: true,
      size: "md",
    },
  ];

  return (
    <section className={wrapClassNames} data-target={index}>
      <div className="flex flex-col md:mt-10">
        {includeAboveHeading && aboveHeading && aboveHeadingType === "text" && (
          <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
        )}
        <CustomHeading
          heading={heading}
          headingClassNames={headingClassNames}
          headingOptions={headingOptions}
        />
        {includeContent && content && (
          <RichText
            className={cn({
              [textBodyInverse]: variant === "dark",
              [textBodyNeutral]: variant !== "dark",
            })}
            tag="p"
          >
            {content}
          </RichText>
        )}
        {includeSecondaryCTA && <div className="mt-8">{features}</div>}
        {includeLinkCTA && (
          <CustomButtonGroup
            marginTop="mt-2.5"
            className={pTextL}
            align="left"
            buttons={buttons}
            mobileWide={false}
          />
        )}
      </div>
      {animation && (
        <div
          className={`animation-wrap w-full transition-opacity duration-700 ease-linear md:hidden`}
        >
          <LottieEmbed aspect="1:1" url={animation} className="w-full" />
        </div>
      )}
    </section>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupHeroRtbSection";

HeroRTBSectionBlock.displayName = BLOCK_NAME;

HeroRTBSectionBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          aboveHeadingType
          button
          className
          content
          heading
          headingOptions
          includeAboveHeading
          includeContent
          includeLinkCTA
          includeSecondaryCTA
          media
          metadata
        }
      }
    }
  `,
};
