import React from "react";
import cn from "classnames";
import Link from "next/link";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { PostPreviewSection } from "@/components/organisms";
import type { Category, OffsetPagination, PostPreview } from "@/types";
import { useTranslate } from "@/hooks/useTranslate";
import { ARCHIVE_NO_RESULTS } from "@/i18n/translations/ARCHIVE_NO_RESULTS";
import Pagination from "@/components/molecules/Pagination";
import ConditionalWrapper from "@/components/molecules/ConditionalWrapper";
import { innerContainer } from "@/constants/standardCSSClasses";
import { ArchiveInserts } from "@/types";

interface Props {
  categories?: Category[];
  description?: string;
  inserts?: ArchiveInserts;
  heading: string;
  posts?: PostPreview[];
  hidePostSection?: boolean;
  variables: {
    authorId?: number;
    categoryName?: string;
    tagName?: string;
    locale: string;
  };
  offsetPagination?: OffsetPagination;
  loading?: boolean;
  readMore?: boolean;
  paginationType?: "default" | "queryParam";
  hero?: boolean;
}

export default function Archive({
  description,
  inserts,
  heading,
  offsetPagination,
  loading = false,
  readMore = true,
  categories,
  posts,
  hidePostSection,
  paginationType = "default",
  hero = false,
}: Props) {
  const { translate } = useTranslate();
  const { page = 1, pages = 1 } = offsetPagination || {};

  return (
    <ConditionalWrapper
      wrapper={(children: React.ReactNode) => <div>{children}</div>}
      condition={!hero}
    >
      {categories && (
        <div
          className={cn(innerContainer, "mt-16 flex flex-wrap")}
          role="group"
        >
          {categories.map((category) => {
            const { uri, name } = category;
            return (
              <Link
                href={uri}
                key={name}
                className="mb-2 mr-2 rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:no-underline"
              >
                {name}
              </Link>
            );
          })}
        </div>
      )}
      <PostPreviewSection
        hero={hero}
        description={description}
        inserts={inserts}
        heading={heading}
        posts={posts}
        hidePostSection={hidePostSection}
        readMore={readMore}
        loading={loading}
      />
      {!hidePostSection && (
        <ConditionalWrapper
          wrapper={(children: React.ReactNode) => (
            <div className={innerContainer}>{children}</div>
          )}
          condition={hero}
        >
          {posts && posts.length === 0 && !loading && (
            <ConditionalWrapper
              wrapper={(children: React.ReactNode) => (
                <div className={innerContainer}>{children}</div>
              )}
              condition={!hero}
            >
              <div
                className="flex items-center justify-start rounded-lg border border-blue-bold bg-blue-light p-4 text-lg text-blue-bold"
                role="alert"
              >
                <ExclamationCircleIcon
                  className="mr-4 inline h-8 w-8 align-baseline"
                  aria-hidden="true"
                />{" "}
                {translate(ARCHIVE_NO_RESULTS)}
              </div>
            </ConditionalWrapper>
          )}
          <ConditionalWrapper
            wrapper={(children: React.ReactNode) => (
              <div className={innerContainer}>{children}</div>
            )}
            condition={!hero}
          >
            <Pagination
              total={pages}
              current={page || 1}
              base={""}
              className="mb-16"
              paginationType={paginationType}
            />
          </ConditionalWrapper>
        </ConditionalWrapper>
      )}
      {inserts?.afterPagination}
    </ConditionalWrapper>
  );
}
