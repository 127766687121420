import { gql } from "@apollo/client";

import * as MENUS from "@/constants/menus";
import {
  BlogInfoFragment,
  MenuItemFragment,
  PreviewFragment,
} from "@/fragments";
import { Main } from "@/components/atoms";
import { Hero, PreviewLoader, SEO } from "@/components/molecules";
import { Footer, PostPreviewSection } from "@/components/organisms";
import type {
  GeneralSettings,
  Locale,
  PostPreview,
  Seo,
  Translated,
} from "@/types";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import NewMenuItemFragment from "@/fragments/NewMenuItem";
import DynamicHeader from "@/components/organisms/DynamicHeader";
import {
  GetEditorBlocksFragments,
  GetEditorBlocksKeys,
} from "@/fragments/fragmentFunctions";
import { Block as BlockType } from "@/types";

interface Props {
  // Seed Node isn't called for previews
  __SEED_NODE__?: {
    translated: Translated[];
  };
  data: {
    indexContent: {
      content: string;
      editorBlocks: BlockType[];
      seo: Seo;
    };
    headerMenuItems: { nodes: never[] };
    newMenuItems: { nodes: never[] };
    footerMenuItems: { nodes: never[] };
    generalSettings: GeneralSettings;
    recentPosts: { nodes: PostPreview[] };
    workplaceTips: { nodes: PostPreview[] };
    personalTips: { nodes: PostPreview[] };
    product: { nodes: PostPreview[] };
    hack: { nodes: PostPreview[] };
    dataAndCulture: { nodes: PostPreview[] };
  };
  loading: boolean;
  locale: Locale;
}

export default function Component(props: Props) {
  // Needed for previews to load on the client
  if (props.loading) {
    return <PreviewLoader />;
  }

  let translated: Translated[] = [];
  if (props.__SEED_NODE__?.translated) {
    // Front page doesn't have en included in translated,
    // so we add en and remove current locale manually
    translated = props.__SEED_NODE__?.translated;
    if (props.locale !== "en") {
      translated = translated.filter((item) => item.uri !== `/${props.locale}`);
      translated.unshift({
        uri: "/en",
        language: { locale: "en_US" },
        status: "publish",
      });
    }
    // TODO: Add a blank translation for ES in wordpress and then remove this code
    if (props.locale !== "es") {
      translated = translated.filter((item) => item.uri !== `/${props.locale}`);
      translated.push({
        uri: "/es",
        language: { locale: "es_ES" },
        status: "publish",
      });
    }
  }

  const { title: siteTitle, description: siteDescription } =
    props.data.generalSettings;
  const primaryMenu = props.data.headerMenuItems.nodes;
  const newMenuItems = props.data.newMenuItems.nodes;
  const footerMenu = props.data.footerMenuItems.nodes;
  const { seo } = props.data.indexContent;
  const isEnglish = props.locale === "en";

  return (
    <>
      <SEO fullHead={seo.fullHead} />
      <DynamicHeader
        title={siteTitle}
        description={siteDescription}
        menuItems={primaryMenu}
        enhancedMenuItems={newMenuItems}
        availableLocales={translated}
      />
      <Main className="w-full">
        {isEnglish && (
          <Hero
            content={props.data.indexContent.editorBlocks}
            image={"/images/blog-header.png"}
          />
        )}
        {props.data.recentPosts.nodes && isEnglish && (
          <PostPreviewSection
            className="bg-neutral-200 py-14 lg:py-28"
            heading="Most recent posts"
            headingColor={"midgreen"}
            hideImage
            level="h2"
            posts={props.data.recentPosts.nodes}
            moreLink="/most-recent-posts"
          />
        )}
        {props.data.workplaceTips.nodes && isEnglish && (
          <PostPreviewSection
            className="bg-white py-14 lg:py-28"
            heading={
              props.data.workplaceTips.nodes[0]?.categories.nodes[0]?.name
            }
            headingColor="orange"
            level="h2"
            posts={props.data.workplaceTips.nodes}
            hideImage
            topBorderColor="orange"
          />
        )}
        {props.data.personalTips.nodes && !isEnglish && (
          <PostPreviewSection
            className="bg-white py-14 lg:py-28"
            heading={
              props.data.personalTips.nodes[0]?.categories.nodes[0]?.name
            }
            headingColor="orange"
            level="h2"
            posts={props.data.personalTips.nodes}
            topBorderColor="orange"
            hideExcerpt
          />
        )}
        {props.data.product.nodes && (
          <PostPreviewSection
            className="bg-neutral-200 py-14 lg:py-28"
            heading={
              isEnglish
                ? "Product 101"
                : props.data.product.nodes[0]?.categories.nodes[0]?.name
            }
            headingColor={"midgreen"}
            level="h2"
            posts={props.data.product.nodes}
            hideExcerpt
            moreLink={"/category/dashlane/product-tips"}
          />
        )}
        {props.data.hack.nodes.length > 0 && isEnglish && (
          <PostPreviewSection
            className="bg-white py-14 lg:py-28"
            heading={props.data.hack.nodes[0]?.categories.nodes[0]?.name}
            headingColor={"pink"}
            level="h2"
            posts={props.data.hack.nodes}
            hideExcerpt
          />
        )}
        {props.data.dataAndCulture.nodes && isEnglish && (
          <PostPreviewSection
            className="bg-neutral-200 py-14 lg:py-28"
            heading={
              props.data.dataAndCulture.nodes[0]?.categories.nodes[0]?.name
            }
            headingColor={"midgreen"}
            hideExcerpt
            level="h2"
            posts={props.data.dataAndCulture.nodes}
          />
        )}
      </Main>
      <Footer
        title={siteTitle}
        menuItems={footerMenu}
        availableLocales={translated}
        locale={props.locale}
      />
    </>
  );
}

Component.query = gql`
  ${BlogInfoFragment}
  ${MenuItemFragment}
  ${NewMenuItemFragment}
  ${PreviewFragment}
  ${GetEditorBlocksFragments()}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $footerLocation: MenuLocationEnum
    $newMenuLocation: MenuLocationEnum
    $asPreview: Boolean
    $locale: LanguageCodeFilterEnum = EN
  ) {
    indexContent: page(
      id: $databaseId
      idType: DATABASE_ID
      asPreview: $asPreview
    ) {
      id
      content
      ${GetEditorBlocksKeys()}
      seo {
        fullHead
      }
    }
    recentPosts: posts(where: { language: $locale }, first: 6) {
      nodes {
        ...PreviewFragment
      }
    }
    workplaceTips: posts(
      where: { language: $locale, categoryName: "workplace-tips" }
      first: 6
    ) {
      nodes {
        ...PreviewFragment
      }
    }
    personalTips: posts(
      where: { language: $locale, categoryName: "personal-tips" }
      first: 6
    ) {
      nodes {
        ...PreviewFragment
      }
    }
    product: posts(
      where: { language: $locale, categoryName: "product-tips" }
      first: 3
    ) {
      nodes {
        ...PreviewFragment
      }
    }
    hack: posts(
      where: { language: $locale, categoryName: "what-the-hack" }
      first: 6
    ) {
      nodes {
        ...PreviewFragment
      }
    }
    dataAndCulture: posts(
      where: { language: $locale, categoryName: "data-and-culture" }
      first: 3
    ) {
      nodes {
        ...PreviewFragment
      }
    }
    generalSettings {
      ...BlogInfoFragment
    }
    headerMenuItems: menuItems(
      where: { location: $headerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    footerMenuItems: menuItems(
      where: { location: $footerLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...MenuItemFragment
      }
    }
    newMenuItems: menuItems(
      where: { location: $newMenuLocation, language: $locale }
      first: ${MENUS.NUMBER_OF_MENU_ITEMS}
    ) {
      nodes {
        ...NewMenuItemFragment
      }
    }
  }
`;

Component.variables = (
  { databaseId }: { databaseId: string },
  { asPreview, locale }: { asPreview?: boolean; locale: Locale }
) => {
  return {
    asPreview,
    databaseId,
    footerLocation: MENUS.newFooterLocation(locale),
    headerLocation: MENUS.newHeaderLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
  };
};
