import cn from "classnames";
import {
  backgroundNeutral,
  borderInverse,
  borderNeutral,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { Blocks } from "@/components/molecules/index";
import { Block } from "@/types";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import React from "react";

interface Props {
  columns: Block[];
  columnCount: number;
  name: string;
  headingOptions?: HeadingOptions;
  variant: string;
  number: number;
}

export default function PricingGroup({
  columns,
  columnCount,
  name,
  headingOptions = {
    tag: 3,
  },
  variant,
  number,
}: Props) {
  const groupClassNames = cn("w-full lg:flex lg:flex-col", {
    "mt-12 lg:ms-12 lg:mt-0": number === 2,
    "lg:w-1/2": columnCount === 4,
    "lg:w-2/5": columnCount === 5 && number === 1,
    "lg:w-3/5": columnCount === 5 && number === 2,
  });

  const nameClassNames = cn("font-medium text-lg m-0", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const nameWrapClassNames = cn(
    "border border-solid px-8 py-4 w-full rounded-ss-lg rounded-se-lg",
    backgroundNeutral,
    {
      [borderInverse]: variant === "dark",
      [borderNeutral]: variant !== "dark",
    }
  );

  const pricingColumns = columns?.length ? <Blocks blocks={columns} /> : null;

  return (
    <div className={groupClassNames}>
      <div className={nameWrapClassNames}>
        <CustomHeading
          heading={name}
          headingOptions={headingOptions}
          headingClassNames={nameClassNames}
        />
      </div>
      <div className="w-full items-stretch md:flex lg:grow">
        {pricingColumns}
      </div>
    </div>
  );
}
