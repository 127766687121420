import { RichText } from "@/components/atoms";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  content: string;
}

export default function ListItemBlock({ content }: Props) {
  return <RichText tag="li">{content}</RichText>;
}

// Must match __typename
const BLOCK_NAME = "CoreListItem";

ListItemBlock.displayName = BLOCK_NAME;

ListItemBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          content
        }
      }
    }
  `,
};
