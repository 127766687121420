import cn from "classnames";
import { MarketoFormFieldAny } from "@/types";
import { RichText } from "@/components/atoms";

export const getFieldWrapClasses = (field: MarketoFormFieldAny) => {
  return cn("flex flex-col items-start gap-1.5 relative", {
    "col-span-2": field.width === 100,
    "col-span-2 md:col-span-1": field.width === 50,
  });
};

export const getRequired = (field: MarketoFormFieldAny) => {
  if (!field.required) return null;

  return <span className="ml-2 inline-block text-red-danger">*</span>;
};

export const getFieldClasses = () => {
  return cn("w-full text-gray-800");
};

export const getErrorMessages = (
  field: MarketoFormFieldAny,
  errors: object
) => {
  if (
    // @ts-ignore
    !errors[field.name]?.message
  )
    return null;

  return (
    <div className="text-sm text-red-danger">
      <RichText className="text-sm text-red-danger" tag="div">
        {/* @ts-ignore */}
        {errors[field.name]?.message}
      </RichText>
    </div>
  );
};
