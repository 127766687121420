export const ABOUT_CAREERS_ERROR_MESSAGE = {
  "en": "Unable to load data. Please try again later.",
  "fr": "Impossible de charger les données. Veuillez réessayer ultérieurement.",
  "de": "Daten können nicht geladen werden. Bitte versuchen Sie es später erneut.",
  "es": "No se pueden cargar los datos. Inténtelo nuevamente más tarde.",
  "pt-br": "Unable to load data. Please try again later.",
  "it": "Unable to load data. Please try again later.",
  "ja": "Unable to load data. Please try again later.",
  "ko": "Unable to load data. Please try again later.",
  "nl": "Unable to load data. Please try again later.",
  "pt-pt": "Unable to load data. Please try again later.",
  "sv": "Unable to load data. Please try again later.",
  "zh": "Unable to load data. Please try again later."
}