import { externalLinks } from "@/constants/externalLinks";
import { MarketoFormValues } from "@/types";
import { isFreeEmail } from "@/utilities/isFreeEmail";

const addQueryParameters = (url: string, params: { [key: string]: string }) => {
  const queryString = Object.keys(params)
    .map(
      // @ts-ignore
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  if (url.includes("?")) {
    return `${url}&${queryString}`;
  } else {
    return `${url}?${queryString}`;
  }
};

interface RedirectProps {
  data: MarketoFormValues;
  locale?: string;
  pathname?: string;
  calendarLink?: string;
  redirectUrl?: string;
  formId?: number;
  email?: string;
}

export const getFormRedirectUrl = (props: RedirectProps) => {
  const { data, locale, pathname, calendarLink, redirectUrl, formId } = props;

  const {
    contactFormReason,
    Inbound_Company_Size__c: inboundCompanySize,
    product_plan__c: plan,
    Email: email,
  } = data;

  if (contactFormReason === "Talk to Support")
    return externalLinks.supportTicket.url;

  const fullRedirectUrl = redirectUrl
    ? redirectUrl
    : locale === "en" || !locale
    ? `${pathname}/success`
    : `/${locale}${pathname}/success`;

  const params: { [key: string]: string } = {};

  if (calendarLink) {
    params["calendarLink"] = calendarLink;
  }
  if (inboundCompanySize !== undefined) {
    params["inboundCompanySize"] = inboundCompanySize;
    params["companySize"] =
      inboundCompanySize !== "1-20" && inboundCompanySize !== "21-50"
        ? "over50"
        : "under50";
  }
  if (plan !== undefined) {
    params["plan"] = plan;
  }
  if (formId !== undefined) {
    params["formId"] = formId.toString();
  }
  if (email !== undefined) {
    const testEmail = email ? email.includes("kw_test") : false;
    const hasFreeEmail = isFreeEmail(email);
    if (!hasFreeEmail && !testEmail) {
      params["emailType"] = "work";
    } else if (testEmail) {
      params["emailType"] = "kw_test";
    }
  }
  if (pathname) {
    params["from"] = pathname;
  }

  return addQueryParameters(fullRedirectUrl, params);
};
