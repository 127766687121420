import { gql } from "@apollo/client";
import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import { CustomImageInterface } from "../molecules/CustomImage";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  h1TextLarge,
  h1Text,
  textBodyInverse,
  pTextL,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import SecondaryCTA, {
  SecondaryCTAInterface,
} from "@/components/molecules/SecondaryCTA";
import AboveHeadingChip, {
  AboveHeadingChipInterface,
} from "@/components/molecules/AboveHeadingChip";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeSecondaryCTA?: boolean;
  aboveHeading?: string;
  aboveHeadingType?: string;
  aboveHeadingChip?: AboveHeadingChipInterface;
  heading?: string;
  headingSize?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  size?: string;
  imageAlign?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  image?: CustomImageInterface;
  contentType: string;
  media?: MediaObjectInterface;
  secondaryCTA?: SecondaryCTAInterface;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function HeroBlock({
  includeAboveHeading = false,
  includeBelowButtons = false,
  includeButtons = false,
  includeContent = false,
  includeSecondaryCTA = false,
  aboveHeading = "",
  aboveHeadingType = "text",
  aboveHeadingChip,
  heading = "",
  headingSize = "lg",
  headingOptions = {
    tag: 1,
  },
  content = "",
  variant = "white",
  size = "lg",
  imageAlign = "right",
  buttonsNumber = 0,
  buttonOne,
  buttonTwo,
  belowButtons = "",
  image = {
    full: "",
    alt: "",
  },
  media = {
    type: "image",
    media: {},
  },
  secondaryCTA,
  contentType,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  const headingClassNames = cn(marginBMd, {
    [h1TextLarge]: size === "lg" && headingSize === "lg",
    [h1Text]:
      size === "md" || size === "sm" || (size === "lg" && headingSize === "md"),
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant === "light",
  });

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      constrain
      {...blockContentProps}
    >
      <div className={`lg:grid lg:grid-cols-2 lg:items-center lg:gap-20`}>
        <div>
          {includeAboveHeading &&
            aboveHeading &&
            aboveHeadingType === "text" && (
              <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
            )}
          {includeAboveHeading &&
            aboveHeadingChip &&
            aboveHeadingType === "chip" && (
              <AboveHeadingChip
                aboveHeadingChip={aboveHeadingChip}
                className={""}
                variant={variant}
              />
            )}
          <CustomHeading
            heading={heading}
            headingClassNames={headingClassNames}
            headingOptions={headingOptions}
          />
          {includeContent && content && (
            <RichText
              className={cn({
                [textBodyInverse]: variant === "dark",
                [textBodyNeutral]: variant !== "dark",
                [pTextL]: size === "lg",
              })}
              tag="p"
            >
              {content}
            </RichText>
          )}
          {includeButtons && (
            <CustomButtonGroup align="left" buttons={buttons} />
          )}
          {includeBelowButtons && (
            <BelowButtonsText variant={variant}>
              {belowButtons}
            </BelowButtonsText>
          )}
        </div>
        {size !== "sm" && (
          <div
            className={cn("mt-8 md:mt-0", {
              "order-first": imageAlign === "left",
            })}
          >
            <CustomMedia
              media={media ?? { media: {}, type: "image" }}
              aspect={size === "lg" ? "1:1" : "3:2"}
              fallbackImage={image}
            />
          </div>
        )}
      </div>
      {includeSecondaryCTA && secondaryCTA && (
        <SecondaryCTA
          secondaryCTA={secondaryCTA}
          variant={variant}
          className={""}
        />
      )}
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupHero";

HeroBlock.displayName = BLOCK_NAME;

HeroBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          aboveHeadingChip
          aboveHeadingType
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          ga4SectionId
          heading
          headingOptions
          headingSize
          image
          imageAlign
          includeAboveHeading
          includeBelowButtons
          includeButtons
          variant
          size
          secondaryCTA
          metadata
          media
          lock
          includeSecondaryCTA
          includeContent
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
