export const RECOMMENDED_POSTS_CTA = {
  "en": "You may also like",
  "fr": "Articles suggérés",
  "de": "Dies könnte Ihnen auch gefallen",
  "es": "También le puede gustar",
  "pt-br": "You may also like",
  "it": "You may also like",
  "ja": "You may also like",
  "ko": "You may also like",
  "nl": "You may also like",
  "pt-pt": "You may also like",
  "sv": "You may also like",
  "zh": "You may also like"
}