export const PRIVACY_CONSENT_CLOSE = {
  "en": "Close",
  "fr": "Fermer",
  "de": "Schließen",
  "es": "Cerrar",
  "pt-br": "Close",
  "it": "Close",
  "ja": "Close",
  "ko": "Close",
  "nl": "Close",
  "pt-pt": "Close",
  "sv": "Close",
  "zh": "Close"
}