import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  marginBLg,
  h3Text,
} from "@/constants/standardCSSClasses";
import CardGroup from "@/components/molecules/CardGroup";
import { CardInterface } from "@/components/molecules/Card";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeCardButtons?: boolean;
  includeStars?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  headingSize?: string;
  content?: string;
  variant?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  width?: string;
  border?: boolean;
  cardsNumber?: number;
  columns?: number;
  cards: Array<CardInterface>;
  imageSize?: string;
  contentType: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function ImageCardsBlock({
  includeAboveHeading = true,
  includeButtons = true,
  includeContent = true,
  includeCardButtons = true,
  includeBelowButtons = false,
  aboveHeading,
  heading,
  headingOptions = {
    tag: 2,
  },
  content,
  belowButtons = "",
  variant = "dark",
  border = true,
  cardsNumber = 4,
  columns = 2,
  buttonOne,
  buttonTwo,
  cards,
  buttonsNumber = 2,
  imageSize,
  contentType,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const headerClassNames = cn("md:text-center", marginBMd, h3Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const pTextClassNames = cn("md:text-center", marginBLg, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      defaultValue="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className="md:mx-auto md:max-w-xl">
        {includeAboveHeading && (
          <AboveHeading className="md:text-center" variant={variant}>
            {aboveHeading}
          </AboveHeading>
        )}
        <CustomHeading
          heading={heading}
          headingOptions={headingOptions}
          headingClassNames={headerClassNames}
        />
        {includeContent && (
          <RichText className={pTextClassNames} tag="p">
            {content}
          </RichText>
        )}
      </div>
      {cards && cards.length && (
        <CardGroup
          columns={columns}
          cardsNumber={cardsNumber}
          cards={cards.map((each) => {
            each.imageAspect = imageSize === "lg" ? "3:2" : "2:1";
            each.border = border;
            each.includeCardButtons = includeCardButtons;
            each.variant = variant;
            each.visual = "image";

            if (each.headingOptions === undefined) {
              each.headingOptions = { tag: headingOptions.tag + 1 };
            } else {
              each.headingOptions.tag = headingOptions.tag + 1;
            }

            return each;
          })}
        />
      )}
      {includeButtons && <CustomButtonGroup align="center" buttons={buttons} />}
      {includeBelowButtons && (
        <BelowButtonsText centered={true} variant={variant}>
          {belowButtons}
        </BelowButtonsText>
      )}
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupImageCards";

ImageCardsBlock.displayName = BLOCK_NAME;

ImageCardsBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          border
          buttonOne
          buttonTwo
          buttonsNumber
          cards
          cardsNumber
          className
          columns
          content
          ga4SectionId
          heading
          headingOptions
          imageSize
          includeAboveHeading
          includeButtons
          includeCardButtons
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
