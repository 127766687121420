import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

export default function CookiePreferenceBlock() {
  // This block is managed entirely by the parent block, CookiePreferencesBlock
  return null;
}

const BLOCK_NAME = "TenupCookiePreference";

CookiePreferenceBlock.displayName = BLOCK_NAME;

CookiePreferenceBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          content
          className
          cookieType
          heading
          metadata
        }
      }
    }
  `,
};
