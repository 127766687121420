import * as SELECTORS from '@/constants/selectors';
import { SKIP_NAVIGATION_LINK_TITLE } from '@/i18n/translations/SKIP_NAVIGATION_LINK_TITLE';
import { useTranslate } from '@/hooks/useTranslate';

export default function SkipNavigationLink() {
  const { translate } = useTranslate();

  return (
    <a
      className="absolute -top-16 left-0 z-50 h-12 bg-white px-4 py-3 shadow-md shadow-dashgreen focus:top-0  focus:transition-all"
      href={`#${SELECTORS.MAIN_CONTENT_ID}`}
    >
      {translate(SKIP_NAVIGATION_LINK_TITLE)}
    </a>
  );
}
