import { gql, useQuery } from "@apollo/client";

import { PostPreviewSection } from "@/components/organisms";
import { PreviewLoader } from "@/components/molecules";
import { PreviewFragment } from "@/fragments";
import { HeadingLevel } from "@/types";

export const postGridByCategoryQuery = gql`
  ${PreviewFragment}
  query GetPostsByCategory(
    $items: Int
    $locale: LanguageCodeFilterEnum = EN
    $category: String
  ) {
    posts(
      where: { categoryName: $category, language: $locale }
      first: $items
    ) {
      nodes {
        ...PreviewFragment
      }
    }
  }
`;

export interface PostGridByCategoryProps {
  heading: string;
  headingColor?: string;
  headingLevel?: HeadingLevel;
  hideExcerpt?: boolean;
  hideImage?: boolean;
  items?: number;
  locale: string;
  category: string;
  topBorderColor?: string;
}

export default function PostGridByCategory({
  category,
  heading,
  headingColor = "orange",
  headingLevel = "h2",
  hideExcerpt = false,
  hideImage = true,
  items = 3,
  locale,
  topBorderColor,
}: PostGridByCategoryProps) {
  const results = useQuery(postGridByCategoryQuery, {
    variables: {
      category,
      items,
      locale: locale.toUpperCase().replace("-", "_"),
    },
  });

  if (!results.data) {
    return <PreviewLoader />;
  }

  return (
    <PostPreviewSection
      className="bg-white py-14 lg:py-28"
      heading={heading}
      headingColor={headingColor}
      hideImage={hideImage}
      hideExcerpt={hideExcerpt}
      level={headingLevel}
      posts={results?.data?.posts?.nodes}
      topBorderColor={topBorderColor}
    />
  );
}
