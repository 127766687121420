import cn from "classnames";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import Link from "next/link";
import {
  textHeadlineInverse,
  textHeadlineNeutral,
  pTextL,
} from "@/constants/standardCSSClasses";
import { iconPlus } from "@/constants/icons";
import CustomHeading from "@/components/molecules/CustomHeading";
import React from "react";
import ConditionalWrapper from "@/components/molecules/ConditionalWrapper";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  variant: string;
  icon: IconInterface;
  iconStyle: string;
  textStyle: string;
  heading?: string;
  content?: string;
  includeIcons?: boolean;
  link?: {
    url?: string;
    opensInNewTab?: string;
  };
  ga4SectionId?: string;
}

export default function IconFeatureRTBBlock({
  heading = "",
  content = "",
  variant = "white",
  iconStyle = "minimal",
  icon = {
    source: iconPlus,
  },
  includeIcons = true,
  link = {},
  ga4SectionId,
}: Props) {
  const includeLink = !!link.url;

  const headerClassNames = cn(pTextL, "font-medium mr-1", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    [`[&_a]:${textHeadlineInverse}`]: variant === "dark",
    [`[&_a]:${textHeadlineNeutral}`]: variant !== "dark",
    ["underline"]: includeLink,
    [`[&_strong]:font-medium`]: true,
  });

  const iconRichTextMargin = includeIcons ? "ms-4" : "";

  const RTBStrongClassName =
    variant === "dark"
      ? "rtb-icon-feature-strong-dark"
      : "rtb-icon-feature-strong";

  const pTextRTBClassNames = cn(
    "[&_a]:no-underline cursor-pointer text-base leading-5 md:text-lg md:leading-6",
    RTBStrongClassName,
    {
      "[&_a]:text-grey-3 text-grey-3": variant === "dark",
      "[&_a]:text-grey-6 text-grey-6": variant !== "dark",
    }
  );

  if (!icon.source) {
    icon.source = iconPlus;
  }

  const linkWrapper = (children: React.ReactNode) => (
    <Link
      target={link.opensInNewTab ? "_blank" : undefined}
      rel={link.opensInNewTab ? "noreferrer noopener" : undefined}
      href={link.url ?? ""}
      locale={false}
      className="hover:no-underline [&>div>span:first-child]:hover:bg-blue-3"
      {...(ga4SectionId ? { "data-item": ga4SectionId } : {})}
    >
      {children}
    </Link>
  );

  return (
    <ConditionalWrapper condition={includeLink} wrapper={linkWrapper}>
      <div className={"mb-4 flex items-center"}>
        {includeIcons && (
          <Icon
            {...icon}
            variant={variant}
            iconStyle={iconStyle}
            width={heading ? 24 : 20}
          />
        )}
        <div className={cn(iconRichTextMargin, "flex flex-wrap")}>
          {heading && (
            <CustomHeading
              heading={heading}
              headingClassNames={headerClassNames}
            />
          )}
          {content && <p className={pTextRTBClassNames}>{content}</p>}
        </div>
      </div>
    </ConditionalWrapper>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupIconFeatureRtb";

IconFeatureRTBBlock.displayName = BLOCK_NAME;

IconFeatureRTBBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          ga4SectionId
          heading
          icon
          iconStyle
          includeIcons
          link
          metadata
        }
      }
    }
  `,
};
