import classnames from "classnames";
import CustomImage from "../molecules/CustomImage";

export interface ValidationLogoInterface {
  full?: string;
  medium?: string;
  alt?: string;
  variant?: string;
  className?: string;
}

const ValidationLogo = ({
  full = "",
  medium = "",
  alt = "",
  variant = "light",
  className = "",
}: ValidationLogoInterface) => {
  const wrapClassName = classnames("h-10 text-center", className);

  const classNames = classnames("h-10 w-full inline-block", {
    "dark-green-image": variant === "light" || variant === "white",
    "white-image": variant === "dark",
  });

  const imageClassNames = "h-10 !relative";

  return (
    <div className={wrapClassName}>
      <CustomImage
        full={medium || full}
        alt={alt}
        className={classNames}
        objectFit="contain"
        imageClassName={imageClassNames}
        rounded={false}
      />
    </div>
  );
};

export default ValidationLogo;
