const handleIntersect: IntersectionObserverCallback = (entries, _) => {
  entries.forEach((entry) => {
    const bgTarget = entry.target.getAttribute("data-target");
    const animationBlock = entry.target.closest(
      ".animation-block"
    ) as HTMLDivElement;
    const animationContainer = animationBlock.querySelector(
      `.animation-${bgTarget}`
    );

    if (entry.isIntersecting) {
      const allAnimations = animationBlock.querySelectorAll(
        `.animation-holder > *`
      ) as NodeListOf<HTMLDivElement>;

      // Reset all opacity
      allAnimations.forEach((animation) => {
        animation.classList.remove("opacity-100");
        animation.classList.add("opacity-0");
      });

      animationContainer?.classList.add("opacity-100");
    } else {
      animationContainer?.classList.add("opacity-0");
      animationContainer?.classList.remove("opacity-100");
    }
  });
};

export const observeElementIntersection = (identifier: string) => {
  if (!document) return;
  const options: IntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  };

  const observer = new IntersectionObserver(handleIntersect, options);
  const targets = document.querySelectorAll(identifier);

  targets.forEach((target) => observer.observe(target));
};
