import { EnhancedMenuItem } from "@/types/Menu";
import { MenuLocations } from "./mapNavPanelData";

export interface HierarchicalItem {
  id: string;
  databaseId: number;
  title: string | null;
  url: string;
  cssClasses: string[];
  connectedNode: { node: { featuredImage: string } };
  menu: { node: { location: MenuLocations } };
  description: string | null;
  label: string;
  linkRelationship: string | null;
  target: string | null;
  parentId: string | null;
  image: string;
  imageVariant: "transparent" | "dark" | "light";
  isShowMoreLink: "false" | "true";
  ga4Id?: string; 
}

export const sanitizedMenuItemsByLocation = (
  items: EnhancedMenuItem[],
  location: MenuLocations
) => {
  return items.filter((item) =>
    item.menu.node.locations.some((loc) => loc.includes(location))
  );
};
