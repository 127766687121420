export const TRIAL_STEP_1 = {
  "en": "Submit the trial form",
  "fr": "Envoyer le formulaire d'essai",
  "de": "Das Probeformular abschicken",
  "es": "Enviar el formulario de prueba",
  "pt-br": "Submit the trial form",
  "it": "Submit the trial form",
  "ja": "Submit the trial form",
  "ko": "Submit the trial form",
  "nl": "Submit the trial form",
  "pt-pt": "Submit the trial form",
  "sv": "Submit the trial form",
  "zh": "Submit the trial form"
}