import { MarketoFormFieldAny } from "@/types";
import { getFieldWrapClasses } from "@/utilities/marketoForms";
import { RichText } from "@/components/atoms";

interface FooterFieldInterface {
  field: MarketoFormFieldAny;
}

export default function FooterField({ field }: FooterFieldInterface) {
  return (
    <div className={getFieldWrapClasses(field)}>
      <RichText className="text-sm" tag="label">
        {field.label}
      </RichText>
      {field.name && <input type="hidden" name={field.name} value={1} />}
    </div>
  );
}
