import cn from "classnames";
import { useEffect, useState } from "react";
import { useTranslate } from "@/hooks/useTranslate";
import {
  BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_1,
  BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_2,
  BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_3,
  BUSINESS_TRY_EMAILCONFIRMATION_CHECK_TITLE,
  BUSINESS_TRY_EMAILCONFIRMATION_CTA_RESEND,
  BUSINESS_TRY_EMAILCONFIRMATION_CTA_RESENDING,
  BUSINESS_TRY_EMAILCONFIRMATION_CTA_SENT,
  BUSINESS_TRY_EMAILCONFIRMATION_SENT_DESCRIPTION_3,
  BUSINESS_TRY_EMAILCONFIRMATION_ERROR_DESCRIPTION,
  BUSINESS_TRY_EMAILCONFIRMATION_ERROR_TITLE,
} from "@/constants/tempTranslations";
import { RichText } from "@/components/atoms";
import CustomButton from "@/components/molecules/CustomButton";
import React from "react";
import { createBusinessTrial } from "@/utilities/createBusinessTrial";
import { MarketoFormValues } from "@/types";
import { redirectToUrl } from "@/utilities/redirectToUrl";
import { externalLinks } from "@/constants/externalLinks";
import {
  h3Text,
  marginBMd,
  marginTMd,
  marginBSm,
} from "@/constants/standardCSSClasses";

enum PageState {
  Default = "Default",
  Sending = "Sending",
  Sent = "Sent",
  Error = "Error",
  Invalid = "Invalid",
}

interface Props {
  plan: string;
  from: string;
  email: string;
}

export default function TrialFormSuccess(props: Props) {
  const { translate, translateInterpolate, locale } = useTranslate();
  const [pageState, setPageState] = useState(PageState.Default);
  const [formValues, setFormValues] = useState({} as MarketoFormValues);
  const { plan, from, email } = props;

  useEffect(() => {
    const localStorageForm = localStorage.getItem("form-submission-data");

    if (localStorageForm) {
      setFormValues(JSON.parse(localStorageForm));
    }
  }, []);

  // We're checking if the user has already requested to receive the email
  // If yes, he's redirected to the form he comes from
  // This is usefull to avoid having a user refreshing this page and submitting the request again and again
  useEffect(() => {
    const decodedFrom = decodeURIComponent(from);
    const localStorageKey = `${decodedFrom}-EmailResent`;
    const resent = localStorage.getItem(localStorageKey);

    if (resent !== null && resent === "true") {
      localStorage.removeItem(localStorageKey);
      redirectToUrl(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from]);

  // By clicking on the submit button, the user requests to receive the form confirmation email again.
  // We use the same function and endpoint as previously used to submit the form.
  // The parent component receives the PageState, then displays the error page or the success page
  const onClick = async () => {
    setPageState(PageState.Sending);
    localStorage.setItem(`${from}-EmailResent`, "true");

    const trialResult = await createBusinessTrial(
      { ...formValues, product_plan__c: plan },
      locale
    );

    const trialErrorMessage =
      trialResult && typeof trialResult !== "boolean"
        ? trialResult?.errorMessage
        : "";

    // If there was an error creating the trial, then set the error message and return without sending lead to Marketo
    if (trialErrorMessage) {
      setPageState(PageState.Error);
    }
  };

  let buttonText = translate(BUSINESS_TRY_EMAILCONFIRMATION_CTA_RESEND);

  switch (pageState) {
    case PageState.Sending:
      buttonText = translate(BUSINESS_TRY_EMAILCONFIRMATION_CTA_RESENDING);
      break;
    case PageState.Sent:
      buttonText = translate(BUSINESS_TRY_EMAILCONFIRMATION_CTA_SENT);
      break;
  }

  return (
    <>
      {pageState !== PageState.Error && (
        <>
          <RichText tag="h1" className={cn(h3Text, marginBMd)}>
            {translate(BUSINESS_TRY_EMAILCONFIRMATION_CHECK_TITLE)}
          </RichText>
          <RichText tag="p" className={marginBSm}>
            {translateInterpolate(
              BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_1,
              { formEmail: email }
            )}
          </RichText>
          <RichText tag="p" className={marginBSm}>
            {translate(BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_2)}
          </RichText>
          <RichText tag="p">
            {PageState.Sent === pageState
              ? translate(BUSINESS_TRY_EMAILCONFIRMATION_SENT_DESCRIPTION_3)
              : translate(BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_3)}
          </RichText>
          <CustomButton
            className={marginTMd}
            variant="dark"
            onClick={onClick}
            disabled={
              pageState === PageState.Sending || pageState === PageState.Sent
            }
            text={buttonText}
          />
        </>
      )}
      {pageState === PageState.Error && (
        <>
          <RichText tag="h1" className={cn(h3Text, marginBMd)}>
            {translate(BUSINESS_TRY_EMAILCONFIRMATION_ERROR_TITLE)}
          </RichText>
          <RichText tag="p">
            {translateInterpolate(
              BUSINESS_TRY_EMAILCONFIRMATION_ERROR_DESCRIPTION,
              { supportLink: externalLinks.supportTicket.url }
            )}
          </RichText>
        </>
      )}
    </>
  );
}
