import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { PanelData, iconBg, iconColor } from "./MenuPanel";
import { sanitizeRawHTML } from "@/utilities/sanitizeRawHTML";

export default function MenuPanelMobile({
  data,
  hide,
}: {
  data: PanelData;
  hide: boolean;
}) {
  return (
    <ul
      className={`flex ${
        hide
          ? "hidden"
          : "border border-l-0 border-r-0 border-t-0 border-grey-1"
      }`}
    >
      <div className={`flex flex-col`}>
        {data.columns.map((column, i) => (
          <div key={column.id} className={`flex flex-col ${i ? "" : "mt-8"}`}>
            <div className="mb-6 text-base text-dashgreen">
              {column.title.toUpperCase()}
            </div>
            <div
              className={`${
                data.columns.length - 1 === i ? "mb-12" : "mb-14"
              } flex flex-col`}
            >
              {column.items.map((item) => {
                if (item.type === "icon") {
                  return (
                    <a
                      key={item.id}
                      href={item.url}
                      className="my-1 flex items-center rounded-lg p-2 pl-0 hover:bg-blue-1 hover:no-underline"
                    >
                      {item.img && (
                        <div
                          className={`flex items-center justify-center`}
                          style={{
                            width: "37px",
                            height: "37px",
                            padding: "8px",
                            borderRadius: "6px",
                            backgroundColor:
                              iconBg[item.variant] ?? "transparent",
                            fill: iconColor[item.variant],
                          }}
                          dangerouslySetInnerHTML={{
                            __html: sanitizeRawHTML(atob(item.img)),
                          }}
                        />
                      )}
                      <h1 className={`${item.img ? "ml-4" : ""}`}>
                        {item.title}
                      </h1>
                    </a>
                  );
                } else if (item.type === "link") {
                  return (
                    <a
                      key={item.id}
                      className={"flex items-center py-3 hover:no-underline"}
                      href={item.url}
                    >
                      {item.label}
                      <ArrowRightIcon width={20} className="ml-1" />
                    </a>
                  );
                }

                return null;
              })}
            </div>
          </div>
        ))}
      </div>
    </ul>
  );
}
