import { internalLinks } from "@/constants/internalLinks";
import {
  trackingConfig as baseTrackingConfig,
  TrackingConfig,
} from "@/types/TrackingConfig";
import {
  FORMS_INVALID_EMAIL,
  FORMS_ACCOUNT_EXISTS,
  FORMS_ACCOUNT_REDEEMED,
  FORMS_GENERIC_ERROR,
} from "@/i18n/translations";
import { Locale } from "@/types";
import paidTracking from "@/utilities/analytics/paidTracking";
import Authentication from "@/utilities/api/authentication";
import SpecialOffer from "@/utilities/api/specialOffer";
import { isBrowser } from "@/utilities/isBrowser";
import { redirectToUrl } from "@/utilities/redirectToUrl";
import Uuid from "@/utilities/uuid";

const specialOfferApi = new SpecialOffer();
const authentication = new Authentication();
interface Props {
  values: { Email: string };
  language: Locale | string;
  offerToken?: string;
  redirectLink?: string;
  partnerOptions?: any;
  customTracking?: TrackingConfig;
  checkAccountExists?: boolean;
  setSubmitting: (submitting: boolean) => void;
  plan?: string;
  identifier?: string;
}

const specialOfferSetup = {
  // Handle possible errors that may be returned from the Special Offers API call
  _handleServerErrors: (error: Error) => {
    switch (error.message) {
      case "accountAlreadyCreated":
        return {
          errorMessage: FORMS_ACCOUNT_EXISTS,
          fieldName: "Email",
        };
      case "alreadyRedeemed":
        return {
          errorMessage: FORMS_ACCOUNT_REDEEMED,
          fieldName: "Email",
        };
      case "The email address is invalid":
        return {
          errorMessage: FORMS_INVALID_EMAIL,
          fieldName: "Email",
        };
      default:
        return {
          errorMessage: FORMS_GENERIC_ERROR,
          fieldName: "Email",
        };
    }
  },

  // get a UUID to pass to the API
  getUuid: () => {
    const uuid4 = new Uuid();
    return uuid4.fetchUuid();
  },

  // Redirect the user upon submit
  redirect: (
    redirectLink: string,
    trackingConfig: TrackingConfig,
    plan?: string,
    identifier?: string
  ) => {
    const adjustURL = paidTracking.getAdjustURLForPage(
      trackingConfig,
      identifier ?? ""
    );
    const desktopPartner = paidTracking.getDesktopPartnerNameForPage(
      trackingConfig,
      identifier ?? ""
    );

    if (adjustURL) {
      redirectLink = adjustURL;
    } else if (desktopPartner) {
      redirectLink = `${redirectLink}?partner=${desktopPartner}`;
    }

    if (plan === "family") {
      redirectLink = `${redirectLink}?plan=${plan}`;
    }

    if (isBrowser()) {
      redirectToUrl(redirectLink);
    }
  },

  createSpecialOffer: ({
    values,
    language,
    offerToken,
    redirectLink = `/${language}${internalLinks.download}`,
    partnerOptions,
    customTracking,
    checkAccountExists = false,
    setSubmitting,
    plan,
    identifier,
  }: Props) => {
    setSubmitting(true);
    const trackingConfig = customTracking ?? baseTrackingConfig;
    const partnerData = partnerOptions?.data;
    const partnerWebservice = partnerOptions?.webservice;

    // Get custom offer token if specified in the tracking file.
    const customOfferToken = paidTracking.getSpecialOfferTokenForPage(
      trackingConfig,
      identifier ?? ""
    );

    const builtOffer = Object.assign(
      {
        offerToken: customOfferToken ?? offerToken,
        browserUuid: specialOfferSetup.getUuid(),
        email: values.Email,
        language,
      },
      partnerData
    );

    const getOffer = () => {
      return specialOfferApi
        .getSpecialOffer({ ...builtOffer }, partnerWebservice)
        .then(() => {
          setSubmitting(false);
          specialOfferSetup.redirect(
            redirectLink,
            trackingConfig,
            plan,
            identifier
          );
        })
        .catch((error) => {
          setSubmitting(false);
          return specialOfferSetup._handleServerErrors(error);
        });
    };
    // no offer token, just redirect
    if (!offerToken) {
      specialOfferSetup.redirect(
        redirectLink,
        trackingConfig,
        plan,
        identifier
      );
      return;
    }

    // Flow for account existence check
    if (checkAccountExists) {
      return authentication
        .exists({ login: values.Email })
        .then((data) => {
          if (data.toString() === "YES") {
            return specialOfferSetup._handleServerErrors(
              new Error("accountAlreadyCreated")
            );
          } else {
            return getOffer();
          }
        })
        .catch((error) => {
          return specialOfferSetup._handleServerErrors(error);
        });
    } else {
      // No account existence check, return getOffer
      return getOffer();
    }
  },
};

export default specialOfferSetup;
