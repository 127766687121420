import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { gql } from "@apollo/client";
import cn from "classnames";
import BlockContent from "../../molecules/BlockContent";
import CustomHeading, { HeadingOptions } from "../../molecules/CustomHeading";
import {
  marginBMd,
  textHeadlineNeutral,
  h3Text,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import { RichText } from "../../atoms";
import { useFetch } from "@/hooks/useFetch";
import { FetchResponse, ParsedJob } from "./types";
import { parseJobs } from "./parseJobs";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslate } from "@/hooks/useTranslate";
import ParagraphBlock from "../ParagraphBlock";
import {
  ABOUT_CAREERS_ERROR_MESSAGE,
  ABOUT_CAREERS_NOT_FOUND,
} from "@/i18n/translations";
import { locationIcon } from "@/constants/icons";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";
/* eslint-disable @typescript-eslint/naming-convention */

interface Props extends AttributesExtension {
  includeSelectors?: boolean;
  includeHeading?: boolean;
  includeContent?: boolean;
  heading?: string;
  content?: string;
  headingOptions?: HeadingOptions;
  variant?: string;
}

export default function CareersBlock({
  includeSelectors = false,
  includeHeading = false,
  includeContent = false,
  heading = "",
  content = "",
  headingOptions = {
    tag: 1,
  },
  blockContentProps,
}: Props) {
  const { translate } = useTranslate();
  const [filteredJobs, setFilteredJobs] = useState([] as ParsedJob[]);

  const headerClassNames = cn(
    marginBMd,
    h3Text,
    textHeadlineNeutral,
    "font-bold"
  );
  const contentStyles = cn(textBodyNeutral);

  const { error, isLoaded, data } = useFetch<FetchResponse>(
    `https://api.greenhouse.io/v1/boards/dashlane/embed/jobs?content=true`
  );

  if (error && isLoaded) {
    return <ParagraphBlock content={translate(ABOUT_CAREERS_ERROR_MESSAGE)} />;
  }

  if (!isLoaded) {
    return (
      <div className="py-4">
        <div className="flex">
          <Skeleton
            style={{ margin: "0rem 2.5rem 1.5rem 0rem" }}
            height={"2.5rem"}
            width={"20rem"}
          />
          <Skeleton
            style={{ margin: "0rem 0rem 1.5rem 0rem" }}
            height={"2.5rem"}
            width={"20rem"}
          />
        </div>
        <Skeleton
          height={"1.5rem"}
          width={"10rem"}
          style={{ marginTop: "2.5rem" }}
        />
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
          {[...Array(10)].map((_, i) => (
            <Skeleton height={"4.25rem"} width={"100%"} key={`skeleton-${i}`} />
          ))}
        </div>
      </div>
    );
  }

  const { jobsList, departmentList, locationList } = parseJobs(
    data as FetchResponse
  );

  return (
    <BlockContent width="constrain" {...blockContentProps}>
      <div className="items-center">
        <div className="pb-6">
          {includeHeading && (
            <CustomHeading
              heading={heading}
              headingClassNames={headerClassNames}
              headingOptions={headingOptions}
            />
          )}
          {includeContent && (
            <RichText className={contentStyles} tag="p">
              {content}
            </RichText>
          )}
        </div>
      </div>
      {includeSelectors && (
        <SelectsGroup
          setFilteredJobs={setFilteredJobs}
          locationList={locationList}
          departmentList={departmentList}
          jobsList={jobsList}
        />
      )}
      <div className="flex-col">
        {filteredJobs.length === 0 ? (
          <h3 className="my-5 text-lg md:text-xl">
            {translate(ABOUT_CAREERS_NOT_FOUND)}
          </h3>
        ) : (
          departmentList.map((department, i) => (
            <DepartmentJobs
              key={`${department}_${i}`}
              department={department}
              departmentJobs={filteredJobs.filter(
                (job) =>
                  job.departments.filter((dep) => dep.name === department)
                    .length > 0
              )}
            />
          ))
        )}
      </div>
    </BlockContent>
  );
}

const DepartmentJobs = ({
  department,
  departmentJobs,
}: {
  department: string;
  departmentJobs: ParsedJob[];
}) => {
  if (!departmentJobs.length) return null;

  return (
    <div
      key={department}
      className={`border-gray border-b py-10 last-of-type:mb-4 last-of-type:border-b-0`}
    >
      <h3 className="text-1xl mb-5 md:text-2xl">{department}</h3>
      <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
        {departmentJobs.map((job) => (
          <div key={job.id} className="grid grid-cols-1 gap-2">
            <a
              className="text-xl text-blue-6 no-underline hover:text-teal-950 hover:no-underline"
              href={job.url}
            >
              {job.title}
            </a>
            <div className="flex align-middle">
              {locationIcon}
              <div className="ml-3">{job.location}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const JobSelect = ({
  options,
  ariaLabel,
  ariaDescribedBy,
  onChange,
}: {
  options: string[];
  ariaLabel: string;
  ariaDescribedBy: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}) => (
  <div
    className={`relative mb-2 mr-5 flex justify-between rounded-md border border-gray-300 bg-opacity-0 shadow-sm focus:border-blue-500 focus:bg-white focus:outline-none focus:ring focus:ring-blue-500 md:w-80`}
  >
    <select
      aria-describedby={ariaDescribedBy}
      aria-label={ariaLabel}
      className="w-full cursor-pointer appearance-none bg-transparent px-2 py-1 text-lg"
      onChange={onChange}
    >
      {options.map((opt: string) => (
        <option key={opt} value={opt}>
          {opt}
        </option>
      ))}
    </select>
    <div className="pointer-events-none absolute right-2 flex h-full cursor-pointer items-center">
      <DownArrow />
    </div>
  </div>
);

const DownArrow = ({ className = "" }) => (
  <svg
    aria-hidden="true"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    version="1.1"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 11.5503L5.63659 6L4.5 7.00399L10 14L15.5 7.00399L14.3634 6L10 11.5503Z"
    ></path>
  </svg>
);

const SelectsGroup = ({
  setFilteredJobs,
  locationList,
  departmentList,
  jobsList,
}: {
  setFilteredJobs: Dispatch<SetStateAction<ParsedJob[]>>;
  locationList: string[];
  departmentList: string[];
  jobsList: ParsedJob[];
}) => {
  const [selectedDepartment, setSelectedDepartment] =
    useState("All Departments");
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const [, setFiltered] = useState(jobsList);

  useEffect(() => {
    const updatedJobs = jobsList
      .filter(
        (job) =>
          job.departments.filter(
            (department) => department.name === selectedDepartment
          ).length > 0 || selectedDepartment === "All Departments"
      )
      .filter(
        (job) =>
          job.location === selectedLocation ||
          selectedLocation === "All Locations"
      );

    setFiltered(updatedJobs);
    setFilteredJobs(updatedJobs);
  }, [selectedDepartment, selectedLocation]);

  return (
    <div className="mb-8 flex flex-col md:flex-row">
      <JobSelect
        ariaLabel="Select a location"
        ariaDescribedBy="locationSelect"
        options={["All Locations"].concat(locationList)}
        onChange={({ currentTarget }) =>
          setSelectedLocation(currentTarget.value)
        }
      />
      <JobSelect
        ariaLabel="Select a department"
        ariaDescribedBy="departmentSelect"
        options={["All Departments"].concat(departmentList)}
        onChange={({ currentTarget }) =>
          setSelectedDepartment(currentTarget.value)
        }
      />
    </div>
  );
};

// Must match __typename
const BLOCK_NAME = "TenupCareers";

CareersBlock.displayName = BLOCK_NAME;

CareersBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          includeSelectors
          includeHeading
          includeContent
          content
          headingOptions
        }
      }
    }
  `,
};
