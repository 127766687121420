export const SKIP_NAVIGATION_LINK_TITLE = {
  "en": "Skip to main content",
  "fr": "Passer au contenu principal",
  "de": "Überspringen und zum Hauptinhalt gehen",
  "es": "Ir al contenido principal",
  "pt-br": "Skip to main content",
  "it": "Skip to main content",
  "ja": "Skip to main content",
  "ko": "Skip to main content",
  "nl": "Skip to main content",
  "pt-pt": "Skip to main content",
  "sv": "Skip to main content",
  "zh": "Skip to main content"
}