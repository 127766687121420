import { useState } from "react";
import cn from "classnames";
import Link from "next/link";
import { useWindowSize } from "@/hooks/useWindowSize";
import { SocialSharing } from "@/components/molecules/index";
import { MD_BREAK } from "@/constants/utility";
import { iconCaretDown } from "@/constants/icons";
import Icon from "@/components/molecules/Icon";
import { MenuItems } from "@/types";

export default function MenuFooter({ items }: { items: MenuItems }) {
  const [open, setOpen] = useState("");
  const { width } = useWindowSize();
  const isMobile = width < MD_BREAK;

  return (
    <ul className="flex w-full flex-col justify-between gap-x-16 border border-l-0 border-r-0 border-t-0 border-grey-1 pb-10 lg:w-full lg:flex-row">
      {items.map((item) => (
        <li key={item.id}>
          <ul>
            {item.children?.map((itemChild: any, index: number) => {
              const TopLevelTag =
                isMobile && itemChild.path !== "#social" ? "button" : "span";
              const isSocial = itemChild.path === "#social";
              const isOpen = itemChild.id === open;

              return (
                <li key={itemChild.id} className={cn({ "lg:mt-8": index > 0 })}>
                  <TopLevelTag
                    className={cn(
                      "border-l-0 border-r-0 border-t-0 border-grey-1 uppercase text-grey-3 lg:border-b-0 lg:py-0 lg:py-4",
                      {
                        "mt-10 flex justify-center pb-4 text-xs lg:mt-0 lg:inline-block lg:text-left lg:text-sm":
                          isSocial,
                        "py-4 text-sm": !isSocial,
                        "flex w-full items-center text-left":
                          !isSocial && isMobile,
                        border: !isSocial && !isOpen,
                      }
                    )}
                    onClick={
                      isMobile
                        ? () => setOpen(isOpen ? "" : itemChild.id)
                        : undefined
                    }
                  >
                    {itemChild.label}
                    {!isSocial && (
                      <Icon
                        className={cn("ms-auto lg:hidden", {
                          "rotate-180": isOpen,
                        })}
                        source={iconCaretDown}
                        variant="dark"
                        width={16}
                        name={isOpen ? "close" : "open"}
                      />
                    )}
                  </TopLevelTag>
                  {itemChild.path === "#social" ? (
                    <div className="flex flex-col items-center justify-between gap-8 lg:mt-4">
                      <SocialSharing />
                    </div>
                  ) : (
                    <ul
                      className={cn("lg:block", {
                        hidden: !isOpen && isMobile,
                        "border border-l-0 border-r-0 border-t-0 border-grey-1 pb-4":
                          isOpen && isMobile,
                      })}
                    >
                      {itemChild.children.map((child: any) => {
                        const { id, path, label } = child;
                        return (
                          <li key={id}>
                            <Link
                              target={child.target ?? "_self"}
                              locale={false}
                              href={path}
                              className="my-1 block text-white no-underline transition-all hover:text-grey-3 hover:no-underline"
                            >
                              {label}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </li>
      ))}
    </ul>
  );
}
