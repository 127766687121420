import { Blocks, ButtonGroup } from "@/components/molecules";
import { Block } from "@/types";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

export type ButtonsLayout = {
  flexWrap?: "nowrap" | "wrap";
  justifyContent?: "left" | "right" | "center" | "space-between";
  orientation?: "vertical" | "horizontal";
  verticalAlignment?: "top" | "middle" | "bottom";
};

interface Props {
  anchor: string;
  layout: ButtonsLayout;
  innerBlocks: Block[];
}

export default function ButtonsBlock({
  anchor,
  innerBlocks,
  layout = {},
}: Props) {
  return (
    <ButtonGroup id={anchor} layout={layout}>
      {!!innerBlocks?.length && <Blocks blocks={innerBlocks} />}
    </ButtonGroup>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreButtons";

ButtonsBlock.displayName = BLOCK_NAME;

ButtonsBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          align
          anchor
          className
          cssClassName
          fontFamily
          fontSize
          layout
          metadata
          style
        }
      }
    }
  `,
};
