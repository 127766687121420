export const BUSINESS_TRIAL_SUCCESS_VERIFY_SENT = {
  "en": "We’ve sent a verification email to",
  "fr": "Nous avons envoyé un e-mail de vérification à",
  "de": "Wir haben eine Verifizierungs-E-Mail gesendet an",
  "es": "Hemos enviado un correo electrónico de verificación a",
  "pt-br": "We’ve sent a verification email to",
  "it": "We’ve sent a verification email to",
  "ja": "We’ve sent a verification email to",
  "ko": "We’ve sent a verification email to",
  "nl": "We’ve sent a verification email to",
  "pt-pt": "We’ve sent a verification email to",
  "sv": "We’ve sent a verification email to",
  "zh": "We’ve sent a verification email to"
}