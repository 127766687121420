/**
 * The set of ASCII numerals (0-9).
 */
export const NUMERALS = "1234567890";

/**
 * The set of ASCII numerals, omitting those which can be difficult to distinguish from other
 * alphanumeric characters. The omitted characters are:
 *
 *  * 0 (zero), often confused with O (oscar, upper case)
 *  * 1 (one), often confused with I (india, upper case) and l (lima, lower case)
 *  * 2 (two), often confused with Z (zulu, upper case)
 *
 */
export const NUMERALS_DISTINGUISHABLE = "3456789";

/**
 * The set of lower case ASCII characters (a-z).
 */
export const LOWER_ALPHA = "abcdefghijklmnopqrstuvwxyz";

/**
 * The set of upper case ASCII characters (A-Z).
 */
export const UPPER_ALPHA = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

/**
 * The set of lower case ASCII characters, omitting those which are difficult to distinguish
 * from other alphanumeric characters. The omitted characters are:
 *
 *  * l (lima), often confused with 1 (one) and I (india, upper case)
 *  * u (uniform), often confused with v (victor)
 *  * v (victor), often confused with u (uniform)
 *  * w (whiskey), often confused with vv (victor victor)
 *
 */
export const LOWER_ALPHA_DISTINGUISHABLE = "abcdefghijkmnopqrstxyz";

/**
 * The set of upper case ASCII characters, omitting those which can be difficult to distinguish
 * from other alphanumeric characters. The omitted characters are:
 * <ul>
 * <li>I (india), often confused with 1 (one) and l (lima, lower case)</li>
 * <li>O (oscar), often confused with 0 (zero)</li>
 * <li>U (uniform), often confused with v (victor)</li>
 * <li>V (victor), often confused with u (uniform)</li>
 * <li>W (whiskey), often confused with vv (victor victor)</li>
 * <li>Z (zulu), often confused with 2 (two)</li>
 * </ul>
 */
export const UPPER_ALPHA_DISTINGUISHABLE = "ABCDEFGHJKLMNPQRSTXY";

/**
 * The set of all printable ASCII symbols.
 */
export const SYMBOLS = "&@$!#?";
