import React from "react";
import cn from "classnames";
import { Caption } from "@/components/atoms";
import { VideoEmbedInterface } from "@/types";

export default function VideoEmbed({
  className,
  url,
  type = "youtube",
  title,
  caption,
  addMargin = true,
}: VideoEmbedInterface) {
  function createVideoUrl(url: string) {
    let videoUrl, videoId;

    videoId = url.indexOf("v=") !== -1 ? url.split("v=") : url.split("/");
    videoId = videoId[videoId.length - 1];

    const videoIdParts = videoId?.split("&");

    if (!videoIdParts || !videoIdParts[0]) return url;

    videoId = videoIdParts.shift();

    videoIdParts.push("controls=0");
    videoIdParts.push("rel=0");

    const videoPath = videoIdParts.length
      ? videoId + "?" + videoIdParts.join("&")
      : videoId;

    videoUrl = url.includes("vimeo")
      ? `https://player.vimeo.com/video/${videoPath}`
      : `https://youtube.com/embed/${videoPath}`;

    return videoUrl;
  }

  return (
    <figure
      className={cn("overflow-hidden rounded", className, {
        "my-3": addMargin,
      })}
    >
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="aspect-[16/9] h-full w-full p-0"
        height="315"
        loading="lazy"
        src={createVideoUrl(url)}
        title={title ?? `Embedded content from ${type}`}
        width="560"
      />
      {caption && <Caption caption={caption} />}
    </figure>
  );
}
