export type BlockContentProps = {
  ga4SectionId?: string;
};

export interface AttributesExtension {
  blockContentProps?: BlockContentProps;
}

export const getBlockContentProps = (
  attributes: Record<string, any>
): BlockContentProps => {
  return {
    ga4SectionId: attributes?.ga4SectionId,
  };
};
