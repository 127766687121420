import React from "react";
import CustomImage, {
  CustomImageInterface,
} from "@/components/molecules/CustomImage";
import { VideoEmbedInterface } from "@/types";
import VideoEmbed from "@/components/molecules/VideoEmbed";
import LottieEmbed, {
  LottieInterface,
} from "@/components/molecules/LottieEmbed";
import CustomVideoEmbed, {
  CustomVideoEmbedInterface,
} from "@/components/molecules/CustomVideoEmbed";

type Media =
  | CustomImageInterface
  | VideoEmbedInterface
  | LottieInterface
  | CustomVideoEmbedInterface
  | {};

export interface MediaObjectInterface {
  media: Media;
  type: string;
}

export interface CustomMediaInterface {
  media: MediaObjectInterface;
  fallbackImage?: CustomImageInterface;
  aspect?: string;
  className?: string;
  imageClassName?: string;
  videoClassName?: string;
  rounded?: boolean;
}

// CustomImageInterface type guard
const isCustomImageInterface = (
  toBeDetermined: Media
): toBeDetermined is CustomImageInterface =>
  !!(toBeDetermined as CustomImageInterface);

// VideoEmbedInterface type guard
const isVideoEmbedInterface = (
  toBeDetermined: Media
): toBeDetermined is VideoEmbedInterface =>
  !!(toBeDetermined as VideoEmbedInterface);

// LottieInterface type guard
const isLottieInterface = (
  toBeDetermined: Media
): toBeDetermined is LottieInterface => !!(toBeDetermined as LottieInterface);

// LottieInterface type guard
const isCustomVideoEmbedInterface = (
  toBeDetermined: Media
): toBeDetermined is CustomVideoEmbedInterface =>
  !!(toBeDetermined as CustomVideoEmbedInterface);

const CustomMedia = ({
  media,
  className,
  fallbackImage,
  imageClassName,
  aspect,
  rounded,
  videoClassName,
}: CustomMediaInterface) => {
  let { media: mediaObject } = media;
  const { type } = media;

  if (type === "image" && fallbackImage && fallbackImage.full) {
    mediaObject = fallbackImage;
  }

  switch (type) {
    case "image": {
      if (isCustomImageInterface(mediaObject)) {
        return (
          <CustomImage
            className={className}
            aspect={aspect}
            alt={mediaObject.alt}
            full={mediaObject.full}
            imageClassName={imageClassName}
            rounded={rounded}
          />
        );
      }
      break;
    }
    case "lottie":
      if (isLottieInterface(mediaObject)) {
        return (
          <LottieEmbed
            className={className}
            url={mediaObject.url}
            aspect={aspect}
          />
        );
      }
      break;
    case "youtube":
      if (isVideoEmbedInterface(mediaObject)) {
        return (
          <VideoEmbed
            className={className}
            url={mediaObject.url}
            caption={mediaObject.caption}
            title={mediaObject.title}
            addMargin={false}
            type="youtube"
          />
        );
      }
      break;
    case "video":
      if (isCustomVideoEmbedInterface(mediaObject)) {
        return (
          <CustomVideoEmbed
            className={className}
            videoClassName={videoClassName}
            url={mediaObject.url}
            loop={true}
            controls={false}
            muted={true}
            aspect={aspect}
          />
        );
      }
      break;
    default:
      break;
  }

  return null;
};

export default CustomMedia;
