import React, { useState, useEffect } from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import CustomImage, { CustomImageInterface } from "../molecules/CustomImage";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  pTextL,
  textBodyNeutral,
  backgroundInverse,
  backgroundNeutral,
  h3Text,
  backgroundWhite,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { iconClose } from "@/constants/icons";
import EmailCaptureForm from "@/components/molecules/EmailCaptureForm";
import { getCookie, setCookie } from "@/utilities/cookies";
import getOfferCookieName from "@/utilities/getOfferCookieName";
import { useRouter } from "next/router";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeImage?: boolean;
  includeForm?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  formButtonText?: string;
  inputPlaceholder?: string;
  variant?: string;
  modalDelay?: number;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  image?: CustomImageInterface;
  disableCookie?: boolean;
  offerId?: string;
  disableLanguage?: Record<string, boolean>;
}

export default function OfferOverlayBlock({
  includeAboveHeading = true,
  includeBelowButtons = true,
  includeButtons = true,
  includeContent = true,
  includeImage = true,
  includeForm = false,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 1,
  },
  content = "",
  formButtonText = "",
  variant = "white",
  modalDelay = 5,
  inputPlaceholder = "",
  buttonsNumber = 2,
  buttonOne,
  buttonTwo,
  belowButtons = "",
  image = {
    full: "",
    alt: "",
  },
  disableCookie = false,
  offerId = "",
  disableLanguage = {},
}: Props) {
  const [active, setActive] = useState(false);
  const cookieName = getOfferCookieName(offerId || "default");
  const { locale = "" } = useRouter();

  useEffect(() => {
    //Get the cookie for this bannerId
    const dismissed = getCookie(cookieName);
    let timer: NodeJS.Timeout | null = null;

    if (!disableLanguage[locale] && (!dismissed || disableCookie)) {
      timer = setTimeout(() => {
        setActive(true);
      }, modalDelay * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  if (!active) return null;

  const dismiss = () => {
    //Set a cookie to save that this offer has been dismissed and expire the cookie tomorrow
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setCookie(cookieName, "true", tomorrow.toUTCString());

    setActive(false);
  };

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      align: "wide",
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      align: "wide",
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  const headingClassNames = cn(marginBMd, h3Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant === "light",
  });

  const modalClasses = cn("relative w-full mx-auto rounded overflow-hidden", {
    [backgroundInverse]: variant === "dark",
    [backgroundNeutral]: variant === "light",
    [backgroundWhite]: variant === "white",
    "md:w-[838px]": includeImage,
    "md:w-[600px]": !includeImage,
    "h-full md:h-auto": includeImage,
  });

  const modalInnerClasses = cn("grid items-center", {
    "md:grid-cols-2": includeImage,
  });

  const closeButtonClasses = cn(
    "absolute right-4 top-4 z-10 h-6 w-6 [&>svg]:w-full",
    {
      "fill-grey-3": variant === "dark" || includeImage,
      "fill-grey-6": variant !== "dark" && !includeImage,
    }
  );

  const modalContentClasses = cn("px-4 py-10 md:h-auto md:px-10", {
    "h-offer-overlay-content overflow-y-auto": includeImage,
  });

  return (
    <dialog
      className="dialog fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/60 p-4"
      open={active}
      onClick={() => dismiss()}
    >
      <div className={modalClasses} onClick={(e) => e.stopPropagation()}>
        <button
          className={closeButtonClasses}
          dangerouslySetInnerHTML={{ __html: iconClose }}
          onClick={() => dismiss()}
        />
        <div className={modalInnerClasses}>
          <div className={modalContentClasses}>
            {includeAboveHeading && aboveHeading && (
              <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
            )}
            <CustomHeading
              heading={heading}
              headingClassNames={headingClassNames}
              headingOptions={headingOptions}
            />
            {includeContent && content && (
              <RichText
                className={cn({
                  [textBodyInverse]: variant === "dark",
                  [textBodyNeutral]: variant !== "dark",
                })}
                tag="p"
              >
                {content}
              </RichText>
            )}
            {includeButtons && !includeForm && (
              <CustomButtonGroup align="left" buttons={buttons} column={true} />
            )}
            {includeForm && (
              <div className="mt-10 [&_.text-red-600]:relative">
                <EmailCaptureForm
                  buttonText={formButtonText}
                  inputPlaceholder={inputPlaceholder}
                  placeholderSize={pTextL}
                  buttonTextSize="lg"
                  variant={variant}
                  column={true}
                />
              </div>
            )}
            {includeBelowButtons && (
              <BelowButtonsText variant={variant} centered={true}>
                {belowButtons}
              </BelowButtonsText>
            )}
          </div>
          {includeImage && (
            <div
              className={cn(
                "h-offer-overlay-image order-first md:order-2 md:h-full md:w-full"
              )}
            >
              <CustomImage
                className="h-offer-overlay-image w-full md:h-full"
                alt={image.alt}
                full={image.full}
                rounded={false}
              />
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupOfferOverlay";

OfferOverlayBlock.displayName = BLOCK_NAME;

OfferOverlayBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          disableCookie
          disableLanguage
          formButtonText
          ga4SectionId
          heading
          headingOptions
          headingSize
          image
          imageAlign
          includeAboveHeading
          includeButtons
          includeBelowButtons
          includeContent
          includeForm
          includeHeading
          includeImage
          inputPlaceholder
          metadata
          modalDelay
          offerId
          size
          variant
        }
      }
    }
  `,
};
