import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  marginBXL,
  marginTLg,
  marginBLg,
  h3Text,
} from "@/constants/standardCSSClasses";
import CardGroup from "@/components/molecules/CardGroup";
import { CardInterface } from "@/components/molecules/Card";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import { CustomImageInterface } from "@/components/molecules/CustomImage";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeCardButtons?: boolean;
  includeCardIcons?: boolean;
  includeStars?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingOptions?: HeadingOptions;
  headingSize?: string;
  content?: string;
  variant?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  width?: string;
  border?: boolean;
  cardsNumber?: number;
  columns?: number;
  cards: Array<CardInterface>;
  iconStyle?: string;
  contentType: string;
  includeImage: boolean;
  image?: CustomImageInterface;
  media?: MediaObjectInterface;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function IconCardsBlock({
  includeAboveHeading = true,
  includeButtons = true,
  includeContent = true,
  includeCardButtons = true,
  includeCardIcons = true,
  includeBelowButtons = false,
  aboveHeading,
  heading,
  headingOptions = {
    tag: 2,
  },
  content,
  belowButtons = "",
  variant = "dark",
  border = true,
  cardsNumber = 4,
  columns = 2,
  buttonOne,
  buttonTwo,
  cards,
  buttonsNumber = 2,
  iconStyle,
  contentType,
  includeImage = false,
  image = {
    full: "",
    alt: "",
  },
  media = {
    type: "image",
    media: {},
  },
  blockContentProps,
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const headerClassNames = cn("md:text-center", marginBMd, h3Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const pTextClassNames = cn("md:text-center", marginBXL, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      defaultValue="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className="md:mx-auto md:max-w-xl">
        {includeAboveHeading && (
          <AboveHeading className="md:text-center" variant={variant}>
            {aboveHeading}
          </AboveHeading>
        )}
        <CustomHeading
          heading={heading}
          headingOptions={headingOptions}
          headingClassNames={headerClassNames}
        />
        {includeContent && (
          <RichText className={pTextClassNames} tag="p">
            {content}
          </RichText>
        )}
      </div>
      {includeImage && (
        <CustomMedia
          className={cn(marginBLg, "block md:hidden")}
          media={media ?? { media: {}, type: "image" }}
          aspect="2:1"
          fallbackImage={image}
        />
      )}
      {cards && cards.length && (
        <CardGroup
          columns={columns}
          cardsNumber={cardsNumber}
          cards={cards.map((each) => {
            each.iconStyle = iconStyle;
            each.border = border;
            each.includeCardButtons = includeCardButtons;
            each.includeCardIcons = includeCardIcons;
            each.variant = variant;

            if (each.headingOptions === undefined) {
              each.headingOptions = { tag: headingOptions.tag + 1 };
            } else {
              each.headingOptions.tag = headingOptions.tag + 1;
            }

            return each;
          })}
        />
      )}
      {includeImage && (
        <CustomMedia
          className={cn(marginTLg, "hidden md:block")}
          media={media ?? { media: {}, type: "image" }}
          aspect="2:1"
          fallbackImage={image}
        />
      )}
      {includeButtons && <CustomButtonGroup align="center" buttons={buttons} />}
      {includeBelowButtons && (
        <BelowButtonsText centered={true} variant={variant}>
          {belowButtons}
        </BelowButtonsText>
      )}
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupIconCards";

IconCardsBlock.displayName = BLOCK_NAME;

IconCardsBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          border
          buttonOne
          buttonTwo
          buttonsNumber
          cards
          cardsNumber
          className
          columns
          content
          ga4SectionId
          heading
          headingOptions
          iconStyle
          image
          includeAboveHeading
          includeButtons
          includeCardButtons
          includeCardIcons
          includeImage
          media
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
