import { Blocks } from "@/components/molecules";
import type { Block } from "@/types";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  innerBlocks: Block[];
}

export default function GalleryBlock({ innerBlocks }: Props) {
  return (
    <div className="my-3 flex flex-col gap-2 md:flex-row">
      {!!innerBlocks.length && <Blocks blocks={innerBlocks} />}
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreGallery";

GalleryBlock.displayName = BLOCK_NAME;

GalleryBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
        }
      }
    }
  `,
};
