export type SupportedCurrencies = "usd" | "eur";

export const intlCurrency = ({
  currency = "usd",
  language = "en",
  setDecimals = false,
  value,
}: {
  currency?: SupportedCurrencies | string;
  language?: string;
  setDecimals?: boolean;
  value: number;
}) =>
  new Intl.NumberFormat(language, {
    style: "currency",
    currency,
    minimumFractionDigits: setDecimals ? 2 : 0,
  }).format(value);
