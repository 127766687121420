import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

export default function BannerBlock() {
  return null;
}

// Must match __typename
const BLOCK_NAME = "TenupBanner";

BannerBlock.displayName = BLOCK_NAME;

BannerBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          bannerId
          button
          className
          content
          disableCookie
          ga4SectionId
          heading
          headingVariant
          icon
          includeButtons
          includeIcons
          metadata
          variant
          width
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
