import React, { PropsWithChildren } from 'react';

import { BlockStyle } from '@/utilities/getBlockStyles';

interface Props {
  attributes?: Record<string, any>;
  className?: string;
  dropCap?: boolean;
  id?: string;
  style?: BlockStyle;
  tag?: string;
}

export default function RichText({
  attributes,
  children,
  className,
  id,
  style,
  tag = 'div',
}: PropsWithChildren<Props>) {
  return React.createElement(tag, {
    ...attributes,
    className,
    id: id ?? null,
    dangerouslySetInnerHTML: { __html: children },
    style,
  });
}
