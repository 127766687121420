import cn from "classnames";
import Icon, { IconInterface } from "./Icon";
import {
  borderBoth,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";

import { RichText } from "@/components/atoms";
import React from "react";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";

export interface SecondaryCTAInterface {
  content: string;
  icon: IconInterface;
  includeIcon: boolean;
  button: CustomButtonInterface;
}

interface SecondaryCTAFullInterface {
  secondaryCTA: SecondaryCTAInterface;
  variant: string;
  className: string;
}

const SecondaryCTA = ({
  secondaryCTA,
  variant,
  className = "",
}: SecondaryCTAFullInterface) => {
  const classNames = cn(
    "flex flex-col md:flex-row md:items-center items-start pt-5 mt-8 md:mt-6 justify-between border border-solid border-l-0 border-r-0 border-b-0 border-t-1",
    className,
    borderBoth
  );

  const textClasses = cn("text-2xl mt-4 md:mt-0 md:whitespace-nowrap", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const iconClasses = cn(
    "h-5 w-5 w-full h-full [&>svg]:w-full [&>svg]:h-full md:mr-2"
  );

  const { includeIcon = false, button = {}, content, icon } = secondaryCTA;

  const buttons = [
    {
      ...button,
      variant: variant,
      textOnly: true,
      arrow: true,
      size: "lg",
    },
  ];

  return (
    <div className={classNames}>
      <div className="flex flex-col md:flex-row md:items-center">
        {includeIcon && (
          <Icon {...icon} variant={variant} className={iconClasses} />
        )}
        <RichText className={textClasses}>{content}</RichText>
      </div>
      <CustomButtonGroup
        align="left"
        buttons={buttons}
        marginTop="mt-2 md:mt-0"
        mobileWide={false}
      />
    </div>
  );
};

export default SecondaryCTA;
