export const TRIAL_STEP_3 = {
  "en": "Install browser extension",
  "fr": "Installer l'extension pour navigateur",
  "de": "Browser-Erweiterung installieren",
  "es": "Instalar la extensión de navegador",
  "pt-br": "Install browser extension",
  "it": "Install browser extension",
  "ja": "Install browser extension",
  "ko": "Install browser extension",
  "nl": "Install browser extension",
  "pt-pt": "Install browser extension",
  "sv": "Install browser extension",
  "zh": "Install browser extension"
}