import { gql } from '@apollo/client';

const PreviewFragment = gql`
  fragment PreviewFragment on Post {
    id
    excerpt
    date
    title
    uri
    featuredImage {
      node {
        id
        sourceUrl
        altText
        mediaDetails {
          width
          height
        }
      }
    }
    author {
      node {
        name
      }
    }
    categories {
      nodes {
        name
      }
    }
  }
`;

export default PreviewFragment;
