import { FaustHooks, FaustPlugin } from "@faustwp/core";

export default class PossibleTemplatesPlugin implements FaustPlugin {
  apply(hooks: FaustHooks) {
    const { addFilter } = hooks;
    addFilter("possibleTemplatesList", "faust", (possibleTemplates, ctx) => {
      // don't add frontPage if isPostsPage is true as both can't be true
      if (ctx.seedNode.isPostsPage && ctx.seedNode.isFrontPage) {
        return possibleTemplates.filter(
          (template) => template !== "front-page"
        );
      }

      return possibleTemplates;
    });
  }
}
