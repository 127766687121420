export const READ_MORE_BUTTON = {
  "en": "Read more",
  "fr": "En savoir plus",
  "de": "Mehr erfahren",
  "es": "Leer más",
  "pt-br": "Read more",
  "it": "Read more",
  "ja": "Read more",
  "ko": "Read more",
  "nl": "Read more",
  "pt-pt": "Read more",
  "sv": "Read more",
  "zh": "Read more"
}