import { useEffect } from "react";

import { marketoFormApi } from "./marketoFormApi";
import { formTypeMap, loadLeanData } from "../loadLeanData";
interface Props {
  marketoFormId: number;
  hidden?: boolean;
}

export const MarketoForm = ({ marketoFormId, hidden = true }: Props) => {
  useEffect(() => {
    marketoFormApi(marketoFormId);
    const formType = formTypeMap[marketoFormId];
    if (formType) {
      loadLeanData(formType);
    }
  }, [marketoFormId]);

  return (
    <form
      id={`mktoForm_${marketoFormId}`}
      className={
        hidden ? "hidden" : `marketo-form marketo-form-${marketoFormId}`
      }
    />
  );
};
