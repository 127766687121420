import { Menu, Transition } from "@headlessui/react";
import { pTextSm } from "@/constants/standardCSSClasses";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { sanitizeRawHTML } from "@/utilities/sanitizeRawHTML";
import ImageWithFallback from "./ImageWithFallback";

interface PanelColumn {
  id: string;
  title: string;
  items: ColumnsData[];
}

interface PostColumn {
  id: string;
  title: string;
  items: ColumnPost[];
}

interface CTABar {
  id: string;
  ga4Id?: string;
  items: CTABarItem[];
}

interface CTABarItem {
  id: string;
  label: string;
  icon: string;
  url: string;
  ga4Id?: string;
}

interface ColumnPost {
  id: string;
  type: "post";
  img?: string;
  customImage?: string | null;
  url: string;
  title: string;
}

export interface ColumnLink {
  id: string;
  type: "link";
  url: string;
  label: string;
}

export interface ColumnIconLink {
  id: string;
  type: "icon";
  img: string;
  variant: "transparent" | "white" | "light" | "dark";
  url: string;
  title: string;
  description: string;
}

type ColumnsData = ColumnLink | ColumnIconLink;

export interface PanelData {
  type: string;
  columns: PanelColumn[];
  posts: PostColumn[];
  ctaBar?: CTABar;
}

export const iconBg = {
  transparent: "transparent",
  light: "#D1E8EC",
  dark: "#026272",
  white: "white",
};

export const iconColor = {
  transparent: iconBg.dark,
  light: "#09363F",
  dark: iconBg.white,
  white: iconBg.dark,
};

export default function MenuPanel({
  data,
  isShowing,
}: {
  data: PanelData;
  isShowing: boolean;
}) {
  const columnsAmount = data.columns.length;
  const isMultiColumn = columnsAmount > 1;

  return (
    <Transition
      show={isShowing}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div
        className={`${
          isMultiColumn ? "w-max-[1280px] absolute left-0 w-full" : "fixed"
        } top-full`}
      >
        <Menu.Items
          data-cy={`menu-items-10987`}
          className={`z-10 mt-2 flex flex-wrap ${
            data.ctaBar ? "rounded-tl-lg rounded-tr-lg" : "rounded-lg"
          } bg-white p-8 ring-1 ring-black ring-opacity-5`}
          style={{ boxShadow: "0px 4px 12px 0px #A5B3BC33" }}
          as="ul"
        >
          <div className={`grid grid-cols-${columnsAmount} w-100 flex-1 gap-4`}>
            {data.columns.map((column) => (
              <div key={column.id} className="flex flex-col px-2">
                <div className="pl-2 text-base text-dashgreen">
                  {column.title.toUpperCase()}
                </div>
                <div className="mt-2 flex flex-col">
                  {column.items.map((item, i) => {
                    if (item.type === "icon") {
                      return (
                        <a
                          key={item.id}
                          href={item.url}
                          className={`mt-2 rounded-lg p-2 text-inherit hover:bg-blue-1 hover:no-underline`}
                        >
                          <div key={item.id} className="flex items-start">
                            {item.img && (
                              <div
                                className={`flex items-center justify-center`}
                                style={{
                                  width: "37px",
                                  height: "37px",
                                  padding: "8px",
                                  borderRadius: "6px",
                                  backgroundColor:
                                    iconBg[item.variant] ?? "transparent",
                                  fill: iconColor[item.variant],
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: sanitizeRawHTML(atob(item.img)),
                                }}
                              />
                            )}
                            <div className={`${item.img ? "ml-4" : ""} w-full`}>
                              <h1>{item.title}</h1>
                              {item.description && (
                                <p
                                  className={`${pTextSm} mt-1 text-grey-6`}
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></p>
                              )}
                            </div>
                          </div>
                        </a>
                      );
                    } else if (item.type === "link") {
                      return (
                        <a
                          key={item.id}
                          className={`flex items-center py-6 pl-2 ${
                            column.items.length - 1 === i ? "pb-0" : ""
                          } hover:no-underline`}
                          href={item.url}
                        >
                          {item.label}
                          <ArrowRightIcon width={20} className="ml-1" />
                        </a>
                      );
                    }

                    return null;
                  })}
                </div>
              </div>
            ))}
          </div>
          {data.posts.map((post) => (
            <div
              key={post.id}
              className={`flex max-w-sm flex-col px-2 ${
                !isMultiColumn ? "ml-20" : ""
              }`}
            >
              <div className="px-2 pb-4 text-base text-dashgreen">
                {post.title.toUpperCase()}
              </div>
              {post.items.map((item, i) => {
                if (item.type === "post") {
                  return (
                    <a
                      key={item.id}
                      href={item.url}
                      className={`flex w-full cursor-pointer items-center justify-between rounded-lg p-2 ${
                        post.items.length - 1 === i ? "mb-0" : "mb-2"
                      }  hover:bg-blue-1 hover:no-underline`}
                    >
                      <div className="h-[90px] w-[160px] overflow-hidden rounded-lg">
                        <ImageWithFallback
                          className="rounded-lg"
                          src={item.customImage || item.img}
                          fallbackSrc={"/images/blog-post-default.webp"}
                          width={160}
                          height={90}
                          alt="post img"
                        />
                      </div>
                      <h1 className="ml-4 flex-1">{item.title}</h1>
                    </a>
                  );
                }
                return null;
              })}
            </div>
          ))}
        </Menu.Items>

        {data.ctaBar && (
          <div
            className="flex w-full rounded-bl-lg rounded-br-lg bg-blue-1 px-8 py-4"
            {...(data.ctaBar.ga4Id
              ? { "data-component": data.ctaBar.ga4Id }
              : {})}
          >
            {data.ctaBar.items.map((ctaBarItem) => (
              <a
                key={ctaBarItem.id}
                href={ctaBarItem.url}
                {...(ctaBarItem.ga4Id ? { "data-item": ctaBarItem.ga4Id } : {})}
              >
                <div className="flex items-center">
                  {ctaBarItem.icon && (
                    <div
                      className={`mr-2 flex items-center justify-center [&>svg]:h-[20px] [&>svg]:w-[20px] [&>svg]:fill-blue-5`}
                      dangerouslySetInnerHTML={{
                        __html: sanitizeRawHTML(atob(ctaBarItem.icon)),
                      }}
                    />
                  )}
                  <p className="mr-8 text-blue-5">{ctaBarItem.label}</p>
                </div>
              </a>
            ))}
          </div>
        )}
      </div>
    </Transition>
  );
}
