import cn from "classnames";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import { RichText } from "@/components/atoms";
import {
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { iconCheckmark } from "@/constants/icons";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  variant: string;
  icon: IconInterface;
  content?: string;
}

export default function PricingItemBlock({
  content = "",
  variant = "white",
  icon = {
    source: iconCheckmark,
  },
}: Props) {
  const pTextClassNames = cn("text-sm md:text-base my-0", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  if (!icon.source) {
    icon.source = iconCheckmark;
  }

  return (
    <div className={cn("mb-3 flex items-center")}>
      <Icon {...icon} variant={variant} className="w-3.5" width={16} />
      <span className="ms-5">
        <RichText className={pTextClassNames} tag="p">
          {content}
        </RichText>
      </span>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupPricingItem";

PricingItemBlock.displayName = BLOCK_NAME;

PricingItemBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          content
          icon
        }
      }
    }
  `,
};
