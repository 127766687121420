import React, { useState, useEffect, memo } from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  backgroundInverse,
  backgroundNeutral,
  backgroundWhite,
  h2Text,
  marginBSm,
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { iconClose } from "@/constants/icons";
import { getCookie, setCookie } from "@/utilities/cookies";
import getOfferCookieName from "@/utilities/getOfferCookieName";
import { useRouter } from "next/router";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import { MarketoFormFieldAny } from "@/types";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import MarketoFormBuilder from "@/components/molecules/MarketoFormBuilder";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MOBILE_BREAK } from "@/constants/utility";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  modalDelay?: number;
  media?: MediaObjectInterface;
  disableCookie?: boolean;
  offerId?: string;
  disableLanguage?: Record<string, boolean>;
  hasCustomRedirect?: boolean;
  redirectUrl?: string;
  formErrorMessage?: string;
  formId?: number;
  formName?: string;
  fields?: MarketoFormFieldAny[];
}

export default function MarketoFormOverlayBlock({
  heading = "",
  headingOptions = {
    tag: 1,
  },
  content = "",
  variant = "white",
  modalDelay = 5,
  media = {
    type: "image",
    media: {},
  },
  disableCookie = false,
  offerId = "",
  hasCustomRedirect = false,
  redirectUrl = "",
  disableLanguage = {},
  formErrorMessage = "",
  formId,
  formName = "",
  fields,
}: Props) {
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < MOBILE_BREAK;
  const [active, setActive] = useState(false);
  const cookieName = getOfferCookieName(offerId || "default");
  const { locale = "" } = useRouter();

  useEffect(() => {
    //Get the cookie for this bannerId
    const dismissed = getCookie(cookieName);
    let timer: NodeJS.Timeout | null = null;

    if (!disableLanguage[locale] && (!dismissed || disableCookie)) {
      timer = setTimeout(() => {
        setActive(true);
      }, modalDelay * 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  if (!active) return null;

  const dismiss = () => {
    //Set a cookie to save that this offer has been dismissed and expire the cookie tomorrow
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setCookie(cookieName, "true", tomorrow.toUTCString());

    setActive(false);
  };

  const headingClassNames = cn(marginBSm, h2Text, {
    [textHeadlineInverse]: variant === "dark" && !isMobile,
    [textHeadlineNeutral]: variant !== "dark",
  });

  const contentClassNames = cn("mb-8", {
    [textBodyInverse]: variant === "dark" && !isMobile,
    [textBodyNeutral]: variant !== "dark",
  });

  const modalClasses = cn(
    "relative w-full mx-auto rounded overflow-hidden md:w-[838px] h-full md:max-h-full md:h-auto",
    {
      [backgroundInverse]: variant === "dark",
      [backgroundNeutral]: variant === "light",
      [backgroundWhite]: variant === "white",
    }
  );

  const modalInnerClasses = cn(
    "grid items-center md:grid-cols-2 max-h-offer-overlay-content-full"
  );

  const closeButtonClasses = cn(
    "absolute right-4 top-4 z-10 h-6 w-6 [&>svg]:w-full",
    {
      "fill-grey-3": isMobile,
      "fill-grey-6": !isMobile,
    }
  );

  const modalContentClasses = cn(
    "px-4 py-10 md:px-10 h-offer-overlay-content overflow-y-auto"
  );

  return (
    <dialog
      className="dialog fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/60 p-4"
      open={active}
      onClick={() => dismiss()}
    >
      <div className={modalClasses} onClick={(e) => e.stopPropagation()}>
        <button
          className={closeButtonClasses}
          dangerouslySetInnerHTML={{ __html: iconClose }}
          onClick={() => dismiss()}
        />
        <div className={modalInnerClasses}>
          {isMobile && (
            <div className={cn("h-offer-overlay-image order-first")}>
              <CustomMedia
                className={cn("max-h-full w-full", {
                  "h-full": media?.type === "youtube",
                })}
                rounded={false}
                media={media ?? { media: {}, type: "image" }}
                aspect={media?.type !== "youtube" ? "2:1" : ""}
              />
            </div>
          )}
          {!isMobile && (
            <div className={cn("h-auto", modalContentClasses)}>
              <CustomHeading
                heading={heading}
                headingClassNames={headingClassNames}
                headingOptions={headingOptions}
              />
              <RichText className={contentClassNames} tag="p">
                {content}
              </RichText>
              <CustomMedia
                media={media ?? { media: {}, type: "image" }}
                aspect="2:1"
              />
            </div>
          )}
          <div
            className={cn(
              modalContentClasses,
              "max-h-offer-overlay-content-full bg-white md:h-full"
            )}
          >
            {isMobile && (
              <>
                <CustomHeading
                  heading={heading}
                  headingClassNames={headingClassNames}
                  headingOptions={headingOptions}
                />
                <RichText className={contentClassNames} tag="p">
                  {content}
                </RichText>
              </>
            )}
            {formId && fields && (!!fields.length || formName === "embed") && (
              <div className="mx-auto md:mx-0 md:max-w-none">
                <MemoizedForm
                  fields={fields}
                  formId={formId}
                  embed={formName === "embed"}
                  variant={variant}
                  settings={{
                    hasCustomRedirect,
                    redirectUrl,
                    formErrorMessage,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </dialog>
  );
}

interface MemoizedFormProps {
  fields: MarketoFormFieldAny[];
  formId: number;
  embed: boolean;
  variant?: string;
  settings: {
    hasCustomRedirect: boolean;
    redirectUrl: string;
    formErrorMessage: string;
    starterInfo?: string;
    starterButton?: CustomButtonInterface;
  };
}

const MemoizedForm = memo(
  ({ fields, formId, settings, embed }: MemoizedFormProps) => {
    return (
      <MarketoFormBuilder
        fields={fields}
        formId={formId}
        settings={settings}
        embed={embed}
      />
    );
  }
);

// Must match __typename
const BLOCK_NAME = "TenupMarketoFormOverlay";

MarketoFormOverlayBlock.displayName = BLOCK_NAME;

MarketoFormOverlayBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          disableCookie
          disableLanguage
          fields
          formErrorMessage
          formId
          formName
          hasCustomRedirect
          heading
          headingOptions
          media
          metadata
          modalDelay
          offerId
          redirectUrl
          variant
        }
      }
    }
  `,
};
