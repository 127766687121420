export const RESOURCE_SEARCH_FOUND_RESULTS = {
  "en": "Found %(number) matching results for \"%(search)\"",
  "fr": "Found %(number) matching results for \"%(search)\"",
  "de": "Found %(number) matching results for \"%(search)\"",
  "es": "Found %(number) matching results for \"%(search)\"",
  "pt-br": "Found %(number) matching results for \"%(search)\"",
  "it": "Found %(number) matching results for \"%(search)\"",
  "ja": "Found %(number) matching results for \"%(search)\"",
  "ko": "Found %(number) matching results for \"%(search)\"",
  "nl": "Found %(number) matching results for \"%(search)\"",
  "pt-pt": "Found %(number) matching results for \"%(search)\"",
  "sv": "Found %(number) matching results for \"%(search)\"",
  "zh": "Found %(number) matching results for \"%(search)\""
}