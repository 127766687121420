import { useEffect, useState } from "react";

import { Blocks } from "@/components/molecules";
import { Cover } from "@/components/organisms";
import { Block, Style } from "@/types";
import formatFocalPoint from "@/utilities/formatFocalPoint";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  align?: "left" | "center" | "right" | "wide" | "full";
  anchor?: string;
  className?: string;
  contentPosition?:
    | "top left"
    | "center left"
    | "bottom left"
    | "top center"
    | "center center"
    | "bottom center"
    | "top right"
    | "center right"
    | "bottom right";
  customGradient?: string;
  customOverlayColor?: string;
  dimRatio: number;
  focalPoint: {
    x: string;
    y: string;
  };
  gradientHex?: string;
  hasParallax?: boolean;
  innerBlocks?: Block[];
  isRepeated?: boolean;
  minHeight?: number;
  minHeightUnit?: string;
  style?: Style;
  url: string;
}

export default function CoverBlock({
  align,
  anchor,
  className,
  contentPosition,
  customGradient,
  customOverlayColor,
  dimRatio,
  focalPoint,
  gradientHex,
  hasParallax,
  innerBlocks,
  isRepeated,
  minHeight,
  minHeightUnit,
  style,
  url,
}: Props) {
  const overlayColor = customOverlayColor || "#000000";
  const overlayGradient = customGradient || gradientHex;
  const overlayOpacity = url ? dimRatio / 100 : 1;

  // Add custom styles for Hero.
  const coverStyle = {};

  if (overlayColor && !overlayGradient) {
    Object.assign(coverStyle, { backgroundColor: overlayColor });
  }

  if (overlayGradient) {
    Object.assign(coverStyle, { background: overlayGradient });
  }

  if (minHeight && minHeightUnit !== "vh") {
    Object.assign(coverStyle, { minHeight: `${minHeight}px` });
  }

  const newFocalPoint = formatFocalPoint(focalPoint);

  const [newInnerBlocks, setNewInnerBlocks] = useState<Block[]>([]);

  // Add extra class(es) to inner blocks on initial load.
  useEffect(() => {
    console.log({ innerBlocks });
    setNewInnerBlocks(
      !innerBlocks?.length
        ? []
        : innerBlocks.map((block) => {
            const classes = (block?.attributes?.className ?? "").split(" ");

            // Extra check to only add class once.
            if (classes.includes("relative")) {
              return block;
            }

            block.attributes = {
              ...block?.attributes,
              className: `${block?.attributes?.className || ""} relative z-10`,
            };

            return block;
          })
    );
  }, [innerBlocks]);

  // Only proceed if we're provided a media URL or a user-selected overlay color/gradient.
  // if (!url && !overlayColorHex && !overlayGradient) {
  //   return null;
  // }

  return (
    <Cover
      align={align}
      contentAlign={contentPosition}
      backgroundImage={{ url }}
      className={className}
      duotone={style?.color?.duotone}
      fixed={hasParallax}
      focalPoint={newFocalPoint}
      fullHeight={minHeight === 100 && minHeightUnit === "vh"}
      id={anchor}
      overlayOpacity={overlayOpacity}
      repeat={isRepeated}
      style={coverStyle}
    >
      {!!newInnerBlocks?.length && <Blocks blocks={newInnerBlocks} />}
    </Cover>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreCover";

CoverBlock.displayName = BLOCK_NAME;

CoverBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          align
          allowedBlocks
          alt
          anchor
          backgroundType
          borderColor
          className
          contentPosition
          customGradient
          customOverlayColor
          dimRatio
          focalPoint
          fontFamily
          fontSize
          gradient
          hasParallax
          id
          isDark
          isRepeated
          isUserOverlayColor
          layout
          metadata
          minHeight
          minHeightUnit
          overlayColor
          style
          tagName
          textColor
          url
          useFeaturedImage
        }
      }
    }
  `,
};
