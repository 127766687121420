import { useState } from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import cn from "classnames";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslate } from "@/hooks/useTranslate";
import {
  SIGNUP_FORM_BUTTON_SUBMIT,
  SIGNUP_FORM_LOADING,
} from "@/i18n/translations";

import { isValidPartnershipEmail } from "@/utilities/isValidPartnershipEmail";
import specialOfferSetup from "@/utilities/specialOfferSetup";
import {
  textBodyInverse,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";

type Inputs = {
  Email: string;
};

interface PartnershipFormInterface {
  buttonText?: string;
  inputPlaceholder?: string;
  placeholderSize?: string;
  buttonTextSize?: string;
  variant?: string;
  column?: boolean;
  label?: string;
  redirectUrl?: string;
  specialOfferToken?: string;
  plan?: string;
  identifier?: string;
  checkAccount?: boolean;
  setIsSubmitted?: (setIsSubmitted: boolean) => void;
  errorMessages?: {
    validation?: string;
    required?: string;
    domainError?: string;
  };
  validDomains?: string;
  center?: boolean;
}

export default function PartnershipForm({
  buttonText = "",
  inputPlaceholder = "Enter email address",
  variant = "white",
  placeholderSize = "",
  buttonTextSize = "",
  label = "",
  redirectUrl = "",
  specialOfferToken = "",
  plan = "",
  identifier = "",
  checkAccount = false,
  column = false,
  center = false,
  errorMessages = {
    validation: "Email is invalid",
    required: "Email is required",
    domainError: "Email is not from an allowed domain",
  },
  validDomains,
}: PartnershipFormInterface) {
  const { locale } = useRouter();
  const { translate } = useTranslate();

  // Email Validation Schema
  const validationSchema = Yup.object({
    Email: Yup.string()
      .email(errorMessages.validation)
      .required(errorMessages.required)
      .test(`test-email-domain`, errorMessages.domainError ?? "", (value) => {
        const allowedDomains: string[] | undefined = validDomains
          ?.split(",")
          .map((each) => each.trim())
          .filter((each) => each);

        // If validDomains is not included, then there isn't an email domain requirement
        if (!allowedDomains || !allowedDomains.length) {
          return true;
        }
        // Uncomment for internal tests
        // if (value?.includes('kw_test')) {
        //   return true;
        // }
        return isValidPartnershipEmail({
          email: value,
          allowedDomains,
        });
      }),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(validationSchema) });

  buttonText = buttonText ? buttonText : translate(SIGNUP_FORM_BUTTON_SUBMIT);

  const onSubmit = async (values: { Email: string }) => {
    if (isSubmitting) return;

    await specialOfferSetup.createSpecialOffer({
      values,
      language: locale ?? "en",
      offerToken: specialOfferToken,
      checkAccountExists: checkAccount,
      redirectLink: redirectUrl,
      setSubmitting: setIsSubmitting,
      plan,
      identifier,
    });
  };

  const inputClassNames = cn(
    placeholderSize,
    "text-center md:text-left h-full w-full rounded border px-4 py-3 leading-tight focus:outline-none bg-transparent",
    {
      "border-red-600 focus:border-red-600": errors.Email,
      "border-grey-1": !errors.Email,
      "text-white": variant === "dark",
      "text-gray-800": variant !== "dark",
    }
  );

  const buttonClassNames = cn(
    "rounded-md cursor-pointer hover:no-underline transition-colors duration-500 px-4 py-3 self-start md:inline-block w-full block text-center",
    {
      "bg-white hover:bg-white-7 active:bg-white-8 disabled:bg-white-disabled text-blue-8":
        variant === "dark",
      "bg-blue-6 hover:bg-blue-7 active:bg-blue-8 disabled:opacity-10 text-white":
        variant !== "dark",
      "text-lg": buttonTextSize === "lg",
      "md:w-auto": !column,
      "m-auto": center,
    }
  );

  return (
    <>
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`md:mx-auto`}
        noValidate
      >
        <label
          className={cn("mb-1 block text-sm uppercase", {
            "mx-auto lg:max-w-md": center,
            [textBodyInverse]: variant === "dark",
            [textBodyNeutral]: variant !== "dark",
          })}
        >
          {label}
        </label>
        <div
          className={cn("wrap flex flex-col gap-2", {
            "mb-8": errors.Email,
            "lg:max-w-md": !column,
            "md:gap-6": column || center,
            "mx-auto": center,
          })}
        >
          {/* include validation with required or other standard HTML validation rules */}
          <div className="w-full">
            <input
              type="email"
              data-form-type="email"
              data-cy="sign-up-input"
              placeholder={inputPlaceholder}
              {...register("Email", { required: true })}
              className={inputClassNames}
            />
            {/* errors will return when field validation fails  */}
            {errors.Email?.message && (
              <p
                data-cy="sign-up-form-errors"
                className="absolute py-2 text-xs text-red-600"
              >
                {errors.Email?.message}
              </p>
            )}
          </div>

          <button
            data-cy="sign-up-button"
            type="submit"
            className={buttonClassNames}
            disabled={isSubmitting}
          >
            <div className="flex items-center justify-center">
              {isSubmitting && (
                <div
                  data-cy="sign-up-button-loader"
                  role="status"
                  className="absolute"
                >
                  <svg
                    aria-hidden="true"
                    className="inline h-4 w-4 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">
                    {translate(SIGNUP_FORM_LOADING)}
                  </span>
                </div>
              )}
              <span className={`${isSubmitting && "invisible"}`}>
                {buttonText}
              </span>
            </div>
          </button>
        </div>
      </form>
    </>
  );
}
