import { FaustHooks, FaustPlugin } from "@faustwp/core";

export default class ResolvedUrlPlugin implements FaustPlugin {
  apply(hooks: FaustHooks) {
    const { addFilter } = hooks;
    addFilter("resolvedUrl", "faust", (resolvedUrl, ctx) => {
      const locale = ctx?.nextContext?.locale;

      if (resolvedUrl === "/" && locale && locale !== "en") {
        resolvedUrl = `/${locale}`;
      }

      return resolvedUrl;
    });
  }
}
