export const SIGNUP_FORM_CONFIRMATION = {
  "en": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox.",
  "fr": "Nous vous remercions ! Vous êtes abonné. Soyez à l'affût des mises à jour envoyées directement à votre boîte de réception.",
  "de": "Vielen Dank! Sie sind abonniert. Halten Sie Ausschau nach Updates direkt in Ihrem Posteingang.",
  "es": "¡Gracias! Está suscrito. Esté atento a las actualizaciones que lleguen directamente a su bandeja de entrada.",
  "pt-br": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox.",
  "it": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox.",
  "ja": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox.",
  "ko": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox.",
  "nl": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox.",
  "pt-pt": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox.",
  "sv": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox.",
  "zh": "Thanks! You're subscribed. Be on the lookout for updates straight to your inbox."
}