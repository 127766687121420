import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor?: string;
  requiredHeight: string;
}

/**
 * The spacer block can be used to create space between content. Suggested use is height multiples of 16 pixels: 32px, 64px, etc.
 */
export default function SpacerBlock({ anchor = "", requiredHeight }: Props) {
  return (
    <div
      id={anchor}
      style={{
        height: requiredHeight,
      }}
      aria-hidden="true"
    />
  );
}

// Must match __typename
const BLOCK_NAME = "CoreSpacer";

SpacerBlock.displayName = BLOCK_NAME;

SpacerBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          requiredHeight: height
        }
      }
    }
  `,
};
