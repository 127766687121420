import cn from "classnames";
import { RichText } from "@/components/atoms";
import {
  textBodyInverse,
  textBodyNeutral,
  pTextL,
  marginBXS,
  textHeadlineNeutral,
  textHeadlineInverse,
} from "@/constants/standardCSSClasses";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  variant: string;
  heading?: string;
  content?: string;
}

export default function EmailCaptureItemBlock({
  heading = "",
  content = "",
  variant = "white",
}: Props) {
  const headerClassNames = cn("mt-0 text-4xl font-normal", marginBXS, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const pTextClassNames = cn("m-0", pTextL, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  return (
    <div>
      <RichText className={headerClassNames} tag="div">
        {heading}
      </RichText>
      <RichText className={pTextClassNames} tag="p">
        {content}
      </RichText>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupEmailCaptureItem";

EmailCaptureItemBlock.displayName = BLOCK_NAME;

EmailCaptureItemBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          heading
          metadata
        }
      }
    }
  `,
};
