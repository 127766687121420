import React, { PropsWithChildren } from "react";

interface Props {
  tagName?: string;
}

export default function Container({
  children,
  tagName = "div",
}: PropsWithChildren<Props>) {
  return React.createElement(
    tagName,
    { className: "mx-auto flex w-full max-w-7xl flex-col px-4 md:px-8" },
    children
  );
}
