import { useEffect, useState } from "react";
import { PreviewLoader } from "@/components/molecules";
import EnterpriseFormSuccess from "@/wp-templates/form-success/EnterpriseFormSuccess";
import DemoFormSuccess from "@/wp-templates/form-success/DemoFormSuccess";
import TrialFormSuccess from "@/wp-templates/form-success/TrialFormSuccess";
import {
  TRIAL_FORM_ID,
  DEMO_FORM_ID,
  SALES_FORM_ID,
} from "@/constants/formIds";
import PartnersFormSuccess from "./PartnersFormSuccess";
import { LeanDataFormType, loadBookItIframe } from "@/utilities/loadLeanData";
import { isMobileBrowser } from "@/utilities/isMobileBrowser";

export default function FormSuccess({
  setShowIframe,
}: {
  setShowIframe: (showIframe: boolean) => void;
}) {
  const [loading, setLoading] = useState(true);
  const [bookItSrc, setBookItSrc] = useState("");
  const [inboundCompanySize, setInboundCompanySize] = useState("");
  const [formId, setFormId] = useState(0);
  const [from, setFrom] = useState("/business-password-manager/try");
  const [plan, setPlan] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const localStorageForm = localStorage.getItem("form-submission-data");
    let formData: { Email?: string } = {};

    if (localStorageForm) {
      formData = JSON.parse(localStorageForm);
    }

    const queryParams = new URLSearchParams(window.location.search);
    const bookItParam = queryParams.get("calendarLink");
    const inboundCompanySizeParam = queryParams.get("inboundCompanySize");
    const formIdParam = queryParams.get("formId");
    const fromParam = queryParams.get("from");
    const planParam = queryParams.get("plan");

    const email = formData.Email ? formData.Email : queryParams.get("email");

    if (bookItParam) {
      setBookItSrc(bookItParam);
    }

    if (inboundCompanySizeParam) {
      setInboundCompanySize(inboundCompanySizeParam);
    }

    if (formIdParam) {
      const parsedFormId = parseInt(formIdParam, 10);
      setFormId(parsedFormId);
    }

    if (fromParam) {
      setFrom(fromParam);
    }

    if (planParam) {
      setPlan(planParam);
    }

    if (email) {
      setEmail(email);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (formId === TRIAL_FORM_ID && isMobileBrowser()) {
      return;
    }
    const formIdMap: Record<number, LeanDataFormType> = {
      [TRIAL_FORM_ID]: "Business Trial",
      [DEMO_FORM_ID]: "Demo Request",
      [SALES_FORM_ID]: "Contact Sales",
    };
    if (formIdMap[formId]) {
      loadBookItIframe(formIdMap[formId] as LeanDataFormType);
    }
  }, [formId]);

  useEffect(() => {
    if (formId === TRIAL_FORM_ID && isMobileBrowser()) {
      return;
    }

    const handleBookItResponse = (e: MessageEvent) => {
      if (e.data.message === "LD_ROUTING_RESPONSE") {
        const routingResponseData = e.data.responseData;
        const calendarLink = routingResponseData?.calendarLink;
        setBookItSrc(calendarLink);
        setShowIframe(!!calendarLink);
        window.removeEventListener("message", handleBookItResponse);
      }
    };

    if (window.LDBookItV2) {
      window.addEventListener("message", handleBookItResponse);
    }

    return () => {
      window.removeEventListener("message", handleBookItResponse);
    };
  }, []);

  return (
    <>
      {loading && <PreviewLoader />}
      {!loading && formId === SALES_FORM_ID && (
        <EnterpriseFormSuccess
          from={from}
          bookItSrc={bookItSrc}
          inboundCompanySize={inboundCompanySize}
        />
      )}
      {!loading && formId === DEMO_FORM_ID && (
        <DemoFormSuccess
          bookItParam={bookItSrc}
          inboundCompanySize={inboundCompanySize}
        />
      )}
      {!loading && formId === TRIAL_FORM_ID && (
        <TrialFormSuccess from={from} plan={plan} email={email} />
      )}
      {from === "deal-submission" && <PartnersFormSuccess />}
    </>
  );
}
