import React, { useContext } from "react";
import cn from "classnames";

import { Blocks } from "@/components/molecules";
import TypeNameContext from "@/contexts/typeNameContext";
import { Block, Style } from "@/types";
import getBlockStyles from "@/utilities/getBlockStyles";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  align?: "full" | "wide";
  ariaLabel: string;
  anchor: string;
  className?: string;
  customBackgroundColor?: string;
  innerBlocks?: Block[];
  layout?: {
    flexWrap?: "nowrap" | "wrap";
    justifyContent?: "left" | "right" | "center" | "space-between";
    orientation?: "vertical" | "horizontal";
    type?: "flex" | "constrained";
    verticalAlignment?: "top" | "center" | "bottom" | "stretch";
  };
  style?: Style;
  tagName?: string;
}

export default function GroupBlock({
  align,
  ariaLabel,
  anchor,
  className,
  customBackgroundColor,
  innerBlocks,
  layout,
  style,
  tagName = "div",
}: Props) {
  const typeNameContext = useContext(TypeNameContext);
  const groupStyle = getBlockStyles({
    backgroundColorHex: customBackgroundColor,
    style,
  });
  const shouldBeRow =
    layout?.type === "flex" &&
    layout.flexWrap === "nowrap" &&
    layout.orientation !== "vertical";

  return React.createElement(
    tagName,
    {
      className: cn(
        "flex gap-4",
        !className?.includes("p-0") && "px-8 py-4",
        !className?.includes("m-0") && "my-3",
        layout?.flexWrap === "wrap" ? "flex-wrap" : "flex-nowrap",
        shouldBeRow ? "flex-row" : "flex-col",
        // default when no justification provided
        shouldBeRow && !layout.justifyContent ? "justify-start" : "items-start",
        // justification for rows
        shouldBeRow && layout.justifyContent === "left" && "justify-start",
        shouldBeRow && layout.justifyContent === "center" && "justify-center",
        shouldBeRow && layout.justifyContent === "right" && "justify-end",
        shouldBeRow &&
          layout.justifyContent === "space-between" &&
          "justify-between",
        // alignment for cols
        !shouldBeRow && layout?.justifyContent === "left" && "items-start",
        !shouldBeRow && layout?.justifyContent === "center" && "items-center",
        !shouldBeRow && layout?.justifyContent === "right" && "items-end",
        // default when no alignment provided
        shouldBeRow && !layout.verticalAlignment
          ? "items-start"
          : "justify-start",
        // alignment for rows
        shouldBeRow && layout.verticalAlignment === "top" && "items-start",
        shouldBeRow && layout.verticalAlignment === "center" && "items-center",
        shouldBeRow && layout.verticalAlignment === "bottom" && "items-end",
        shouldBeRow &&
          layout.verticalAlignment === "stretch" &&
          "items-stretch",
        // justification for cols
        !shouldBeRow && layout?.verticalAlignment === "top" && "justify-start",
        !shouldBeRow &&
          layout?.verticalAlignment === "center" &&
          "justify-center",
        !shouldBeRow && layout?.verticalAlignment === "bottom" && "justify-end",
        !shouldBeRow &&
          layout?.verticalAlignment === "stretch" &&
          "justify-stretch",
        align === "wide" && "w-full mx-auto max-w-7xl",
        align === "full" &&
          typeNameContext === "Page" &&
          "w-full mx-auto max-w-7xl min-h-[80vh]",
        className
      ),
      id: anchor,
      style: groupStyle,
      "aria-label": ariaLabel,
    },
    !!innerBlocks?.length && <Blocks blocks={innerBlocks} />
  );
}

// Must match __typename
const BLOCK_NAME = "CoreGroup";

GroupBlock.displayName = BLOCK_NAME;

GroupBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          align
          anchor
          backgroundColor
          borderColor
          className
          gradient
          layout
          metadata
          style
          tagName
        }
      }
    }
  `,
};
