import React from "react";
import classnames from "classnames";
import Image from "next/image";

export interface CustomImageInterface {
  full: string;
  alt: string;
  objectFit?: string;
  aspect?: string;
  caption?: string;
  height?: string | number;
  width?: string | number;
  className?: string;
  imageClassName?: string;
  rounded?: boolean;
}

const CustomImage = ({
  full,
  alt = "",
  aspect,
  className,
  imageClassName,
  objectFit = "cover",
  rounded = true,
}: CustomImageInterface) => {
  const classNames = classnames(className, "relative", {
    "aspect-[1/1]": aspect === "1:1",
    "aspect-[3/2]": aspect === "3:2",
    "aspect-[2/1]": aspect === "2:1",
    "aspect-[16/9]": aspect === "16:9",
    "aspect-[824/477]": aspect === "824:477" || aspect === "contentCard",
    "aspect-[423/331]":
      aspect === "423:331" || aspect === "featInteractiveMobile",
  });

  const imageClassNames = classnames(imageClassName, {
    rounded: rounded,
    "object-cover": objectFit === "cover",
    "object-contain": objectFit === "contain",
  });

  return (
    <figure className={classNames}>
      <Image
        className={imageClassNames}
        src={full}
        alt={alt}
        fill
        sizes="(max-width: 350px) 33vw (max-width: 768px) 50vw, (max-width: 1200px) 75vw, 100vw"
      />
    </figure>
  );
};

export default CustomImage;
