import { useState } from "react";
import cn from "classnames";
import CustomImage from "../molecules/CustomImage";
import ConditionalWrapper from "@/components/molecules/ConditionalWrapper";
import {
  h6Text,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { RichText } from "@/components/atoms";
import React from "react";
import Link from "next/link";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

export interface TeamMemberBlockInterface {
  image?: object;
  name?: string;
  position?: string;
  content?: string;
  buttonText?: string;
  buttonTextOpen?: string;
  variant?: string;
  className?: string;
  link?: {
    url?: string;
    opensInNewTab?: string;
  };
}

export interface TeamMemberImageInterface {
  full?: string;
  medium?: string;
  alt?: string;
}

const TeamMemberBlock = ({
  image = {},
  name = "",
  position = "",
  content = "",
  buttonText = "",
  buttonTextOpen = "",
  variant = "light",
  link = {},
  className = "",
}: TeamMemberBlockInterface) => {
  const [open, setOpen] = useState(false);
  const { full = "", medium, alt = "" }: TeamMemberImageInterface = image;

  //Classes to account for mobile, borders, variable number of items, etc.
  const wrapClassName = cn("my-10", className);

  const textColor = cn({
    [textHeadlineNeutral]: variant !== "dark",
    [textHeadlineInverse]: variant === "dark",
  });

  const nameClassNames = cn("mt-8", h6Text, textColor);
  const positionClassNames = cn("mt-2", textColor);
  const readMoreClassNames = cn(
    "block underline hover:no-underline mt-4 text-xl",
    textColor
  );
  const contentClassNames = cn("inline-block mt-4", textColor);

  const includeLink = !!link.url;

  const linkWrapper = (children: React.ReactNode) => (
    <Link
      target={link.opensInNewTab ? "_blank" : undefined}
      rel={link.opensInNewTab ? "noreferrer noopener" : undefined}
      href={link.url ?? ""}
    >
      {children}
    </Link>
  );

  return (
    <div className={wrapClassName}>
      <ConditionalWrapper condition={includeLink} wrapper={linkWrapper}>
        <CustomImage
          full={medium || full}
          alt={alt}
          className="w-full max-w-[298px] overflow-hidden rounded"
          objectFit="cover"
          aspect="1:1"
          rounded={false}
        />
      </ConditionalWrapper>
      <RichText className={nameClassNames} tag="p">
        {name}
      </RichText>
      <RichText className={positionClassNames} tag="p">
        {position}
      </RichText>
      {content && (
        <>
          {!open && (
            <Link
              className={readMoreClassNames}
              href="#"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
            >
              {buttonText}
            </Link>
          )}
          {open && (
            <Link
              className={readMoreClassNames}
              href="#"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setOpen(!open);
              }}
            >
              {buttonTextOpen}
            </Link>
          )}
          {open && (
            <RichText className={contentClassNames} tag="div">
              {content}
            </RichText>
          )}
        </>
      )}
    </div>
  );
};

// Must match __typename
const BLOCK_NAME = "TenupTeamMember";

TeamMemberBlock.displayName = BLOCK_NAME;

TeamMemberBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          buttonText
          buttonTextOpen
          className
          content
          image
          link
          metadata
          name
          position
        }
      }
    }
  `,
};

export default TeamMemberBlock;
