export const htmlDecode = (str: string) => {
  const entities: { [key: string]: string } = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&apos;": "'",
    "&quot;": '"',
    // Add more entities as needed
  };

  return str.replace(/&[a-z]+;/g, (entity) => {
    return entities[entity] || entity;
  });
};
