import { gql } from "@apollo/client";

const ResourceFragment = gql`
  fragment ResourceFragment on Resource {
    title
    content
    link
    excerpt
    uri
    slug
    ...BlocksFragment
    ...FeaturedImageFragment
    resourceFields {
      ctaText
      featured
      file {
        node {
          mediaItemUrl
          title
        }
      }
      youtubeTitle
      youtubeUrl
      related {
        nodes {
          ... on Resource {
            id
            title
            uri
            excerpt
            ...FeaturedImageFragment
            resourceTypes(first: 1) {
              nodes {
                name
              }
            }
          }
        }
      }
    }
    resourceTypes(first: 1) {
      nodes {
        name
      }
    }
    resourceCategories(first: 1) {
      nodes {
        name
      }
    }
    contentType {
      node {
        name
      }
    }
    seo {
      fullHead
    }
  }
`;

export default ResourceFragment;
