import { Block, BlockAttributesJson } from "@/types";

/**
 * Turn JSON attributes into objects
 *
 * @param attributes
 */
const parseAtts = (attributes: any) => {
  const newAtts: any = {};

  if (!attributes) return attributes;

  Object.keys(attributes).forEach((each) => {
    let origVal = attributes[each];
    let newVal = "";

    try {
      newVal = JSON.parse(attributes[each]);
    } catch (e) {
      newVal = origVal;
    }

    if (newVal !== null) {
      newAtts[each] = newVal;
    }
  });

  return newAtts;
};

export const parseBlocks = (blocks: BlockAttributesJson[]): Block[] => {
  return blocks.map((each) => {
    return {
      ...each,
      attributes: parseAtts(each.attributes),
    };
  });
};
