import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import Link from 'next/link';

export default function parseHtml(html: string, locale?: string) {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      const { name, attribs, children } = domNode as any;
      // Convert internal links to Next.js Link components.
      const isInternalLink =
        name === 'a' && attribs['data-internal-link'] === 'true';

      if (isInternalLink) {
        return (
          <Link href={attribs.href} {...attribs} locale={locale}>
            {domToReact(children, options)}
          </Link>
        );
      } else {
        return;
      }
    },
  };

  return parse(html, options);
}
