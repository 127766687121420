import React from "react";
import cn from "classnames";
import { Heading, RichText } from "@/components/atoms";
import type { HeadingLevel, PostPreview } from "@/types";
import PostPreviewPosts from "@/components/molecules/PostPreviewPosts";
import { LoadingIcon } from "@/components/icons";
import ConditionalWrapper from "@/components/molecules/ConditionalWrapper";
import { innerContainer } from "@/constants/standardCSSClasses";
import { ArchiveInserts } from "@/types";

interface Props {
  className?: string;
  description?: string;
  inserts?: ArchiveInserts;
  heading?: string;
  headingColor?: string;
  hideExcerpt?: boolean;
  hideImage?: boolean;
  level?: HeadingLevel;
  moreLink?: string;
  posts?: PostPreview[];
  hidePostSection?: boolean;
  readMore?: boolean;
  topBorderColor?: string;
  loading?: boolean;
  hero?: boolean;
}

export default function PostPreviewSection({
  className,
  description,
  inserts,
  heading,
  headingColor,
  hideExcerpt = false,
  hideImage = false,
  level = "h1",
  moreLink,
  posts,
  hidePostSection,
  readMore = false,
  topBorderColor,
  loading = false,
  hero = false,
}: Props) {
  return (
    <div
      className={cn(className, {
        "bg-white py-4": !className && !hero,
      })}
    >
      <ConditionalWrapper
        wrapper={(children: React.ReactNode) => (
          <div className={innerContainer}>{children}</div>
        )}
        condition={!className?.includes("bg-") && !hero}
      >
        <ConditionalWrapper
          wrapper={(children: React.ReactNode) => (
            <div className="bg-blue-1 py-10">{children}</div>
          )}
          condition={hero}
        >
          <ConditionalWrapper
            wrapper={(children: React.ReactNode) => (
              <div className={innerContainer}>{children}</div>
            )}
            condition={hero}
          >
            {inserts?.beforeTitle ?? null}
            {heading && (
              <Heading
                level={level}
                className={cn({
                  "text-blue-8": headingColor === "midgreen",
                  "text-orange-500": headingColor === "orange",
                  "text-pink-bold": headingColor === "pink",
                  "mb-4 mt-12 text-left text-3xl lg:text-5xl": level === "h1",
                  "text-center text-xl uppercase lg:text-2xl": level === "h2",
                })}
              >
                {heading}
              </Heading>
            )}
            {inserts?.afterTitle ?? null}
            {description && (
              <RichText tag="div" className="mb-4 text-xl text-blue-8">
                {description}
              </RichText>
            )}
            {inserts?.afterDescription ?? null}
          </ConditionalWrapper>
        </ConditionalWrapper>
        <ConditionalWrapper
          wrapper={(children: React.ReactNode) => (
            <div className={innerContainer}>{children}</div>
          )}
          condition={hero}
        >
          {inserts?.featured ?? null}
          {inserts?.beforeArchive ?? null}
          {loading && <LoadingIcon />}
          {posts && !hidePostSection && (
            <PostPreviewPosts
              posts={posts}
              readMore={readMore}
              moreLink={moreLink}
              hideExcerpt={hideExcerpt}
              hideImage={hideImage}
              topBorderColor={topBorderColor}
            />
          )}
        </ConditionalWrapper>
      </ConditionalWrapper>
    </div>
  );
}
