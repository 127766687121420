import { SEO } from "@/components/molecules";
import { Archive, Footer } from "@/components/organisms/index";
import { Main } from "@/components/atoms";
import React from "react";
import { Locale, MenuItems, OffsetPagination, PostPreview } from "@/types";
import { ArchiveInserts } from "@/types";
import DynamicHeader from "@/components/organisms/DynamicHeader";
import { EnhancedMenuItems } from "@/types/Menu";

interface ArchiveTemplateInterface {
  name: string;
  metaTitle: string;
  inserts?: ArchiveInserts;
  description: string;
  metaDescription: string;
  primaryMenu: MenuItems;
  newMenu?: EnhancedMenuItems;
  posts?: PostPreview[];
  hidePostSection?: boolean;
  offsetPagination?: OffsetPagination;
  locale: Locale;
  categoryName?: string;
  footerMenu: MenuItems;
  loading?: boolean;
  paginationType?: "default" | "queryParam";
  hero?: boolean;
  uri?: string;
}

export default function ArchiveTemplate({
  name,
  metaTitle,
  description,
  inserts,
  metaDescription,
  primaryMenu,
  newMenu,
  posts,
  hidePostSection,
  offsetPagination,
  locale,
  categoryName,
  footerMenu,
  loading = false,
  paginationType = "default",
  hero = false,
  uri,
}: ArchiveTemplateInterface) {
  return (
    <>
      <SEO
        title={`${name} - ${metaTitle}`}
        description={description ?? metaDescription}
        locale={locale}
        uri={uri}
      />
      <DynamicHeader
        title={metaTitle}
        description={metaDescription}
        menuItems={primaryMenu}
        enhancedMenuItems={newMenu}
      />
      <Main>
        <Archive
          inserts={inserts}
          hero={hero}
          description={description}
          heading={name}
          posts={posts}
          hidePostSection={hidePostSection}
          offsetPagination={offsetPagination}
          variables={{
            locale,
            categoryName,
          }}
          loading={loading}
          readMore={false}
          paginationType={paginationType}
        />
      </Main>
      <Footer title={metaTitle} menuItems={footerMenu} />
    </>
  );
}
