import { coreDashGreen } from "@/styles/colors";

export default function CloseIcon({
  color = coreDashGreen,
}: {
  color?: string;
}) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3495 23.5416L0.652344 43.2387L4.029 46.6154L23.7261 26.9182L43.4233 46.6154L46.8 43.2388L27.1028 23.5416L46.8 3.84443L43.4233 0.467773L23.7261 20.1649L4.029 0.467802L0.652344 3.84446L20.3495 23.5416Z"
        fill={color}
      />
    </svg>
  );
}
