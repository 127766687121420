import axios from "axios";
import queryString from "query-string";

export default class BaseLogs {
  protected WSURL: string;

  protected WSVERSION: string;

  protected WSNAME: string;

  protected CONFIG: Record<string, unknown>;

  constructor() {
    // WSURL w/ subdomain required due to adblocker blacklisting
    // This will probably need to change again in the future
    this.WSURL = "https://kck3hlb9.dashlane.com";
    this.WSVERSION = "";
    this.WSNAME = "";
    this.CONFIG = {
      headers: { "client-agent": "{version:1,platform:website}" },
      transformRequest: [(data: any) => queryString.stringify(data)],
    };
  }

  makeRequest(wsMethod: string, options: any) {
    return axios
      .post(
        `${this.WSURL}/${this.WSVERSION}/${this.WSNAME}/${wsMethod}`,
        options,
        this.CONFIG
      )
      .then((result) => {
        return result.data;
      });
  }
}
