import { PlanSegment } from "@/types/PlanSegment";

interface IPricingProps {
  planSegment: PlanSegment;
  teamOffers: any;
  webOffers: any;
  fallbackPrice?: string;
}

export default function Price({
  planSegment,
  teamOffers,
  webOffers,
}: IPricingProps) {
  const loadingPrice = "--";

  if (
    planSegment === "businessPlus" ||
    planSegment === "business" ||
    (planSegment === "standard" && teamOffers)
  ) {
    return (
      teamOffers?.formattedPlanPrices[planSegment]?.equivalentMonthly ??
      loadingPrice
    );
  }

  if (
    (planSegment === "premium" ||
      planSegment === "family" ||
      planSegment === "free") &&
    webOffers
  ) {
    return (
      webOffers?.formattedDefaultWebOffers[planSegment]?.annually ??
      loadingPrice
    );
  }

  return loadingPrice;
}
