import { Locale } from "@/types";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";

export const PRIMARY_LOCATION = "PRIMARY";
export const FOOTER_LOCATION = "FOOTER";
export const NEW_MENU_LOCATION = "DASHLANE_MEGAMENU";
export const NUMBER_OF_MENU_ITEMS = 100;

function formatLocation(location: string, locale: Locale) {
  if (formatLocaleForGraphQL(locale) === "EN" || !locale) return location;

  return location + "___" + formatLocaleForGraphQL(locale);
}

export const newMenuLocation = (locale: Locale) => {
  return formatLocation(NEW_MENU_LOCATION, locale);
};

export const newFooterLocation = (locale: Locale) => {
  return formatLocation(FOOTER_LOCATION, locale);
};

export const newHeaderLocation = (locale: Locale) => {
  return formatLocation(PRIMARY_LOCATION, locale);
};
