import { useState, useEffect } from "react";
import { Locale } from "@/types";

export const useBrowserLanguage = (locale: Locale | string = "en") => {
  const [language, setLanguage] = useState(locale);

  useEffect(() => {
    const newLang =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;

    if (newLang) setLanguage(newLang);
  }, [language]);

  return { language };
};
