export const ABOUT_CAREERS_NOT_FOUND = {
  "en": "Looks like nothing matches your criteria, but please check back—we're growing!",
  "fr": "Aucun élément ne correspond à votre recherche. N'hésitez pas à revenir consulter nos offres régulièrement, nous sommes en plein développement !",
  "de": "Sieht so aus, als würde gerade kein Eintrag Ihren Suchkriterien entsprechen. Schauen Sie aber bald wieder vorbei, unser Unternehmen wächst!",
  "es": "Parece que no hay coincidencias para los criterios establecidos, pero vuelva a consultar pronto, ¡estamos creciendo!",
  "pt-br": "Looks like nothing matches your criteria, but please check back—we're growing!",
  "it": "Looks like nothing matches your criteria, but please check back—we're growing!",
  "ja": "Looks like nothing matches your criteria, but please check back—we're growing!",
  "ko": "Looks like nothing matches your criteria, but please check back—we're growing!",
  "nl": "Looks like nothing matches your criteria, but please check back—we're growing!",
  "pt-pt": "Looks like nothing matches your criteria, but please check back—we're growing!",
  "sv": "Looks like nothing matches your criteria, but please check back—we're growing!",
  "zh": "Looks like nothing matches your criteria, but please check back—we're growing!"
}