import cn from 'classnames';

import { RichText } from '@/components/atoms';
import { TableRow } from '@/types/Table';

interface Props {
  body: TableRow[];
  caption?: string;
  className?: string;
  foot?: TableRow[];
  head?: TableRow[];
  id?: string;
}

export default function Table({
  body,
  caption,
  className,
  foot,
  head,
  id,
}: Props) {
  return (
    <>
      {body.length && (
        <figure className={cn('my-3', className)} id={id}>
          <table className="w-full border-collapse">
            {!!head?.length && (
              <thead>
                {head.map((row, index) => {
                  return (
                    <tr key={index} className="border-b">
                      {row?.cells &&
                        row.cells.map((cell, index) => {
                          return (
                            <RichText
                              tag="th"
                              key={index}
                              className={cn(
                                'p-6 uppercase',
                                cell?.align === 'center' ? 'text-center' : null,
                                !cell?.align || cell.align === 'left'
                                  ? 'text-left'
                                  : null,
                                cell?.align === 'right' ? 'text-right' : null
                              )}
                            >
                              {cell.content}
                            </RichText>
                          );
                        })}
                    </tr>
                  );
                })}
              </thead>
            )}
            <tbody>
              {body.map((row, index) => {
                return (
                  <tr key={index} className="border">
                    {row?.cells &&
                      row.cells.map((cell, index) => {
                        return (
                          <RichText
                            tag="td"
                            key={index}
                            className={cn(
                              'border-l p-2',
                              cell?.align !== ''
                                ? `text-${cell.align}`
                                : 'text-left'
                            )}
                          >
                            {cell.content}
                          </RichText>
                        );
                      })}
                  </tr>
                );
              })}
            </tbody>

            {!!foot?.length && (
              <tfoot>
                {foot.map((row, index) => {
                  return (
                    <tr key={index} className="border-t">
                      {row?.cells &&
                        row.cells.map((cell, index) => {
                          return (
                            <RichText
                              tag="td"
                              key={index}
                              className={cn(
                                'font-bold',
                                cell?.align !== ''
                                  ? `text-${cell.align}`
                                  : 'text-left'
                              )}
                            >
                              {cell.content}
                            </RichText>
                          );
                        })}
                    </tr>
                  );
                })}
              </tfoot>
            )}
          </table>
          {caption && (
            <div className="mt-4 mb-8 text-center text-xs">
              <RichText tag="span">{caption}</RichText>
            </div>
          )}
        </figure>
      )}
    </>
  );
}
