import { Locale, I18nKey } from "@/types";
import { useRouter } from "next/router";
import {
  I18nVariables,
  interpolateTranslatedString,
} from "@/utilities/interpolate";

export const useTranslate = () => {
  const router = useRouter();
  const locale = (router.locale as Locale) ?? "en";

  return {
    translate: (key: I18nKey) => {
      if (!key) return "";
      return key[locale];
    },
    translateInterpolate: (key: I18nKey, variables: I18nVariables) => {
      if (!key) return "";
      return interpolateTranslatedString(key[locale], variables);
    },
    locale: locale,
  };
};
