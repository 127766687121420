import { gql } from "@apollo/client";
import * as MENUS from "@/constants/menus";
import { FeaturedImageFragment } from "@/fragments";
import { Container, ContentWrapper, Main } from "@/components/atoms";
import {
  Blocks,
  EntryHeader,
  PostShare,
  PreviewLoader,
  SEO,
  SignUpForm,
} from "@/components/molecules";
import { Footer } from "@/components/organisms";
import type {
  Block,
  Locale,
  Translated,
  Resource as ResourceType,
  RelatedResource,
} from "@/types";
import { formatLocaleForGraphQL } from "@/utilities/formatLocaleForGraphQL";
import CustomButton from "@/components/molecules/CustomButton";
import { h4Text } from "@/constants/standardCSSClasses";
import cn from "classnames";
import Card, { CardInterface } from "@/components/molecules/Card";
import React from "react";
import ArchiveBackButton from "@/components/atoms/ArchiveBackButton";
import { Site } from "@/types";
import {
  GetEditorBlocksFragments,
  GetEditorBlocksKeys,
  GetSingle,
} from "@/fragments/fragmentFunctions";
import { useTranslate } from "@/hooks/useTranslate";
import {
  RESOURCE_BACK_TO_HUB_BUTTON,
  RESOURCE_SINGLE_RELATED_HEADING,
} from "@/i18n/translations";
import DynamicHeader from "@/components/organisms/DynamicHeader";
import getFeaturedImageFallback from "@/utilities/getFeaturedImageFallback";
import flatListToHierarchical from "@/utilities/flatListToHierarchical";

interface Props extends Site {
  data: Site["data"] & {
    resource: ResourceType;
  };
  loading: boolean;
}

export default function Component(props: Props) {
  const { translate } = useTranslate();

  const {
    title,
    content,
    featuredImage,
    link,
    seo,
    uri,
    resourceTypes,
    resourceCategories,
    resourceFields,
  } = props.data.resource;

  if (typeof window !== "undefined" && resourceFields.externalUrl) {
    window.location.href = resourceFields.externalUrl;
  }

  // Needed for previews to load on the client
  if (props.loading) {
    return <PreviewLoader />;
  }

  const translated =
    props.__SEED_NODE__?.translations?.filter((each: Translated) => {
      return each.status === "publish";
    }) ?? [];
  const { title: siteTitle, description: siteDescription } =
    props.data.generalSettings;
  const primaryMenu = props.data.headerMenuItems.nodes;
  const footerMenu = props.data.footerMenuItems.nodes;
  const newMenuItems = props.data.newMenuItems.nodes;
  const { file, related, ctaText, youtubeTitle, youtubeUrl } = resourceFields;
  const types = resourceTypes?.nodes;
  const type = types[0] ? types[0].name : "";
  const categories = resourceCategories?.nodes;

  // We retrieve extra resources in GraphQL to account for duplicates
  // An alternative would be to extend GraphQL to not have to retrieve these extra,
  // but that query could potentially end up being just as expensive, if not more
  const categoryRelated =
    categories && categories[0] ? categories[0].resources?.nodes : [];

  let allRelated: RelatedResource[] = [];
  if (related?.nodes?.length) {
    allRelated = allRelated.concat(related.nodes);
  }
  if (categoryRelated?.length) {
    allRelated = allRelated.concat(
      categoryRelated.filter((each) => each.uri !== uri)
    );
  }

  // Remove duplicates
  const relatedAdded: string[] = [];
  allRelated = allRelated.filter((each) => {
    if (relatedAdded.includes(each.uri)) return false;
    relatedAdded.push(each.uri);
    return true;
  });

  const blocks = flatListToHierarchical(props.data.resource.editorBlocks, {
    idKey: "id",
    parentKey: "parentClientId",
    childrenKey: "innerBlocks",
  });
  const isFreeformBlock = blocks?.[0]?.name === "core/freeform";
  const shouldUseBlocks =
    blocks[0]?.name?.includes("tenup/") ||
    blocks[0]?.attributes?.content ||
    blocks[0]?.attributes?.value ||
    blocks[0]?.innerBlocks?.length; // case study posts begin with columns component where content lies within innerBlocks

  const jumpLinks = blocks.filter(
    (each: Block) => each.name === "tenup/jump-link-section"
  );

  const banner = blocks.filter((each: Block) => each.name === "tenup/banner");

  return (
    <>
      <SEO
        fullHead={seo.fullHead}
        availableLocales={translated}
        uri={uri}
        locale={props.locale}
      />
      <DynamicHeader
        title={siteTitle}
        description={siteDescription}
        menuItems={primaryMenu}
        enhancedMenuItems={newMenuItems}
        availableLocales={translated}
        banner={banner?.[0]}
      />
      <Main>
        <Container tagName="article">
          <ArchiveBackButton
            className="mt-10"
            text={translate(RESOURCE_BACK_TO_HUB_BUTTON)}
            href={"resources"}
          />
          <div className="mx-auto max-w-4xl pb-20">
            {!jumpLinks.length ? (
              <EntryHeader
                title={title}
                image={featuredImage}
                category={type}
                youtubeTitle={youtubeTitle}
                youtubeUrl={youtubeUrl}
              />
            ) : null}
            <div className="flex flex-col gap-10 md:flex-row md:gap-20">
              <div className="lg:grow">
                {!isFreeformBlock && shouldUseBlocks ? (
                  <Blocks blocks={blocks} />
                ) : (
                  <ContentWrapper content={content} />
                )}
                {ctaText && file && (
                  <CustomButton
                    className="mt-6"
                    variant="dark"
                    size="lg"
                    href={file.node.mediaItemUrl}
                    text={ctaText}
                  />
                )}
              </div>
              <div>
                <PostShare
                  url={link}
                  title={title}
                  className="rounded bg-blue-1 p-6 md:w-[186px]"
                  shareStyle="resource"
                />
              </div>
            </div>
            <SignUpForm />
          </div>
        </Container>
      </Main>
      {allRelated && allRelated!.length && (
        <div className="bg-blue-1 py-10">
          <div className="mx-auto max-w-7xl px-4 md:px-8">
            <h2 className={cn(h4Text, "mb-10 text-center")}>
              {translate(RESOURCE_SINGLE_RELATED_HEADING)}
            </h2>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
              {allRelated.map((each, index) => {
                if (index > 2) return null;

                const card: CardInterface = {
                  heading: each.title,
                  content: each.excerpt.replace(/\n/g, " ").trim(),
                  imageAspect: "contentCard",
                  border: false,
                  includeCardButtons: false,
                  variant: "light",
                  visual: "image",
                  headingOptions: { tag: 2 },
                  linkCard: true,
                  linkUri: each.resourceFields?.externalUrl
                    ? each.resourceFields?.externalUrl
                    : each.uri,
                  tag: {
                    link: "",
                    name: type,
                  },
                  media: {
                    type: "image",
                    media: {
                      ...getFeaturedImageFallback(each.featuredImage?.node),
                      aspect: "contentCard",
                      rounded: true,
                    },
                  },
                };

                return <Card key={index} {...card} />;
              })}
            </div>
          </div>
        </div>
      )}
      <Footer
        title={siteTitle}
        menuItems={footerMenu}
        availableLocales={translated}
        locale={props.locale}
      />
    </>
  );
}

Component.query = GetSingle(
  `
  resource(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      content
      ${GetEditorBlocksKeys()}
      link
      excerpt
      uri
      slug
      ...FeaturedImageFragment
      resourceFields {
        ctaText
        featured
        file {
          node {
            mediaItemUrl
            title
          }
        }
        externalUrl
        youtubeTitle
        youtubeUrl
        related {
          nodes {
            ... on Resource {
              id
              title
              uri
              excerpt
              ...FeaturedImageFragment
              resourceTypes {
                nodes {
                  name
                }
              }
              resourceFields {
                externalUrl
              }
            }
          }
        }
      }
      resourceTypes(first: 1) {
        nodes {
          name
        }
      }
      resourceCategories(first: 1) {
        nodes {
          name
          resources(first: 5) {
            nodes {
              ... on Resource {
                id
                title
                uri
                excerpt
                ...FeaturedImageFragment
                resourceTypes {
                  nodes {
                    name
                  }
                }
                resourceFields {
                  externalUrl
                }
              }
            }
          }
        }
      }
      seo {
        fullHead
      }
    }
`,
  gql`
    ${FeaturedImageFragment}
    ${GetEditorBlocksFragments()}
  `
);

Component.variables = (
  { databaseId }: { databaseId: string },
  { asPreview, locale }: { asPreview?: boolean; locale: Locale }
) => {
  return {
    asPreview,
    footerLocation: MENUS.newFooterLocation(locale),
    headerLocation: MENUS.newHeaderLocation(locale),
    newMenuLocation: MENUS.newMenuLocation(locale),
    locale: formatLocaleForGraphQL(locale),
    databaseId,
  };
};
