import cn from "classnames";
import {
  backgroundInverseLight,
  backgroundWhiteDark,
} from "@/constants/standardCSSClasses";
import { CAROUSEL_TIME } from "@/constants/slider";

export interface TimeProgressBarInterface {
  variant?: string;
  time: number;
}

export default function TimeProgressBar({
  variant = "",
  time = 0,
}: TimeProgressBarInterface) {
  const width = (time / CAROUSEL_TIME) * 100 + "%";

  const classNames = cn(
    "absolute left-0 top-[-1px] h-0.5 transition-all ease-linear duration-1000 z-10",
    {
      [backgroundWhiteDark]: variant === "dark",
      [backgroundInverseLight]: variant === "light" || variant === "white",
    }
  );

  return <div className={classNames} style={{ width }} />;
}
