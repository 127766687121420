import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

export default function ContentCardBlock() {
  // This block is managed entirely by the parent block, ContentCardsManualBlock
  return null;
}

const BLOCK_NAME = "TenupContentCard";

ContentCardBlock.displayName = BLOCK_NAME;

ContentCardBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          button
          className
          content
          heading
          image
          link
          metadata
          tag
        }
      }
    }
  `,
};
