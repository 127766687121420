export const SIGNUP_FORM_TITLE = {
  "en": "Sign up to receive news and updates about Dashlane",
  "fr": "Inscrivez-vous pour connaître toute l'actualité de Dashlane",
  "de": "Melden Sie sich an, um Neuigkeiten und Updates zu Dashlane zu erhalten",
  "es": "Regístrese para recibir noticias y actualizaciones acerca de Dashlane",
  "pt-br": "Sign up to receive news and updates about Dashlane",
  "it": "Sign up to receive news and updates about Dashlane",
  "ja": "Sign up to receive news and updates about Dashlane",
  "ko": "Sign up to receive news and updates about Dashlane",
  "nl": "Sign up to receive news and updates about Dashlane",
  "pt-pt": "Sign up to receive news and updates about Dashlane",
  "sv": "Sign up to receive news and updates about Dashlane",
  "zh": "Sign up to receive news and updates about Dashlane"
}