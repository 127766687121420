import cn from "classnames";
import {
  backgroundWhiteDark,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { RichText } from "@/components/atoms";
import Tooltip from "@/components/molecules/Tooltip";
import { info as InfoIcon } from "@/constants/icons";
import { tabularCellClasses } from "@/utilities/getTabularCellClasses";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  variant?: string;
  sticky?: boolean;
  highlightColumn?: number;
  index: number;
  columns: number;
  includeTooltip?: boolean;
  tooltip?: string;
  content: string;
  headerColumn: boolean;
  striped: boolean;
}

export default function TabularContentTextCellBlock({
  variant = "white",
  sticky = false,
  index,
  columns,
  highlightColumn = 0,
  includeTooltip = false,
  tooltip,
  content = "",
  headerColumn = false,
  striped = false,
}: Props) {
  const blockClassNames = cn(
    tabularCellClasses({
      columns,
      sticky,
      variant,
      striped,
      headerColumn: headerColumn && index === 0,
    }),
    {
      [backgroundWhiteDark]: index + 1 === highlightColumn,
    }
  );

  const pTextClassNames = cn({
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  //If there's no content, return an empty div with the height of the normal content
  //so that absolutely positioned column headers with background cover entire cell
  if (!content) {
    return <td className={blockClassNames} />;
  }

  return (
    <td className={blockClassNames}>
      <span className="flex items-center">
        <RichText className={pTextClassNames} tag="span">
          {content}
        </RichText>
        {includeTooltip && tooltip && (
          <Tooltip delay={0} direction="right" content={tooltip}>
            <span
              className={cn("ml-1 mt-[-8px] block h-[16px] w-[16px]", {
                "fill-white": variant === "dark",
                "fill-grey-6": variant !== "dark",
              })}
            >
              <InfoIcon width="16px" />
            </span>
          </Tooltip>
        )}
      </span>
    </td>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupTabularContentTextCell";

TabularContentTextCellBlock.displayName = BLOCK_NAME;

TabularContentTextCellBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          includeTooltip
          metadata
          tooltip
        }
      }
    }
  `,
};
