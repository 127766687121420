import { DocumentNode, gql } from "@apollo/client";
import {
  GetEditorBlocksFragments,
  GetEditorBlocksKeys,
  GetSite,
} from "@/fragments/fragmentFunctions";

const GetArchive = (postsQuery: string, fragments: DocumentNode) => {
  const { siteFragments, siteQuery, siteArgs } = GetSite();

  return gql`
      ${siteFragments}
      ${fragments}
      ${GetEditorBlocksFragments()}
      query GetArchive(
        $categoryName: ID!
        ${siteArgs}
        $size: Int = 12
        $offset: Int = 0
        $blockId: ID! = 0
      ) {
        ${postsQuery}
        ${siteQuery}
        wpBlock(id: $blockId, idType: DATABASE_ID) {
          ${GetEditorBlocksKeys()}
        }
      }
    `;
};

export default GetArchive;
