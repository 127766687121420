import { gql } from "@apollo/client";
import { FeaturedImageFragment } from "@/fragments";

const ResourceCardFragment = gql`
  ${FeaturedImageFragment}
  fragment RootQueryToResourceConnectionFragment on RootQueryToResourceConnection {
    nodes {
      id
      title
      uri
      excerpt
      ...FeaturedImageFragment
      resourceTypes(first: 1) {
        nodes {
          name
          description
          uri
        }
      }
      resourceCategories(first: 1) {
        nodes {
          name
          description
          uri
        }
      }
      resourceFields {
        externalUrl
        eventDate
      }
    }
    pageInfo {
      offsetPagination {
        total
        page
        pages
      }
    }
  }
`;

export default ResourceCardFragment;
