import EmailCaptureForm from "@/components/molecules/EmailCaptureForm";
import { SIGNUP_FORM_TITLE } from "@/i18n/translations";
import { useTranslate } from "@/hooks/useTranslate";
import { useState } from "react";

export default function SignUpForm() {
  const { translate } = useTranslate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const titleGridClass = !isSubmitted ? "md:grid-cols-2" : "md:grid-cols-1";

  return (
    <div className="mx-auto flex w-full max-w-4xl flex-col">
      <div
        className={`grid grid-cols-1 items-center justify-center gap-4 py-16 ${titleGridClass}`}
      >
        {!isSubmitted && (
          <p className="max-w-md text-2xl font-medium md:text-3xl">
            {translate(SIGNUP_FORM_TITLE)}
          </p>
        )}
        <EmailCaptureForm setIsSubmitted={setIsSubmitted} />
      </div>
    </div>
  );
}
