import { gql } from "@apollo/client";
import { FeaturedImageFragment } from "@/fragments";

const ResourceCategoryFragment = gql`
  ${FeaturedImageFragment}
  fragment ResourceCategoryFragment on ResourceCategory {
    name
    description
    resources(where: { offsetPagination: { offset: $offset, size: $size } }) {
      nodes {
        id
        title
        uri
        excerpt
        ...FeaturedImageFragment
        resourceTypes(first: 1) {
          nodes {
            name
            description
            uri
          }
        }
        resourceCategories(first: 1) {
          nodes {
            name
            description
            uri
          }
        }
        resourceFields {
          externalUrl
          eventDate
        }
      }
      pageInfo {
        offsetPagination {
          total
          page
          pages
        }
      }
    }
  }
`;

export default ResourceCategoryFragment;
