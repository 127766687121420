import { ReactNode } from "react";

interface Props {
  condition: boolean;
  wrapper: Function;
  children: ReactNode;
}

export default function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: Props) {
  return condition ? wrapper(children) : children;
}
