export const RESOURCE_BACK_TO_PARENT_CATEGORY = {
  "en": "Back to %(parent)",
  "fr": "Back to %(parent)",
  "de": "Back to %(parent)",
  "es": "Back to %(parent)",
  "pt-br": "Back to %(parent)",
  "it": "Back to %(parent)",
  "ja": "Back to %(parent)",
  "ko": "Back to %(parent)",
  "nl": "Back to %(parent)",
  "pt-pt": "Back to %(parent)",
  "sv": "Back to %(parent)",
  "zh": "Back to %(parent)"
}