/* eslint-disable @typescript-eslint/naming-convention */
import Cookies from "js-cookie";
import { BaseAPI } from "./baseAPI";

interface InvoiceTeamUpgradeParams {
  login: string;
  planId: string;
  tokenId: string;
  membersNumber: number;
  amountToPayInCents: number;
  taxesToPayInCents?: number;
  couponCode?: string;
  countryCode?: string;
  vatNumber?: string;
}

export interface InvoiceTeamUpgradeResponse {
  customerId: string;
  invoiceId: string;
}

interface InvoiceTeamCreateParams {
  login: string;
  planId: string;
  tokenId: string;
  membersNumber: number;
  amountToPayInCents: number;
  taxesToPayInCents?: number;
  coupon?: string;
  countryCode?: string;
  vatNumber?: string;
}

interface RegisterFreeTrialParams {
  creatorEmail: string;
  language: string;
  tier: string;
  firstName: string;
  lastName: string;
  creatorPhoneNumber: string;
  companyName: string;
  companySize: string;
}

export interface InvoiceTeamCreateResponse {
  customerId: string;
  invoiceId: string;
}

export default class Teams extends BaseAPI {
  invoiceTeamUpgrade(params: InvoiceTeamUpgradeParams) {
    return this.postData<InvoiceTeamUpgradeResponse>({
      apiName: "teams",
      apiMethod: "InvoiceTeamUpgrade",
      payload: { ...params },
    });
  }

  invoiceTeamCreate(params: InvoiceTeamCreateParams) {
    return this.postData<InvoiceTeamCreateResponse>({
      apiName: "teams",
      apiMethod: "InvoiceTeamCreate",
      payload: { ...params },
    });
  }

  registerFreeTrial(params: RegisterFreeTrialParams) {
    const userFunnelCookie = JSON.parse(
      Cookies.get("userFunnelCookie") || "{}"
    );

    const {
      last_click_source,
      last_click_campaign,
      last_click_medium,
      last_click_content,
      last_click_term,
      utm_source,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term,
    } = userFunnelCookie;

    return this.postData({
      apiName: "teams",
      apiMethod: "RegisterFreeTrial",
      payload: {
        ...params,
        last_click_source,
        last_click_campaign,
        last_click_medium,
        last_click_content,
        last_click_term,
        utm_source,
        utm_campaign,
        utm_medium,
        utm_content,
        utm_term,
        originHostname: window.location.hostname,
        originPathname: window.location.pathname,
      },
    });
  }
}
