export { default as BlogInfoFragment } from "./BlogInfo";
export { default as FeaturedImageFragment } from "./FeaturedImage";
export { default as MenuItemFragment } from "./MenuItem";
export { default as PreviewFragment } from "./PreviewFragment";
export { default as BlocksFragment } from "./Blocks";
export { default as NewMenuItemFragment } from "./NewMenuItem";
export { default as ResourceFragment } from "./Resource";
export { default as ResourceTypeFragment } from "./ResourceType";
export { default as ResourceCategoryFragment } from "./ResourceCategory";
export { default as ResourcesCardFragment } from "./ResourcesCard";
export { default as ResourcePageInfoFragment } from "./ResourcePageInfo";
export { default as WPBlockFragment } from "./WPBlock";
