import { DocumentNode, gql } from "@apollo/client";
import { GetSite } from "@/fragments/fragmentFunctions";

const GetSingle = (postQuery: string, fragments: DocumentNode) => {
  const { siteFragments, siteQuery, siteArgs } = GetSite();

  return gql`
      ${siteFragments}
      ${fragments}
      query GetSingle(
        ${siteArgs}
        $databaseId: ID!
        $asPreview: Boolean = false
      ) {
        ${postQuery}
        ${siteQuery}
      }
    `;
};

export default GetSingle;
