import { useTranslate } from "@/hooks/useTranslate";
import {
  BUSINESS_ENTERPRISE_SUCCESS_CTA_LABEL,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_1,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_2,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_3,
  BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_V2,
  BUSINESS_ENTERPRISE_SUCCESS_SECONDARY_DESCRIPTION,
  BUSINESS_ENTERPRISE_SUCCESS_TITLE,
} from "@/constants/tempTranslations";
import { RichText } from "@/components/atoms";
import CustomButton from "@/components/molecules/CustomButton";
import React from "react";
import { internalLinks } from "@/constants/internalLinks";
import {
  h3Text,
  marginBMd,
  marginBSm,
  marginTMd,
} from "@/constants/standardCSSClasses";
import cn from "classnames";

interface Props {
  bookItSrc: string;
  inboundCompanySize: string;
  from: string;
}

export default function EnterpriseFormSuccess(props: Props) {
  const { bookItSrc, inboundCompanySize, from } = props;
  const { translate } = useTranslate();
  const isUpgrade = from === "/business-password-manager/upgrade";

  return (
    <>
      <RichText tag="h1" className={cn(h3Text, marginBMd)}>
        {translate(BUSINESS_ENTERPRISE_SUCCESS_TITLE)}
      </RichText>
      {bookItSrc && !isUpgrade && (
        <>
          <RichText tag="p" className={marginBSm}>
            {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_V2)}
          </RichText>
          <RichText tag="p">
            {translate(BUSINESS_ENTERPRISE_SUCCESS_SECONDARY_DESCRIPTION)}
          </RichText>
        </>
      )}
      {!bookItSrc && inboundCompanySize === "1-20" && (
        <>
          <RichText tag="p" className={marginBSm}>
            {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_1)}
          </RichText>
          <RichText tag="p" className={marginBSm}>
            {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_2)}
          </RichText>
          <RichText tag="p">
            {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_3)}
          </RichText>
        </>
      )}
      {!bookItSrc && inboundCompanySize !== "1-20" && (
        <>
          <RichText tag="p" className={marginBSm}>
            {translate(BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION)}
          </RichText>
          {!isUpgrade && (
            <RichText tag="p">
              {translate(BUSINESS_ENTERPRISE_SUCCESS_SECONDARY_DESCRIPTION)}
            </RichText>
          )}
        </>
      )}
      {!isUpgrade && (
        <CustomButton
          className={marginTMd}
          variant="dark"
          href={internalLinks.businessTry}
          text={translate(BUSINESS_ENTERPRISE_SUCCESS_CTA_LABEL)}
        />
      )}
    </>
  );
}
