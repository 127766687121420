import { Locale, MarketoFormValues } from "@/types";
import Teams from "@/utilities/api/teams";
import {
  NOTIF_GENERIC_MESSAGE,
  BUSINESS_TRY_EMAIL_INPUT_ERROR_ALREADY_IN_TEAM,
  BUSINESS_TRY_EMAIL_INPUT_ERROR_ALREADY_IN_TRIAL,
  BUSINESS_TRY_EMAIL_INPUT_ERROR_INVALID_COMPANYNAME,
  BUSINESS_TRY_EMAIL_INPUT_ERROR_DOMAIN_IN_USE,
} from "@/constants/tempTranslations";
import { getMocki18n } from "@/i18n/variables";

const teams = new Teams();

type RegisterFreeTrialError = Error & {
  code: string;
  type: string;
};

const _handleServerErrors = (error: RegisterFreeTrialError) => {
  switch (error.code) {
    case "ALREADY_IN_TEAM":
      return {
        errorMessage: BUSINESS_TRY_EMAIL_INPUT_ERROR_ALREADY_IN_TEAM,
        fieldName: "Email",
      };
    case "already_used_freetrial":
      return {
        errorMessage: BUSINESS_TRY_EMAIL_INPUT_ERROR_ALREADY_IN_TRIAL,
        fieldName: "Email",
      };
    case "invalid_company_name":
      return {
        errorMessage: BUSINESS_TRY_EMAIL_INPUT_ERROR_INVALID_COMPANYNAME,
        fieldName: "Company",
      };
    case "MAIL_DOMAIN_IS_ALREADY_USED_BY_ANOTHER_TEAM":
      return {
        errorMessage: BUSINESS_TRY_EMAIL_INPUT_ERROR_DOMAIN_IN_USE,
        fieldName: "Email",
      };
    case "out_of_bounds_timestamp":
      return {
        errorMessage: getMocki18n(error.message),
      };
    default:
      return {
        errorMessage: NOTIF_GENERIC_MESSAGE,
      };
  }
};

export const createBusinessTrial = async (
  values: MarketoFormValues,
  locale?: Locale | string | undefined
) => {
  try {
    await teams.registerFreeTrial({
      creatorEmail: values.Email,
      companyName: values.Company,
      language: locale ?? "en",
      tier: values.product_plan__c ?? "",
      companySize: values.Inbound_Company_Size__c,
      firstName: values.FirstName,
      lastName: values.LastName,
      creatorPhoneNumber: values.Phone,
    });

    return true;
  } catch (error) {
    return _handleServerErrors(error as RegisterFreeTrialError);
  }
};
