import { Fragment, useEffect, useState } from "react";
import cn from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Locale, Translated } from "@/types";
import router from "next/router";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const localeMap = {
  de: {
    country: "Deutschland",
    label: "Deutsch",
  },
  en: {
    country: "United States",
    label: "English",
  },
  es: {
    country: "Internacional",
    label: "Español",
  },
  fr: {
    country: "France",
    label: "Français",
  },
  "pt-br": {
    country: "Brasil",
    label: "Português",
  },
  "pt-pt": {
    country: "Portugal",
    label: "Português",
  },
  it: {
    country: "Italia",
    label: "Italiana",
  },
  ja: {
    country: "日本",
    label: "日本語",
  },
  ko: {
    country: "대한민국",
    label: "한국인",
  },
  nl: {
    country: "Nederland",
    label: "Nederlands",
  },
  sv: {
    country: "Sverige",
    label: "Svenska",
  },
  zh: {
    country: "中国",
    label: "中国人",
  },
};

interface Props {
  availableLocales?: Translated[];
  color?: "white" | "dashgreen";
  location?: "header" | "footer";
  locale?: Locale;
}

type MenuItem = {
  country?: string;
  label?: string;
  locale: string;
  uri: string;
};

export default function LanguageSwitcher({
  color = "dashgreen",
  availableLocales,
  location = "header",
  locale = "en",
}: Props) {
  const [locales, setLocales] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (availableLocales) {
      // Change WP localization (fr_FR) to Next.js localization (fr)
      const filteredAvailableLocales: MenuItem[] = [];
      const switcherLanguages: string[] = [];
      availableLocales.forEach((item) => {
        // Filter out any locales with missing URLs which is a bug returned from WPGQL/WPML
        // If not all locales are available on a post, it will have placeholder locales sometimes
        // that do not contain URIs. We have to filter them out for this to work correctly
        if (item.language.locale && item.uri) {
          //Homepage was showing language duplicates
          if (switcherLanguages.includes(item.language.locale)) return;
          switcherLanguages.push(item.language.locale);

          switch (item.language.locale) {
            case "fr_FR":
              return filteredAvailableLocales.push({
                ...localeMap.fr,
                locale: "fr",
                uri: item.uri,
              });
            case "de_DE":
              return filteredAvailableLocales.push({
                ...localeMap.de,
                locale: "de",
                uri: item.uri,
              });
            case "es_ES":
              return filteredAvailableLocales.push({
                ...localeMap.es,
                locale: "es",
                uri: item.uri,
              });
            case "pt_BR":
              return filteredAvailableLocales.push({
                ...localeMap["pt-br"],
                locale: "pt-br",
                uri: item.uri,
              });
            case "it_IT":
              return filteredAvailableLocales.push({
                ...localeMap.it,
                locale: "it",
                uri: item.uri,
              });
            case "ja_JA":
              return filteredAvailableLocales.push({
                ...localeMap.ja,
                locale: "ja",
                uri: item.uri,
              });
            case "ko_KO":
              return filteredAvailableLocales.push({
                ...localeMap.ko,
                locale: "ko",
                uri: item.uri,
              });
            case "nl_NL":
              return filteredAvailableLocales.push({
                ...localeMap.nl,
                locale: "nl",
                uri: item.uri,
              });
            case "pt_PT":
              return filteredAvailableLocales.push({
                ...localeMap["pt-pt"],
                locale: "pt-pt",
                uri: item.uri,
              });
            case "sv_SE":
              return filteredAvailableLocales.push({
                ...localeMap.sv,
                locale: "sv",
                uri: item.uri,
              });
            case "zh_CN":
              return filteredAvailableLocales.push({
                ...localeMap.zh,
                locale: "zh",
                uri: item.uri,
              });
            default:
              return filteredAvailableLocales.push({
                ...localeMap.en,
                locale: "en",
                uri: item.uri,
              });
          }
        }
        return null;
      });
      setLocales(filteredAvailableLocales);
    } else {
      // We use the router locales registered in next.config.js
      const { locales: routerLocales, locale: currentLocale, asPath } = router;
      // Remove the locale we are on
      const filteredLocales: MenuItem[] | undefined = routerLocales
        ?.filter((locale) => locale !== currentLocale)
        // Typecasting locale from the router which is string to Locale which is a defined string
        .map((locale) => {
          return {
            ...localeMap[locale as Locale],
            locale,
            uri: `/${locale}${asPath}`,
          };
        });
      setLocales(filteredLocales ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide the language switcher if there are no other languages than the one we are viewing it in
  if (!locales || !locales.length) {
    //If this is the footer and there's no language switcher, add a spacer
    if (location === "footer") {
      return <div className="mt-10 lg:mt-0" />;
    }
    return null;
  }

  const localeLabel = localeMap[locale]?.label;

  return (
    <Menu
      as="div"
      className={cn("relative inline-block lg:block", {
        "my-10 lg:my-0": location === "footer",
      })}
    >
      <div
        className={cn({
          "flex justify-center lg:justify-end": location === "footer",
        })}
      >
        <Menu.Button
          data-cy="lang-menu-button"
          className={cn(
            `text-${color} group inline-flex items-center rounded-md text-base`,
            {
              "px-3 py-2 uppercase": location === "header",
            }
          )}
        >
          <span className="sr-only">Open language switcher menu</span>
          {location === "header" ? (
            <GlobeAltIcon
              className="h-6 w-6 transition duration-150 ease-in-out"
              aria-hidden="true"
            />
          ) : (
            <div className="group inline-flex items-center rounded-md text-white">
              {localeLabel ?? "Language"}
              <ChevronDownIcon
                className="ml-1 h-5 w-5 text-white transition duration-150 ease-in-out"
                aria-hidden="true"
              />
            </div>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cn(
            "absolute right-0 z-10 mt-1 max-w-sm transform px-2 sm:px-0 lg:max-w-lg lg:max-w-md",
            { "bottom-full": location === "footer" }
          )}
        >
          <ul className="space-y-4 overflow-hidden rounded-lg bg-white p-5 shadow-lg ring-1 ring-black ring-opacity-5 ">
            {locales?.map((item, index) => (
              <Menu.Item
                data-cy={`lang-menu-item-${index}`}
                as="li"
                key={item.locale}
              >
                {/* Force a refresh when lang is switched by using <a> instead of <Link> */}
                <a
                  href={`${
                    item.locale !== "en"
                      ? item.uri.replace(/\/$/, "")
                      : item.uri
                  }`}
                  className="group flex w-full items-center whitespace-nowrap border border-b border-l-0 border-r-0 border-t-0 border-transparent py-2 text-grey-6 transition hover:border-blue-8 hover:text-blue-8 hover:no-underline"
                >
                  <span>{item.label}</span>
                  <span className="ms-2 inline-block text-gray-400">
                    {item.country}
                  </span>
                </a>
              </Menu.Item>
            ))}
          </ul>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
