import cn from "classnames";
import {
  FacebookIcon,
  LinkedInIcon,
  RedditIcon,
  TwitterIcon,
} from "@/components/icons";
import { POST_SHARE_TITLE } from "@/i18n/translations/POST_SHARE_TITLE";
import { useTranslate } from "@/hooks/useTranslate";

interface Props {
  url: string;
  title: string;
  className?: string;
  shareStyle?: string;
}

export default function PostShare({
  url,
  title,
  className,
  shareStyle = "default",
}: Props) {
  const { translate } = useTranslate();

  const pClasses = cn({
    "text-sm mb-4 font-medium text-blue-8": shareStyle === "resource",
    "m-2 text-xl font-bold uppercase": shareStyle === "default",
  });

  const linkClasses = cn({
    "flex h-6 w-6 items-center justify-center text-blue-8 hover:text-blue-5 transition-all lg:border lg:border-blue-8 lg:rounded-full":
      shareStyle === "resource",
    "m-2 text-xl font-bold uppercase": shareStyle === "default",
  });

  const iconsClasses = cn("grid grid-cols-4", {
    "gap-3": shareStyle === "resource",
    "gap-8": shareStyle !== "resource",
  });

  const iconClasses = cn({
    "h-8 w-8": shareStyle === "default",
    "h-3 w-3": shareStyle === "resource",
  });

  const encodedTitle = encodeURIComponent(title);
  return (
    <div className={className}>
      <p className={pClasses}>{translate(POST_SHARE_TITLE)}</p>
      <div className={iconsClasses}>
        <a
          className={linkClasses}
          href={`https://www.facebook.com/sharer.php?u=${url}`}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon className={iconClasses} />
        </a>
        <a
          className={linkClasses}
          href={`https://twitter.com/share?url=${url}`}
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon className={iconClasses} />
        </a>
        <a
          className={linkClasses}
          href={`https://linkedin.com/shareArticle?url=${url}&title=${encodedTitle}`}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon className={iconClasses} />
        </a>
        <a
          className={linkClasses}
          href={`https://reddit.com/submit?url=${url}&title=${encodedTitle}`}
          target="_blank"
          rel="noreferrer"
        >
          <RedditIcon className={iconClasses} />
        </a>
      </div>
    </div>
  );
}
