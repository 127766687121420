import { Locale } from "@/types";

export const internalLinks = {
  about: "/about",
  aboutLeadership: "/about/leadership",
  aboutCareers: "/about/careers",
  accessibilityStatement: "/accessibility-statement",
  betaSubcribeSuccess: "/beta/success",
  betaUnsubcribe: "/beta/unsubscribe",
  betaUnsubcribeSuccess: "/beta/unsubscribe/success",
  business: "/business-password-manager",
  businessBuy: "/business-password-manager/buy",
  businessBuyStarter: "/business-password-manager/buy",
  businessBuyTeam: "/business-password-manager/buy?plan=team",
  businessBuySuccess: "/business-password-manager/buy/success",
  businessDirectBuySuccess: "/business-password-manager/directbuy/success",
  businessDownload: "/business-password-manager/download/",
  businessEnterprise: "/business-password-manager/enterprise",
  businessPasswordManager: "/business-password-manager",
  businessPricing: "/business/pricing",
  businessRequestDemo: "/business-password-manager/request-demo",
  businessRequestDemoSuccess: "/business-password-manager/request-demo/success",
  businessTerms: "/business-password-manager/terms",
  businessTry: "/business-password-manager/try",
  businessTryBusiness: "/business-password-manager/try?plan=business",
  businessTryTeam: "/business-password-manager/try?plan=team",
  cookiePolicy: "/privacy/cookiepolicy",
  cookiePreferences: "/privacy/cookiepreferences",
  darkwebInsights: "/darkwebinsights",
  digitalWallet: "/personal-password-manager/digital-wallet",
  doNotSell: "/privacy/donotsell",
  download: "/download",
  downloadSaex: "/download/saex",
  emailManage: "/email/manage",
  familyPricing: "/plans",
  familyPlans: "/family-password-manager",
  features: "/personal-password-manager",
  featuresAutofill: "/personal-password-manager/autofill",
  featuresPasswordChanger: "/personal-password-manager/password-changer",
  featuresPasswordGenerator: "/features/password-generator",
  featuresSecurityAlerts: "/personal-password-manager/security-alerts",
  b2b: "/b2b",
  getAdvanced: "/getadvanced",
  getFamily: "/getfamily",
  getFamilySuccess: "/getfamily/success",
  getPremium: "/getpremium",
  getPremiumFriend: "/getpremium/for-a-friend",
  home: "/",
  legal: "/legal",
  marketingVpn: "/marketing/vpn",
  otpPhoneLostCodes: "/otpphonelost/codes",
  otpPhoneLostSuccess: "/otpphonelost/success",
  partnerships: "/partnerships",
  partnershipsAffiliates: "/partnerships/affiliates",
  partnershipsEmailConfirmation: "/partnerships/email-confirmation",
  partnershipsInfluencers: "/partnerships/influencers",
  partnershipsResellers: "/partnerships/resellers",
  partnershipsFormSuccess: "/partnerships/success",
  passwordGenerator: "/features/password-generator",
  passwordless: "/passwordless",
  passkeys: "/passkeys",
  personal: "/personal-password-manager",
  personalPasswordManager: "/personal-password-manager",
  plans: "/plans",
  pricing: "/pricing",
  pricingBusiness: "/pricing?page=business",
  pricingPersonal: "/pricing?page=personal",
  privacy: "/privacy",
  privacyOverview: "/privacy/overview",
  privacyRights: "/privacy/rights",
  productDarkwebDeactivate: "/product/darkweb/deactivate",
  resources: "/resources",
  resourcesSearch: "/resources/search",
  resourcesSuccessStories: "/resources#SuccessStories",
  resourcesTopFiveSecurityFindings:
    "/resources/top-five-security-findings-for-small-businesses",
  resourcesTopTenCybersecurityTrends:
    "/resources/the-future-of-secure-work-for-people-and-organizations",
  reviews: "/reviews",
  security: "/security",
  securitymadesimple: "/securitymadesimple",
  securityResearchers: "/security/researchers",
  sso: "/sso",
  subprocessor: "/privacy/subprocessor",
  teamBuy: "/business-password-manager/buy?plan=team",
  terms: "/terms",
  termsBusiness: "/terms/business",
  termsFamily: "/terms/family-plan",
  termsDpa: "/terms/business/dpa",
  tryAutofillData: "/try-autofill-data",
  tryAutofillSave: "/try-autofill-save",
  tryAutofillDemo: "/try-autofill-demo",
  usernameGenerator: "/features/username-generator",
  whitePlan: "/security/white-paper",
};

export interface InternalLinkLocalized {
  en: string;
  variants: { [lg in Locale]?: string };
}

export const internalLinksLocalized: Record<string, InternalLinkLocalized> = {
  securityWhitePaper: {
    en: "https://www.dashlane.com/download/whitepaper-en.pdf",
    variants: {
      fr: "https://www.dashlane.com/download/whitepaper-fr.pdf",
    },
  },
};
