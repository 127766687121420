import React from "react";
import cn from "classnames";
import {
  eyebrowText,
  textBodyInverse,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import { RichText } from "@/components/atoms";

interface AboveHeadingInterface {
  children: string | React.ReactNode;
  className?: string;
  variant?: string;
}

const AboveHeading = ({
  children,
  className,
  variant,
}: AboveHeadingInterface) => {
  return (
    <RichText
      className={cn("uppercase", eyebrowText, className, {
        [textBodyInverse]: variant === "dark",
        [textBodyNeutral]: variant !== "dark",
      })}
      tag="p"
    >
      {children}
    </RichText>
  );
};

export default AboveHeading;
