import cn from "classnames";
import React from "react";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import { iconCheckmark } from "@/constants/icons";
import {
  backgroundWhiteDark,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { tabularCellClasses } from "@/utilities/getTabularCellClasses";
import { RichText } from "@/components/atoms";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  variant?: string;
  highlightColumn?: number;
  columns: number;
  index: number;
  includeTooltip?: boolean;
  tooltip?: string;
  sticky?: boolean;
  icon: IconInterface;
  useText?: boolean;
  content?: string;
}

export default function TabularContentIconCellBlock({
  variant = "white",
  index,
  columns,
  sticky = false,
  highlightColumn = 0,
  icon = { source: "" },
  useText = false,
  content = "",
}: Props) {
  const blockClassNames = cn(tabularCellClasses({ columns, sticky, variant }), {
    [backgroundWhiteDark]: index + 1 === highlightColumn,
  });

  const pTextClassNames = cn({
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  if (!icon.name) return <td className={blockClassNames} />;

  if (icon.name && !icon.source) {
    icon.source = iconCheckmark;
  }

  return (
    <td className={blockClassNames}>
      {!useText && <Icon {...icon} variant={variant} width={20} />}
      {useText && (
        <RichText className={pTextClassNames} tag="span">
          {content}
        </RichText>
      )}
    </td>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupTabularContentIconCell";

TabularContentIconCellBlock.displayName = BLOCK_NAME;

TabularContentIconCellBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          content
          icon
          metadata
          useText
        }
      }
    }
  `,
};
