import {
  GetEditorBlocksFragments,
  GetEditorBlocksKeys,
  GetSite,
} from "@/fragments/fragmentFunctions";
import { gql } from "@apollo/client";

const GetSearch = () => {
  const { siteFragments, siteQuery, siteArgs } = GetSite();

  return gql`
      ${siteFragments}
      ${GetEditorBlocksFragments()}
      query GetSearch(
        ${siteArgs}
        $blockId: ID! = 0
      ) {
        ${siteQuery}
        wpBlock(id: $blockId, idType: DATABASE_ID) {
          ${GetEditorBlocksKeys()}
        }
      }
    `;
};

export default GetSearch;
