// @ts-ignore
import * as zxcvbn from "zxcvbn/lib/main";

import {
  EvaluatePasswordResult,
  EvaluatePasswordSuggestions,
  EvaluatePasswordWarning,
} from "./types";

const PASSWORD_MAXIMUM_LENGTH = 50;

const suggestionsMapping: Record<string, EvaluatePasswordSuggestions> = {
  [`Use a few words, avoid common phrases`]:
    EvaluatePasswordSuggestions.USE_A_FEW_WORDS,
  [`No need for symbols, digits, or uppercase letters`]:
    EvaluatePasswordSuggestions.NO_NEED_FOR_SYMBOL,
  [`Add another word or two. Uncommon words are better.`]:
    EvaluatePasswordSuggestions.ADD_ANOTHER_WORD,
  [`Use a longer keyboard pattern with more turns`]:
    EvaluatePasswordSuggestions.USE_A_LONGER,
  [`Avoid repeated words and characters`]:
    EvaluatePasswordSuggestions.AVOID_REPEATED,
  [`Avoid sequences`]: EvaluatePasswordSuggestions.AVOID_SEQUENCES,
  [`Avoid recent years`]: EvaluatePasswordSuggestions.AVOID_RECENT_YEARS,
  [`Avoid years that are associated with you`]:
    EvaluatePasswordSuggestions.AVOID_RELATED_YEARS,
  [`Avoid dates and years that are associated with you`]:
    EvaluatePasswordSuggestions.AVOID_RELATED_DATES,
  [`Capitalization doesn't help very much`]:
    EvaluatePasswordSuggestions.CAPITALIZATION,
  [`All-uppercase is almost as easy to guess as all-lowercase`]:
    EvaluatePasswordSuggestions.ALL_UPPERCASE,
  [`Reversed words aren't much harder to guess`]:
    EvaluatePasswordSuggestions.REVERSED_WORDS,
  [`Predictable substitutions like '@' instead of 'a' don't help very much`]:
    EvaluatePasswordSuggestions.PREDICTABLE_SUBSTITUTIONS,
};

const warningMapping: Record<string, EvaluatePasswordWarning> = {
  [`Straight rows of keys are easy to guess`]:
    EvaluatePasswordWarning.STRAIGHT_ROWS,
  [`Short keyboard patterns are easy to guess`]:
    EvaluatePasswordWarning.SHORT_KEYBOARD_PATTERNS,
  [`Repeats like "aaa" are easy to guess`]:
    EvaluatePasswordWarning.REPEATS_EASY,
  [`Repeats like "abcabcabc" are only slightly harder to guess than "abc"`]:
    EvaluatePasswordWarning.REPEATS_SLIGHTLY_HARDER,
  [`Sequences like abc or 6543 are easy to guess`]:
    EvaluatePasswordWarning.SEQUENCES,
  [`Recent years are easy to guess`]: EvaluatePasswordWarning.RECENT_YEARS,
  [`Dates are often easy to guess`]: EvaluatePasswordWarning.DATES_TOO_EASY,
  [`This is a top-10 common password`]:
    EvaluatePasswordWarning.TOP_10_COMMON_PASSWORD,
  [`This is a top-100 common password`]:
    EvaluatePasswordWarning.TOP_100_COMMON_PASSWORD,
  [`This is a very common password`]: EvaluatePasswordWarning.COMMON_PASSWORD,
  [`This is similar to a commonly used password`]:
    EvaluatePasswordWarning.SIMILAR_COMMON_PASSWORD,
  [`A word by itself is easy to guess`]: EvaluatePasswordWarning.SINGLE_WORD,
  [`Names and surnames by themselves are easy to guess`]:
    EvaluatePasswordWarning.NAMES,
  [`Common names and surnames are easy to guess`]:
    EvaluatePasswordWarning.COMMON_NAMES,
};

export function setFrequencyLists(list: zxcvbn.ZXCVBNFrequencyLists) {
  zxcvbn.set_frequency_lists(list);
}

/* 
  This function evaluates a given password using the ZXCVBN algorithm
  We consider that evaluating the first 50 characters of a given password
  is enough to have a good feedback about the strength of the password.

  The library can be found here : https://github.com/Dashlane/zxcvbn

  If we want to evaluate a passphrase, we base our result on its length
  which corresponds on the number of words in that case. More info can
  be found here : https://www.eff.org/dice
*/
export function evaluate(
  password: string,
  passphrases?: boolean,
  length?: number
): EvaluatePasswordResult {
  let {
    score,
    guesses,
    guesses_log10,
    crack_times_display,
    crack_times_seconds,
    feedback,
  } = zxcvbn(password.substr(0, PASSWORD_MAXIMUM_LENGTH));

  if (passphrases && length) {
    if (length < 4) {
      score = 1;
    } else if (length >= 4 && length < 6) {
      score = 2;
    } else {
      score = 4;
    }
  }

  return {
    score: score * 25,
    guesses,
    guessesLog10: guesses_log10,
    crackTimesDisplay: {
      offlineFastHashing1e10PerSecond:
        crack_times_display.offline_fast_hashing_1e10_per_second,
      offlineSlowHashing1e4PerSecond:
        crack_times_display.offline_slow_hashing_1e4_per_second,
      onlineNoThrottling10PerSecond:
        crack_times_display.online_no_throttling_10_per_second,
      onlineThrottling100PerHour:
        crack_times_display.online_throttling_100_per_hour,
    },
    crackTimesSeconds: {
      offlineFastHashing1e10PerSecond:
        crack_times_seconds.offline_fast_hashing_1e10_per_second,
      offlineSlowHashing1e4PerSecond:
        crack_times_seconds.offline_slow_hashing_1e4_per_second,
      onlineNoThrottling10PerSecond:
        crack_times_seconds.online_no_throttling_10_per_second,
      onlineThrottling100PerHour:
        crack_times_seconds.online_throttling_100_per_hour,
    },
    feedback: {
      suggestions: feedback.suggestions.map(
        (suggestion: string | number) => suggestionsMapping[suggestion]
      ),
      warning: warningMapping[feedback.warning],
    },
  };
}
