import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";

import {
  marginBMd,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import { Block } from "@/types";
import { Blocks } from "@/components/molecules";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  width?: string;
  includeAboveHeading?: boolean;
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  includeFeatures?: boolean;
  aboveHeading?: string;
  heading?: string;
  headingSize?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  size?: string;
  imageAlign?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  contentType: string;
  media?: MediaObjectInterface;
  innerBlocks?: Block[];
  paddingTop?: string;
  paddingBottom?: string;
}

export default function GenericTwoColumnNarrativeBlock({
  width = "",
  includeAboveHeading = false,
  includeBelowButtons = false,
  includeButtons = false,
  includeContent = false,
  includeFeatures = false,
  aboveHeading = "",
  heading = "",
  headingOptions = {
    tag: 1,
  },
  content = "",
  variant = "white",
  size = "lg",
  imageAlign = "right",
  buttonsNumber = 0,
  buttonOne,
  buttonTwo,
  belowButtons = "",
  media = {
    type: "image",
    media: {},
  },
  contentType,
  innerBlocks,
  paddingTop = "default",
  paddingBottom = "default",
  blockContentProps,
}: Props) {
  const blockWidth = cn({
    rounded: width !== "full",
  });

  const features = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;

        if (each.attributes.headingOptions === undefined) {
          each.attributes.headingOptions = {};
        }
        each.attributes.headingOptions.tag = headingOptions.tag + 1;
        return each;
      })}
    />
  ) : null;

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
    });
  }

  const headingClassNames = cn("text-4xl", marginBMd, {
    "md:text-5xl": size === "lg",
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant === "light",
  });

  return (
    <BlockContent
      contentType={contentType}
      className={blockWidth}
      width={width}
      defaultValue="box"
      variant={variant}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className={`lg:grid lg:grid-cols-2 lg:items-center lg:gap-20`}>
        <div>
          {includeAboveHeading && aboveHeading && (
            <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
          )}
          <CustomHeading
            heading={heading}
            headingClassNames={headingClassNames}
            headingOptions={headingOptions}
          />
          {includeContent && content && (
            <RichText
              className={cn({
                [textBodyInverse]: variant === "dark",
                [textBodyNeutral]: variant !== "dark",
              })}
              tag="p"
            >
              {content}
            </RichText>
          )}
          {includeFeatures && <div className="mt-4">{features}</div>}
          {includeButtons && (
            <CustomButtonGroup
              align="left"
              buttons={buttons}
              marginTop="mt-8"
            />
          )}
          {includeBelowButtons && (
            <BelowButtonsText variant={variant}>
              {belowButtons}
            </BelowButtonsText>
          )}
        </div>
        {size !== "sm" && (
          <div
            className={cn("mt-6 lg:mt-0", {
              "order-first": imageAlign === "left",
            })}
          >
            <CustomMedia
              media={media ?? { media: {}, type: "image" }}
              aspect={size === "lg" ? "1:1" : "3:2"}
            />
          </div>
        )}
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupTwoColumnNarrative";

GenericTwoColumnNarrativeBlock.displayName = BLOCK_NAME;

GenericTwoColumnNarrativeBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          aboveHeadingChip
          aboveHeadingType
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          ga4SectionId
          heading
          headingOptions
          headingSize
          imageAlign
          includeAboveHeading
          includeBelowButtons
          includeButtons
          includeContent
          includeFeatures
          includeSecondaryCTA
          media
          metadata
          paddingBottom
          paddingTop
          size
          variant
          width
        }
      }
    }
  `,
};
