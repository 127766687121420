import { MarketoFormErrorsInterface } from "@/types";
import {
  getFieldWrapClasses,
  getRequired,
  getFieldClasses,
  getErrorMessages,
} from "@/utilities/marketoForms";
import { RichText } from "@/components/atoms";
import cn from "classnames";
import { MarketoFormFieldAny } from "@/types";
import { isFreeEmail } from "@/utilities/isFreeEmail";

interface TextFieldInterface {
  field: MarketoFormFieldAny;
  register: Function;
  errors: MarketoFormErrorsInterface;
  watch: Function;
}

export default function TextField({
  field,
  register,
  errors,
  watch,
}: TextFieldInterface) {
  const inputClassNames = cn(
    getFieldClasses(),
    "px-4 py-2.5 rounded border leading-tight focus:outline-none bg-transparent",
    {
      "border-pink-dark focus:border-pink-dark": errors[field.name],
      "border-grey-1 focus:border-blue-8": !errors[field.name],
    }
  );

  let watchEmail: null | string = null;

  if (field.type === "email") {
    watchEmail = watch("Email", "");
  }

  const isFree = watchEmail ? isFreeEmail(watchEmail) : false;

  return (
    <div className={getFieldWrapClasses(field)}>
      <label className="block text-sm">
        {field.label}
        {getRequired(field)}
      </label>
      <input
        className={inputClassNames}
        id={field.name}
        type={field.type === "phone" ? "tel" : field.type}
        placeholder={field.placeholder}
        {...register(field.name, { required: true })}
      />
      {watchEmail &&
        isFree &&
        field.errorMessages &&
        field.errorMessages.freeEmail && (
          <RichText className="text-sm text-red-danger" tag="div">
            {field.errorMessages.freeEmail}
          </RichText>
        )}
      {getErrorMessages(field, errors)}
    </div>
  );
}
