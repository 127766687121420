export const calculateMonthlyPrice = (
  formattedDuration: string,
  wholePrice: number
) => {
  if (!formattedDuration) {
    return 0;
  }

  const amount = parseInt(formattedDuration.substring(2), 10);

  const months = formattedDuration.includes("y") ? amount * 12 : amount;

  if (!months) {
    return 0;
  }
  const truncatedPrice = Math.floor(wholePrice / months) || 0;
  return truncatedPrice / 100;
};
