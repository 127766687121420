export type TrackingConfig = {
  campaigns: Array<Campaign>;
};

export type Campaign = {
  identifier: string;
  conditions: Condition;
  tracking: {
    iosAdjustURL?: string;
    androidAdjustURL?: string;
    desktopPartnerName?: string;
    utms?: {
      utm_source?: string;
      utm_campaign?: string;
      utm_medium?: string;
      utm_term?: string;
      utm_content?: string;
    };
    specialOfferToken?: string;
  };
};

type Condition = {
  pageIdentifier?: string;
  query?: Record<string, unknown>;
};

export const trackingConfig = {
  campaigns: [
    {
      identifier: "download-transunion",
      conditions: {
        pageIdentifier: "download",
        query: {
          utm_source: "transunion",
        },
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/lzwtbd?campaign={{utm_content}}&adgroup={{utm_term}}&creative={{utm_campaign}}",
        androidAdjustURL:
          "https://app.adjust.com/jlme27?campaign={{utm_content}}&adgroup={{utm_term}}&creative={{utm_campaign}}}",
      },
    },
    {
      identifier: "download-powerinbox",
      conditions: {
        pageIdentifier: "download",
        query: {
          utm_source: "PowerInbox",
        },
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/4aehwq?campaign=US&adgroup={{utm_campaign}}&creative={{utm_term}}",
        androidAdjustURL:
          "https://app.adjust.com/grx1ik?campaign=US&adgroup={{utm_campaign}}&creative={{utm_term}}",
      },
    },
    {
      identifier: "download-societegenerale",
      conditions: {
        pageIdentifier: "download",
        query: {
          from: "societegenerale",
        },
      },
      tracking: {
        iosAdjustURL: "https://app.adjust.com/10fqkl?campaign={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/lmihz1?campaign={{utm_content}}",
        desktopPartnerName: "bd_partnerships_fi_sg",
      },
    },
    {
      identifier: "lp-hulu",
      conditions: {
        pageIdentifier: "lp-hulu",
      },
      tracking: {
        utms: {
          utm_source: "hulu",
          utm_campaign: "Q22019",
        },
        iosAdjustURL: "https://app.adjust.com/ucup4jr?campaign={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/yg6p6da?campaign={{utm_content}}",
      },
    },
    {
      identifier: "so-societegenerale-1",
      conditions: {
        pageIdentifier: "so-societegenerale-1",
      },
      tracking: {
        iosAdjustURL: "https://app.adjust.com/10fqkl?campaign={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/lmihz1?campaign={{utm_content}}",
        desktopPartnerName: "bd_partnerships_fi_sg",
      },
    },
    {
      identifier: "so-societegenerale-2",
      conditions: {
        pageIdentifier: "so-societegenerale-2",
      },
      tracking: {
        iosAdjustURL: "https://app.adjust.com/10fqkl?campaign={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/lmihz1?campaign={{utm_content}}",
        desktopPartnerName: "bd_partnerships_fi_sg3",
      },
    },
    {
      identifier: "so-lenovo-marchtoast",
      conditions: {
        pageIdentifier: "so-lenovo",
        query: {
          utm_campaign: "marchtoast",
        },
      },
      tracking: {
        utms: {
          utm_campaign: "marchtoast",
        },
        desktopPartnerName: "bd_partnerships_lenovo_19marchtoast",
        specialOfferToken: "nHZv0Vp5PESmUDY4SMYpwKONm5DrVJQ2uSpcBvAl",
      },
    },
    {
      identifier: "so-lenovo-wpdtoast",
      conditions: {
        pageIdentifier: "so-lenovo",
        query: {
          utm_campaign: "WPDtoast",
        },
      },
      tracking: {
        utms: {
          utm_campaign: "WPDtoast",
        },
        desktopPartnerName: "bd_partnerships_LWP",
        specialOfferToken: "1-xkS.PgpSkTE3wd5qmJj8p1IQ1NoVhWOsKsuLsa",
      },
    },
    {
      identifier: "so-lenovo-vantageclient",
      conditions: {
        pageIdentifier: "so-lenovo",
      },
      tracking: {
        utms: {
          utm_source: "vantageclient",
        },
        desktopPartnerName: "bd_partnerships_lenovo_19",
        specialOfferToken: "uEZLje1X593k9C.tjyAU0RfYIXa-At4p7JsLEizL",
      },
    },
    {
      identifier: "lp-neverforget-cj",
      conditions: {
        pageIdentifier: "lp-neverforget-cj",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/4tngd6?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/o40hwq?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-top5passwordmanager",
      conditions: {
        pageIdentifier: "lp-top5passwordmanager",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/8ewn868?campaign={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/g1wu7tq?campaign={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-transunion",
      conditions: {
        pageIdentifier: "lp-transunion",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/lzwtbd?campaign={{utm_content}}&adgroup={{utm_term}}&creative={{utm_campaign}}",
        androidAdjustURL:
          "https://app.adjust.com/jlme27?campaign={{utm_content}}&adgroup={{utm_term}}&creative={{utm_campaign}}}",
      },
    },
    {
      identifier: "lp-influencer",
      conditions: {
        pageIdentifier: "lp-influencer",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/j3e6zf?campaign={{utm_campaign}}&adgroup={{utm_term}}",
        androidAdjustURL:
          "https://app.adjust.com/x19v1p?campaign={{utm_campaign}}&adgroup={{utm_term}}",
      },
    },
    {
      identifier: "lp-newsmax-teal",
      conditions: {
        pageIdentifier: "lp-newsmax-teal",
      },
      tracking: {
        utms: {
          utm_source: "smt",
          utm_campaign: "smt_spring2018",
          utm_medium: "paid_content",
          utm_content: "spring",
        },
        iosAdjustURL:
          "https://app.adjust.com/nv1xxv?campaign={{utm_source}}&adgroup={{utm_campaign}}",
        androidAdjustURL:
          "https://app.adjust.com/xuqj2j?campaign={{utm_source}}&adgroup={{utm_campaign}}",
      },
    },
    {
      identifier: "lp-neverforget-td",
      conditions: {
        pageIdentifier: "lp-neverforget-td",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/w9x44h?campaign={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/fprivt?campaign={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-neverforget-teal-ca",
      conditions: {
        pageIdentifier: "lp-neverforget-teal-ca",
      },
      tracking: {
        utms: {
          utm_source: "ca",
          utm_medium: "affiliates",
        },
        iosAdjustURL:
          "https://app.adjust.com/duh5mw?campaign={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/7depfp?campaign={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-neverforget-liveintent",
      conditions: {
        pageIdentifier: "lp-neverforget-liveintent",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/hi3vap?campaign={{utm_campaign}}&adgroup={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/mc88ws?campaign={{utm_campaign}}&adgroup={{utm_content}}",
      },
    },
    {
      identifier: "lp-reelio",
      conditions: {
        pageIdentifier: "lp-reelio",
      },
      tracking: {
        iosAdjustURL: "https://app.adjust.com/z62356?campaign={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/w57pv3?campaign={{utm_content}}",
      },
    },
    {
      identifier: "lp-cultofmac",
      conditions: {
        pageIdentifier: "lp-cultofmac",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/mo3h6a?campaign={{utm_campaign}}&creative={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/76blpw?campaign={{utm_campaign}}&creative={{utm_content}}",
      },
    },
    {
      identifier: "lp-geistm",
      conditions: {
        pageIdentifier: "lp-geistm",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/h8tkge?campaign={{utm_campaign}}&creative={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/efsqgc?campaign={{utm_campaign}}&creative={{utm_content}}",
      },
    },
    {
      identifier: "lp-hustle",
      conditions: {
        pageIdentifier: "lp-hustle",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/nlx9iqy?campaign={{utm_campaign}}&adgroup={{utm_term}}&creative={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/9pmw5f0?campaign={{utm_campaign}}&adgroup={{utm_term}}&creative={{utm_content}}",
      },
    },
    {
      identifier: "lp-inside",
      conditions: {
        pageIdentifier: "lp-inside",
      },
      tracking: {
        utms: {
          utm_source: "Content",
          utm_campaign: "inside",
          utm_medium: "paid_social",
          utm_term: "20181203inside",
        },
        iosAdjustURL:
          "https://app.adjust.com/pdvb6ir?campaign={{utm_campaign}}",
        androidAdjustURL:
          "https://app.adjust.com/6bayyhf?campaign={{utm_campaign}}",
      },
    },
    {
      identifier: "lp-watch",
      conditions: {
        pageIdentifier: "lp-watch",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/9sunbzd?campaign={{utm_campaign}}&adgroup={{utm_term}}&creative={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/cjf3qnj?campaign={{utm_campaign}}&adgroup={{utm_term}}&creative={{utm_content}}",
      },
    },
    {
      identifier: "lp-trustmod",
      conditions: {
        pageIdentifier: "lp-trustmod",
      },
      tracking: {
        iosAdjustURL: "https://app.adjust.com/bl6ppa?campaign={{utm_campaign}}",
        androidAdjustURL:
          "https://app.adjust.com/6k7mko?campaign={{utm_campaign}}",
      },
    },
    {
      identifier: "lp-affiliate-cj",
      conditions: {
        pageIdentifier: "lp-affiliate-cj",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/4tngd6?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/o40hwq?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-affiliate-ca",
      conditions: {
        pageIdentifier: "lp-affiliate-ca",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/duh5mw?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/7depfp?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-affiliate-revwire",
      conditions: {
        pageIdentifier: "lp-affiliate-revwire",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/mq0k15g?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/yjg05a1?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-social",
      conditions: {
        pageIdentifier: "lp-social",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/kc3tjwt?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/x8p48io?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-affiliate",
      conditions: {
        pageIdentifier: "lp-affiliate",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/57qw5r6?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/biu57vo?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-affiliate-alt",
      conditions: {
        pageIdentifier: "lp-affiliate-alt",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/4tngd6?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/o40hwq?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-affiliate-cm",
      conditions: {
        pageIdentifier: "lp-affiliate-cm",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/aysi2dz?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/pp73ufm?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-search-alt-2",
      conditions: {
        pageIdentifier: "lp-search-alt-2",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/57qw5r6?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/biu57vo?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-affiliate-switch",
      conditions: {
        pageIdentifier: "lp-affiliate-switch",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/6g0xv47?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/ba9mixg?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-ig",
      conditions: {
        pageIdentifier: "lp-ig",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/r7b5ktu?campaign=organic&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/z5091s8?campaign=organic&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
    {
      identifier: "lp-tre",
      conditions: {
        pageIdentifier: "lp-tre",
      },
      tracking: {
        utms: {
          utm_source: "tre",
          utm_campaign: "paid_social",
        },
        iosAdjustURL:
          "https://app.adjust.com/b08wz69?campaign={{utm_campaign}}&creative={{utm_term}}",
        androidAdjustURL:
          "https://app.adjust.com/yn3xcgf?campaign={{utm_campaign}}&creative={{utm_term}}",
      },
    },
    {
      identifier: "lp-reddit",
      conditions: {
        pageIdentifier: "lp-reddit",
      },
      tracking: {
        utms: {
          utm_source: "reddit",
          utm_campaign: "paid_social",
        },
        iosAdjustURL:
          "https://s2s.adjust.com/dovw4gp?campaign={{campaign_id}}&adgroup={{adgroup_name}}&creative={{ad_name}}&idfa={{ios_advertising_id}}&click_id={{click_id}}&gps_adid={{android_advertising_id}}&android_id={{android_advertising_id}}&creative_id={{ad_id}}",
        androidAdjustURL:
          "https://s2s.adjust.com/o2gecog?campaign={{campaign_id}}&adgroup={{adgroup_name}}&creative={{ad_name}}&idfa={{ios_advertising_id}}&click_id={{click_id}}&gps_adid={{android_advertising_id}}&android_id={{android_advertising_id}}&creative_id={{ad_id}}",
      },
    },
    {
      identifier: "lp-via",
      conditions: {
        pageIdentifier: "lp-via",
      },
      tracking: {
        utms: {
          utm_source: "via",
          utm_campaign: "paid_social",
        },
        iosAdjustURL:
          "https://app.adjust.com/j47bkjq?campaign={{utm_campaign}}&creative={{utm_term}}",
        androidAdjustURL:
          "https://app.adjust.com/jlxji84?campaign={{utm_campaign}}&creative={{utm_term}}",
      },
    },
    {
      identifier: "lp-prog",
      conditions: {
        pageIdentifier: "lp-prog",
      },
      tracking: {
        utms: {
          utm_source: "prog",
          utm_campaign: "paid_social",
        },
        iosAdjustURL:
          "https://app.adjust.com/b9wa4zt?campaign={{utm_campaign}}&creative={{utm_term}}",
        androidAdjustURL:
          "https://app.adjust.com/b9wa4zt?campaign={{utm_campaign}}&creative={{utm_term}}",
      },
    },
    {
      identifier: "lp-voxmedia",
      conditions: {
        pageIdentifier: "lp-voxmedia",
      },
      tracking: {
        utms: {
          utm_source: "voxmedia",
          utm_campaign: "paid_social",
        },
        iosAdjustURL:
          "https://app.adjust.com/265cwss?campaign={{utm_campaign}}&creative={{utm_term}}",
        androidAdjustURL:
          "https://app.adjust.com/s4g9q47?campaign={{utm_campaign}}&creative={{utm_term}}",
      },
    },
    {
      identifier: "lp-esports",
      conditions: {
        pageIdentifier: "lp-esports",
      },
      tracking: {
        utms: {
          utm_source: "esports",
          utm_campaign: "paid_social",
        },
        iosAdjustURL:
          "https://app.adjust.com/j3e6zf?campaign={{utm_campaign}}&creative={{utm_term}}",
        androidAdjustURL:
          "https://app.adjust.com/x19v1p?campaign={{utm_campaign}}&creative={{utm_term}}",
      },
    },
    {
      identifier: "lp-herb",
      conditions: {
        pageIdentifier: "lp-herb",
      },
      tracking: {
        utms: {
          utm_source: "herb",
          utm_campaign: "paid_social",
        },
        iosAdjustURL:
          "https://app.adjust.com/1r1a3ft?campaign={{utm_campaign}}&creative={{utm_term}}",
        androidAdjustURL:
          "https://app.adjust.com/8e07co6?campaign={{utm_campaign}}&creative={{utm_term}}",
      },
    },
    {
      identifier: "lp-asus",
      conditions: {
        pageIdentifier: "lp-asus",
      },
      tracking: {
        iosAdjustURL:
          "https://app.adjust.com/uzw2zg3?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
        androidAdjustURL:
          "https://app.adjust.com/7kg4g3j?campaign={{utm_medium}}&adgroup={{utm_campaign}}&creative={{utm_term}}&label={{utm_content}}",
      },
    },
  ],
};
