import cn from "classnames";
import { Blocks, Quote } from "@/components/molecules";
import { Block } from "@/types";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  align?: "right" | "center";
  anchor?: string;
  citation?: string;
  className?: string;
  innerBlocks?: Block[];
  value?: string;
}
/**
 * The quote block can be aligned in a few different ways including left, center, and right
 */
export default function QuoteBlock({
  align,
  anchor,
  citation,
  className,
  innerBlocks,
  value,
}: Props) {
  return (
    <Quote
      align={align}
      className={cn("text-blue-8", className)}
      id={anchor}
      value={value}
      citation={citation}
    >
      {!!innerBlocks?.length && <Blocks blocks={innerBlocks} />}
    </Quote>
  );
}

// Must match __typename
const BLOCK_NAME = "CoreQuote";

QuoteBlock.displayName = BLOCK_NAME;

QuoteBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          backgroundColor
          citation
          className
          layout
          metadata
          style
          textColor
          value
        }
      }
    }
  `,
};
