import { useEffect, useState } from "react";

import { FetchResponseType } from "./types";

export const useFetch = <T>(
  url: string | null,
  options?: Record<string, unknown>,
  responseType?: FetchResponseType
): {
  data?: T | string | undefined;
  isLoaded: boolean;
  loading: boolean;
  error: Error | null;
} => {
  const [data, setData] = useState<T | string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (url) {
        try {
          setLoading(true);
          const res = await fetch(url, options);

          if (res.ok) {
            let responseData: T | string;
            if (responseType === FetchResponseType.Text) {
              responseData = await res.text();
            } else {
              responseData = await res.json();
            }

            setData(responseData);
            setIsLoaded(true);
          } else {
            setError(new Error("response not ok"));
          }

          setLoading(false);
        } catch (err) {
          setError(err as Error);
          setLoading(false);
        }
      }
    };
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { error, isLoaded, loading, data };
};
