import { gql } from '@apollo/client';

const MenuItemFragment = gql`
  fragment MenuItemFragment on MenuItem {
    id
    path
    label
    parentId
    cssClasses
    target
    ga4Id
    menu {
      node {
        name
      }
    }
  }
`;

export default MenuItemFragment;
