import type { ReactNode } from "react";

import styles from "./contentWrapper.module.css";
import { sanitizeRawHTML } from "@/utilities/sanitizeRawHTML";

interface Props {
  content: string;
  children?: ReactNode;
}

export default function ContentWrapper({ content, children }: Props) {
  return (
    <div>
      <div
        className={styles["contentWrapper"]}
        dangerouslySetInnerHTML={{ __html: sanitizeRawHTML(content) ?? "" }}
      />
      {children}
    </div>
  );
}
