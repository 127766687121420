import { gql } from "@apollo/client";
import {
  BlogInfoFragment,
  MenuItemFragment,
  NewMenuItemFragment,
} from "@/fragments";
import * as MENUS from "@/constants/menus";

const GetSite = () => {
  return {
    siteArgs: `$footerLocation: MenuLocationEnum
        $headerLocation: MenuLocationEnum
        $newMenuLocation: MenuLocationEnum
        $locale: LanguageCodeFilterEnum = EN
        `,
    siteFragments: gql`
      ${BlogInfoFragment}
      ${MenuItemFragment}
      ${NewMenuItemFragment}
    `,
    siteQuery: `
            generalSettings {
              ...BlogInfoFragment
            }
            headerMenuItems: menuItems(
              where: { location: $headerLocation, language: $locale }
              first: ${MENUS.NUMBER_OF_MENU_ITEMS}
            ) {
              nodes {
                ...MenuItemFragment
              }
            }
            footerMenuItems: menuItems(
              where: { location: $footerLocation, language: $locale }
              first: ${MENUS.NUMBER_OF_MENU_ITEMS}
            ) {
              nodes {
                ...MenuItemFragment
              }
            }
            newMenuItems: menuItems(
              where: { location: $newMenuLocation, language: $locale }
              first: ${MENUS.NUMBER_OF_MENU_ITEMS}
            ) {
              nodes {
                ...NewMenuItemFragment
              }
            }`,
  };
};

export default GetSite;
