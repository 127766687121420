import cn from "classnames";
import { RichText } from "@/components/atoms";
import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  borderNeutral,
  borderInverse,
} from "@/constants/standardCSSClasses";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import { CustomImageInterface } from "@/components/molecules/CustomImage";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import { useTouchSlide } from "@/hooks/useTouchSlider";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  image?: CustomImageInterface;
  media?: MediaObjectInterface;
  icon?: IconInterface;
  heading?: string;
  content?: string;
  button?: CustomButtonInterface;
  variant: string;
}

export default function InnovationBlock({
  image,
  media = {
    type: "image",
    media: {},
  },
  icon = {
    source: "",
  },
  heading,
  content,
  button,
  variant,
}: Props) {
  const { slideWrapClass } = useTouchSlide(1, true);

  const wrapClassNames = cn(
    "py-12 rounded mb-0 md:mb-5",
    slideWrapClass,
    "w-full",
    {
      [borderInverse]: variant === "dark",
      [borderNeutral]: variant !== "dark",
    }
  );

  const pTextClassNames = cn(
    "mt-0 font-normal text-base leading-5 md:text-lg md:leading-6",
    {
      [textBodyInverse]: variant === "dark",
      [textBodyNeutral]: variant !== "dark",
    }
  );

  const attrClassNames = cn(
    "block text-xl md:text-3xl md:leading-9 font-medium",
    {
      [textHeadlineInverse]: variant === "dark",
      [textHeadlineNeutral]: variant !== "dark",
    }
  );

  const imageClassNames = cn("w-full max-w-full h-auto rounded");

  const buttons = [
    {
      ...button,
      text: button?.text,
      wrap: false,
      variant: variant,
      textOnly: true,
      arrow: true,
      size: "md",
    },
  ];

  return (
    <div className={wrapClassNames}>
      <div className="flex w-full">
        <CustomMedia
          className="h-auto w-full"
          media={media ?? { media: {}, type: "image" }}
          aspect="2:1"
          fallbackImage={image}
          imageClassName={imageClassNames}
        />
      </div>
      <div className="mt-12 grid grid-flow-row md:grid-cols-2">
        <div className="mb-4 flex w-full flex-row items-center justify-start gap-4 md:items-start">
          <Icon
            {...icon}
            variant={variant}
            iconStyle="heading"
            className="flex h-[37px] w-[37px] items-center justify-center"
            width={20}
          />
          <RichText className={attrClassNames} tag="strong">
            {heading}
          </RichText>
        </div>
        <div className="flex w-full flex-col justify-start gap-6">
          <RichText className={pTextClassNames} tag="p">
            {content}
          </RichText>
          <CustomButtonGroup
            align="left"
            buttons={buttons}
            marginTop=""
            mobileWide={false}
            className="max-w-min py-4 text-base font-normal leading-5 md:py-0 md:text-lg md:leading-6"
          />
        </div>
      </div>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupInnovationSlide";

InnovationBlock.displayName = BLOCK_NAME;

InnovationBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          button
          className
          heading
          content
          icon
          image
          media
          metadata
        }
      }
    }
  `,
};
