import { PlanSegment } from "@/types/PlanSegment";

interface IPricingProps {
  planSegment: PlanSegment;
  teamOffers: any;
  webOffers: any;
}

export default function DiscountedPrice({
  planSegment,
  teamOffers,
  webOffers,
}: IPricingProps) {
  const loadingPrice = "";

  if (
    planSegment === "business" ||
    (planSegment === "businessPlus" && teamOffers)
  ) {
    const teamOfferPrice =
      teamOffers?.formattedPlanPrices[planSegment]?.discountedMonthlyPrice;
    return <>{teamOfferPrice ? teamOfferPrice : loadingPrice}</>;
  }

  if ((planSegment === "premium" || planSegment === "family") && webOffers) {
    const webOfferPrice =
      webOffers?.formattedDefaultWebOffers[planSegment]?.discountedMonthlyPrice;
    return <>{webOfferPrice ? webOfferPrice : loadingPrice}</>;
  }

  return <>{loadingPrice}</>;
}
