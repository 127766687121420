import { FaustHooks, FaustPlugin } from "@faustwp/core";
import { gql } from "@apollo/client";

// Replaces default seed query and adds extra locale and translated information for posts and pages
export default class SeedQueryWithLocalePlugin implements FaustPlugin {
  apply(hooks: FaustHooks) {
    const { addFilter } = hooks;
    addFilter("seedQueryDocumentNode", "faust", () => {
      return gql`
        query GetSeedNode(
          $id: ID = "0"
          $uri: String = ""
          $asPreview: Boolean = false
        ) {
          ... on RootQuery @skip(if: $asPreview) {
            nodeByUri(uri: $uri) {
              __typename
              ...GetNodeFrag
            }
          }
          ... on RootQuery @include(if: $asPreview) {
            contentNode(id: $id, idType: DATABASE_ID, asPreview: true) {
              __typename
              ...GetNodeFrag
            }
          }
        }

        fragment GetNodeFrag on UniformResourceIdentifiable {
          __typename
          uri
          id
          ...DatabaseIdentifier
          ...ContentType
          ...User
          ...TermNode
          ...ContentNode
          ...MediaItem
          ...PageFrag
          ...PostFrag
        }

        fragment DatabaseIdentifier on DatabaseIdentifier {
          databaseId
        }

        fragment MediaItem on MediaItem {
          id
          mimeType
        }

        fragment ContentType on ContentType {
          name
          isFrontPage

          # This is currently broken. The home page (blog page) can not be
          # resolved when set to a custom page until the below issue is resolved.
          # Link: https://github.com/wp-graphql/wp-graphql/issues/2514
          isPostsPage
        }

        fragment PostFrag on Post {
          language {
            locale
          }
          translations {
            uri
            language {
              locale
            }
            status
          }
        }

        fragment PageFrag on Page {
          isFrontPage
          isPostsPage
          language {
            locale
          }
          translations {
            uri
            language {
              locale
            }
            status
          }
        }

        fragment TermNode on TermNode {
          isTermNode
          slug
          taxonomyName
        }

        fragment ContentNode on ContentNode {
          isContentNode
          slug
          contentType {
            node {
              name
            }
          }
          template {
            templateName
          }
        }

        fragment User on User {
          name
          userId
          databaseId
        }
      `;
    });
  }
}
