import UAParser from "ua-parser-js";

const updateOSName = (parsedResults: any) => {
  // On iOS 13+, iPad OS is recognized incorrectly as Mac OS and the device is undefined
  // This is a workaround to identify iPad for mobile downloads until supported
  const iPadOS =
    parsedResults.ua.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === "MacIntel" &&
      navigator.maxTouchPoints > 1); /* iPad OS 13 */
  // osName: 'Mac OS' | 'Windows' | 'iOS' | 'Android' | 'Windows [Phone/Mobile]'
  return iPadOS ? "iOS" : parsedResults.os.name;
};

// Used as a wrapper around the ua-parser-js library for overrides
const getParsedUserAgent = () => {
  const parsedResults = new UAParser().getResult();
  parsedResults.os.name = updateOSName(parsedResults);
  return parsedResults;
};

export { getParsedUserAgent };
