import React from "react";
import cn from "classnames";
import BlockContent from "../molecules/BlockContent";
import { Block } from "@/types";
import { Blocks } from "@/components/molecules";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  variant?: string;
  width?: string;
  headingTag?: number;
  innerBlocks?: Block[];
}

export default function ImageCardsAddonBlock({
  variant = "dark",
  width = "full",
  headingTag = 3,
  innerBlocks,
  blockContentProps,
}: Props) {
  const cards = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;

        if (each.attributes.headingOptions === undefined) {
          each.attributes.headingOptions = {};
        }
        each.attributes.headingOptions.tag = headingTag + 1;

        return each;
      })}
    />
  ) : null;

  const blockClassNames = cn({
    "mt-[-40px]": width !== "full",
    "mt-[-16px]": width === "full",
  });

  return (
    <BlockContent
      className={blockClassNames}
      padding="pt-0 pb-14 md:pb-20"
      variant={variant}
      width={width}
      defaultValue="full"
      constrain
      {...blockContentProps}
    >
      <div className="grid gap-6 lg:grid-cols-2">{cards}</div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupImageCardsAddon";

ImageCardsAddonBlock.displayName = BLOCK_NAME;

ImageCardsAddonBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          className
          ga4SectionId
          headingTag
          metadata
          variant
          width
        }
      }
    }
  `,
};
