export const SIGNUP_FORM_BUTTON_SUBMIT = {
  "en": "Submit",
  "fr": "Envoyer",
  "de": "Senden",
  "es": "Enviar",
  "pt-br": "Submit",
  "it": "Submit",
  "ja": "Submit",
  "ko": "Submit",
  "nl": "Submit",
  "pt-pt": "Submit",
  "sv": "Submit",
  "zh": "Submit"
}