export const RESOURCE_ARCHIVE_ALL_HEADING = {
  "en": "All %(category)",
  "fr": "All %(category)",
  "de": "All %(category)",
  "es": "All %(category)",
  "pt-br": "All %(category)",
  "it": "All %(category)",
  "ja": "All %(category)",
  "ko": "All %(category)",
  "nl": "All %(category)",
  "pt-pt": "All %(category)",
  "sv": "All %(category)",
  "zh": "All %(category)"
}