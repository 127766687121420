export const dashGreen010 = '#E8F2F4';
export const dashGreen020 = '#D8E7EB';
export const dashGreen030 = '#B0D4DA';
export const dashGreen040 = '#75BCC7';
export const dashGreen050 = '#2F9CAE';
export const dashGreen060 = '#0C7D90';
export const dashGreen070 = '#026272';
export const dashGreen080 = '#034854';
export const dashGreen090 = '#0D3038';
export const dashGreen100 = '#012025';

export const red010 = '#F8EEF0';
export const red020 = '#F8DEE2';
export const red030 = '#FEBDC2';
export const red040 = '#F8959E';
export const red050 = '#ED5F72';
export const red060 = '#D1364E';
export const red070 = '#AD1C34';
export const red080 = '#831024';
export const red090 = '#5C0715';
export const red100 = '#3D070E';

export const orange010 = '#FBEEE7';
export const orange020 = '#F9DFD4';
export const orange030 = '#F9C1AF';
export const orange040 = '#F49A81';
export const orange050 = '#DD6F58';
export const orange060 = '#BE4E3A';
export const orange070 = '#9D3625';
export const orange080 = '#792315';
export const orange090 = '#52180E';
export const orange100 = '#341110';

export const green010 = '#E8F3EE';
export const green020 = '#D5E9DE';
export const green030 = '#AED7C1';
export const green040 = '#6CC1A0';
export const green050 = '#38A079';
export const green060 = '#1C815A';
export const green070 = '#176547';
export const green080 = '#084B33';
export const green090 = '#0A3226';
export const green100 = '#042117';

export const neutral000 = '#FFFFFF';
export const neutral005 = '#F5F8F9';
export const neutral010 = '#EDF1F4';
export const neutral020 = '#E0E5E9';
export const neutral030 = '#C6CFD5';
export const neutral040 = '#a5b3bc';
export const neutral050 = '#81919E';
export const neutral060 = '#637482';
export const neutral070 = '#4A5B69';
export const neutral080 = '#344350';
export const neutral090 = '#212D37';
export const neutral100 = '#141D25';
export const neutral105 = '#0E181F';
export const neutral110 = '#0D171C';
export const neutral120 = '#051014';
// TODO: Replace color when new colors are implemented
export const neutral130 = '#EBE9E7';
export const midGreen5 = '#D9E6E9';
export const sharingCenterBlue = '#F0F8FA';
export const resourceCenterBlue = '#F3F5F7';
export const rulesCore = '#C5CFD6';
export const dashMidGreen5 = '#D9E6E9';
export const teal010 = '#F0F8FA';
export const teal020 = '#E7F2F5';
export const dashGreen00 = '#0E353D';
export const darkTeal = '#08272F';
export const boldGreen = '#3EC69B';
export const pink = '#FEA7A6';
export const orangeQuiet = '#FFEDE3';
export const warningStandard = '#BD502B';
export const dashGreen06 = '#F5F7F7';
export const errorRed = '#FF002D';
export const dashIron = '#D9DFE0';
export const black = '#000000';
export const grey = '#676360';
export const lightGrey = '#909090';
export const midGreen00 = '#0E6476';
export const dashDarkerGreen00 = '#071A1E';
export const pink01 = '#FDC4BF';
export const containerDecorativeBrandCatchy = '#41B3BF';
export const textBrandStandard = '#009EAA';

// Core Colors for Marketing
export const coreText = '#04242B';
export const coreDashGreen = '#09363F';
export const yellowLight = '#FFEED5';
export const gray005 = '#F1F4F6';
export const blueLight = '#E7F2F5';

// Vibrant Colors for Marketing
export const vibrantPinkBold = '#FDB5AF';
export const vibrantBlueBold = '#009EAA';

// Colors for social networks
export const facebookBlue = '#43609c';
export const twitterBlue = '#1da1f2';

// Holiday promo page background
export const holidayPromoBg = '#D1E8EC';
export const holidayPromoValuesBg = '#F1F4F6';
