import cn from "classnames";
import React, { ChangeEventHandler } from "react";

export interface ToggleInterface {
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  activeText?: string;
  inactiveText?: string;
}

export default function Toggle({
  checked = true,
  onChange,
  activeText = "",
  inactiveText = "",
}: ToggleInterface) {
  const toggleClassNames =
    "absolute bottom-0 left-0 right-0 top-0 cursor-pointer bg-grey-3 transition duration-300 rounded-[17px]";
  const toggleCheckedClassNames =
    "before:peer-checked:translate-x-[13px] peer-checked:bg-blue-5";
  const toggleBeforeClassNames =
    "before:rounded-[17px] before:absolute before:bottom-0.5 before:left-0.5 before:h-[13px] before:w-[13px] before:bg-white before:transition before:duration-300 before:content-['']";

  return (
    <span className="relative flex h-[17px] w-[30px] items-center">
      <input
        className="peer h-0 w-0 opacity-0"
        type="checkbox"
        onChange={onChange}
        checked={checked}
      />
      <span
        className={cn(
          toggleClassNames,
          toggleBeforeClassNames,
          toggleCheckedClassNames
        )}
      />
      {(activeText || inactiveText) && (
        <strong className="ml-[38px] inline-block text-sm">
          {checked ? activeText : inactiveText}
        </strong>
      )}
    </span>
  );
}
