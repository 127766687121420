export const RESOURCE_SEARCH_PLACEHOLDER = {
  "en": "Search...",
  "fr": "Search...",
  "de": "Search...",
  "es": "Search...",
  "pt-br": "Search...",
  "it": "Search...",
  "ja": "Search...",
  "ko": "Search...",
  "nl": "Search...",
  "pt-pt": "Search...",
  "sv": "Search...",
  "zh": "Search..."
}