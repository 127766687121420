import React from "react";
import cn from "classnames";
import { useTranslate } from "@/hooks/useTranslate";

import { RichText } from "@/components/atoms";
import { h3Text, marginBMd, marginBSm } from "@/constants/standardCSSClasses";
import {
  PARTNERS_REGISTRATION_SUCCESS_HEADER,
  PARTNERS_REGISTRATION_SUCCESS_PARAGRAPH,
} from "@/i18n/translations";

export default function PartnersFormSuccess() {
  const { translate, translateInterpolate } = useTranslate();

  return (
    <>
      <RichText tag="h1" className={cn(h3Text, marginBMd)}>
        {translate(PARTNERS_REGISTRATION_SUCCESS_HEADER)}
      </RichText>
      <RichText tag="p" className={marginBSm}>
        {translateInterpolate(PARTNERS_REGISTRATION_SUCCESS_PARAGRAPH, {
          resellersEmail: "resellers@dashlane.com",
        })}
      </RichText>
    </>
  );
}
