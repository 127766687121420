export const TRIAL_STEP_2 = {
  "en": "Verify your email",
  "fr": "Vérifier votre adresse e-mail",
  "de": "E-Mail-Adresse verifizieren",
  "es": "Verifique su correo electrónico",
  "pt-br": "Verify your email",
  "it": "Verify your email",
  "ja": "Verify your email",
  "ko": "Verify your email",
  "nl": "Verify your email",
  "pt-pt": "Verify your email",
  "sv": "Verify your email",
  "zh": "Verify your email"
}