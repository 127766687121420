import React, { PropsWithChildren } from "react";
import Image from "next/image";
import cn from "classnames";

import { Style } from "@/types";
import convertHexToRgb from "@/utilities/convertHexToRgb";
import extractRgbValues from "@/utilities/extractRgbValues";

interface DuotoneFilterProps {
  className: string;
  duotone: string[];
  id: string;
}

function DuotoneFilter({ className, duotone, id }: DuotoneFilterProps) {
  const rgbValues = duotone?.map((color) =>
    color.includes("#") ? convertHexToRgb(color) : extractRgbValues(color)
  );

  // Calculate R, G, B decimal values.
  const rValues = rgbValues.map((color) => color[0] / 255);
  const gValues = rgbValues.map((color) => color[1] / 255);
  const bValues = rgbValues.map((color) => color[2] / 255);

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id={id}>
          <feColorMatrix
            type="matrix"
            values=".299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 0 0 0 1 0"
          ></feColorMatrix>
          <feComponentTransfer colorInterpolationFilters="sRGB">
            <feFuncR type="table" tableValues={rValues.join(" ")}></feFuncR>
            <feFuncG type="table" tableValues={gValues.join(" ")}></feFuncG>
            <feFuncB type="table" tableValues={bValues.join(" ")}></feFuncB>
          </feComponentTransfer>
        </filter>
      </defs>
    </svg>
  );
}

interface Props {
  align?: "left" | "center" | "right" | "wide" | "full";
  backgroundImage?: { url: string };
  className?: string;
  contentAlign?:
    | "top left"
    | "center left"
    | "bottom left"
    | "top center"
    | "center center"
    | "bottom center"
    | "top right"
    | "center right"
    | "bottom right";
  duotone?: any[];
  fixed?: boolean;
  focalPoint?: { x: string; y: string };
  fullHeight?: boolean;
  id?: string;
  overlayOpacity?: number;
  repeat?: boolean;
  style?: Style;
}

export default function Cover({
  align,
  backgroundImage,
  className,
  children,
  contentAlign,
  duotone,
  fixed,
  focalPoint,
  fullHeight,
  id,
  overlayOpacity = 0.5,
  repeat,
  style,
}: PropsWithChildren<Props>) {
  const coverStyle = backgroundImage?.url
    ? {
        backgroundImage: `url(${backgroundImage.url})`,
        backgroundSize: `cover`,
        backgroundPosition: `50%`,
      }
    : {};

  if (style?.backgroundColor) {
    Object.assign(coverStyle, { backgroundColor: style.backgroundColor });
  }

  const hasFilter = !!backgroundImage?.url && !!duotone && !fixed && !repeat;

  // Generate unique ID for filter.
  const filterKey = Math.random().toString(36).substr(2, 9);
  const filterId = `duotone-filter-${filterKey}`;

  const filterStyle = {
    filter: `url(#${filterId})`,
  };

  // Conditionally apply focal point.
  if (focalPoint) {
    const focalPointStyle = `${focalPoint.x} ${focalPoint.y}`;

    Object.assign(coverStyle, { backgroundPosition: focalPointStyle });
    Object.assign(filterStyle, { objectPosition: focalPointStyle });
  }

  return (
    <>
      {hasFilter && (
        <DuotoneFilter
          className="absolute left-[-9999px] hidden overflow-hidden"
          duotone={duotone}
          id={filterId}
        />
      )}
      <section
        id={id}
        className={cn(
          "relative flex flex-col",
          align === "left" && "mr-auto w-1/2",
          align === "center" && "mx-auto w-1/2",
          align === "right" && "ml-auto w-1/2",
          align === "wide" && "mx-auto w-full max-w-7xl",
          align === "full" && "w-full",
          fixed && "bg-fixed",
          fullHeight && "min-h-[80vh]",
          repeat && "bg-auto bg-repeat",
          className
        )}
        style={{
          ...style?.color,
          ...style?.typography,
          ...coverStyle,
        }}
      >
        <div
          className={cn(
            "absolute bottom-0 left-0 right-0 top-0 z-10 rounded bg-inherit",
            !backgroundImage?.url && "z-auto"
          )}
          style={{ opacity: overlayOpacity }}
        />
        {hasFilter && (
          <Image
            alt="Filtered image"
            className="absolute inset-0 m-auto box-border block h-0 max-h-full min-h-full w-0 min-w-full max-w-full object-cover p-0"
            src={backgroundImage?.url}
            style={filterStyle}
          />
        )}
        <div
          className={cn(
            "relative z-20 mx-auto flex w-full max-w-7xl grow flex-col p-8",
            !contentAlign && "items-center justify-center",
            contentAlign === "top left" && "items-start justify-start",
            contentAlign === "center left" && "items-start justify-center",
            contentAlign === "bottom left" && "items-start justify-end",
            contentAlign === "top center" && "items-center justify-start",
            contentAlign === "center center" && "items-center justify-center",
            contentAlign === "bottom center" && "items-center justify-end",
            contentAlign === "top right" && "items-end justify-start",
            contentAlign === "center right" && "items-end justify-center",
            contentAlign === "bottom right" && "items-end justify-end"
          )}
        >
          <div className="md:w-1/2">{children}</div>
        </div>
      </section>
    </>
  );
}
