export const setCookie = (
  name: string,
  value: string,
  expiration = "Fri, 31 Dec 9999 23:59:59 GMT"
) => {
  document.cookie = `${name}=${value}; expires=${expiration}; Secure`;
};

export const getCookie = (name: string) => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(name))
    ?.split("=")[1];
};
