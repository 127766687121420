import { LoadingIcon } from '@/components/icons';
import { Button } from '@/components/molecules';
import { LOAD_MORE_BUTTON } from '@/i18n/translations/LOAD_MORE_BUTTON';
import { useTranslate } from '@/hooks/useTranslate';

interface Props {
  hasNextPage: boolean;
  loading: boolean;
  loadPosts: () => Promise<void>;
}

export default function LoadMoreSection({
  hasNextPage,
  loading,
  loadPosts,
}: Props) {
  const { translate } = useTranslate();
  return (
    <div className="flex h-40 items-center justify-center">
      {loading ? (
        <div data-cy="load-more-loader">
          <LoadingIcon />
        </div>
      ) : (
        hasNextPage && (
          <div data-cy="load-more-button">
            <Button onClick={loadPosts}>{translate(LOAD_MORE_BUTTON)}</Button>
          </div>
        )
      )}
    </div>
  );
}
