import cn from "classnames";
import {
  backgroundInverseLight,
  backgroundNeutralDark,
  backgroundWhiteDark,
  imageFillCatchyStandard,
  imageFillDark,
  imageFillInverse,
  imageFillNeutral,
  imageFillStandard,
  imageFillCatchyInverse,
} from "@/constants/standardCSSClasses";
import { iconDefault } from "@/constants/icons";

export interface IconInterface {
  variant?: string;
  source: string;
  name?: string;
  iconSet?: string;
  className?: string;
  iconClassName?: string;
  iconStyle?: string;
  width?: number;
  as?: string;
  onClick?(): any;
  ariaLabel?: string;
}

export default function Icon({
  variant = "",
  source = "",
  className = "",
  iconClassName = "",
  width = 24,
  iconStyle = "minimal",
  as = "span",
  onClick,
  ariaLabel,
  name = "",
}: IconInterface) {
  const classNames = cn("min-w-fit", className, {
    "p-2 rounded": (iconStyle === "standard" || iconStyle === "heading"),
    [backgroundInverseLight]: (iconStyle === "standard" || iconStyle === "heading") && variant === "dark",
    [backgroundNeutralDark]: (iconStyle === "standard" || iconStyle === "heading") && variant === "light",
    [backgroundWhiteDark]: (iconStyle === "standard" || iconStyle === "heading") && variant === "white",
  });

  const iconClassNames = cn(iconClassName, "inline-block", {
    [imageFillNeutral]: variant !== "dark" && iconStyle === "minimal",
    [imageFillStandard]: variant !== "dark" && iconStyle === "standard",
    [imageFillCatchyStandard]: variant !== "dark" && iconStyle === "catchy",
    [imageFillDark]: variant !== "dark" && iconStyle === "heading",
    [imageFillInverse]:
      variant === "dark" &&
      (iconStyle === "minimal" || iconStyle === "standard" || iconStyle === "heading"),
    [imageFillCatchyInverse]: variant === "dark" && iconStyle === "catchy",
  });

  if (!source) source = iconDefault;

  const theIcon = (
    <img
      alt={name ? name : undefined}
      width={width ? width : undefined}
      className={iconClassNames}
      src={`data:image/svg+xml;utf8,${encodeURIComponent(source)}`}
    />
  );

  if (as === "button") {
    return (
      <button className={classNames} onClick={onClick} aria-label={ariaLabel}>
        {theIcon}
      </button>
    );
  }

  return (
    <span className={classNames} onClick={onClick}>
      {theIcon}
    </span>
  );
}
