import { Locale, RelatedResource } from "@/types";
import { formatEventDate } from "@/utilities/formatEventDate";
import { useTranslate } from "@/hooks/useTranslate";
import { RESOURCE_ARCHIVE_VIEW_BUTTON } from "@/i18n/translations";
import { useBrowserLanguage } from "@/hooks/useBrowserLanguage";

export const mapResourcesToCards = (
  resources: RelatedResource[],
  locale: Locale,
  tagTaxonomy: "resourceCategories" | "resourceTypes" = "resourceCategories"
) => {
  const { translate } = useTranslate();
  const { language } = useBrowserLanguage(locale);

  return resources && resources.length
    ? resources.map((each: RelatedResource) => {
        return {
          variant: "white",
          imageAspect: "contentCard",
          visual: "image",
          border: false,
          heading: each.title,
          content: each.excerpt,
          tag: {
            link: each[tagTaxonomy]?.nodes[0]?.uri,
            name: each[tagTaxonomy]?.nodes[0]?.name ?? "",
          },
          image: {
            full: each.featuredImage?.node?.sourceUrl
              ? each.featuredImage.node.sourceUrl
              : "/images/blog-post-default.webp",
            alt: each.featuredImage?.node?.altText
              ? each.featuredImage.node.altText
              : "A green and blue background with three blue slanted rectangles in the middle. A white Dashlane logo is overlaid on top.",
          },
          includeCardButtons: false,
          linkUri: each.resourceFields.externalUrl
            ? each.resourceFields.externalUrl
            : each.uri,
          button: {
            text: translate(RESOURCE_ARCHIVE_VIEW_BUTTON),
            href: each.resourceFields.externalUrl
              ? each.resourceFields.externalUrl
              : each.uri,
            target: each.resourceFields.externalUrl ? "_blank" : "",
          },
          linkCard: true,
          eventDate: formatEventDate(
            each.resourceFields.eventDate,
            language,
            true
          ),
        };
      })
    : [];
};
