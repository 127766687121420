import cn from "classnames";
import { RichText } from "@/components/atoms";
import {
  textHeadlineInverse,
  textHeadlineNeutral,
  textBodyInverse,
  textBodyNeutral,
  marginBXS,
  borderBoth,
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomImage, {
  CustomImageInterface,
} from "@/components/molecules/CustomImage";
import React from "react";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import { useTouchSlide } from "@/hooks/useTouchSlider";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  variant: string;
  heading?: string;
  date?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  includeContent?: boolean;
  includeDate?: boolean;
  button?: CustomButtonInterface;
  layout: string;
  logo: CustomImageInterface;
}

export default function NewsFeedArticleBlock({
  variant = "white",
  heading = "",
  date = "",
  headingOptions = {
    tag: 3,
  },
  content = "",
  includeContent = false,
  includeDate = false,
  button,
  layout = "list",
  logo,
}: Props) {
  const headerClassNames = cn("text-2xl font-medium", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    [marginBXS]: layout === "list",
  });

  const pTextClassNames = cn({
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
    "mb-6": layout === "list",
  });

  const dateClassNames = cn("mb-6", {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const logoClassNames = cn("object-contain max-w-[170px] h-[40px]", {
    "white-image": variant === "dark",
  });

  const { slideWrapClass } = useTouchSlide(3, false);
  const cardsWrapper = cn(
    slideWrapClass,
    "p-8 rounded border border-grey-1 border-solid"
  );

  const buttons = [
    {
      ...button,
      variant: variant,
      textOnly: true,
      arrow: true,
      size: "md",
    },
  ];

  return (
    <div
      className={cn(borderBoth, {
        "mb-10 border border-l-0 border-r-0 border-t-0 border-solid pb-10":
          layout === "list",
        [cardsWrapper]: layout !== "list",
      })}
    >
      {layout !== "list" && logo?.full && (
        <div className="mb-8">
          <CustomImage
            className={logoClassNames}
            imageClassName="object-left"
            objectFit="contain"
            rounded={false}
            {...logo}
          />
        </div>
      )}
      {includeDate && (
        <RichText className={dateClassNames} tag="p">
          {date}
        </RichText>
      )}
      <CustomHeading
        heading={heading}
        headingOptions={headingOptions}
        headingClassNames={headerClassNames}
      />
      {includeContent && (
        <RichText className={pTextClassNames} tag="p">
          {content}
        </RichText>
      )}
      <CustomButtonGroup
        marginTop={cn("mt-2.5", { "mt-6": layout !== "list" })}
        align="left"
        buttons={buttons}
        mobileWide={false}
      />
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupNewsFeedArticle";

NewsFeedArticleBlock.displayName = BLOCK_NAME;

NewsFeedArticleBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          button
          className
          content
          date
          heading
          headingOptions
          includeContent
          includeDate
          logo
          metadata
        }
      }
    }
  `,
};
