import dynamic from 'next/dynamic';
import cn from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
const SyntaxHighlighter = dynamic(
  () =>
    import('react-syntax-highlighter/dist/esm/prism').then(
      (mod) => mod.default
    ),
  { ssr: false }
);
import prismStyle from 'react-syntax-highlighter/dist/cjs/styles/prism/tomorrow';

import { BlockStyle } from '@/utilities/getBlockStyles';

interface Props {
  id: string;
  className?: string;
  content: string;
  style: BlockStyle;
}

export default function Code({ id, className, content, style }: Props) {
  const classNames = className?.length ? className.split(' ') : [];

  // Use the first entry in className to pass the language.
  const language = classNames?.length ? classNames.shift() : 'javascript';

  function codeFormatter(content: string) {
    return content
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&#91;/g, '[');
  }

  const prismProps = {
    style: prismStyle,
    customStyle: style,
    language,
  };

  // Manually add needed styles and remove padding to prevent indentation bug on first line
  const codeTagStyles = {
    color: 'rgb(212,212,212)',
    background: 'none',
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    padding: '0',
  };

  // Add color to code tag props if custom color provided.
  if (style?.color) {
    Object.assign(codeTagStyles, {
      color: 'inherit',
    });
  }

  return (
    <div
      id={id}
      className={cn('relative mb-8', classNames.join(' '))}
      style={style}
    >
      <SyntaxHighlighter
        className="rounded-md"
        codeTagProps={{
          style: codeTagStyles,
        }}
        showLineNumbers={true}
        {...prismProps}
      >
        {codeFormatter(content)}
      </SyntaxHighlighter>
      <CopyToClipboard text={codeFormatter(content)}>
        <button className="absolute right-2 top-4 z-10 rounded-md bg-black px-3 py-2 text-neutral-300 transition ease-in-out hover:text-neutral-400 active:text-transparent">
          <ClipboardDocumentListIcon className="h-6 w-6" />
        </button>
      </CopyToClipboard>
    </div>
  );
}
