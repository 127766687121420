export const SIGNUP_FORM_ENTER_VALID_EMAIL = {
  "en": "Please enter a valid email address.",
  "fr": "Veuillez saisir une adresse e-mail valide.",
  "de": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
  "es": "Introduzca una dirección de correo electrónico válida.",
  "pt-br": "Please enter a valid email address.",
  "it": "Please enter a valid email address.",
  "ja": "Please enter a valid email address.",
  "ko": "Please enter a valid email address.",
  "nl": "Please enter a valid email address.",
  "pt-pt": "Please enter a valid email address.",
  "sv": "Please enter a valid email address.",
  "zh": "Please enter a valid email address."
}