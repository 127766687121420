/**
 * Function used to determine is an email address is valid
 * given a set of domains and top-level domains
 *
 */

interface Props {
  email: string | undefined;
  allowedDomains: string[];
}

/**
 * Function used to determine is an email address is valid
 * given a set of domains and top-level domains
 * @param email Email Address to Validate
 * @param allowedDomains Array of domain objects to validate against
 * @returns boolean
 */
export const isValidPartnershipEmail = ({ email, allowedDomains }: Props) => {
  const emailDomain = email?.toLowerCase().split("@")[1];

  console.log({ allowedDomains, emailDomain });

  // If allowed Domains isn't set, then we don't need to check
  if (!allowedDomains || !allowedDomains.length) {
    return true;
  }

  // If the email doesn't have a domain, then it can't match
  if (!emailDomain) {
    return false;
  }

  // We want to be able to capture subdomain variations so if the email is name@students.stanford.edu,
  // then it will still be captured by including stanford.edu
  const emailParts = emailDomain.split(".");
  const tld = emailParts.pop();

  const simplifiedEmails = emailParts.map((each) => each + "." + tld);

  return (
    allowedDomains.includes(emailDomain) ||
    allowedDomains.some((each) => simplifiedEmails.includes(each))
  );
};
