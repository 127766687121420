export default function convertHextoRgb(
  hexValue: string | Record<string, any>
) {
  return (
    hexValue
      // replace shorthand hexcodes with full hexcode value
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (_: any, r: string, g: string, b: string) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x: string) => parseInt(x, 16))
  );
}
