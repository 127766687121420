export const FORMS_ACCOUNT_EXISTS = {
  "en": "",
  "fr": "",
  "de": "",
  "es": "",
  "pt-br": "",
  "it": "",
  "ja": "",
  "ko": "",
  "nl": "",
  "pt-pt": "",
  "sv": "",
  "zh": ""
}