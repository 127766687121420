import React, { TouchEventHandler } from "react";
import { CustomButtonInterface } from "@/components/molecules/CustomButton";
import Icon, { IconInterface } from "@/components/molecules/Icon";
import { CustomImageInterface } from "@/components/molecules/CustomImage";
import cn from "classnames";
import { RichText } from "@/components/atoms";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";
import {
  backgroundInverse,
  backgroundNeutral,
  borderInverse,
  borderNeutral,
  headingCard,
  headingCardSm,
  marginBXS,
  marginTSm,
  textBodyInverse,
  textBodyNeutral,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import Link from "next/link";
import CustomTag from "@/components/molecules/CustomTag";
import ConditionalWrapper from "@/components/molecules/ConditionalWrapper";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia, {
  MediaObjectInterface,
} from "@/components/molecules/CustomMedia";
import { clock } from "@/constants/icons";

export interface CardInterface {
  variant: string;
  visual?: string;
  border?: boolean;
  heading: string;
  headingOptions?: HeadingOptions;
  headingClassName?: string;
  content: string;
  imageAspect?: string;
  button?: CustomButtonInterface;
  tag?: {
    link?: string;
    name: string;
  };
  icon?: IconInterface;
  image?: CustomImageInterface;
  media?: MediaObjectInterface;
  includeCardButtons?: boolean;
  includeCardIcons?: boolean;
  includeCardImages?: boolean;
  iconStyle?: string;
  linkCard?: boolean;
  linkUri?: string;
  className?: string;
  eventDate?: string;
  ga4Id?: string;
  backgroundStyle?: "full" | "boxed";
  handleTouchStart?: TouchEventHandler<HTMLDivElement>;
  handleTouchEnd?: TouchEventHandler<HTMLDivElement>;
  handleTouchMove?: TouchEventHandler<HTMLDivElement>;
}

const Card = ({
  variant = "light",
  visual = "icon",
  border = true,
  heading = "",
  headingClassName = "",
  headingOptions = {
    tag: 3,
  },
  content = "",
  imageAspect = "contentCard",
  button,
  tag,
  icon = {
    source: "",
  },
  image,
  media = {
    type: "image",
    media: {},
  },
  includeCardButtons = true,
  includeCardIcons = true,
  includeCardImages = true,
  iconStyle = "minimal",
  linkUri = "",
  linkCard = false,
  className = "",
  eventDate = "",
  backgroundStyle = "full",
  handleTouchStart,
  handleTouchEnd,
  handleTouchMove,
  ga4Id,
}: CardInterface) => {
  let currentUrl = process.env["NEXT_PUBLIC_SITE_URL"];

  const classNames = cn(className, {
    "border border-solid rounded p-6": border,
    [borderInverse]: variant === "dark",
    [borderNeutral]: variant !== "dark",
    "p-10 rounded overflow-hidden": backgroundStyle === "boxed",
    [backgroundInverse]: backgroundStyle === "boxed" && variant === "dark",
    [backgroundNeutral]: backgroundStyle === "boxed" && variant === "light",
  });

  const headerClassNames = cn("leading-[30px]", marginBXS, headingClassName, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
    [headingCard]:
      visual !== "icon" || (visual === "icon" && iconStyle !== "minimal"),
    [headingCardSm]: visual === "icon" && iconStyle === "minimal",
  });

  const pTextClassNames = cn({
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
    "text-sm": visual !== "image",
    "md:text-base":
      visual !== "icon" || (visual === "icon" && iconStyle !== "minimal"),
  });

  const iconClassNames = cn("inline-block mb-4");
  const imageClassNames = cn("mb-6 md:mb-8");
  //const tagClassNames = cn(marginBMd);

  const buttons = [];

  if (includeCardButtons) {
    buttons.push({
      ...button,
      variant: variant,
      textOnly: true,
      arrow: true,
      size:
        backgroundStyle === "boxed" && button?.size
          ? button.size
          : visual === "image"
          ? "lg"
          : "md",
      ga4Id,
    });
  }

  const includeLink = linkCard && linkUri && !includeCardButtons;
  const linkWrapper = (children: React.ReactNode) => (
    <Link
      href={linkUri}
      target={
        (!currentUrl || !linkUri?.includes(currentUrl)) &&
        !linkUri?.startsWith("/")
          ? "_blank"
          : ""
      }
      locale={false}
      {...(ga4Id ? { "data-item": ga4Id } : {})}
    >
      {children}
    </Link>
  );

  return (
    <div className={classNames}>
      <div
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <ConditionalWrapper condition={!!includeLink} wrapper={linkWrapper}>
          {visual === "icon" && includeCardIcons && (
            <Icon
              {...icon}
              variant={variant}
              className={iconClassNames}
              iconStyle={iconStyle}
            />
          )}
          {includeCardImages &&
            visual === "image" &&
            (image || (media && media.type !== "none")) && (
              <CustomMedia
                className={imageClassNames}
                media={media ?? { media: {}, type: "image" }}
                aspect={imageAspect}
                fallbackImage={image}
              />
            )}
        </ConditionalWrapper>
      </div>
      {(tag?.name || eventDate) && (
        <div className="flex items-center justify-between gap-3">
          {tag?.name && (
            <CustomTag variant={variant} link={tag.link} name={tag.name} />
          )}
          {eventDate && (
            <div className="mb-4 flex items-center text-sm text-blue-8">
              <span className="mr-1 inline-block [&>svg]:fill-blue-8">
                {clock}
              </span>
              {/* Suppress warning since TZ will cause hydration errors */}
              <span suppressHydrationWarning>{eventDate}</span>
            </div>
          )}
        </div>
      )}
      <ConditionalWrapper condition={!!includeLink} wrapper={linkWrapper}>
        {heading && (
          <CustomHeading
            heading={heading}
            headingOptions={headingOptions}
            headingClassNames={headerClassNames}
          />
        )}
      </ConditionalWrapper>
      <div
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        {content && (
          <RichText
            className={pTextClassNames}
            tag={content.includes("<p>") ? "div" : "p"}
          >
            {content}
          </RichText>
        )}
      </div>
      {includeCardButtons && (
        <CustomButtonGroup
          marginTop={marginTSm}
          align="left"
          buttons={buttons}
          mobileWide={false}
        />
      )}
    </div>
  );
};

export default Card;
