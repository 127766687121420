import cn from "classnames";
import BlockContent from "@/components/molecules/BlockContent";
import {
  h3Text,
  marginBMd,
  textBodyInverse,
  textBodyNeutral,
  textHeadlineInverse,
  textHeadlineNeutral,
} from "@/constants/standardCSSClasses";
import { Blocks } from "@/components/molecules";
import React from "react";
import { Block } from "@/types";
import { useTouchSlider } from "@/hooks/useTouchSlider";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import AboveHeading from "../molecules/AboveHeading";
import { RichText } from "@/components/atoms";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeHeading?: boolean;
  includeContent?: boolean;
  variant: string;
  aboveHeading?: string;
  heading?: string;
  content?: string;
  headingOptions?: HeadingOptions;
  innerBlocks?: Block[];
  contentType: string;
  paddingTop?: string;
  paddingBottom?: string;
}

export default function InnovationsBlock({
  includeAboveHeading = true,
  includeHeading = true,
  includeContent = true,
  aboveHeading = "",
  heading = "",
  content = "",
  headingOptions = {
    tag: 3,
  },
  variant = "light",
  innerBlocks,
  contentType,
  blockContentProps,
  paddingTop,
  paddingBottom,
}: Props) {
  const numSlides = innerBlocks?.length || 0;

  const innovationSlides = innerBlocks?.length ? (
    <Blocks
      blocks={innerBlocks.map((each) => {
        each.attributes.variant = variant;
        return each;
      })}
    />
  ) : null;

  const innovationSlidesHeadings = innerBlocks?.length
    ? innerBlocks.map((element) => element.attributes.heading)
    : undefined;

  const {
    wrapClassNames,
    sliderInnerControls,
    sliderInnovationClassNames,
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
    sliderRef,
    sliderTitlesNavigation,
  } = useTouchSlider(
    numSlides,
    variant,
    undefined,
    true,
    innovationSlidesHeadings
  );

  const headerClassNames = cn("font-bold my-0 md:me-10", h3Text, marginBMd, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const pTextClassNames = cn("text-lg leading-6", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const wrapper = cn("relative", wrapClassNames);

  return (
    <BlockContent
      contentType={contentType}
      width="full"
      constrain
      variant={variant}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className="items-top mb-10 flex items-center justify-center">
        <div className="max-w-[880px] text-center">
          {includeAboveHeading && aboveHeading && (
            <AboveHeading variant={variant}>{aboveHeading}</AboveHeading>
          )}
          {includeHeading && heading && (
            <CustomHeading
              heading={heading}
              headingOptions={headingOptions}
              headingClassNames={headerClassNames}
            />
          )}
          {includeContent && content && (
            <RichText className={pTextClassNames} tag="p">
              {content}
            </RichText>
          )}
        </div>
      </div>
      {sliderTitlesNavigation}
      <div className={wrapper}>
        {numSlides > 1 && sliderInnerControls}
        <div
          className={sliderInnovationClassNames}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          ref={sliderRef}
        >
          {innovationSlides}
        </div>
      </div>
    </BlockContent>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupInnovation";

InnovationsBlock.displayName = BLOCK_NAME;

InnovationsBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          className
          content
          ga4SectionId
          heading
          headingOptions
          includeAboveHeading
          includeContent
          includeHeading
          metadata
          variant
          paddingTop
          paddingBottom
        }
      }
    }
  `,
};
