import React from "react";
import cn from "classnames";
import { Blocks } from "@/components/molecules";
import { Block } from "@/types";
import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  borderInverse,
  borderNeutral,
  marginBXS,
  innerSectionPadding,
} from "@/constants/standardCSSClasses";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeHeading?: boolean;
  aboveHeading?: string;
  heading?: string;
  variant?: string;
  headingSize?: string;
  headingOptions?: HeadingOptions;
  width?: string;
  contentType: string;
  innerBlocks?: Block[];
  paddingTop?: string;
  paddingBottom?: string;
}

export default function AccoladesBlock({
  includeAboveHeading = true,
  includeHeading = true,
  aboveHeading = "",
  heading = "",
  headingSize = "md",
  headingOptions = {
    tag: 0,
  },
  variant = "white",
  width = "",
  contentType,
  innerBlocks,
  blockContentProps,
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const accolades = innerBlocks?.length ? (
    <Blocks
      contentType={contentType}
      blocks={innerBlocks.map((each, index) => {
        each.attributes.variant = variant;
        each.attributes.index = index;
        each.attributes.length = innerBlocks.length;
        return each;
      })}
    />
  ) : null;

  const headingClassNames = cn("mb-0 mt-0 font-medium", {
    "text-2xl md:text-4xl": headingSize === "lg",
    "text-2xl md:text-3xl": headingSize === "md",
    "text-2xl": headingSize === "sm",
    [textHeadlineNeutral]: variant !== "dark",
    [textHeadlineInverse]: variant === "dark",
  });

  return (
    <BlockContent
      contentType={contentType}
      variant={variant}
      width={width}
      defaultValue="box"
      constrain
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div
        className={cn(
          "flex flex-wrap items-center items-stretch justify-between rounded border border-solid",
          {
            [borderInverse]: variant === "dark",
            [borderNeutral]: variant !== "dark",
            ["lg:flex-nowrap"]: innerBlocks && innerBlocks.length > 3,
            ["md:flex-nowrap"]: !innerBlocks || innerBlocks.length <= 3,
          }
        )}
      >
        {(includeAboveHeading || includeHeading) && (
          <div
            className={cn(
              innerSectionPadding,
              "flex w-full min-w-fit grow flex-col justify-center pe-10 text-center",
              {
                ["lg:text-left"]: innerBlocks && innerBlocks.length > 3,
                ["md:w-auto md:pe-20 md:text-left"]:
                  !innerBlocks || innerBlocks.length <= 3,
              }
            )}
          >
            {includeAboveHeading && (
              <RichText
                className={cn(marginBXS, "text-lg", {
                  [textBodyInverse]: variant === "dark",
                  [textBodyNeutral]: variant !== "dark",
                  "md:text-xl": headingSize === "lg" || headingSize === "md",
                })}
                tag="div"
              >
                {aboveHeading}
              </RichText>
            )}
            {includeHeading && (
              <CustomHeading
                heading={heading}
                headingOptions={headingOptions}
                headingClassNames={headingClassNames}
              />
            )}
          </div>
        )}
        {accolades}
      </div>
    </BlockContent>
  );
}

const BLOCK_NAME = "TenupAccolades";

AccoladesBlock.displayName = BLOCK_NAME;

AccoladesBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          aboveHeading
          className
          content
          heading
          headingOptions
          headingSize
          includeAboveHeading
          includeHeading
          metadata
          variant
          width
        }
      }
    }
  `,
};
