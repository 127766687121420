import { MarketoFormFieldAny } from "@/types";

interface HiddenFieldInterface {
  field: MarketoFormFieldAny;
  register: Function;
}

export default function HiddenField({ field, register }: HiddenFieldInterface) {
  return (
    <input
      id={field.name}
      type="hidden"
      value={field.value}
      {...register(field.name, { required: true })}
    />
  );
}
