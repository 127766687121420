import cn from "classnames";
import type { ReactNode } from "react";

import * as SELECTORS from "@/constants/selectors";

interface Props {
  children: ReactNode;
  className?: string;
  props?: any;
}

export default function Main({ children, className, ...props }: Props) {
  return (
    <main
      id={SELECTORS.MAIN_CONTENT_ID}
      tabIndex={-1}
      className={cn(className, "flex w-full flex-col")}
      {...props}
    >
      {children}
    </main>
  );
}
