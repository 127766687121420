import React from "react";
import cn from "classnames";

import { RichText } from "@/components/atoms";
import CustomButtonGroup from "../molecules/CustomButtonGroup";
import { CustomButtonInterface } from "../molecules/CustomButton";
import CustomImage, { CustomImageInterface } from "../molecules/CustomImage";

import {
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  h6Text,
  pTextSm,
  marginBXS,
} from "@/constants/standardCSSClasses";
import BelowButtonsText from "@/components/molecules/BelowButtonsText";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  includeBelowButtons?: boolean;
  includeButtons?: boolean;
  includeContent?: boolean;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  buttonsNumber?: number;
  buttonOne?: CustomButtonInterface;
  buttonTwo?: CustomButtonInterface;
  belowButtons?: string;
  image?: CustomImageInterface;
}

export default function ImageCardAddonBlock({
  includeBelowButtons = false,
  includeButtons = false,
  includeContent = false,
  heading = "",
  headingOptions = {
    tag: 3,
  },
  content = "",
  variant = "dark",
  buttonsNumber = 0,
  buttonOne,
  buttonTwo,
  belowButtons = "",
  image = {
    full: "",
    alt: "",
  },
}: Props) {
  const headerClassNames = cn(marginBXS, h6Text, {
    [textHeadlineInverse]: variant === "dark",
    [textHeadlineNeutral]: variant !== "dark",
  });

  const pTextClassNames = cn("mb-6", pTextSm, {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const belowTextClassNames = cn("mt-3 md:mt-2.5", {
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  const buttons = [];

  if (buttonsNumber > 0) {
    buttons.push({
      ...buttonOne,
      variant: variant === "dark" ? "white" : "dark",
      size: "sm",
    });
  }

  if (buttonsNumber > 1) {
    buttons.push({
      ...buttonTwo,
      variant: variant === "dark" ? "dark" : "light",
      size: "sm",
    });
  }

  return (
    <div className="grid items-center rounded border border-solid border-grey-1 p-4 md:grid-cols-2 md:p-2">
      <CustomImage
        className="mb-4 md:mb-0"
        aspect="1:1"
        alt={image.alt}
        full={image.full}
      />
      <div className="md:pl-6 md:pr-10">
        <CustomHeading
          heading={heading}
          headingClassNames={headerClassNames}
          headingOptions={headingOptions}
        />
        {includeContent && (
          <RichText className={pTextClassNames} tag="p">
            {content}
          </RichText>
        )}
        {includeButtons && (
          <CustomButtonGroup
            align="left"
            buttons={buttons}
            marginTop=""
            gap="gap-2"
            className="max-w-full flex-wrap"
          />
        )}
        {includeBelowButtons && (
          <BelowButtonsText className={belowTextClassNames} variant={variant}>
            {belowButtons}
          </BelowButtonsText>
        )}
      </div>
    </div>
  );
}

// Must match __typename
const BLOCK_NAME = "TenupImageCardAddon";

ImageCardAddonBlock.displayName = BLOCK_NAME;

ImageCardAddonBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          belowButtons
          buttonOne
          buttonTwo
          buttonsNumber
          className
          content
          ga4SectionId
          heading
          image
          includeBelowButtons
          includeButtons
          includeContent
          metadata
        }
      }
    }
  `,
};
