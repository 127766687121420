import { RichText } from '@/components/atoms';

interface Props {
  caption: string;
}

export default function Caption({ caption }: Props) {
  return (
    <div className="pt-4 text-center text-xs">
      <RichText tag="span">{caption}</RichText>
    </div>
  );
}
