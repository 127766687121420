import cn from "classnames";

import { RichText } from "@/components/atoms";
import BlockContent from "../molecules/BlockContent";
import AboveHeading from "../molecules/AboveHeading";
import CustomButton, { CustomButtonInterface } from "../molecules/CustomButton";

import {
  marginBMd,
  marginBSm,
  marginBLg,
  textHeadlineNeutral,
  textHeadlineInverse,
  textBodyInverse,
  textBodyNeutral,
  h3Text,
  borderBoth,
  backgroundInverse,
  backgroundNeutral,
  h4Text,
} from "@/constants/standardCSSClasses";
import Card, { CardInterface } from "@/components/molecules/Card";
import { PreviewLoader } from "@/components/molecules";
import { useTouchSlide, useTouchSlider } from "@/hooks/useTouchSlider";
import React from "react";
import CustomHeading, {
  HeadingOptions,
} from "@/components/molecules/CustomHeading";
import CustomMedia from "@/components/molecules/CustomMedia";
import CustomTag from "@/components/molecules/CustomTag";
import { clock } from "@/constants/icons";
import { AttributesExtension } from "@/utilities/getBlockContentProps";
import CustomButtonGroup from "@/components/molecules/CustomButtonGroup";

interface Props extends AttributesExtension {
  includeAboveHeading?: boolean;
  includeButtons?: boolean;
  includeHeading?: boolean;
  includeContent?: boolean;
  featureSingle?: boolean;
  featureSize?: "sm" | "lg";
  aboveHeading?: string;
  border?: boolean;
  heading?: string;
  headingOptions?: HeadingOptions;
  content?: string;
  variant?: string;
  button?: CustomButtonInterface;
  buttonBottom?: boolean;
  contentType: string;
  loading?: boolean;
  cards?: Array<CardInterface>;
  columns?: number;
  backgroundStyle?: "full" | "boxed";
  paddingTop?: string;
  paddingBottom?: string;
}

export default function ContentCards({
  includeAboveHeading = true,
  includeButtons = true,
  includeHeading = true,
  includeContent = true,
  featureSingle = false,
  featureSize = "lg",
  border = false,
  aboveHeading,
  heading,
  headingOptions = {
    tag: 2,
  },
  content,
  variant = "dark",
  button,
  buttonBottom = false,
  contentType,
  loading = false,
  cards,
  columns = 3,
  backgroundStyle = "full",
  blockContentProps = {},
  paddingTop = "large",
  paddingBottom = "large",
}: Props) {
  const cardsNumber = !loading && cards ? cards.length : 0;

  const headerClassNames = cn(h3Text, {
    [textHeadlineInverse]: backgroundStyle !== "boxed" && variant === "dark",
    [textHeadlineNeutral]: backgroundStyle !== "boxed" || variant !== "dark",
    [marginBMd]: includeContent,
  });

  const pTextClassNames = cn({
    [textBodyInverse]: variant === "dark",
    [textBodyNeutral]: variant !== "dark",
  });

  //Create the slider
  const {
    sliderControls,
    wrapClassNames,
    sliderClassNames,
    handleTouchStart,
    handleTouchEnd,
    handleTouchMove,
    sliderRef,
  } = useTouchSlider(cardsNumber, variant, columns, false, undefined);

  const combinedSliderWrapClassNames = cn(wrapClassNames, "md:me-auto", {
    "me-auto": cardsNumber <= columns || includeButtons,
  });

  const { slideWrapClass } = useTouchSlide(columns);

  const slideWrapClasses = cn({
    "me-auto w-full md:me-auto md:w-full":
      cardsNumber <= columns || includeButtons,
    [slideWrapClass]: cardsNumber > columns && !includeButtons,
  });

  const combinedSliderClassNames = cn({
    [sliderClassNames]: cardsNumber > columns && !includeButtons,
    "grid gap-8 grid-cols-1 md:grid md:gap-8 md:w-auto":
      cardsNumber <= columns || includeButtons,
    "md:grid-cols-2":
      columns === 2 && (cardsNumber <= columns || includeButtons),
    "md:grid-cols-2 lg:grid-cols-3":
      columns === 3 && (cardsNumber <= columns || includeButtons),
  });

  return (
    <BlockContent
      className={cn({
        "border border-l-0 border-r-0 border-t-0 border-solid": border,
        [borderBoth]: border,
      })}
      width={border ? "none" : ""}
      contentType={contentType}
      variant={backgroundStyle === "full" ? variant : "white"}
      defaultValue="full"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      {...blockContentProps}
    >
      <div className={cn("flex items-center justify-between", marginBLg)}>
        <div className="md:max-w-xl">
          {includeAboveHeading && (
            <AboveHeading
              variant={backgroundStyle !== "boxed" ? variant : "white"}
            >
              {aboveHeading}
            </AboveHeading>
          )}
          {includeHeading && (
            <CustomHeading
              heading={heading}
              headingOptions={headingOptions}
              headingClassNames={headerClassNames}
            />
          )}
          {includeContent && (
            <RichText className={pTextClassNames} tag="p">
              {content}
            </RichText>
          )}
        </div>
        <div>
          {includeButtons && !buttonBottom && (
            <CustomButton
              className="header-button-here hidden md:block"
              variant={variant === "dark" ? "white" : "dark"}
              {...button}
            />
          )}
          {(!includeButtons || buttonBottom) && cardsNumber > columns && (
            <div>{sliderControls}</div>
          )}
        </div>
      </div>
      {(!featureSingle || !cards || cards?.length !== 1) && (
        <div className={combinedSliderWrapClassNames}>
          {cards && !loading ? (
            <div className={combinedSliderClassNames} ref={sliderRef}>
              {cards.map((each, index) => {
                if (index >= cards.length) return null;

                each.imageAspect = "contentCard";
                each.border = false;
                each.variant = variant;
                each.visual = "image";

                if (each.headingOptions === undefined) {
                  each.headingOptions = { tag: headingOptions.tag + 1 };
                } else {
                  each.headingOptions.tag = headingOptions.tag + 1;
                }

                return (
                  <Card
                    key={index}
                    className={slideWrapClasses}
                    handleTouchStart={
                      cardsNumber > columns ? handleTouchStart : undefined
                    }
                    handleTouchEnd={
                      cardsNumber > columns ? handleTouchEnd : undefined
                    }
                    handleTouchMove={
                      cardsNumber > columns ? handleTouchMove : undefined
                    }
                    {...each}
                  />
                );
              })}
            </div>
          ) : (
            <PreviewLoader />
          )}
        </div>
      )}
      {featureSingle && cards?.length === 1 && cards[0] && (
        <div
          className={cn(
            "md:grid md:grid-cols-2 md:gap-8 lg:items-center lg:gap-20",
            {
              [backgroundInverse]:
                backgroundStyle === "boxed" && variant === "dark",
              [backgroundNeutral]:
                backgroundStyle === "boxed" && variant === "light",
              "overflow-hidden rounded p-10": backgroundStyle === "boxed",
            }
          )}
        >
          <div>
            {(cards[0].tag?.name || cards[0].eventDate) && (
              <div className="flex items-center justify-between">
                {cards[0].tag?.name && (
                  <CustomTag
                    variant={variant}
                    link={cards[0].tag.link}
                    name={cards[0].tag.name}
                  />
                )}
                {cards[0].eventDate && (
                  <div className="mb-4 flex items-center text-sm text-blue-8">
                    <span className="mr-1 inline-block [&>svg]:fill-blue-8">
                      {clock}
                    </span>
                    {cards[0].eventDate}
                  </div>
                )}
              </div>
            )}
            <CustomHeading
              heading={cards[0].heading}
              headingOptions={{ tag: headingOptions?.tag - 1 }}
              headingClassNames={cn(h4Text, marginBSm)}
            />
            {cards[0].content && (
              <RichText
                className={cn("text-xl text-blue-8", {
                  "md:text-2xl": featureSize === "lg",
                })}
                tag={cards[0].content.includes("<p>") ? "div" : "p"}
              >
                {cards[0].content}
              </RichText>
            )}
            <CustomButton
              className="mt-8 block"
              variant={variant === "dark" ? "white" : "dark"}
              textOnly={featureSize === "sm"}
              arrow={featureSize === "sm"}
              {...cards[0].button}
            />
          </div>
          <div>
            {(cards[0].image ||
              (cards[0].media && cards[0].media.type !== "none")) && (
              <CustomMedia
                className="mt-8 md:mt-0"
                media={cards[0].media ?? { media: {}, type: "image" }}
                aspect="contentCard"
                fallbackImage={cards[0].image}
              />
            )}
          </div>
        </div>
      )}
      {includeButtons && (
        <CustomButtonGroup
          align="center"
          buttons={[
            {
              className: cn("mt-12 block", {
                "md:hidden": !buttonBottom,
              }),
              variant: variant === "dark" ? "white" : "dark",
              ...button,
            },
          ]}
        />
      )}
    </BlockContent>
  );
}
