import { Table } from "@/components/molecules";
import { TableRow } from "@/types/Table";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  anchor?: string;
  body: TableRow[];
  caption?: string;
  className?: string;
  foot?: TableRow[];
  head?: TableRow[];
  renderedHtml?: string;
}

export default function TableBlock({
  anchor,
  body,
  caption,
  className,
  foot,
  head,
  renderedHtml,
}: Props) {
  if (!body?.[0]?.cells?.[0]?.content && renderedHtml) {
    // It doesn't seem like the GraphQL for tables is set up correctly by the Content Blocks plugin
    // So we have to do some hacky stuff to the HTML to make built-in tables work for now

    renderedHtml = renderedHtml.replaceAll(
      "<table>",
      '<table class="w-full border-collapse">'
    );
    renderedHtml = renderedHtml.replaceAll("<tr>", '<tr class="border">');
    renderedHtml = renderedHtml.replaceAll(
      "<td>",
      '<td class="border-l border-solid p-2">'
    );
    renderedHtml = renderedHtml.replaceAll(
      '<figcaption class="',
      '<figcaption class="mt-4 mb-8 text-center text-xs '
    );

    return <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />;
  }

  return (
    <Table
      id={anchor}
      className={className}
      head={head}
      body={body}
      foot={foot}
      caption={caption}
    />
  );
}

// Must match __typename
const BLOCK_NAME = "CoreTable";

TableBlock.displayName = BLOCK_NAME;

TableBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      renderedHtml
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          anchor
          body {
            cells {
              align
              content
            }
          }
          caption
          className
          foot {
            cells {
              align
              content
            }
          }
          head {
            cells {
              align
              content
            }
          }
        }
      }
    }
  `,
};
