import React from "react";
import Image from "next/image";

import { Block as BlockType, Block, HeadingLevel } from "@/types";
import { Container, Heading } from "@/components/atoms";

interface Props {
  content: BlockType[];
  image: string;
  level?: HeadingLevel;
  className?: string;
}

export default function Hero({
  content,
  image,
  level = "h2",
  className,
}: Props) {
  let heading;
  let subheading;

  content.forEach(({ name, attributes }: Block) => {
    if (name === "core/heading") {
      heading = attributes.content;
    }
    if (name === "core/paragraph") {
      subheading = attributes.content;
    }
  });

  return (
    <div
      className={`${
        className ?? "relative overflow-hidden py-16 md:py-36 lg:py-52"
      }`}
    >
      <Image
        src={image}
        fill
        alt="Dashlane background image for blog home page hero"
        className="-z-10 object-cover"
        priority
        quality={100}
      />
      <div className="relative top-1/4 md:top-1/3">
        <Container>
          <div className="w-full lg:w-1/2">
            <Heading
              className="mb-4 text-3xl text-white md:text-4xl lg:text-5xl"
              level={level}
            >
              {heading}
            </Heading>
            <p className="text-xl text-white">{subheading}</p>
          </div>
        </Container>
      </div>
    </div>
  );
}
