import { postRequestAPI } from "./api-connect/postRequestAPI";
import { Authentication, RequestFunctionOptions } from "./api-connect/types";

export interface BaseApiProps {
  apiName: string;
  apiMethod: string;
  payload: Record<string, unknown>;
  authentication?: Authentication;
}

interface PostDataResponse extends Response {
  data?: Record<string, unknown>;
  errors?: Record<string, unknown>;
}

export enum AppKeys {
  "BusinessBreachReport" = "BusinessBreachReport",
  "Regular" = "Regular",
}

const Keys = {
  BusinessBreachReport: {
    accessKey: "RH9V3D5XVHN8JNYC2VTZWQ9AY1QJNTJY",
    secretKey:
      "9lxoY73mexmPHe/V++3KEqskAe+SQFodf7prGMj0jrDq9hBAuNvJItZlMMaTx+up",
  },
  Regular: {
    accessKey: "N5B5Y91M9AC0AW0QGBTRNGNC3AG503GJ",
    secretKey:
      "gQKutM6FxAuD+XqCuKFmFbXT4RAgoDItVwXhGfZdHq9rM2Yyh94l9dZjcGjnYV+P",
  },
};

export class BaseAPI {
  protected APIVERSION: string;

  protected APPACCESSKEY: string;

  protected APPSECRETKEY: string;

  constructor(key = AppKeys.Regular) {
    this.APIVERSION = "v1";
    this.APPACCESSKEY = Keys[key].accessKey;
    this.APPSECRETKEY = Keys[key].secretKey;
  }

  fetchImplementation = async (options: RequestFunctionOptions) => {
    const { headers, json, url, method } = options;

    const response = await fetch(url, {
      method,
      headers,
      body: JSON.stringify(json),
    });
    const results: PostDataResponse = await response.json();

    if (results?.errors) {
      throw results.errors[0];
    }
    return results;
  };

  postData = async <Type>({
    apiName,
    apiMethod,
    payload,
    authentication = {
      type: "app",
      appAccessKey: this.APPACCESSKEY,
      appSecretKey: this.APPSECRETKEY,
    },
  }: BaseApiProps): Promise<Type> => {
    const results = await postRequestAPI({
      requestFunction: this.fetchImplementation,
      authentication,
      path: `${this.APIVERSION}/${apiName}/${apiMethod}`,
      payload,
      // Passing in custom UA due to a bug in Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=571722
      userAgent: navigator.userAgent,
    });

    return results?.data as Type;
  };
}
