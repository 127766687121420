export const PARTNERS_REGISTRATION_SUCCESS_HEADER = {
  "en": "Thank you for submitting your deal!",
  "fr": "Merci d'avoir soumis votre offre !",
  "de": "Vielen Dank für die Übermittlung Ihres Angebots!",
  "es": "¡Gracias por enviar su oferta!",
  "pt-br": "Thank you for submitting your deal!",
  "it": "Thank you for submitting your deal!",
  "ja": "Thank you for submitting your deal!",
  "ko": "Thank you for submitting your deal!",
  "nl": "Thank you for submitting your deal!",
  "pt-pt": "Thank you for submitting your deal!",
  "sv": "Thank you for submitting your deal!",
  "zh": "Thank you for submitting your deal!"
}