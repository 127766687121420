import React, { ReactNode } from 'react';

import type { HeadingLevel } from '@/types';

interface Props {
  level?: HeadingLevel;
  children: ReactNode;
  className?: string;
}

export default function Heading({ level = 'h1', children, className }: Props) {
  const Tag = ({ ...props }) => React.createElement(level, props, children);

  return <Tag className={className}>{children}</Tag>;
}
