import React, { useContext } from "react";
import cn from "classnames";
import Image from "next/image";
import Link from "next/link";

import { Caption } from "@/components/atoms";
import getBlockStyles from "@/utilities/getBlockStyles";
import TypeNameContext from "@/contexts/typeNameContext";
import { gql } from "@apollo/client";
import getEditorBlock from "@/fragments/fragmentFunctions/GetEditorBlock";

interface Props {
  id?: string;
  align?: string;
  alt: string;
  anchor?: string;
  caption?: string;
  className?: string;
  height: number;
  href?: string;
  linkClass?: string;
  linkTarget?: string;
  rel?: string;
  style?: any;
  title?: string;
  url: string;
  maybeWidth: number;
}

export default function ImageBlock({
  id,
  align,
  alt,
  anchor,
  caption,
  className,
  height,
  href,
  linkClass,
  linkTarget,
  rel,
  style,
  title,
  url,
  maybeWidth,
}: Props) {
  const typeNameContext = useContext(TypeNameContext);

  if (
    !maybeWidth ||
    (typeof maybeWidth === "string" && !containsDigit(maybeWidth))
  ) {
    maybeWidth = 1024;
  } else if (typeof maybeWidth === "string" && containsDigit(maybeWidth)) {
    maybeWidth = parseStringToNumber(maybeWidth as string);
  }

  if (!height || (typeof height === "string" && !containsDigit(height))) {
    height = 576;
  } else if (typeof height === "string" && containsDigit(height)) {
    height = parseStringToNumber(height as string);
  }

  const imageStyle = getBlockStyles({
    style,
  });

  const image = url ? (
    <>
      <Image
        alt={alt}
        className={cn(
          align === "right" && "ml-auto",
          align === "center" && "mx-auto",
          (align === "full" || align === "wide") && "w-full",
          className === "is-style-rounded" && "rounded-full",
          className
        )}
        id={id}
        height={height}
        src={url}
        style={imageStyle}
        width={maybeWidth}
      />
      {caption && <Caption caption={caption} />}
    </>
  ) : null;

  return (
    <figure
      id={anchor}
      className={cn(typeNameContext !== "Page" ? "my-6" : "")}
    >
      {href ? (
        <Link
          href={href}
          rel={rel}
          className={linkClass}
          target={linkTarget}
          title={title}
          locale={false}
        >
          {image}
        </Link>
      ) : (
        image
      )}
    </figure>
  );
}

const parseStringToNumber = (string: string) => {
  return parseInt(string.replace(/\D/g, ""), 10);
};

const containsDigit = (string: string) => {
  return /\d/.test(string);
};

// Must match __typename
const BLOCK_NAME = "CoreImage";

ImageBlock.displayName = BLOCK_NAME;

ImageBlock.fragments = {
  key: `${BLOCK_NAME}BlockFragment`,
  entry: gql`
    fragment ${BLOCK_NAME}BlockFragment on ${BLOCK_NAME} {
      ${getEditorBlock()}
      attributes {
        ... on ${BLOCK_NAME}Attributes {
          align
          alt
          anchor
          aspectRatio
          borderColor
          caption
          className
          cssClassName
          maybeWidth: width
          url
          title
          style
          src
          sizeSlug
          scale
          rel
          metadata
          linkTarget
          linkDestination
          linkClass
          lightbox
          id
          href
          height
        }
      }
    }
  `,
};
