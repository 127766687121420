import React from "react";
import Link from "next/link";
import type { RecommendedPost } from "@/types";
import { Heading } from "@/components/atoms";
import { ReadMore } from "@/components/molecules";
import { RECOMMENDED_POSTS_CTA } from "@/i18n/translations/RECOMMENDED_POSTS_CTA";
import { useTranslate } from "@/hooks/useTranslate";
import CustomImage from "@/components/molecules/CustomImage";

interface Props {
  posts: RecommendedPost[];
  uri: string;
}

export default function RecommendedPosts({ posts, uri }: Props) {
  const { translate } = useTranslate();

  // Only show when there is more than one post, posts array includes current post
  return posts.length > 1 ? (
    <aside className="my-12">
      <Heading level="h2" className="mb-10 text-center text-3xl uppercase">
        {translate(RECOMMENDED_POSTS_CTA)}
      </Heading>
      <div className="grid gap-x-4 gap-y-12 md:grid-cols-3">
        {/* Filter out the page URI that we are on and then map the rest, with a maximum of 3 */}
        {posts
          .filter((post) => post.uri !== uri)
          .map((post, i) => {
            return i < 3 ? (
              <div key={post.uri} className="w-full">
                <Link
                  href={post.uri}
                  className="text-dashgreen-text transition hover:text-midgreen hover:no-underline"
                >
                  <CustomImage
                    aspect="contentCard"
                    full={
                      post.featuredImage?.node.sourceUrl ??
                      "/images/blog-post-default.webp"
                    }
                    alt={
                      post.featuredImage?.node.altText ??
                      "A green and blue background with three blue slanted rectangles in the middle. A white Dashlane logo is overlaid on top."
                    }
                    className="mb-4"
                  />
                  <Heading
                    level="h3"
                    className="line-clamp-2 text-ellipsis text-xl"
                  >
                    {post.title}
                  </Heading>
                  <div
                    className="mt-2 line-clamp-3"
                    dangerouslySetInnerHTML={{ __html: post.excerpt }}
                  />
                </Link>
                <ReadMore href={post.uri} />
              </div>
            ) : null;
          })}
      </div>
    </aside>
  ) : (
    // Create more space above footer when no posts
    <div className="h-8" />
  );
}
