export const ERROR_404_SUBHEADING = {
  "en": "Hate when that happens... Go back to our homepage instead.",
  "fr": "Je déteste quand cela se produit... Retournez plutôt sur notre page d'accueil.",
  "de": "Blöd, wenn das passiert... Gehen Sie stattdessen zurück zu unserer Startseite.",
  "es": "Odio cuando eso sucede... Volver a nuestra página de inicio.",
  "pt-br": "Hate when that happens... Go back to our homepage instead.",
  "it": "Hate when that happens... Go back to our homepage instead.",
  "ja": "Hate when that happens... Go back to our homepage instead.",
  "ko": "Hate when that happens... Go back to our homepage instead.",
  "nl": "Hate when that happens... Go back to our homepage instead.",
  "pt-pt": "Hate when that happens... Go back to our homepage instead.",
  "sv": "Hate when that happens... Go back to our homepage instead.",
  "zh": "Hate when that happens... Go back to our homepage instead."
}