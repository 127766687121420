export const BUSINESS_TRY_EMAIL_INPUT_ERROR_ALREADY_IN_TEAM = {
  en: "You are already part of a Dashlane Business account.",
  fr: "Vous êtes déjà membre d'un compte Dashlane Business.",
  de: "Sie sind bereits Teil eines Dashlane-Business-Kontos.",
  es: "Ya es parte de una cuenta de Dashlane Business.",
  it: "Hai già un account Dashlane Business.",
  ja: "あなたはすでに Dashlane Business アカウントのメンバーです。",
  ko: "귀하는 Dashlane Business 계정을 이미 가지고 계십니다.",
  nl: "U maakt al deel uit van een Dashlane Business-account.",
  "pt-br": "Você já faz parte da conta Dashlane Business.",
  "pt-pt": "Já faz parte de uma conta Dashlane Business.",
  sv: "Du är redan del av ett Dashlane Business-konto.",
  zh: "您已是 Dashlane 商务版帐户的成员。",
};

export const BUSINESS_TRY_EMAIL_INPUT_ERROR_ALREADY_IN_TRIAL = {
  en: "You are already on a Dashlane Business trial.",
  fr: "Vous essayez déjà gratuitement Dashlane Business.",
  de: "Sie nutzen bereits eine Probeversion von Dashlane Business.",
  es: "Ya está utilizando una prueba de Dashlane Business.",
  it: "Hai già attivato un periodo di prova su Dashlane Business.",
  ja: "あなたはすでに Dashlane Business のトライアルをご利用中です。",
  ko: "귀하는 Dashlane Business 체험판을 이미 사용 중입니다.",
  nl: "U bent al begonnen aan een proefperiode van Dashlane Business.",
  "pt-br": "Você já está em um período de avaliação do Dashlane Business.",
  "pt-pt": "Já está num período de avaliação do Dashlane Business.",
  sv: "Du har redan Dashlane Business på prov.",
  zh: "您已加入 Dashlane 商务版试用行列。",
};

export const BUSINESS_TRY_EMAIL_INPUT_ERROR_INVALID_COMPANYNAME = {
  en: "Please enter a valid company name. Make sure it does not include any special characters.",
  fr: "Veuillez saisir un nom d'entreprise valide. Les caractères spéciaux ne sont pas autorisés.",
  de: "Bitte geben Sie einen gültigen Unternehmensnamen ein. Dieser darf keine Sonderzeichen enthalten.",
  es: "Ingrese un nombre válido de compañía. Asegúrese de que no incluya ningún carácter especial.",
  it: "Inserisci una ragione sociale valida. Assicurati che non includa caratteri speciali.",
  ja: "有効な会社名を入力してください。特殊文字が含まれていないことを確認してください。",
  ko: "유효한 회사 이름을 입력하세요. 어떠한 특수 문자도 포함해서는 안 됩니다.",
  nl: "Voer een geldige bedrijfsnaam in. Zorg ervoor dat die geen speciale tekens bevat.",
  "pt-br":
    "Informe um nome de empresa válido. Certifique-se de que o nome não inclui nenhum caractere especial.",
  "pt-pt":
    "Introduza um nome de empresa válido. Certifique-se de que o nome não inclui carateres especiais.",
  sv: "Ange ett giltigt företagsnamn. Se till att det inte inkluderar specialtecken.",
  zh: "请输入有效的公司名称。确保该名称不包含任何特殊字符。",
};

export const BUSINESS_TRY_EMAIL_INPUT_ERROR_DOMAIN_IN_USE = {
  en: "This email domain is already registered with Dashlane. Please enter another one.",
  fr: "Ce nom de domaine est déjà enregistré dans Dashlane. Veuillez en saisir un autre.",
  de: "Diese E-Mail-Domain ist bereits bei Dashlane registriert. Bitte geben Sie eine andere ein.",
  es: "El dominio de este correo electrónico ya está registrado en Dashlane. Escriba otro.",
  it: "Questo dominio e-mail è già registrato su Dashlane. Inseriscine un altro.",
  ja: "このメールドメインはすでに Dashlane に登録されています。別のメールドメインを入力してください。",
  ko: "이 이메일 도메인은 이미 Dashlane에 등록되어 있습니다. 다른 이메일을 입력하세요.",
  nl: "Dit e-maildomein is al geregistreerd bij Dashlane. Voer een ander in.",
  "pt-br":
    "Este domínio de e-mail já está registrado na Dashlane. Favor inserir outro.",
  "pt-pt":
    "Este domínio de e-mail já está registado na Dashlane. Introduza outro.",
  sv: "Den här e-postdomänen är redan registrerad på Dashlane. Ange en annan.",
  zh: "此电子邮件域已在 Dashlane 中注册。请输入其他电子邮件域。",
};

export const NOTIF_GENERIC_MESSAGE = {
  en: "That's strange. Something didn't go as planned. Please try again in a few moments.",
  fr: "Désolé, une erreur est survenue. Veuillez réessayer dans quelques instants.",
  de: "Seltsam. Da ist wohl etwas schiefgegangen. Bitte versuchen Sie es in einigen Augenblicken erneut.",
  es: "Eso es extraño. Se produjo un error al procesar la solicitud. Inténtelo nuevamente en unos minutos.",
  it: "Che strano! Qualcosa è andato storto. Riprova tra qualche minuto.",
  ja: "変ですね。何かが予定通りに行かなかったようです。少し経ってから再度お試しください。",
  ko: "문제가 생겼습니다. 계획대로 진행되지 않았습니다. 몇 분 후에 다시 시도하세요.",
  nl: "Dat is vreemd. Er is iets niet volgens plan gegaan. Probeer het over een paar tellen nog eens.",
  "pt-br":
    "Que estranho. Algo não saiu como planejado. Tente novamente daqui a pouco.",
  "pt-pt":
    "Que estranho. Algo não correu como previsto. Tente novamente dentro de alguns momentos.",
  sv: "Det är konstigt. Något gick fel. Försök igen litet senare.",
  zh: "奇怪，出现意外问题。请稍后重试。",
};

export const BUSINESS_TRY_FAILED_ERROR_TOKEN_EXPIRED_CTA_LABEL = {
  en: "Try again",
  fr: "Réessayer",
  de: "Erneut versuchen",
  es: "Inténtelo de nuevo",
  it: "Riprova",
  ja: "再度お試しください",
  ko: "다시 시도하세요",
  nl: "Probeer het opnieuw",
  "pt-br": "Tentar novamente",
  "pt-pt": "Tentar novamente",
  sv: "Försök igen",
  zh: "请重试",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_1 = {
  en: "We have just sent a verification email to <strong>%(formEmail)</strong>.",
  fr: "Nous venons d'envoyer un e-mail de vérification à <strong>%(formEmail)</strong>.",
  de: "Wir haben soeben eine Verifizierungs-E-Mail an <strong>%(formEmail)</strong> versendet.",
  es: "Hemos enviado un correo electrónico de verificación a <strong>%(formEmail)</strong>.",
  it: "Abbiamo appena inviato un'e-mail di verifica all'indirizzo <strong>%(formEmail)</strong>.",
  ja: "確認メールを <strong>%(formEmail)</strong> 宛に送信しました。",
  ko: "<strong>%(formEmail)</strong>(으)로 확인 이메일을 보내드렸습니다.",
  nl: "We hebben zojuist een verificatiemail verstuurd naar <strong>%(formEmail)</strong>.",
  "pt-br":
    "Acabamos de enviar um e-mail de verificação para <strong>%(formEmail)</strong>.",
  "pt-pt":
    "Acabámos de enviar um e-mail de verificação para <strong>%(formEmail)</strong>.",
  sv: "Vi har precis skickat ett verifieringsmeddelande till <strong>%(formEmail)</strong>.",
  zh: "我们刚刚向 <strong>%(formEmail)</strong> 发送了一封验证电子邮件。",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_2 = {
  en: "Follow the link in it to start your free trial.",
  fr: "Suivez le lien qui s'y trouve pour débuter votre essai gratuit.",
  de: "Klicken Sie auf den Link in der E-Mail und beginnen Sie Ihr kostenloses Probeabo.",
  es: "Para iniciar su prueba gratis, haga clic en el enlace que figura en este.",
  it: "Fai clic sul link che trovi al suo interno per iniziare la prova gratuita.",
  ja: "リンクをクリックして無料トライアルを開始してください。",
  ko: "무료 평가판을 시작하려면 링크를 따라 이동하세요.",
  nl: "Volg de link in de e-mail om uw gratis proefperiode te starten.",
  "pt-br": "Clique no link recebido para dar início à sua avaliação gratuita.",
  "pt-pt":
    "Abra a hiperligação no e-mail para iniciar a sua avaliação gratuita.",
  sv: "Följ länken i meddelandet för att påbörja din gratis provperiod.",
  zh: "访问其中的链接开始免费试用。",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_CHECK_DESCRIPTION_3 = {
  en: "If you did not receive our email, please check your spam folder in your mailbox or resend a verification email.",
  fr: "Si ce message est introuvable, veuillez vérifier la section Courrier indésirable de votre boîte de réception ou demander à renvoyer un e-mail de vérification.",
  de: "Wenn Sie unsere E-Mail nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner oder senden Sie erneut eine Verifizierungs-E-Mail.",
  es: "Si no lo recibió, revise su carpeta de correo no deseado o solicite que se lo reenviemos.",
  it: "Se non hai ricevuto la nostra e-mail, controlla la cartella della posta indesiderata o richiedi l'invio di una nuova e-mail di verifica.",
  ja: "メールが届かない場合は、迷惑メールフォルダをご確認いただくか、確認メールの再送信をリクエストしてください。",
  ko: "이메일을 받지 못하셨다면 메일함의 스팸 폴더를 살펴보거나 확인 이메일을 재전송하세요.",
  nl: "Als u onze e-mail niet heeft ontvangen, controleer dan uw spam-map of laat de verificatiemail opnieuw versturen.",
  "pt-br":
    "Se você não recebeu nosso e-mail, acesse a pasta de spam em sua caixa de entrada ou reenvie o e-mail de verificação.",
  "pt-pt":
    "Se não recebeu o nosso e-mail, verifique a pasta de spam na sua caixa de correio ou reenvie um e-mail de verificação.",
  sv: "Om du inte har fått något meddelande från oss kan du titta efter i skräppostmappen eller skicka ett nytt verifieringsmeddelande.",
  zh: "如果您没有收到，请检查垃圾邮件文件夹，或选择重新发送验证电子邮件。",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_CHECK_TITLE = {
  en: "Please check your email",
  fr: "Veuillez vérifier votre boîte de réception",
  de: "Bitte prüfen Sie Ihren E-Mail-Posteingang.",
  es: "Revise su correo electrónico",
  it: "Controlla la tua e-mail",
  ja: "E メールを確認してください",
  ko: "이메일을 확인하세요.",
  nl: "Controleer uw e-mail",
  "pt-br": "Verifique seu e-mail",
  "pt-pt": "Verifique o seu e-mail",
  sv: "Kontrollera din e-post",
  zh: "请检查您的电子邮件",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_CTA_RESEND = {
  en: "Resend a verification email",
  fr: "Renvoyer un e-mail de vérification",
  de: "Verifizierungs-E-Mail erneut senden",
  es: "Reenviar un correo electrónico de verificación",
  it: "Invia nuovamente un'e-mail di verifica",
  ja: "確認メールを再送信",
  ko: "확인 이메일 다시 보내기",
  nl: "Verificatiemail opnieuw verzenden",
  "pt-br": "Reenviar o e-mail de verificação",
  "pt-pt": "Reenviar um e-mail de verificação",
  sv: "Skicka ett nytt verifieringsmeddelande",
  zh: "重新发送验证电子邮件",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_CTA_RESENDING = {
  en: "Resending email...",
  fr: "Renvoi de l'e-mail...",
  de: "E-Mail wird erneut gesendet...",
  es: "Reenviando correo electrónico...",
  it: "Stiamo rimandando l'e-mail...",
  ja: "メールを再送しています...",
  ko: "이메일 다시 전송 중...",
  nl: "E-mail opnieuw verzenden...",
  "pt-br": "Reenviando o e-mail...",
  "pt-pt": "A reenviar e-mail...",
  sv: "Skickar e-post igen...",
  zh: "正在重新发送电子邮件...",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_CTA_SENT = {
  en: "Email sent",
  fr: "E-mail envoyé!",
  de: "E-Mail gesendet",
  es: "Correo electrónico enviado",
  it: "E-mail inviata",
  ja: "メールを送信しました",
  ko: "이메일 전송 완료",
  nl: "E-mail verzonden",
  "pt-br": "E-mail enviado",
  "pt-pt": "E-mail enviado",
  sv: "E-postmeddelande skickat",
  zh: "电子邮件已发送",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_SENT_DESCRIPTION_3 = {
  en: "If you did not receive our email, please check your spam folder in your mailbox.",
  fr: "Si ce message est introuvable, veuillez vérifier la section Courrier indésirable de votre boîte de réception.",
  de: "Wenn Sie unsere E-Mail nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner.",
  es: "Si no recibió nuestro correo electrónico, revise la carpeta de correo no deseado de su bandeja de entrada.",
  it: "Se non hai ricevuto la nostra e-mail, controlla la cartella della posta indesiderata.",
  ja: "メールが届かない場合は、迷惑メールフォルダをご確認ください。",
  ko: "이메일을 받지 못하셨다면 메일함의 스팸 폴더를 살펴보세요.",
  nl: "Als u onze e-mail niet heeft ontvangen, controleer dan de spam-map in uw mailbox.",
  "pt-br": "Se você não recebeu nosso e-mail, confira sua pasta de spam.",
  "pt-pt":
    "Se não recebeu o nosso e-mail, verifique a pasta de spam na sua caixa de correio.",
  sv: "Om du inte har fått något meddelande från oss kan du titta efter i skräppostmappen.",
  zh: "如果您没有收到我们的电子邮件，请检查您邮箱中的垃圾邮件文件夹。",
};

export const BUSINESS_ENTERPRISE_SUCCESS_CTA_LABEL = {
  en: "Start a trial",
  fr: "Commencer un essai",
  de: "Probeabo beginnen",
  es: "Iniciar una prueba",
  it: "Inizia una prova",
  ja: "トライアルを開始",
  ko: "평가판 시작",
  nl: "Begin een proefperiode",
  "pt-br": "Iniciar um período de avaliação",
  "pt-pt": "Iniciar uma avaliação",
  sv: "Starta provperiod",
  zh: "开始试用",
};

export const BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION = {
  en: "One of our sales experts will be in touch soon.",
  fr: "Vous serez bientôt contacté par un membre de notre service commercial.",
  de: "Einer unserer Vertriebsexperten wird sich in Kürze mit Ihnen in Verbindung setzen.",
  es: "Uno de nuestros expertos en ventas se pondrá en contacto a la brevedad.",
  it: "Presto verrai contattato da uno dei nostri addetti alle vendite.",
  ja: "弊社の販売エキスパートよりご連絡差し上げます。",
  ko: "당사 판매 전문가 중 한 명이 곧 연락드릴 것입니다.",
  nl: "Een van onze verkoopexperts neemt spoedig contact met u op.",
  "pt-br": "Um dos nossos especialistas em vendas entrará em contato em breve.",
  "pt-pt": "Um dos nossos especialistas de vendas entrará em contato em breve.",
  sv: "En av våra försäljningsexperter kommer att höra av sig snart.",
  zh: "我们的一位销售专家将很快与您联系。",
};

export const BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_1 = {
  en: "We have evaluated your company’s needs and have determined that a free trial of our product would be the best fit.",
  fr: "Nous avons évalué les besoins de votre entreprise et avons déterminé qu'un essai gratuit de notre produit serait la meilleure solution.",
  de: "Wir haben die Anforderungen Ihres Unternehmens geprüft und sind zu dem Schluss gekommen, dass eine kostenlose Testversion unseres Produkts die beste Lösung darstellt.",
  es: "Hemos evaluado las necesidades de su empresa y hemos llegado a la conclusión de que una prueba gratuita es lo que mejor le encajaría.",
  it: "Abbiamo valutato le esigenze della tua azienda e abbiamo stabilito che una prova gratuita del nostro prodotto sarebbe la migliore opzione.",
  ja: "この度、御社のニーズを評価したうえで、製品のフリートライアルが最適であると判断しました。",
  ko: "우리는 귀사의 요구 사항을 평가했으며 우리 제품의 무료 체험판이 가장 적합할 것이라고 결정했습니다.",
  nl: "We hebben de behoeften van uw bedrijf geëvalueerd en daarna vastgesteld dat een gratis proefperiode van ons product het beste bij u zou passen.",
  "pt-br":
    "Avaliamos as necessidades da sua empresa e determinamos que o mais adequado seria uma avaliação gratuita de nosso produto.",
  "pt-pt":
    "Avaliamos as necessidades da sua empresa e determinamos que uma avaliação gratuita do nosso produto é a opção mais adequada.",
  sv: "Vi har utvärderat ditt företags behov och har fastställt att en gratis provperiod av vår produkt skulle vara den bästa metoden.",
  zh: "我们已经评估了贵公司的需求，认为免费试用我们的产品是最合适的。",
};

export const BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_2 = {
  en: "To get started, simply click on the link below to sign up for the trial. During the trial period, you will have access to all of the features of our product, and you can try it out at no cost. You will also be given an opportunity to join a live training session of Dashlane.",
  fr: "Pour commencer, il suffit de cliquer sur le lien ci-dessous pour vous inscrire à l'essai. Pendant la période d'essai, vous aurez accès à toutes les fonctionnalités de notre produit et vous pourrez l'essayer sans frais. Vous aurez également l'occasion de participer à une session de formation en direct de Dashlane.",
  de: "Klicken Sie einfach auf den untenstehenden Link, um sich für die Testversion zu registrieren. Während der Testphase haben Sie Zugriff auf alle Funktionen unseres Produkts und können es kostenlos ausprobieren. Sie haben auch die Möglichkeit, an einer Live-Schulung von Dashlane teilzunehmen.",
  es: "Para empezar, simplemente haga clic en el siguiente enlace para registrarse para la prueba. Durante el periodo de prueba, tendrá acceso a todas las funciones de nuestro producto y puede probarlo sin coste alguno. También tendrá la oportunidad de disfrutar de una sesión de formación de Dashlane en directo.",
  it: "Per iniziare, fai clic sul link qui sotto per iscriverti alla prova. Durante il periodo di prova, avrai accesso a tutte le funzionalità del nostro prodotto e potrai provarle senza alcun costo. Ti verrà anche data l'opportunità di unirti a una sessione di formazione dal vivo di Dashlane.",
  ja: "はじめに以下のリンクをクリックし、トライアルに登録してください。トライアル期間中は製品のすべての機能にアクセスでき、かつ無料でお試しいただけます。また、Dashlane のライブトレーニングセッションに参加することができます。",
  ko: "시작하려면 아래 링크를 클릭하여 체험판을 등록하십시오. 체험판 기간 동안 제품의 모든 기능에 액세스할 수 있으며 무료로 사용해 볼 수 있습니다. Dashlane의 실시간 교육 세션에 참여할 기회도 주어집니다.",
  nl: "Om aan de slag te gaan, klikt u op de onderstaande link om in te loggen voor de proefperiode. Tijdens de proefperiode hebt u toegang tot alle functies van ons product. Dit kunt u kosteloos uitproberen. U krijgt ook de mogelijkheid om deel te nemen aan een live trainingssessie van Dashlane.",
  "pt-br":
    "Para começar, basta clicar no link abaixo para fazer a inscrição na avaliação. Durante o período de avaliação, você terá acesso a todos os recursos de nosso produto e poderá experimentá-los sem nenhum custo. Você também terá a oportunidade de participar de uma sessão de treinamento ao vivo da Dashlane.",
  "pt-pt":
    "Para começar, basta clicar na hiperligação abaixo para se inscrever na avaliação. Durante o período de avaliação, terá acesso a todas as funcionalidades do nosso produto e pode experimentá-lo sem qualquer custo. Também terá a oportunidade de participar numa sessão de formação ao vivo da Dashlane.",
  sv: "För att komma igång klickar du bara på länken nedan för att registrera dig för provperioden. Under provperioden har du åtkomst till alla funktioner i vår produkt och du kan prova utan kostnad. Du kommer också att få en möjlighet att delta i en live utbildningssession för Dashlane.",
  zh: "首先，单击下方链接，注册试用。试用期间，您可以访问我们产品的所有功能，并且可以免费试用。您还将有机会参加 Dashlane 的现场培训课程。",
};

export const BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_NO_CHILI_3 = {
  en: "If you have any questions or need assistance during the trial, please don’t hesitate to reach out to our customer support team. We hope you enjoy your trial and we look forward to the opportunity to work with you in the future.",
  fr: "Si vous avez des questions ou besoin d'aide pendant l'essai, n'hésitez pas à contacter notre équipe d'assistance à la clientèle. Nous espérons que vous apprécierez votre essai et nous nous réjouissons de pouvoir travailler avec vous à l'avenir.",
  de: "Wenn Sie Fragen haben oder während der Testphase Hilfe benötigen, wenden Sie sich bitte an unseren Kundendienst. Wir wünschen Ihnen viel Spaß beim Ausprobieren und freuen uns auf die künftige Zusammenarbeit mit Ihnen.",
  es: "Si tiene alguna pregunta o necesita ayuda durante la prueba, no dude en ponerse en contacto con nuestro equipo de asistencia técnica al cliente. Esperamos que disfrute de su prueba y esperamos poder trabajar con usted en el futuro.",
  it: "Se hai domande o hai bisogno di assistenza durante la prova non esitare a contattare il nostro team di assistenza clienti. Speriamo che possa goderti la prova e non vediamo l'ora di avere l'opportunità di lavorare con te in futuro.",
  ja: "トライアル中にご質問やサポートが必要な場合は、お気軽にカスタマーサポートチームまでご連絡ください。トライアルをぜひご活用ください。ご一緒にお仕事をできる日をお待ちしております。",
  ko: "체험판 사용 중에 질문이 있거나 도움이 필요한 경우 주저하지 말고 고객 지원 팀에 문의하십시오. 체험판을 즐기시기 바라며 향후 귀하와 함께 일할 수 있는 기회가 있기를 기대합니다.",
  nl: "Als u vragen heeft of hulp nodig heeft tijdens de proefperiode, aarzel dan niet om contact op te nemen met onze klantenondersteuning. We hopen dat u een prettige ervaring heeft tijdens de proefperiode en we verheugen om in de toekomst met u samen te kunnen werken.",
  "pt-br":
    "Se tiver alguma dúvida ou precisar de assistência durante a avaliação, não hesite em entrar em contato com nossa equipe de suporte ao cliente. Esperamos que aproveite sua avaliação e aguardamos com expectativa a oportunidade de trabalhar com você no futuro.",
  "pt-pt":
    "Em caso de dúvidas ou se precisar de assistência durante a avaliação, não hesite em contactar a nossa equipa de apoio ao cliente. Esperamos que tire o máximo proveito da sua avaliação e aguardamos a oportunidade de colaborar consigo no futuro.",
  sv: "Tveka inte att höra av dig till vårt kundsupportteam om du har några frågor eller behöver hjälp under provperioden. Vi hoppas att du gillar din provperiod och vi ser fram emot möjligheten att arbeta med dig i framtiden.",
  zh: "如在试用期间遇到问题或需要帮助，请尽管联系我们的客户支持团队。我们希望您对试用满意，并且期待将来有机会与您合作。",
};

export const BUSINESS_ENTERPRISE_SUCCESS_DESCRIPTION_V2 = {
  en: "One of our sales experts will be in touch soon or you can select a time you're available to chat.",
  fr: "Un membre de notre équipe commerciale prendra bientôt contact avec vous, sinon, vous pouvez choisir un moment où vous êtes disponible pour chatter.",
  de: "Einer unserer Vertriebsexperten wird sich bald mit Ihnen in Verbindung setzen. Alternativ können Sie eine Zeit wählen, zu der Sie chatten können.",
  es: "Uno de nuestros expertos de ventas se pondrá en contacto pronto o puede seleccionar la hora en la que esté disponible para chatear.",
  it: "Presto verrai contattato da uno dei nostri addetti alle vendite oppure puoi indicare un orario nel quale sei disponibile via chat.",
  ja: "販売の専門家の一人がすぐにご連絡するか、チャットできる時間を選択することができます。",
  ko: "영업 전문가가 곧 연락을 드릴 것입니다. 이를 원하지 않으시면 채팅이 가능한 시간을 선택할 수 있습니다.",
  nl: "Een van onze verkoopexperts neemt binnenkort contact met u op of u kunt een tijdstip boeken om te chatten.",
  "pt-br":
    "Um dos nossos especialistas em vendas entrará em contato em breve, ou você pode escolher momento que for apropriado e utilizar nosso chat.",
  "pt-pt":
    "Um dos nossos especialistas de vendas entrará em contacto em breve ou poderá selecionar uma hora em que está disponível para conversar.",
  sv: "En av våra försäljningsexperter kommer att kontakta dig snart eller så kan du välja en tid när du är tillgänglig för att chatta.",
  zh: "我们的一位销售专家将很快与您联络，您也可以选择方便通话的时间。",
};

export const BUSINESS_ENTERPRISE_SUCCESS_SECONDARY_DESCRIPTION = {
  en: "In the meantime, get started on your free trial of Dashlane now.",
  fr: "En attendant, vous pouvez essayer gratuitement Dashlane dès maintenant.",
  de: "Derweil können Sie Dashlane schon einmal kostenlos testen.",
  es: "Mientras tanto, comience a usar su versión de prueba gratis de Dashlane ahora.",
  it: "Nel frattempo, inizia la prova gratuita di Dashlane.",
  ja: "お待ちいただく間、Dashlane の無料トライアルをお試しください。",
  ko: "기다리시는 동안 Dashlane 무료 평가판을 시작해 보세요.",
  nl: "Ga in de tussentijd aan de slag met uw gratis proefabonnement op Dashlane.",
  "pt-br": "Enquanto isso, inicie sua avaliação gratuita da Dashlane.",
  "pt-pt": "Entretanto, inicie já a sua avaliação gratuita Dashlane.",
  sv: "Under tiden kan du börja använda ditt kostnadsfria prov på Dashlane nu.",
  zh: "同时，立即开始免费试用 Dashlane。",
};

export const BUSINESS_ENTERPRISE_SUCCESS_TITLE = {
  en: "Thanks for contacting us!",
  fr: "Merci de nous avoir contactés !",
  de: "Vielen Dank, dass Sie uns kontaktiert haben!",
  es: "¡Gracias por comunicarse con nosotros!",
  it: "Grazie per averci contattato!",
  ja: "お問合せいただきありがとうございます",
  ko: "연락 주셔서 감사합니다!",
  nl: "Bedankt dat u contact opgenomen hebt!",
  "pt-br": "Obrigado por entrar em contato conosco!",
  "pt-pt": "Obrigado por nos contactar!",
  sv: "Tack för att du kontaktar oss!",
  zh: "感谢您联系我们！",
};

export const BUSINESS_REQUEST_DEMO_SUCCESS_HERO_DESCRIPTION = {
  en: "One of our account executives will be in touch soon.",
  fr: "Vous serez bientôt contacté par l'un de nos responsables de la gestion des comptes.",
  de: "Einer unserer Kundenbetreuer wird sich in Kürze mit Ihnen in Verbindung setzen.",
  es: "En breve uno de nuestros ejecutivos de cuentas se pondrá en contacto.",
  it: "Presto verrai contattato da uno dei nostri account executive.",
  ja: "弊社のアカウントエグゼクティブよりご連絡差し上げます。",
  ko: "계정 관리자 중 한 명이 곧 연락할 것입니다.",
  nl: "Een van onze accountmanagers neemt spoedig contact met u op.",
  "pt-br": "Um dos nossos executivos de conta entrará em contato em breve.",
  "pt-pt": "Um dos nossos executivos de contas entrará em contacto brevemente.",
  sv: "Någon av våra kontoansvariga kommer att höra av sig snart.",
  zh: "我们的一位客户经理将很快与您联系。",
};

export const BUSINESS_REQUEST_DEMO_SUCCESS_HERO_TITLE = {
  en: "Thanks for requesting a demo!",
  fr: "Nous vous remercions de votre demande !",
  de: "Vielen Dank, dass Sie eine Demo angefordert haben!",
  es: "Gracias por solicitar una demostración",
  it: "Grazie per aver richiesto una demo!",
  ja: "デモをリクエストいただきありがとうございます。",
  ko: "데모를 요청해 주셔서 감사합니다!",
  nl: "Bedankt voor het aanvragen van een demo!",
  "pt-br": "Obrigado por solicitar uma demonstração!",
  "pt-pt": "Obrigado pelo pedido de uma demonstração!",
  sv: "Tack för att du vill prova en demoversion!",
  zh: "感谢您请求演示！",
};

export const BUSINESS_TRY_EMAILCONFIRMATION_ERROR_DESCRIPTION = {
  en: 'If you did not receive our email, please check your spam folder in your mailbox or <a href="%(supportLink)">submit a support request</a> to our Customer Support team.',
  fr: 'Si ce message est introuvable, veuillez vérifier la section Courrier indésirable de votre boîte de réception ou <a href="%(supportLink)">envoyez-nous une requête d\'assistance</a>.',
  de: 'Wenn Sie unsere E-Mail nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner oder <a href="%(supportLink)">stellen Sie eine Supportanfrage</a> an unseren Kundendienst.',
  es: 'Si no recibió nuestro correo electrónico, revise la carpeta de correo no deseado de su bandeja de entrada o <a href="%(supportLink)">envíe una solicitud de asistencia técnica</a> a nuestro equipo de asistencia técnica al cliente.',
  it: 'Se non hai ricevuto la nostra e-mail, controlla la cartella della posta indesiderata o <a href="%(supportLink)">invia una richiesta</a> al nostro team di assistenza.',
  ja: 'メールが届かない場合は、迷惑メールフォルダをご確認いただくか、カスタマーサポートチームに<a href="%(supportLink)">サポートリクエストを送信</a>してください。',
  ko: '이메일을 받지 못하셨다면 메일함의 스팸 폴더를 살펴보거나 저희 고객지원팀에 <a href="%(supportLink)">지원 요청을 제출</a>하세요.',
  nl: 'Als u onze e-mail niet heeft ontvangen, controleer dan uw spam-map of <a href="%(supportLink)">dien een ondersteuningsverzoek in</a> bij onze klantenservice.',
  "pt-br":
    'Se você não recebeu nosso e-mail, confira sua pasta de spam, ou <a href="%(supportLink)">envie uma solicitação de suporte</a> para nossa equipe de suporte ao cliente.',
  "pt-pt":
    'Se não recebeu o nosso e-mail, verifique a pasta de spam na sua caixa de correio ou <a href="%(supportLink)">envie um pedido de apoio</a> à nossa equipa de Apoio ao Cliente.',
  sv: 'Om du inte har fått något meddelande från oss kan du titta efter i skräppostmappen eller <a href="%(supportLink)">skicka in en supportbegäran</a> till vår kundsupport.',
  zh: '如果您没有收到我们的电子邮件，请检查您邮箱中的垃圾邮件文件夹，或向我们的客户支持团队<a href="%(supportLink)">提交支持请求</a>。',
};

export const BUSINESS_TRY_EMAILCONFIRMATION_ERROR_TITLE = {
  en: "Something went wrong on our end.",
  fr: "Nous avons rencontré une erreur.",
  de: "Auf unserer Seite ist etwas schiefgegangen.",
  es: "Se produjo un error",
  it: "Qualcosa è andato storto nei nostri sistemi.",
  ja: "こちら側で問題が発生しました。",
  ko: "문제가 발생했습니다.",
  nl: "Er is iets fout gegaan.",
  "pt-br": "Ocorreu um problema por aqui.",
  "pt-pt": "Algo correu mal do nosso lado.",
  sv: "Något gick fel hos oss.",
  zh: "我们这边出问题了。",
};

export const PRIVACY_SETTINGS_STATUS = {
  en: "Status:",
  fr: "Statut :",
  de: "Status:",
  es: "Estado:",
  it: "Stato:",
  ja: "ステータス：",
  ko: "상태:",
  nl: "Status:",
  "pt-br": "Status:",
  "pt-pt": "Estado:",
  sv: "Status:",
  zh: "状态：",
};

export const PRIVACY_SETTINGS_INACTIVE = {
  en: "Inactive",
  fr: "Désactivés",
  de: "Nicht aktiv",
  es: "Inactivas",
  it: "Non attivo",
  ja: "非アクティブ",
  ko: "비활성화",
  nl: "Inactief",
  "pt-br": "Inativo",
  "pt-pt": "Inativo",
  sv: "Inaktiv",
  zh: "非活动状态",
};

export const PRIVACY_SETTINGS_ACTIVE = {
  en: "Active",
  fr: "Activé",
  de: "Aktiviert",
  es: "Activado",
  it: "Attivo",
  ja: "有効",
  ko: "활성화",
  nl: "Actief",
  "pt-br": "Ativo",
  "pt-pt": "Ativo",
  sv: "Aktiverad",
  zh: "活动",
};

export const PRIVACY_SETTINGS_ALWAYS = {
  en: "Always active",
  fr: "Toujours activés",
  de: "Immer aktiv",
  es: "Siempre activas",
  it: "Sempre attivi",
  ja: "常にアクティブ",
  ko: "항상 활성화",
  nl: "Altijd actief",
  "pt-br": "Sempre ativo",
  "pt-pt": "Sempre ativo",
  sv: "Alltid aktiv",
  zh: "始终处于活动状态",
};
