// @TODO are there any other categories?
import { Locale, MarketoFormValues } from "@/types";
import { munchkinImposter } from "@/utilities/marketo/munchkinImposter";
import { gaGenerateLead } from "@/utilities/dataLayer";
import {
  DEMO_FORM_ID,
  SALES_FORM_ID,
  TRIAL_FORM_ID,
} from "@/constants/formIds";

const LEAD_CATEGORY = "B to B";

export const sendLeadToMarketo = (
  values: MarketoFormValues,
  formId: number,
  locale: Locale,
  redirect: string,
  setIsSubmitting: (isSubmitting: boolean) => void,
  setFormError: (formError: boolean) => void
) => {
  setFormError(false);

  values.Lead_Category__c = values.PartnershipsType
    ? values.PartnershipsType
    : LEAD_CATEGORY;
  values.Language_Preference__c = locale;
  values.Marketo_Form_ID = formId;

  const originalPlan = values.product_plan__c;

  if (values.product_plan__c) {
    values.product_plan__c = `Dashlane ${
      values.product_plan__c.charAt(0).toUpperCase() +
      values.product_plan__c.slice(1)
    }`;
  }

  const result = munchkinImposter.sendLead(values, formId, redirect, () => {
    if (window.dataLayer) {
      switch (formId) {
        // Business Trial
        case TRIAL_FORM_ID:
          gaGenerateLead({
            formName: "business-try-form",
            planType: originalPlan,
          });
          break;

        // Contact Sales
        case SALES_FORM_ID:
          gaGenerateLead({
            formName: "business-enterprise-form",
          });
          break;

        // Request Demo
        case DEMO_FORM_ID:
          gaGenerateLead({
            formName: "request-demo-form",
          });
          break;
      }
    }

    window.location.href = redirect;
  });

  if (result) {
    //Success
    window.location.href = redirect;
  } else {
    //Error
    setFormError(true);
    setIsSubmitting(false);
  }
};
