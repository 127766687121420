export const RESOURCE_SEARCH_FOUND_RESULTS_CATEGORY = {
  "en": "in \"%(category)\"",
  "fr": "in \"%(category)\"",
  "de": "in \"%(category)\"",
  "es": "in \"%(category)\"",
  "pt-br": "in \"%(category)\"",
  "it": "in \"%(category)\"",
  "ja": "in \"%(category)\"",
  "ko": "in \"%(category)\"",
  "nl": "in \"%(category)\"",
  "pt-pt": "in \"%(category)\"",
  "sv": "in \"%(category)\"",
  "zh": "in \"%(category)\""
}