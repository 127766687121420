export const BUSINESS_TRIAL_SUCCESS_SELECT_PROVIDER = {
  "en": "Please select your provider to confirm your email.",
  "fr": "Veuillez sélectionner votre fournisseur pour confirmer votre e-mail",
  "de": "Bitte wählen Sie Ihren Anbieter aus, um Ihre E-Mail-Adresse zu bestätigen.",
  "es": "Seleccione su proveedor para confirmar su correo electrónico.",
  "pt-br": "Please select your provider to confirm your email.",
  "it": "Please select your provider to confirm your email.",
  "ja": "Please select your provider to confirm your email.",
  "ko": "Please select your provider to confirm your email.",
  "nl": "Please select your provider to confirm your email.",
  "pt-pt": "Please select your provider to confirm your email.",
  "sv": "Please select your provider to confirm your email.",
  "zh": "Please select your provider to confirm your email."
}