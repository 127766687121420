import { PropsWithChildren } from "react";

import RichText from "@/components/atoms/RichText";
import cn from "classnames";

interface Props {
  align?: "left" | "center" | "right";
  citation?: string;
  className?: string;
  id?: string;
  value?: string;
}

export default function Quote({
  align = "left",
  children,
  citation,
  className,
  id,
  value,
}: PropsWithChildren<Props>) {
  return (
    <figure
      id={id}
      className={cn(
        "my-3 border-dashgreen-text px-10 py-6",
        align === "left" && "border-l-2",
        align === "center" && "text-center",
        align === "right" && "text-right",
        className
      )}
    >
      {value && (
        <RichText tag="blockquote" className="mb-8 text-xl">
          {value}
        </RichText>
      )}
      {!value && children}
      {citation && (
        <RichText tag="figcaption" className="font-bold">
          {citation}
        </RichText>
      )}
    </figure>
  );
}
