import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import getJumpLinkId from "@/utilities/getJumpLinkId";
import {
  backgroundNeutral,
  borderBrand,
  h1Text,
  pTextL,
  textBodyNeutral,
  pTextSm,
} from "@/constants/standardCSSClasses";
import { RichText, SearchForm } from "@/components/atoms";
import { htmlDecode } from "@/utilities/htmlDecode";
import { useRouterBasePath } from "@/hooks/useRouterBasePath";

const CUTOFF_ONE = 70;
const CUTOFF_TWO = 150;
const CUTOFF_THREE = 250;
const ACTIVE_CUTOFF = 250;

export interface JumpLinkInterface {
  attributes: {
    name: string;
  };
}

export interface JumpLinkSubnavInterface {
  jumpLinks: Array<JumpLinkInterface>;
  excerpt: string;
  title: string;
  className?: string;
}

const JumpLinkSubnav = ({
  jumpLinks,
  excerpt,
  title,
  className,
}: JumpLinkSubnavInterface) => {
  const basePath = useRouterBasePath();
  const isResourceHub = basePath === "/resources";
  const [scrollTop, setScrollTop] = useState(0);
  const [active, setActive] = useState("");
  const [navHeight, setNavHeight] = useState("330px");
  const [searchFocus, setSearchFocus] = useState(false);

  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.scrollY; // or use document.documentElement.scrollTop;
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);

      let active = "";
      jumpLinks.forEach((each) => {
        const elementTarget = document.getElementById(
          getJumpLinkId(each.attributes.name)
        );
        if (
          elementTarget &&
          window.scrollY >
            elementTarget.offsetTop + elementTarget.offsetHeight - ACTIVE_CUTOFF
        ) {
          active = getJumpLinkId(each.attributes.name);
        }
      });

      setActive(active);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (navRef.current) {
      setNavHeight(`${navRef.current.scrollHeight}px`);
    }
  }, []);

  return (
    <>
      <div style={{ height: navHeight }} />
      <div
        ref={navRef}
        className={cn(
          backgroundNeutral,
          "fixed top-[61px] z-20 w-full border-b border-l-0 border-r-0 border-t-0 border-solid transition-all",
          {
            "pt-24": searchFocus || scrollTop < CUTOFF_ONE,
            "pt-8":
              !searchFocus &&
              scrollTop >= CUTOFF_ONE &&
              scrollTop < CUTOFF_THREE,
            "pt-0": !searchFocus && scrollTop >= CUTOFF_THREE,
          }
        )}
      >
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <RichText
            className={cn(h1Text, "transition-all", {
              "max-h-0 overflow-hidden":
                !searchFocus && scrollTop >= CUTOFF_THREE,
              "mb-0": !searchFocus && scrollTop >= CUTOFF_THREE,
            })}
            tag="h1"
          >
            {title}
          </RichText>
          <RichText
            className={cn(
              pTextL,
              textBodyNeutral,
              "block max-w-[405px] overflow-hidden transition-all",
              {
                "mb-14":
                  !isResourceHub &&
                  scrollTop < CUTOFF_ONE &&
                  scrollTop < CUTOFF_TWO,
                "mb-4":
                  searchFocus ||
                  (isResourceHub &&
                    scrollTop < CUTOFF_ONE &&
                    scrollTop < CUTOFF_TWO),
                "mt-6":
                  searchFocus ||
                  (scrollTop < CUTOFF_ONE && scrollTop < CUTOFF_TWO),
                "mb-4 mt-4":
                  !searchFocus &&
                  scrollTop >= CUTOFF_ONE &&
                  scrollTop < CUTOFF_TWO,

                "max-h-0": !searchFocus && scrollTop >= CUTOFF_TWO,
                "mb-0 mt-0 pb-0": !searchFocus && scrollTop >= CUTOFF_TWO,
              }
            )}
            tag="span"
          >
            {excerpt}
          </RichText>
          {isResourceHub && (
            <SearchForm
              className={cn("max-w-xl overflow-hidden transition-all", {
                "max-h-0": !searchFocus && scrollTop >= CUTOFF_TWO,
                "mb-0 mt-0 pb-0": !searchFocus && scrollTop >= CUTOFF_TWO,
                "mb-6": searchFocus || scrollTop < CUTOFF_TWO,
              })}
              path="/search"
              setSearchFocusParent={setSearchFocus}
              filters={{ resourceType: true, resourceCategory: true }}
            />
          )}
        </div>
        <nav
          className={cn(
            "mx-auto -mb-[1px] max-w-7xl overflow-x-auto whitespace-nowrap px-4 md:px-8",
            className
          )}
        >
          {jumpLinks.map((each: JumpLinkInterface) => {
            return (
              <a
                key={each.attributes.name}
                className={cn(
                  "me-8 inline-block py-5 hover:no-underline md:me-[59px]",
                  borderBrand,
                  textBodyNeutral,
                  pTextSm,
                  {
                    "border-b border-l-0 border-r-0 border-t-0 border-solid":
                      active === getJumpLinkId(each.attributes.name),
                  }
                )}
                href={`#${getJumpLinkId(each.attributes.name)}`}
              >
                {htmlDecode(each.attributes.name)}
              </a>
            );
          })}
        </nav>
      </div>
    </>
  );
};

export default JumpLinkSubnav;
