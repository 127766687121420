import React from "react";
import classnames from "classnames";
import CustomButton, { CustomButtonInterface } from "./CustomButton";
import { marginTLg } from "@/constants/standardCSSClasses";

interface ButtonGroupInterface {
  align: string;
  buttons: Array<CustomButtonInterface> | null;
  className?: string;
  marginTop?: string;
  gap?: string;
  mobileWide?: boolean;
  column?: boolean;
  downloadAndOpenTab?: boolean;
}

const CustomButtonGroup = ({
  align,
  buttons,
  className,
  mobileWide = true,
  marginTop = marginTLg,
  gap = "",
  column = false,
  downloadAndOpenTab = false,
}: ButtonGroupInterface) => {
  if (!buttons) return null;

  const classNames = classnames(className, marginTop, "flex flex-col", {
    "justify-start": align === "left" || align === "start",
    "justify-center": align === "center",
    "justify-end": align === "right" || align === "end",
    "md:flex": mobileWide,
    flex: !mobileWide,
    "md:flex-row": !column,
    [gap]: gap,
  });

  return (
    <div className={classNames}>
      {buttons.map((each, index) => {
        each.className = classnames(each.className, {
          "mt-2": index !== 0 && !gap && !each.className?.includes("mt-"),
          "md:mt-0 md:ms-4":
            index !== 0 &&
            !column &&
            !gap &&
            !each.className?.includes("md:mt-"),
        });
        each.mobileWide = mobileWide;
        return (
          <CustomButton
            key={index}
            downloadAndOpenTab={downloadAndOpenTab}
            {...each}
          />
        );
      })}
    </div>
  );
};

export default CustomButtonGroup;
