import React from "react";
import Link from "next/link";
import cn from "classnames";

import { Heading } from "@/components/atoms";
import { Button, ReadMore } from "@/components/molecules";
import type { PostPreview } from "@/types";
import { useTranslate } from "@/hooks/useTranslate";
import { POST_PREVIEW_SECTION_SEE_MORE } from "@/i18n/translations/POST_PREVIEW_SECTION_SEE_MORE";
import CustomTag from "@/components/molecules/CustomTag";
import CustomImage from "@/components/molecules/CustomImage";
import { clock } from "@/constants/icons";

interface Props {
  hideExcerpt?: boolean;
  hideImage?: boolean;
  moreLink?: string;
  posts: PostPreview[];
  readMore?: boolean;
  topBorderColor?: string;
}

export default function PostPreviewPosts({
  hideExcerpt = false,
  hideImage = false,
  moreLink,
  posts,
  readMore = false,
  topBorderColor,
}: Props) {
  const { translate } = useTranslate();

  return (
    <>
      <div
        data-cy="posts-preview-grid"
        className="mt-8 grid gap-x-8 gap-y-16 md:grid-cols-3"
      >
        {posts.map((post) => {
          return (
            <div
              key={post.uri}
              className={cn(
                topBorderColor === "midgreen" &&
                  "border-t-2 border-midgreen pt-2",
                topBorderColor === "orange" &&
                  "border-t-2 border-orange-500 pt-2",
                topBorderColor === "pink" && "border-t-2 border-pink-bold pt-2",
                "w-full"
              )}
            >
              <Link
                target={!post.uri.startsWith("/") ? "_blank" : ""}
                href={post.uri}
              >
                {!hideImage && (
                  <CustomImage
                    full={
                      post.featuredImage?.node.sourceUrl ??
                      "/images/blog-post-default.webp"
                    }
                    alt={
                      post.featuredImage?.node.altText ??
                      "A green and blue background with three blue slanted rectangles in the middle. A white Dashlane logo is overlaid on top."
                    }
                    className="mb-8 overflow-hidden rounded"
                    aspect="contentCard"
                  />
                )}
              </Link>
              {(post?.categories?.nodes?.length || post?.eventDate) && (
                <div className="flex items-center justify-between">
                  {post?.categories?.nodes?.length && (
                    <div className="flex gap-3">
                      {post?.categories?.nodes?.map((each, index) => {
                        // Max of 2
                        if (index > 1) return null;
                        return (
                          <CustomTag
                            key={index}
                            variant="dark"
                            link={each.uri}
                            name={each.name}
                          />
                        );
                      })}
                    </div>
                  )}
                  {post?.eventDate && (
                    <div className="mb-4 flex items-center text-sm text-blue-8">
                      <span className="mr-1 inline-block [&>svg]:fill-blue-8">
                        {clock}
                      </span>
                      {/* Suppress warning since TZ will cause hydration errors */}
                      <span suppressHydrationWarning>{post.eventDate}</span>
                    </div>
                  )}
                </div>
              )}
              <Link
                href={post.uri}
                className="text-dashgreen-text transition hover:text-midgreen hover:no-underline"
              >
                <Heading
                  level="h3"
                  className="mb-4 line-clamp-2 text-ellipsis text-xl hover:underline"
                >
                  {post.title}
                </Heading>
                {!hideExcerpt && post.excerpt && (
                  <div
                    className="line-clamp-3"
                    dangerouslySetInnerHTML={{ __html: post.excerpt }}
                  />
                )}
              </Link>
              {readMore && <ReadMore href={post.uri} />}
            </div>
          );
        })}
      </div>
      {moreLink && (
        <div className="text-center">
          <div className="mx-auto mt-12 inline-block">
            <Button href={moreLink}>
              {translate(POST_PREVIEW_SECTION_SEE_MORE)}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
