import React from "react";
import cn from "classnames";
import {
  belowButtonText,
  textBodyInverse,
  textBodyNeutral,
} from "@/constants/standardCSSClasses";
import { RichText } from "@/components/atoms";

interface AboveHeadingInterface {
  children: string | React.ReactNode;
  className?: string;
  variant?: string;
  centered?: boolean;
  mobileAlign?: string;
}

const BelowButtons = ({
  children,
  className,
  variant,
  centered = false,
  mobileAlign = "center",
}: AboveHeadingInterface) => {
  return (
    <RichText
      className={cn(belowButtonText, className, {
        [textBodyInverse]: variant === "dark",
        [textBodyNeutral]: variant !== "dark",
        "md:text-left": !centered,
        [`text-${mobileAlign}`]: mobileAlign,
      })}
      tag="p"
    >
      {children}
    </RichText>
  );
};

export default BelowButtons;
