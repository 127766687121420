/* eslint-disable @typescript-eslint/naming-convention */
import Url from "domurl";
import cookies from "js-cookie";

import getCookieDomain from "@/utilities/getCookieDomain";

interface UrlParams {
  utm_source?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_content?: string;
  utm_term?: string;
  utm_visitTimestamp?: string;
  nan_pid: string;
  gclid: string | null;
  gclsrc: string | null;
  transaction_id: string;
  postback: "true" | "false";
}

const userFunnel = {
  now() {
    return +new Date();
  },

  getUrl: () => {
    return new Url<UrlParams>();
  },

  saveUserFunnelCookie(
    Cookies: cookies.CookieAttributes,
    isProduction: boolean,
    localCookie: cookies.CookieAttributes
  ) {
    Cookies.set("userFunnelCookie", JSON.stringify(localCookie), {
      expires: 365,
      path: "/",
      secure: isProduction,
      // Set to "None" for new rules in Chrome 84+ allowing
      // to work in context of an iframe for SAEX
      // https://web.dev/samesite-cookies-explained/
      // Keep it 'Strict' in debug mode, as 'None' has to be over HTTPS.
      sameSite: isProduction ? "None" : "Strict",
      domain: getCookieDomain(window.location.hostname),
    });
  },

  buildLocalCookie(
    currentUrl: Url<any>,
    userFunnelCookie: cookies.CookieAttributes,
    customTrackingUtms: Record<string, unknown>
  ) {
    let localCookie;
    if (typeof userFunnelCookie !== "undefined") {
      localCookie = Object.assign({}, userFunnelCookie);
    } else {
      localCookie = {};
    }

    const nowTimestamp = this.now();
    // A session is 30 minutes
    const halfHourAgoTimestamp = nowTimestamp - 30 * 60 * 1000;

    // Get utm params
    // TODO: add the customTrackingUtms to the below logic
    // customTrackingUtms would have 2nd priority

    const utm_source =
      currentUrl.query.utm_source || customTrackingUtms.utm_source || "";
    const utm_campaign =
      currentUrl.query.utm_campaign || customTrackingUtms.utm_campaign || "";
    const utm_medium =
      currentUrl.query.utm_medium || customTrackingUtms.utm_medium || "";
    const utm_content =
      currentUrl.query.utm_content || customTrackingUtms.utm_content || "";
    const utm_term =
      currentUrl.query.utm_term || customTrackingUtms.utm_term || "";

    if (typeof userFunnelCookie !== "undefined") {
      localCookie.currentPath = currentUrl.path;
    }

    // Create the cookie if it's been cleared or if it doesn't exist
    if (typeof userFunnelCookie === "undefined") {
      localCookie = {
        firstVisit: nowTimestamp,
        utm_visitTimestamp: nowTimestamp,
        pathname: currentUrl.path,
        referrer: document.referrer,
        utm_source,
        utm_campaign,
        utm_medium,
        utm_content,
        utm_term,
        downloadId: 0,
      };
    }

    // The following information is only kept during a session
    const hasSessionExpired =
      !localCookie.lastVisitTimestamp ||
      localCookie.lastVisitTimestamp < halfHourAgoTimestamp;

    this.addPaidTrackingParameters(
      localCookie,
      currentUrl,
      userFunnelCookie,
      nowTimestamp,
      hasSessionExpired
    );

    if (hasSessionExpired || utm_source) {
      localCookie.last_click_pathname = currentUrl.path;
      localCookie.last_click_referrer = document.referrer;
      localCookie.last_click_source = utm_source;
      localCookie.last_click_campaign = utm_campaign;
      localCookie.last_click_medium = utm_medium;
      localCookie.last_click_content = utm_content;
      localCookie.last_click_term = utm_term;
      localCookie.last_click_visitTimestamp = nowTimestamp;

      // Check if the app is currently the source
      const isApputm_source = utm_source === "app";

      // The Dashlane client during webonboarding changes the
      // utm_source to app which prevents us from being able
      // to attribute paid after account creation so we must
      // keep the previous utm values somewhere else.
      localCookie.last_click_preapp_source = isApputm_source
        ? localCookie.last_click_preapp_source
        : utm_source;
    }

    // Reset the cookie with the latest information
    localCookie.lastVisitTimestamp = nowTimestamp;

    return localCookie;
  },

  addPaidTrackingParameters(
    localCookie: cookies.CookieAttributes,
    currentUrl: Url<UrlParams>,
    userFunnelCookie: cookies.CookieAttributes,
    nowTimestamp: number,
    hasSessionExpired: boolean
  ) {
    // Get partner params for attribution
    const nan_pid = currentUrl.query.nan_pid; // Nanigans
    let gclid = currentUrl.query.gclid; // Google
    const gclsrc = currentUrl.query.gclsrc; // Google
    const transaction_id = currentUrl.query.transaction_id; // Everflow
    const postback = currentUrl.query.postback === "true"; // Google Parallel Tracking filter

    // According to https://support.google.com/adwords/answer/2998031#setupoct
    // we have to filter out gclid when gclsrc doesn't contain 'aw'
    if (gclid && gclsrc && !gclsrc.includes("aw")) {
      gclid = null;
    }

    // Generate and keep a trackingId only if the user has agreed to it.
    // [2020-09-21] Update: Used to be restricted by 'fullTracking', but we decided to allow tracking Id even if the user has not accepted the cookies.
    if (
      typeof userFunnelCookie === "undefined" ||
      !userFunnelCookie.trackingId
    ) {
      localCookie.trackingId = `kw_${nowTimestamp}-${Math.round(
        Math.random() * 100000
      )}`;
    }
    /* if (!trackUserId) {
      delete localCookie.trackingId;
    } */

    if (hasSessionExpired || nan_pid) {
      localCookie.nan_pid = nan_pid;
    }
    if (hasSessionExpired || gclid) {
      localCookie.gclid = gclid;
    }
    if (hasSessionExpired || transaction_id) {
      localCookie.transaction_id = transaction_id;
    }
    if (hasSessionExpired || postback) {
      localCookie.postback = postback;
    }
  },

  storeDownloadId(
    Cookies: cookies.CookieAttributes,
    isProduction: boolean,
    downloadId: number
  ) {
    const userFunnelCookie = JSON.parse(
      Cookies.get("userFunnelCookie") || "{}"
    );

    if (userFunnelCookie) {
      userFunnelCookie.downloadId = downloadId;
      this.saveUserFunnelCookie(Cookies, isProduction, userFunnelCookie);
    }
  },

  init(Cookies: cookies.CookieAttributes, isProduction: boolean) {
    try {
      // Override trackingConfig if defined in the pageConfig.
      // const trackingConfig = window.pageConfig.trackingConfig;
      const userFunnelCookie = Cookies.get("userFunnelCookie");
      const parsedFunnelCookie = userFunnelCookie
        ? JSON.parse(userFunnelCookie)
        : userFunnelCookie;

      const currentUrl = this.getUrl();

      // TODO - Add support for custom tracking and paidTracking

      // const customTrackingUtms = paidTracking.getCustomUTMsForPage(
      //   trackingConfig,
      //   window.pageConfig.identifier
      // );

      const localCookie = this.buildLocalCookie(
        currentUrl,
        parsedFunnelCookie,
        {} // customTrackingUtms
      );

      this.saveUserFunnelCookie(Cookies, isProduction, localCookie);
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = error.message;

        console.error(
          "Error during websiteNew-userFunnelCookie computation",
          errorMessage
        );
      }
    }
  },
};

export default userFunnel;
