import { MenuItem } from "@/types";

export const getMenuItemIds = (
  items: MenuItem,
  ids: Array<string> = []
): Array<string> => {
  if (items?.id) {
    ids = ids.concat(items.id);
  }
  if (items?.children) {
    items.children.forEach((each: any) => {
      ids = ids.concat(getMenuItemIds(each, ids));
    });
  }

  return ids;
};
