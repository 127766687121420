export const ARCHIVE_NO_RESULTS = {
  "en": "It seems we can't find what you're looking for.",
  "fr": "Il semble que nous ne puissions pas trouver ce que vous cherchez.",
  "de": "Anscheinend können wir nicht finden, wonach Sie suchen.",
  "es": "Parece que no podemos encontrar lo que busca.",
  "pt-br": "It seems we can't find what you're looking for.",
  "it": "It seems we can't find what you're looking for.",
  "ja": "It seems we can't find what you're looking for.",
  "ko": "It seems we can't find what you're looking for.",
  "nl": "It seems we can't find what you're looking for.",
  "pt-pt": "It seems we can't find what you're looking for.",
  "sv": "It seems we can't find what you're looking for.",
  "zh": "It seems we can't find what you're looking for."
}